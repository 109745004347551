import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { APP_CONFIG } from '@apis/app-config';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SharedModule } from '@apis/shared/shared.module';
import { SideNavComponent } from './shared/components/side-nav/side-nav.component';
import { AdjudicationService } from './shared/services/adjudication.service';
import { ContraventionListingComponent } from './contravention-listing/contravention-listing.component';
import { ContraventionDetailsModule } from "./contravention-details/contravention-details.module";
import { SeizureDetailsModule } from "./seizure-details/seizure-details.module";
import { ReviewDetailsModule } from "./review-details/review-details.module";
import { RequestDetailsModule } from "./request-details/request-details.module";
import { ReviewListingComponent } from "./review-listing/review-listing.component";
import { ReviewTypePipe } from "./shared/pipes/review-type.pipe";
import { ReviewMethodTypePipe } from "./shared/pipes/review-method-type.pipe";
import { ReviewStatusTypePipe } from "./shared/pipes/review-status-type.pipe";
import { AdjudicationHeaderComponent } from './shared/components/adjudication-header/adjudication-header.component';
import { AdjudicationFooterComponent } from './shared/components/adjudication-footer/adjudication-footer.component';
import { FinanceComponent } from './finance/finance.component';
import { RequestListingComponent } from './request-listing/request-listing.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component'
import { StatusCheckboxesComponent } from './shared/components/status-checkboxes/status-checkboxes.component';
import { RequestService } from './shared/services/request.service';
import { SeizureListingComponent } from './seizure-listing/seizure-listing.component';
import { VehicleSeizureService } from './shared/services/vehicle-seizure.service';
import {initializeKeycloak} from './shared/security/keycloak.init';
import { APP_INITIALIZER } from '@angular/core';
import { KeycloakAngularModule } from 'keycloak-angular';
import { KeycloakService } from 'keycloak-angular';
import { ReviewService } from "./shared/services/review.service";
import { ActionCheckboxesComponent } from './shared/components/action-checkboxes/action-checkboxes.component';
import { ReviewRequestExtensionModalComponent } from './shared/components/modals/review-request-extension-modal/review-request-extension-modal.component';
import { CustomHttpInterceptor } from './shared/interceptors/custom-http-interceptor';
import { SpinnerHttpInterceptor } from 'libs/shared/interceptors/spinner-http-interceptor';
import { ScheduledMaintenanceComponent } from './scheduled-maintenance/scheduled-maintenance.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { EnableLateReviewModalComponent } from './shared/components/modals/enable-late-review-modal/enable-late-review-modal.component';
import { DashboardModule } from "./dashboard/dasboard.module";
import { SchedulerService } from './shared/services/scheduler.service';
import { ReportService } from './shared/services/report.service';
import { JudicialReviewListingComponent } from './judicial-review-listing/judicial-review-listing.component';
import { JudicialReviewDetailsModule } from "./judicial-review-details/judicial-review-details.module";
import { ReReviewDetailsComponent } from './re-review-details/re-review-details.component';
import { TrafficDisclosureComponent } from './traffic-disclosure/traffic-disclosure.component';
import { DisclosureService } from "./shared/services/disclosure.service";
import { ReportsComponent } from './reports/reports.component';
import { ReviewCancellationReportComponent } from './reports/components/review-cancellation-report.component';
import { ContraventionsExtractReportComponent } from './reports/components/contraventions-extract-report.component';
import { SeizuresExtractReportComponent } from './reports/components/seizures-extract-report.component';
import { ReviewExtractReportComponent } from './reports/components/review-extract-report.component';
import { LogRefundModalComponent } from './shared/components/modals/log-refund-modal/log-refund-modal.component';
import { LogService } from "./shared/services/log.service";
import { EnableReviewModalComponent } from './shared/components/modals/enable-review-modal/enable-review-modal.component';
import { TowCompaniesComponent } from './tow-companies/tow-companies.component';

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    ContraventionListingComponent,
    ReviewListingComponent,
    ReviewTypePipe,
    ReviewMethodTypePipe,
    ReviewStatusTypePipe,
    AdjudicationHeaderComponent,
    AdjudicationFooterComponent,
    FinanceComponent,
    RequestListingComponent,
    PaginationComponent,
    StatusCheckboxesComponent,
    SeizureListingComponent,
    ActionCheckboxesComponent,
    ReviewRequestExtensionModalComponent,
    ScheduledMaintenanceComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    EnableLateReviewModalComponent,
    JudicialReviewListingComponent,
    ReReviewDetailsComponent,
    TrafficDisclosureComponent,
    ReportsComponent,
    ReviewCancellationReportComponent,
    ContraventionsExtractReportComponent,
    SeizuresExtractReportComponent,
    ReviewExtractReportComponent,
    LogRefundModalComponent,
    EnableReviewModalComponent,
    TowCompaniesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FlexLayoutModule,
    ContraventionDetailsModule,
    SeizureDetailsModule,
    ReviewDetailsModule,
    RequestDetailsModule,
    DashboardModule,
    KeycloakAngularModule,
    JudicialReviewDetailsModule
  ],
  providers: [
    { provide: APP_CONFIG, useValue: environment},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: SpinnerHttpInterceptor, 
      multi: true 
    },
    AdjudicationService,
    RequestService,
    VehicleSeizureService,
    ReviewService,
    SchedulerService,
    ReportService,
    DisclosureService,
    LogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
