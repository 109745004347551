<div class="d-flex flex-column h-100">
    <div class="header">
        <div class="top-header">
            <div class="d-flex">Seizure {{seizureNumber}} <span
                    [ngClass]="getSeizureClass(vehicleSeizure?.seizureStatusTypeId)">
                    {{localStorageService.getTypeItemDescriptionById(vehicleSeizure?.seizureStatusTypeId,
                    TypeTable.SeizureStatusType)}}</span>
                <span *ngIf="vehicleSeizure?.isRecipientYouth" class="youth-flag">YOUTH</span>
                <div *ngIf="vehicleSeizure?.judicialReviews.length > 0" class="jr-info">
                    <img class="jr-info-flag" src="assets/images/triangular-flag-white.svg" />
                    JR Served
                </div>
                <div *ngIf="isAnotherSeizureExists" class="additional-seizure-info">
                    <img class="additional-seizure-info-flag" src="assets/images/triangular-flag.svg" />
                    {{ anotherSeizureTypeId == SeizureTypes.IRSContraventionSelected? "IRS Seizure (" +
                    anotherSeizureStatus + ")": "Suspended Driver Seizure (" + anotherSeizureStatus + ")" }}
                </div>
            </div>
            <select id="manageSeizure" class="manage-seizure form-control" (change)="onManageSeizureChange($event)">
                <option value="0">Manage seizure...</option>
                <option
                    *ngIf="(vehicleSeizure?.seizureTypeId == SeizureTypes.SuspendedDriversProgramFirstOccurrence || vehicleSeizure?.seizureTypeId == SeizureTypes.SuspendedDriversProgramSecondOccurrence) && canImmediateCancel"
                    value="1">Cancel Seizure</option>
                <option value="2" *ngIf="canReviewRequestExtension" [disabled]="disableExtendReviewDeadline">Review
                    Request Extension</option>
            </select>
        </div>
        <div *ngIf="isRedactionRequired" class="seizure-alert">
            <div class="alert-icon">
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="flex-fill pl-4">This seizure contains supporting documents that may require redaction</div>
            <a (click)="onViewClick(scrolltarget)">View</a>
        </div>
    </div>
    <div class="main">
        <div class="summary-section" *ngIf="vehicleSeizure != null">
            <div class="summary-header">Summary</div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/contravention-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Seizure Type</div>
                        <div class="summary-item-value">{{vehicleSeizure?.seizureTypeId==1?
                            localStorageService.getTypeItemDescriptionById(contravention?.contraventionTypeId,
                            TypeTable.ContraventionType) :
                            localStorageService.getTypeItemDescriptionById(vehicleSeizure?.seizureTypeId,
                            TypeTable.SeizureType)}}</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Recipient</div>
                        <div class="summary-item-value">
                            {{vehicleSeizure?.seizureTypeId==1 ? contravention?.recipient?.firstName + " " +
                            (contravention?.recipient?.otherName==null? "" : contravention?.recipient?.otherName + " ")
                            + contravention?.recipient?.lastName : vehicleSeizure?.recipient?.firstName + " " +
                            (vehicleSeizure?.recipient?.otherName==null? "" : vehicleSeizure?.recipient?.otherName + "
                            ") + vehicleSeizure?.recipient?.lastName}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Registered Owner</div>
                        <div class="summary-item-value">
                            {{vehicleSeizure?.vehicle?.isNoRegisteredOwner?"Unable to determine":
                                (vehicleSeizure?.vehicle?.registeredOwner?.firstName + " " +
                            (vehicleSeizure?.vehicle?.registeredOwner?.otherName==null? "" :
                            vehicleSeizure?.vehicle?.registeredOwner?.otherName + " ") +
                            vehicleSeizure?.vehicle?.registeredOwner?.lastName)}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/badge-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Issuing Detachment</div>
                        <div class="summary-item-value">{{detachmentName}}</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Seizure Date & Time</div>
                        <div class="summary-item-value">
                            {{vehicleSeizure.seizureDate | date:'mediumDate'}} at {{vehicleSeizure.seizureTime.substring(0,5)}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Vehicle Release Date</div>
                        <div class="summary-item-value">
                            {{vehicleSeizure.vehicleReleaseDate | date:'mediumDate'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="contravention != null || review != null || (isBusinessSuperUser && vehicleSeizure.noticeCancellationRequestMappings.length > 0)">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <i class="fas fa-link fa-lg"></i>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Linked files</div>
                        <div class="summary-item-value" *ngIf="contravention != null"><a
                                [routerLink]="['/contravention', contravention.contraventionNumber]">Contravention
                                {{contravention.contraventionNumber}}</a></div>
                        <div class="summary-item-value" *ngIf="isBusinessSuperUser">
                            <div *ngFor="let noticeCancellationRequestMapping of vehicleSeizure.noticeCancellationRequestMappings">
                                <a [routerLink]="['/requests', noticeCancellationRequestMapping.noticeCancellationRequestNumber]" [queryParams]="{type: RequestTypes.LEACancellationRequest}">Request
                                    {{noticeCancellationRequestMapping.noticeCancellationRequestNumber}}</a>
                            </div>
                        </div>
                        <div class="summary-item-value" *ngFor="let lateReviewRequestItem of vehicleSeizure.lateReviewRequestItems">
                            <a [routerLink]="['/requests', lateReviewRequestItem.lateReviewRequest.lateReviewRequestNumber]" [queryParams]="{type: RequestTypes.LateSeizureReview}">Request
                                {{lateReviewRequestItem.lateReviewRequest.lateReviewRequestNumber}}</a>
                        </div>
                        <div class="summary-item-value" *ngFor="let reviewItem of vehicleSeizure.reviewItems">
                            <a [routerLink]="['/review', reviewItem.review.reviewNumber]">Review
                                {{reviewItem.review.reviewNumber}}</a>
                        </div>
                        <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.JUDICIAL_REVIEWS">
                            <div class="summary-item-value"
                                *ngFor="let judicialReview of vehicleSeizure.judicialReviews">
                                <a [routerLink]="['/judicial-reviews', judicialReview.judicialReviewNumber]">Judicial
                                    Review {{judicialReview.judicialReviewNumber}}</a>
                            </div>
                        </app-access-control>
                    </div>
                </div>
            </div>
            <div class="recent-activity-summary-header">
                Recent Activity

                <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="add-note-icon">
                            <i class="fas fa-plus-circle"></i>
                        </div>
                        <div class="add-note-link" (click)="onAddNoteLinkClick()">Add a note</div>
                    </div>
                </app-access-control>
            </div>
            <div class="form-group recent-activity-group">

                <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
                    <div class="recent-activity-connector"></div>
                    <div *ngFor="let recentActivity of recentActivities; let i=index;"
                        class="recent-activity-container">
                        <div class="recent-activity-dot"
                            [ngClass]="{ 'recent-activity-dot-submitted': i==recentActivities.length-1}"></div>
                        <div class="recent-activity-header">
                            <div>{{recentActivity.date}}</div>
                            <div>by {{recentActivity.by}}</div>
                        </div>
                        <div class="recent-activity-title">{{recentActivity.title}}</div>
                        <div *ngIf="recentActivity.body != null && isCancelledByLEArequest(recentActivity)"
                            class="recent-activity-content">
                            This seizure has been cancelled as the result of
                            <a [routerLink]="['/requests', recentActivity.body]" [queryParams]="{type: requestType}">
                                {{recentActivity.body}}
                            </a>
                        </div>
                        <div *ngIf="recentActivity.body != null && !isCancelledByLEArequest(recentActivity)"
                            class="recent-activity-content">{{recentActivity.body}}</div>
                        <ng-container *ngIf="recentActivity.documents?.length > 0">
                            <div class="recent-activity-document" *ngFor="let document of recentActivity.documents">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onRecentActivityDocumentNameClick(document)"
                                    class="download-file-link">{{document.documentName}}</a>
                            </div>
                        </ng-container>
                        <app-access-control
                            *ngIf="canModifyNote(recentActivity)"
                            [Resource]="Resource.SEIZURE" [Permission]="Permission.ACTIVITY_NOTE">
                            <div class="recent-activity-edit">
                                <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                                <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete
                                </div>
                            </div>
                        </app-access-control>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="tabs-container">
            <div class="tabs">
                <div class="tab-header" (click)="onTabHeaderClick($event)">
                    <div id="tabHeader_SeizureInformation" class="tab-header-item selected">Seizure Information</div>
                    <div id="tabHeader_RecipientInformation" class="tab-header-item">Recipient Information</div>
                    <div id="tabHeader_SeizureActivity" class="tab-header-item" (click)="seizureActivityTabSelected()">
                        Activity</div>
                    <div class="tab-header-item-fill"></div>
                </div>
                <div class="tab-content">
                    <div #scrolltarget id="tab_SeizureInformation" class="tab-content-item selected">
                        <seizure-information (getVehicleSeizureChange)="seizureInformationCallback()">
                        </seizure-information>
                    </div>
                    <div id="tab_RecipientInformation" class="tab-content-item">
                        <seizure-recipient-information (getVehicleSeizureChange)="seizureInformationCallback()">
                        </seizure-recipient-information>
                    </div>
                    <div id="tab_SeizureActivity" class="tab-content-item">
                        <seizure-activity [vehicleSeizure]="vehicleSeizure" (activityNoteSubmitted)="refreshSeizure($event)">
                        </seizure-activity>
                    </div>
                </div>
            </div>

            <div class="note-modal-overlay">
                <div class="note-modal">
                    <div class="note-modal-header">
                        <div class="note-modal-title">Add a note</div>
                        <div class="close-icon" (click)="onCloseIconClick()">
                            <i class="far fa-times-circle fa-lg"></i>
                        </div>
                    </div>
                    <div class="note-modal-body">
                        <textarea class="note-modal-textarea" (input)="onTextareaInput($event)"></textarea>
                        <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters:
                            {{maxCharacters}}</span>
                    </div>
                    <div class="button-container">
                        <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
                        <div id="addButton" class="add-button" [ngClass]="{'disabled': maxCharacters < 0 }"
                            (click)="onAddModalClick($event)">Add</div>
                    </div>
                </div>
            </div>
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
            </ngx-spinner>