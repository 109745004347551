import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ProvinceType } from '@apis/models/types/province-type.model';
import { DriversLicenceClassType } from '@apis/models/types/drivers-licence-class-type.model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { CountryProvinceModel } from '@apis/shared/models/country-province.model';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { IdentificationTypes } from '@apis/shared/enums/app.enum';
import { IdentificationType } from '@apis/shared/models/types/identification-type.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { DriversLicenceStatusType } from '@apis/shared/models/types/drivers-licence-status-type.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { MovesRecipientContact } from '@apis/shared/models/moves-recipient-contact.model';

@Component({
  selector: 'recipient-information',
  templateUrl: './recipient-information.component.html',
  styleUrls: ['./recipient-information.component.scss']
})
export class RecipientInformationComponent implements OnInit {
  @Input() contravention: Contravention;
  @Output() getContraventionChange = new EventEmitter<Contravention>();
  @Input() model: any;
  provinceCode: string;
  licenceClass: string;
  licenceIssuingAuthority: String;
  licenceStatus: string;
  licenceExpiry: string;
  isIdentificationInformationEdited: boolean=false;
  driversLicenceClassTypes: DriversLicenceClassType[];
  dateOfBirth: Date;
  latestAllowableBirthDate: Date;
  identificationNumber:string;
  driversLicenceClassTypeId: number;
  motorVehicleIdentificationNumber: string;
  isSubmitClicked: boolean;
  datePickerConfig: Partial<BsDatepickerConfig>;
  isNoLicenceProduced:boolean;
  driversLicenceClass: string;
  issuingCountryId: number;
  countryProvince: CountryProvinceModel;
  issuingProvinceId: number;
  driverLicenceClassification: any;
  issuingProvinceOther: string;
  isAlbertaDriverLicense: boolean;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  errorMessage:string;
  IdentificationTypes = IdentificationTypes;
  identificationVerifiedBy: string;
  identificationTypes: IdentificationType[];
  identificationTypeId:number;
  identificationOther:string;
  isAllowEditIdentification:boolean = false; 
  conditionCodes: string;
  recipientContactInformation: SectionDetailsModel[] = [];
  movesRecipientContactInformation: SectionDetailsModel[] = [];
  identificationExpiryDate: Date;
  genderTypes: GenderType[];
  genderId: number;
  driverLicenceStatusTypes: DriversLicenceStatusType[];
  driversLicenceStatusTypeId: number;
  intIsGDLDriver: number;
  movesRecipientContact: MovesRecipientContact;
  recipientContact: RecipientContact;
  countryId: number;
  provinceId: number;
  countryTypes: any = [];
  provinceTypes: any = [];
  isRecipientContactInformationEdited: boolean = false;
  isRecipientContactDetailsReadonly: boolean = false;
  isAllowEditIdentificationBusinessUser: boolean = false;
  errorMessageContactInfo: string;
  isSubmitRecipientContactClicked: boolean;

  constructor(private localStorageService: LocalStorageService,
    private readonly adjudicationService: AdjudicationService,
    private readonly spinner: NgxSpinnerService,
    private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
        minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
        maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
        isAnimated: true
      });
    this.countryProvince = new CountryProvinceModel();
    this.countryProvince.isCountryRequired=false;
    this.countryProvince.isProvinceRequired=false;
    this.countryProvince.countryLabel = "Country";
    this.countryProvince.provinceLabel = "Province/State";
    this.genderTypes = this.localStorageService.getGenderTypes();
    this.driverLicenceStatusTypes = this.localStorageService.getDriversLicenceStatusTypes();

    this.toCheckEditPermission();
  }

  PopulateRecipientInformation() {
    this.toCheckEditPermission();
    this.provinceCode =  this.localStorageService.getProvinceTypes().find(x => x.id == this.contravention?.recipient?.recipientContact?.provinceId)?.code;

    if(this.contravention?.recipient?.recipientIdentification?.driversLicenceClassTypeId==null) {
      this.licenceClass =  this.contravention?.recipient?.recipientIdentification?.driversLicenceClass;
    } else {
      this.licenceClass =  this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.contravention?.recipient?.recipientIdentification?.driversLicenceClassTypeId)?.name;
    }

    this.licenceIssuingAuthority=this.localStorageService.getTypeItemDescriptionById(this.contravention?.recipient?.recipientIdentification?.issuingCountryId, TypeTable.CountryType);    

    if (this.contravention?.recipient?.recipientIdentification?.issuingCountryId == 40 || this.contravention?.recipient?.recipientIdentification?.issuingCountryId == 236) // Canada or USA
        this.licenceIssuingAuthority = this.contravention?.recipient?.recipientIdentification?.issuingProvinceId==null ? this.licenceIssuingAuthority : `${this.licenceIssuingAuthority}, ${this.localStorageService.getTypeItemDescriptionById(this.contravention?.recipient?.recipientIdentification?.issuingProvinceId, TypeTable.ProvinceType)}`;
    else
        this.licenceIssuingAuthority = this.contravention?.recipient?.recipientIdentification?.issuingProvinceOther==null ? this.licenceIssuingAuthority : `${this.licenceIssuingAuthority}, ${this.contravention?.recipient?.recipientIdentification?.issuingProvinceOther}`;

    this.licenceStatus = this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.contravention?.recipient?.recipientIdentification?.driversLicenceStatusTypeId)?.name;
    this.licenceExpiry = this.datePipe.transform(this.contravention?.recipient?.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')
    this.identificationTypes = this.localStorageService.getIdentificationTypes();
    this.identificationTypeId = this.contravention?.recipient?.recipientIdentification?.identificationTypeId;
    this.identificationVerifiedBy = this.localStorageService.getIdentificationTypes().find(x => x.id == this.contravention?.recipient?.recipientIdentification?.identificationTypeId)?.name;
    this.identificationOther = this.contravention?.recipient?.recipientIdentification?.identificationOther;
    this.conditionCodes = Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.contravention?.recipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join("");
    this.identificationExpiryDate = this.contravention?.recipient?.recipientIdentification?.identificationExpiryDate;
    if (this.contravention?.recipient?.recipientIdentification?.gender?.id != undefined && this.contravention?.recipient?.recipientIdentification?.gender?.id != null) {
      this.genderId = Number(this.contravention?.recipient?.recipientIdentification?.gender?.id);
    }

    if (this.contravention?.recipient?.recipientIdentification?.driversLicenceStatusTypeId != undefined && this.contravention?.recipient?.recipientIdentification?.driversLicenceStatusTypeId != null) {
      this.driversLicenceStatusTypeId = Number(this.contravention?.recipient?.recipientIdentification?.driversLicenceStatusTypeId);
    }

    this.intIsGDLDriver = (this.contravention?.recipient?.recipientIdentification?.isGDLDriver != null) ? (this.contravention?.recipient?.recipientIdentification?.isGDLDriver ? 1 : 2) : 0;

    // Recipient Contact Information Section
    this.movesRecipientContact = JSON.parse(JSON.stringify(this.contravention?.recipient?.movesRecipientContact));
    this.recipientContact = JSON.parse(JSON.stringify(this.contravention?.recipient?.recipientContact));
    this.recipientContactInformation = [];
    this.movesRecipientContactInformation = [];
    this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.contravention?.recipient?.recipientContact?.addressLine1));
    this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.contravention?.recipient?.recipientContact?.addressLine2));
    this.recipientContactInformation.push(new SectionDetailsModel('City',this.contravention?.recipient?.recipientContact?.city));
    this.recipientContactInformation.push(new SectionDetailsModel('Province/State', (this.contravention?.recipient?.recipientContact?.countryId == 40 || this.contravention?.recipient?.recipientContact?.countryId == 236)? (this.contravention?.recipient?.recipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.contravention?.recipient?.recipientContact?.provinceId)?.name) : this.contravention?.recipient?.recipientContact?.province));
    this.recipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.contravention?.recipient?.recipientContact?.countryId)?.name));
    this.recipientContactInformation.push(new SectionDetailsModel('Postal Code',this.contravention?.recipient?.recipientContact?.postalCode));
    this.recipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.contravention?.recipient?.recipientContact?.phoneNumber1));
    this.recipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.contravention?.recipient?.recipientContact?.phoneNumber2));
    this.recipientContactInformation.push(new SectionDetailsModel('Business Phone', this.contravention?.recipient?.recipientContact?.phoneNumber3));
    this.recipientContactInformation.push(new SectionDetailsModel('Email Address', this.contravention?.recipient?.recipientContact?.emailAddress));
    this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.contravention?.recipient?.recipientContact?.isAddressDifferentFromMOVES? "Yes" : "No"));
    this.recipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.contravention?.recipient?.recipientContact?.isNoFixedAddress? "Yes" : "No"));

    this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.contravention?.recipient?.movesRecipientContact?.addressLine1));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.contravention?.recipient?.movesRecipientContact?.addressLine2));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('City',this.contravention?.recipient?.movesRecipientContact?.city));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.contravention?.recipient?.movesRecipientContact?.countryId == 40 || this.contravention?.recipient?.movesRecipientContact?.countryId == 236)? (this.contravention?.recipient?.movesRecipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.contravention?.recipient?.movesRecipientContact?.provinceId)?.name) : this.contravention?.recipient?.movesRecipientContact?.province));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.contravention?.recipient?.movesRecipientContact?.countryId)?.name));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code',this.contravention?.recipient?.movesRecipientContact?.postalCode));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.contravention?.recipient?.movesRecipientContact?.phoneNumber1));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.contravention?.recipient?.movesRecipientContact?.phoneNumber3));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.contravention?.recipient?.movesRecipientContact?.phoneNumber2));
  }

  public refreshContraventionVehicleCountryProvince(e) {
    if(e.provinceId == 1) {      
      this.driversLicenceClass=null; 
    } else {
      this.driversLicenceClassTypeId = 0;
    }
    this.issuingCountryId = e.countryId;
    this.driversLicenceClassTypes =  this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == e.provinceId);
    this.setDriverLicenceClassification(e.provinceId);

    this.countryProvince.isProvinceRequired = this.countryProvince.countryId == 40 || this.countryProvince.countryId == 236; //Canada or USA
  }

  editIdentificationInformationClick() {
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey!==null && rolePermissionKey!==undefined) {
      rolePermissionKey.forEach( p => {
        if (p.roleName===Constants.Role.BUSINESS_ANALYST || p.roleName===Constants.Role.BUSINESS_SUPER_USER) {
          this.isAllowEditIdentification = true;
          if (p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
            this.isAllowEditIdentificationBusinessUser = true;
          }
        }
      });
    }
    // set current value in variables
    this.identificationNumber = this.contravention?.recipient?.recipientIdentification?.identificationNumber;
    this.motorVehicleIdentificationNumber = this.contravention?.recipient?.recipientIdentification?.motorVehicleIdentificationNumber;
    this.dateOfBirth = new Date(this.contravention?.recipient?.recipientIdentification?.dateOfBirth);
    this.issuingCountryId = Number(this.contravention?.recipient?.recipientIdentification?.issuingCountryId);
    this.issuingProvinceId = Number(this.contravention?.recipient?.recipientIdentification?.issuingProvinceId);
    this.issuingProvinceOther = this.contravention?.recipient?.recipientIdentification?.issuingProvinceOther;
    this.driversLicenceClassTypeId = Number(this.contravention?.recipient?.recipientIdentification?.driversLicenceClassTypeId);
    this.driversLicenceClass = this.contravention?.recipient?.recipientIdentification?.driversLicenceClass;
    this.setDriverLicenceClassification(this.contravention?.recipient?.recipientIdentification.issuingProvinceId);
    if(this.contravention?.recipient?.recipientIdentification?.identificationTypeId == IdentificationTypes.Other)
    {
      this.identificationTypeId = this.contravention?.recipient?.recipientIdentification?.identificationTypeId;
      this.identificationOther = this.contravention?.recipient?.recipientIdentification?.identificationOther;
    }

    //For birth date validation, get latest birth date where recipient would be 12 years old on the contravention occurrence date
    const occurrenceDate = new Date(this.contravention.occurrenceDate);
    this.latestAllowableBirthDate = new Date(occurrenceDate.getFullYear() - 12, occurrenceDate.getMonth(), occurrenceDate.getDate());

    // Populate Binding Properties
    this.countryProvince.countryId = this.issuingCountryId;
    if(this.contravention?.recipient?.recipientIdentification?.issuingProvinceId==null) {
      this.countryProvince.province = this.contravention?.recipient?.recipientIdentification?.issuingProvinceOther;
    } else {
      this.countryProvince.provinceId = this.issuingProvinceId;
      this.driversLicenceClassTypes =  this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == this.issuingProvinceId);
    }
    this.countryProvince.isProvinceRequired = this.countryProvince.countryId == 40 || this.countryProvince.countryId == 236; //Canada or USA

    this.isIdentificationInformationEdited =! this.isIdentificationInformationEdited;
  }

  setDriverLicenceClassification(provinceId:any) {
    this.isNoLicenceProduced =  this.contravention?.recipient?.isNoLicenceProduced;
    this.isAlbertaDriverLicense = provinceId == 1;

    // Phase 2 Build - driver licence classification was added to the database so get it from there is available.
    if (this.contravention?.recipient?.recipientIdentification?.driversLicenceClassificationId)
    {
      this.driverLicenceClassification = this.contravention?.recipient?.recipientIdentification?.driversLicenceClassificationId;
      return;
    }

    // If driver licence classification is not available in database then derive it from province.
    if (this.isAlbertaDriverLicense) {
      this.driverLicenceClassification = 1;
    } else if (this.contravention?.recipient?.isNoLicenceProduced) {
      this.driverLicenceClassification = 3;
    } else {
      this.driverLicenceClassification = 2;
    }
  }

  cancelEditedIdentificationInformation() {
    this.clearEditedIdentificationInformation();
    this.getContraventionChange.emit(this.contravention);
  }

  saveEditedIdentificationInformation(isValid:boolean) {
    this.isSubmitClicked=true;
    if(isValid)
    {
      this.contravention.recipient.recipientIdentification.issuingCountryId = this.countryProvince.countryId;
      this.contravention.recipient.recipientIdentification.issuingProvinceId = this.countryProvince.provinceId;
      this.contravention.recipient.recipientIdentification.issuingProvinceOther = this.countryProvince.province;
      this.contravention.recipient.recipientIdentification.driversLicenceClassTypeId = this.driversLicenceClassTypeId;
      this.contravention.recipient.recipientIdentification.driversLicenceClass = this.driversLicenceClass;
      this.contravention.recipient.recipientIdentification.identificationNumber = this.identificationNumber;
      this.contravention.recipient.recipientIdentification.motorVehicleIdentificationNumber = this.motorVehicleIdentificationNumber;
      this.contravention.recipient.recipientIdentification.dateOfBirth = this.dateOfBirth;
      this.contravention.recipient.recipientIdentification.identificationTypeId = this.identificationTypeId;
      this.contravention.recipient.recipientIdentification.identificationOther = this.identificationOther;

      if (this.isAllowEditIdentificationBusinessUser) {
        this.contravention.recipient.recipientIdentification.identificationExpiryDate = this.identificationExpiryDate;
        this.contravention.recipient.recipientIdentification.genderId = this.genderId;
        this.contravention.recipient.recipientIdentification.driversLicenceStatusTypeId = this.driversLicenceStatusTypeId;
        this.contravention.recipient.recipientIdentification.isGDLDriver = this.contravention?.recipient?.recipientIdentification?.isGDLDriver;
      }
      this.spinner.show();
      this.adjudicationService.updateContraventionRecipientIdentification(this.contravention.contraventionNumber, this.contravention.recipient.recipientIdentification)
        .subscribe((result: Contravention) => {
          this.getContraventionChange.emit(result);
          this.clearEditedIdentificationInformation();
          this.spinner.hide();
        }, (error: any) => {

          if (error.status == 401 || error.status == 403) {
            this.errorMessage = "You are unauthorized to perform this operation";
          }
          else  {
            this.showErrors(error);
          }
          this.spinner.hide();
        });
    }
  }

  clearEditedIdentificationInformation() {
    this.issuingCountryId = null;
    this.issuingProvinceId = null;
    this.issuingProvinceOther=null;
    this.driversLicenceClassTypeId = null;
    this.driversLicenceClass =null;
    this.identificationNumber = null;
    this.motorVehicleIdentificationNumber = null;
    this.dateOfBirth = null;
    this.setDriverLicenceClassification(this.contravention?.recipient?.recipientIdentification.issuingProvinceId);
    this.identificationOther = null;
    this.isIdentificationInformationEdited =! this.isIdentificationInformationEdited;
    this.isAllowEditIdentification = false;
    this.errorMessage=null;
    this.genderId = null;

    switch (this.contravention?.recipient?.recipientIdentification?.isGDLDriver) {
      case true: {
        this.intIsGDLDriver = 1;
        break;
      }
      case false: {
        this.intIsGDLDriver = 2;
        break;
      }
      default: {
        this.intIsGDLDriver = 0;
      }
    }
  }

  displayValue(value: any): string {
    if (value == null || value.trim() == "")
      return "-";
    else
      return value.trim();
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;
    else
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  showErrorsContactInfo(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessageContactInfo = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessageContactInfo = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessageContactInfo = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessageContactInfo = error.error.errors;
    else
      this.errorMessageContactInfo = "Something went wrong. Please try again later.";
  }

  public onGDLDriverChange(event) {
    switch (event.target.value) {
      case "1": {
        this.contravention.recipient.recipientIdentification.isGDLDriver = true;
        break;
      }
      case "2": {
        this.contravention.recipient.recipientIdentification.isGDLDriver = false;
        break;
      }
      default: {
        this.contravention.recipient.recipientIdentification.isGDLDriver = null;
      }
    }
  }

  onAddressDifferentFromMovesChange() {
    if (!this.isRecipientReadOnly()) return;

    if (this.contravention.recipient.recipientContact.isAddressDifferentFromMOVES) {
      if (this.recipientContact.isAddressDifferentFromMOVES) {
        this.contravention.recipient.movesRecipientContact = new MovesRecipientContact();
        this.contravention.recipient.movesRecipientContact.addressLine1 = this.movesRecipientContact.addressLine1;
        this.contravention.recipient.movesRecipientContact.addressLine2 = this.movesRecipientContact.addressLine2;
        this.contravention.recipient.movesRecipientContact.addressLine3 = this.movesRecipientContact.addressLine3;
        this.contravention.recipient.movesRecipientContact.countryId = this.movesRecipientContact.countryId;
        this.contravention.recipient.movesRecipientContact.provinceId = this.movesRecipientContact.provinceId;
        this.contravention.recipient.movesRecipientContact.province = this.movesRecipientContact.province;
        this.contravention.recipient.movesRecipientContact.city = this.movesRecipientContact.city;
        this.contravention.recipient.movesRecipientContact.postalCode = this.movesRecipientContact.postalCode;
        this.contravention.recipient.movesRecipientContact.phoneNumber1 = this.movesRecipientContact.phoneNumber1;
        this.contravention.recipient.movesRecipientContact.phoneNumber2 = this.movesRecipientContact.phoneNumber2;
        this.contravention.recipient.movesRecipientContact.phoneNumber3 = this.movesRecipientContact.phoneNumber3;
        this.contravention.recipient.movesRecipientContact.emailAddress = this.movesRecipientContact.emailAddress;

        this.contravention.recipient.recipientContact.addressLine1 = this.recipientContact.addressLine1;
        this.contravention.recipient.recipientContact.addressLine2 = this.recipientContact.addressLine2;
        this.contravention.recipient.recipientContact.addressLine3 = this.recipientContact.addressLine3;
        this.contravention.recipient.recipientContact.countryId = this.recipientContact.countryId;
        this.contravention.recipient.recipientContact.provinceId = this.recipientContact.provinceId;
        this.contravention.recipient.recipientContact.province = this.recipientContact.province;
        this.contravention.recipient.recipientContact.city = this.recipientContact.city;
        this.contravention.recipient.recipientContact.postalCode = this.recipientContact.postalCode;
        this.contravention.recipient.recipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
        this.contravention.recipient.recipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
        this.contravention.recipient.recipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
        this.contravention.recipient.recipientContact.emailAddress = this.recipientContact.emailAddress;
        this.contravention.recipient.recipientContact.isNoFixedAddress = this.recipientContact.isNoFixedAddress;

        this.countryId = Number(this.recipientContact.countryId) ? Number(this.recipientContact.countryId) : null;
        this.provinceId = Number(this.recipientContact.provinceId) ? Number(this.recipientContact.provinceId) : null;
      }
      else {
        this.contravention.recipient.movesRecipientContact = new MovesRecipientContact();
        this.contravention.recipient.movesRecipientContact.addressLine1 = this.recipientContact.addressLine1;
        this.contravention.recipient.movesRecipientContact.addressLine2 = this.recipientContact.addressLine2;
        this.contravention.recipient.movesRecipientContact.addressLine3 = this.recipientContact.addressLine3;
        this.contravention.recipient.movesRecipientContact.countryId = this.recipientContact.countryId;
        this.contravention.recipient.movesRecipientContact.provinceId = this.recipientContact.provinceId;
        this.contravention.recipient.movesRecipientContact.province = this.recipientContact.province;
        this.contravention.recipient.movesRecipientContact.city = this.recipientContact.city;
        this.contravention.recipient.movesRecipientContact.postalCode = this.recipientContact.postalCode;
        this.contravention.recipient.movesRecipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
        this.contravention.recipient.movesRecipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
        this.contravention.recipient.movesRecipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
        this.contravention.recipient.movesRecipientContact.emailAddress = this.recipientContact.emailAddress;

        this.movesRecipientContact = new MovesRecipientContact();
        this.movesRecipientContact.addressLine1 = this.recipientContact.addressLine1;
        this.movesRecipientContact.addressLine2 = this.recipientContact.addressLine2;
        this.movesRecipientContact.addressLine3 = this.recipientContact.addressLine3;
        this.movesRecipientContact.countryId = this.recipientContact.countryId;
        this.movesRecipientContact.provinceId = this.recipientContact.provinceId;
        this.movesRecipientContact.province = this.recipientContact.province;
        this.movesRecipientContact.city = this.recipientContact.city;
        this.movesRecipientContact.postalCode = this.recipientContact.postalCode;
        this.movesRecipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
        this.movesRecipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
        this.movesRecipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
        this.movesRecipientContact.emailAddress = this.recipientContact.emailAddress;

        this.movesRecipientContactInformation = [];
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.contravention?.recipient?.movesRecipientContact?.addressLine1));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.contravention?.recipient?.movesRecipientContact?.addressLine2));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('City', this.contravention?.recipient?.movesRecipientContact?.city));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.contravention?.recipient?.movesRecipientContact?.countryId == 40 || this.contravention?.recipient?.movesRecipientContact?.countryId == 236) ? (this.contravention?.recipient?.movesRecipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.contravention?.recipient?.movesRecipientContact?.provinceId)?.name) : this.contravention?.recipient?.movesRecipientContact?.province));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.contravention?.recipient?.movesRecipientContact?.countryId)?.name));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code', this.contravention?.recipient?.movesRecipientContact?.postalCode));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.contravention?.recipient?.movesRecipientContact?.phoneNumber1));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.contravention?.recipient?.movesRecipientContact?.phoneNumber3));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.contravention?.recipient?.movesRecipientContact?.phoneNumber2));

        this.contravention.recipient.recipientContact.addressLine1 = null;
        this.contravention.recipient.recipientContact.addressLine2 = null;
        this.contravention.recipient.recipientContact.addressLine3 = null;
        this.contravention.recipient.recipientContact.countryId = null;
        this.contravention.recipient.recipientContact.provinceId = null;
        this.contravention.recipient.recipientContact.province = null;
        this.contravention.recipient.recipientContact.city = null;
        this.contravention.recipient.recipientContact.postalCode = null;
        this.contravention.recipient.recipientContact.phoneNumber1 = null;
        this.contravention.recipient.recipientContact.phoneNumber2 = null;
        this.contravention.recipient.recipientContact.phoneNumber3 = null;
        this.contravention.recipient.recipientContact.emailAddress = null;
        this.contravention.recipient.recipientContact.isNoFixedAddress = false;

        this.countryId = null;
        this.provinceId = null;
      }
    }
    else {
      //Restore all driver contact information to it's original state
      this.contravention.recipient.recipientContact.addressLine1 = this.movesRecipientContact.addressLine1;
      this.contravention.recipient.recipientContact.addressLine2 = this.movesRecipientContact.addressLine2;
      this.contravention.recipient.recipientContact.addressLine3 = this.movesRecipientContact.addressLine3;
      this.contravention.recipient.recipientContact.countryId = this.movesRecipientContact.countryId;
      this.contravention.recipient.recipientContact.provinceId = this.movesRecipientContact.provinceId;
      this.contravention.recipient.recipientContact.province = this.movesRecipientContact.province;
      this.contravention.recipient.recipientContact.city = this.movesRecipientContact.city;
      this.contravention.recipient.recipientContact.postalCode = this.movesRecipientContact.postalCode;
      this.contravention.recipient.recipientContact.phoneNumber1 = this.movesRecipientContact.phoneNumber1;
      this.contravention.recipient.recipientContact.phoneNumber2 = this.movesRecipientContact.phoneNumber2;
      this.contravention.recipient.recipientContact.phoneNumber3 = this.movesRecipientContact.phoneNumber3;
      this.contravention.recipient.recipientContact.emailAddress = this.movesRecipientContact.emailAddress;
      this.contravention.recipient.recipientContact.isNoFixedAddress = false;

      this.countryId = Number(this.movesRecipientContact.countryId) ? Number(this.movesRecipientContact.countryId) : null;
      this.provinceId = Number(this.movesRecipientContact.provinceId) ? Number(this.movesRecipientContact.provinceId) : null;

      this.contravention.recipient.movesRecipientContact = null;
    }
  }

  isRecipientReadOnly() {
    return this.contravention?.recipient?.movesLookupDate;
  }

  onProvinceTextChange(e) {
    this.contravention.recipient.recipientContact.provinceId = null;
    this.provinceId = null;
  }

  onCountryChange() {
    if (this.countryId == 40) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryId == 236) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 236; });
    }
    else {
      this.contravention.recipient.recipientContact.provinceId = null;
      this.provinceId = null;
    }

    this.contravention.recipient.recipientContact.province = null;

    return this.provinceTypes;
  }

  saveReceipentContactClick(isValid: boolean) {
    this.isSubmitRecipientContactClicked = true;
    if (isValid) {

      this.contravention.recipient.recipientContact.provinceId = this.provinceId;
      this.contravention.recipient.recipientContact.countryId = this.countryId;

      if (this.contravention.recipient.recipientContact?.postalCode)
        this.contravention.recipient.recipientContact.postalCode = this.contravention.recipient.recipientContact.postalCode.toUpperCase();

      this.spinner.show();
      this.adjudicationService.updateContraventionRecipientContact(this.contravention.contraventionNumber, this.contravention.recipient)
        .subscribe((result: Contravention) => {
          this.getContraventionChange.emit(result);
          this.clearEditedRecipientContactInformation();
          this.spinner.hide();
        }, (error: any) => {

          if (error.status == 401 || error.status == 403) {
            this.errorMessageContactInfo = "You are unauthorized to perform this operation";
          }
          else {
            this.showErrorsContactInfo(error);
          }
          this.spinner.hide();
        });
    }
  }

  cancelReceipentContactClick() {
    this.clearEditedRecipientContactInformation();
    this.getContraventionChange.emit(this.contravention);
  }

  clearEditedRecipientContactInformation() {
    this.isRecipientContactInformationEdited = false;
    this.errorMessageContactInfo = null;
  }

  editReceipentContactClick() {
    this.isRecipientContactInformationEdited = true;
    this.countryTypes = this.localStorageService.getCountryTypes();
    this.provinceTypes = this.localStorageService.getProvinceTypes();

    this.countryId = Number(this.contravention?.recipient?.recipientContact?.countryId) ? Number(this.contravention?.recipient?.recipientContact?.countryId) : null;
    this.provinceId = Number(this.contravention?.recipient?.recipientContact?.provinceId) ? Number(this.contravention?.recipient?.recipientContact?.provinceId) : null;
  
    if (this.countryId == 40) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryId == 236) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 236; });
    }
  }

  toCheckEditPermission() {
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey !== null && rolePermissionKey !== undefined) {
      rolePermissionKey.forEach(p => {
        if (p.roleName === Constants.Role.BUSINESS_ANALYST || p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
          this.isAllowEditIdentification = true;
          if (p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
            this.isAllowEditIdentificationBusinessUser = true;
          }
        }
      });
    }
  }
}
