<div class="d-flex flex-column">
    <div class="review-recent-activity-summary-header">
        All Activity
        <div class="d-flex">
            <div class="d-flex justify-content-end align-items-center">
                <div class="add-note-icon">
                    <i class="fas fa-plus-circle"></i>
                </div>
                <div class="add-note-link" (click)="onAddNoteLinkClick()">Add a note</div>
            </div>
        </div>
    </div>

    <div class="form-group review-recent-activity-group">
        <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
            <div class="review-recent-activity-connector"></div>
            <div *ngFor="let recentActivity of recentActivities; let i=index;" class="review-recent-activity-container">
                <div class="review-recent-activity-dot" [ngClass]="{ 'review-recent-activity-dot-submitted': i==0}">
                </div>
                <div class="review-recent-activity-header">
                    <div>{{recentActivity.date}}</div>
                    <div>by {{recentActivity.by}}</div>
                </div>
                <div class="review-recent-activity-title">{{recentActivity.title}}</div>
                <div *ngIf="recentActivity.body != null" class="review-recent-activity-content">{{recentActivity.body}}
                </div>
                <ng-container *ngIf="(recentActivity.title == 'Judicial Review Decision Issued') && judicialReview.reReview && (judicialReview.judicialReviewDecisionTypeId == JudicialReviewDecisionTypes.CourtOrderedReReview
                || judicialReview.judicialReviewDecisionTypeId == JudicialReviewDecisionTypes.ConsentToReReview)">
                    <ul>
                        <li *ngIf="judicialReview.reReview.isLEAAllowedToAddSupportingDocs"
                            class="review-recent-activity-content conditions">
                            <div>Allow law enforcement to add
                                supporting documents</div>
                        </li>
                        <li *ngIf="judicialReview.reReview.isAllowedToEditReviewGrounds"
                            class="review-recent-activity-content conditions">
                            <div>Allow recipient to edit grounds for
                                review</div>
                        </li>
                        <li *ngIf="judicialReview.reReview.isAllowedToAmendSupportingDocs"
                            class="review-recent-activity-content conditions">
                            <div>Allow recipient to add or amend
                                supporting documents</div>
                        </li>
                        <li *ngIf="judicialReview.reReview.isAllowedToEditReviewMethod"
                            class="review-recent-activity-content conditions">
                            <div>Allow recipient to edit review method
                            </div>
                        </li>
                        <li *ngIf="judicialReview.reReview.isReReviewBySameAdjudicator"
                            class="review-recent-activity-content conditions">
                            <div>To be re-reviewed by the same
                                adjudicator</div>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="recentActivity.documents?.length > 0">
                    <div class="review-recent-activity-document" *ngFor="let document of recentActivity.documents">
                        <img class="download-file-icon" src="assets/images/download-file.png" />
                        <a (click)="onEventDocumentNameClick(document)"
                            class="download-file-link">{{document.documentName}}</a>
                    </div>
                </ng-container>
                <app-access-control
                    *ngIf="canModifyNote(recentActivity)"
                    [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="review-recent-activity-edit">
                        <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                        <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                    </div>
                </app-access-control>
            </div>
        </ng-container>
    </div>

</div>