import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DisclosuresResult } from '@apis/shared/models/disclosures-result.model';
import { DisclosureService } from '../shared/services/disclosure.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from "file-saver";
import { DisclosureInfo } from '@apis/shared/models/disclosure-info.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DeleteDisclosureModalComponent } from './modals/delete-disclosure-modal/delete-disclosure-modal.component';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-traffic-disclosure',
  templateUrl: './traffic-disclosure.component.html',
  styleUrls: ['./traffic-disclosure.component.scss']
})
export class TrafficDisclosureComponent implements OnInit {
  searchText: string;
  disclosuresResult: DisclosuresResult;
  allItemsSelected: boolean;
  errorMessage: string;

  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;

  constructor(
    private readonly disclosureService: DisclosureService,
    private readonly spinner: NgxSpinnerService,
    private readonly viewContainerRef: ViewContainerRef
    ) { }

  ngOnInit(): void {
  }

  onSelectAllChanged(ev: any, ) {
    this.disclosuresResult.disclosures.forEach(d => d.isSelected = ev.target.checked);
  }

  onDownloadClick() {
    var disclosuresToDownload = this.disclosuresResult.disclosures.filter(d => d.isSelected);

    this.disclosureService.downloadDisclosures(this.disclosuresResult.ticketNumber, disclosuresToDownload)
    .subscribe((result: any) => {          
      fileSaver.saveAs(result, `${this.disclosuresResult.ticketNumber}.Zip`);          
    },
    (error: any) => { console.log(error); });
  }

  isAnyItemSelected() {
    return this.disclosuresResult.disclosures.some(d => d.isSelected);
  }

  onTxtTicketNumberKeyDown(ev: KeyboardEvent, isValid: boolean): void {
    if (ev.key.toLowerCase() === "enter") {
      this.onSearchButtonClick(isValid);
    }
  }

  onSearchButtonClick(isValid: boolean): void {
    if (isValid) {
      this.errorMessage = null;
      this.searchText = this.searchText.toUpperCase();
      this.findTicket(this.searchText);
    }
  }

  findTicket(ticketNumber: string) {
    if (ticketNumber == null || ticketNumber.trim().length == 0) {
      return;
    }

    this.spinner.show();    

    this.disclosureService.getDisclosures(ticketNumber)
    .subscribe((result: DisclosuresResult) => {
      this.allItemsSelected = false;
      this.disclosuresResult = result;
      this.spinner.hide();
    });
  }

  getSize(d: DisclosureInfo) {
    if (d.metadata["originalcontentlength"]) {
      return CommonUtil.formatBytes(d.metadata["originalcontentlength"]);
    } else {
      return d.size;
    }
  }

  onDeleteDisclosureDocumentClick(ev: any, document: DisclosureInfo): void {
    this.errorMessage = null;
    let componentRef = this.viewContainerRef.createComponent(DeleteDisclosureModalComponent);

    componentRef.instance.disclosureDocument = Object.assign({}, document);
    componentRef.instance.ticketNumber = this.disclosuresResult.ticketNumber;
    componentRef.instance.close.subscribe((documentDeleted: boolean) => {
      this.viewContainerRef.clear();
      if (documentDeleted) {
        let index = this.disclosuresResult.disclosures.indexOf(document);
        if (index != -1) {
          this.disclosuresResult.disclosures.splice(index, 1);
        }
        else {
          this.errorMessage = "Unable to delete the disclosure document. The document cannot be found in the list."
        }
      }
    },
    (error: any) => {
      this.errorMessage = "Unable to delete the disclosure document. Please try again later.";
    });
  }
}
