

import { Document } from './document.model';

export class DecisionRequest {
    public requestNumber: string;
    public decisionTypeId: number = 0;
    public document: Document;
    public tempFolderName: string;
    public requestDenyReasonNotes:string;
    public requestDenyReasonTypeId:number = 0;

    constructor(init?: Partial<DecisionRequest>) {
        
        Object.assign(this, init);
    }
}
