import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, Subscriber, throwError } from 'rxjs';
import { map, find, catchError } from "rxjs/operators";
import { environment } from "apps/adjudication/src/environments/environment";
import { SeizureView } from '@apis/shared/models/seizure-view.model';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { PagedResult } from "@apis/shared/models/paged-result.model";
import { VehicleSeizurePagedSearch } from "@apis/shared/models/vehicle-seizure-paged-search.model";

@Injectable()
export class VehicleSeizureService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }

  getSeizures(): Observable<SeizureView[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/vehicleseizures`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<SeizureView>(response));
        }
        
        return [new SeizureView(response)];
      })
    );
  }

  getSeizuresByPagedSearch(vehicleSeizurePagedSearch: VehicleSeizurePagedSearch): Observable<PagedResult> {
    this.user = this.localStorageService.getUser();

    let params = new HttpParams();
    Object.entries(vehicleSeizurePagedSearch).forEach((pair: [string, any]) => {
      params = params.append(pair[0], pair[1]);
    });
    
    return this.httpClient.get(`${this.apiUrl}/vehicleseizures/findall`, {
      headers: { "Authorization": `Bearer ${this.user.token}` },
      params: params      
    }).pipe(
      map(response => new PagedResult(response))
    );
  }  

  downloadVehicleSeizuresExcelExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/vehicleseizures/exportexcel`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }

  downloadVehicleSeizuresCSVExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/vehicleseizures/exportcsv`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }
}
