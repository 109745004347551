import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes } from "@apis/shared/enums/app.enum";
import { User } from '@apis/shared/models/user.model';
import { ReviewService } from '../../../shared/services/review.service';
import { SchedulerService } from '../../../shared/services/scheduler.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reassign-adjudicator-modal',
  templateUrl: './reassign-adjudicator-modal.component.html',
  styleUrls: ['./reassign-adjudicator-modal.component.scss']
})
export class ReassignAdjudicatorModalComponent implements OnInit {
  review: Review;  
  adjudicatorId: number;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;
  adjudicators: User[] = [];  
  assignButton: JQuery<HTMLElement>;
  eventDetails: string;  
  userId: number = 0;
      
  @Output() close: EventEmitter<Review> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService,
    private readonly schedulerService: SchedulerService) { }

  ngOnInit(): void {               
    this.schedulerService.getAvailableAdjudicators(
            new Date(this.review.scheduledEvent.startDate),
            new Date(this.review.scheduledEvent.endDate),
            this.review.scheduledEvent.meetingTypeId, this.review.scheduledEvent.complexity).subscribe((result: any) => {
              this.adjudicators = this.localStorageService.getAdjudicators().filter(a => result.includes(a.userId.toString()));
            });

    this.adjudicatorId = +this.review.userId;
        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.assignButton = $(".assign-button");      
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onAssignModalClick(ev: any): void {
    if (this.assignButton.hasClass("disabled")) {
      return;
    }
    
    this.assignButton.addClass("disabled");
    this.assignButton.addClass("saving");

    const event = new Event({      
      eventTypeId: EventTypes.AdjudicatorReassigned,
      eventDetails: this.eventDetails      
    });

    if (event.eventDetails == null || event.eventDetails.length == 0) {
      // Set new adjudicator's name in the event details
      event.eventDetails = this.getAdjudicatorAssignedName();
    }

    const review = new Review({
      reviewId: +this.review.reviewId,
      userId: +this.userId      
    });

    review.events.push(event);

    this.reviewService.reassignAdjudicator(review)
      .subscribe(
        (result: Review) => { 
          this.assignButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {});    
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }

  isAssignButtonDisabled(): boolean {
    return this.maxCharacters < 0 
      || this.userId == 0
      || this.userId == this.adjudicatorId;
  }

  private getAdjudicatorAssignedName(): string {
    const user = this.adjudicators.find(adjudicator => +adjudicator.userId === +this.userId);
    if (user != null) {
      return `${user.firstName} ${user.lastName}`;
    }
  }
}