<div *ngIf="environmentName.length > 0 && environmentName != 'Production'" class="environment-name">
    {{environmentName}}
</div>  
<div *ngIf="environmentName.length > 0 && environmentName != 'Production'" class="mb-4"></div>
<div class="header d-flex justify-content-start align-items-center row">   
    <div class="logo-container col-1"> 
        <div class="ab-logo"></div>
    </div>
    <div class="header-title col-3">Advanced Projects Information System</div>
    <div class="maintenance-message-section col-6">
        <div *ngIf="maintenanceMessage" class="maintenance-message-box"> 
            <div class="maintenance-message-alert">{{ maintenanceMessageHeader }}</div>
            <div class="maintenance-message">{{ maintenanceMessage }} </div>
        </div>
    </div>
    <div class="username-container col-2">
        <div class="username-icon"></div>
        <div class="username-link">{{username}}</div>
        <button class="btn-link" (click)="onLogoutClick()">Logout</button>
    </div>
</div>
