import { Component, OnInit, ViewChild } from '@angular/core';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { AvailabilityReportRequest } from '@apis/shared/models/availability-report-request.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { addDays, addHours, addMinutes, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { SchedulerService } from '../../../shared/services/scheduler.service';
import { ScheduleEventComponent } from '../modals/schedule-event/schedule-event.component';
import { Colors } from '../../../shared/helpers/colors'
import { Resource } from '../../components/resource-time-line-view/resource-time-line-view.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { CommonMethods } from '../../../shared/helpers/common-methods';
import { ReportService } from '../../../shared/services/report.service';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { Constants } from '@apis/shared/helpers/constants';
import { AvailabilityReportComponent } from '../modals/availability-report/availability-report.component';

@Component({
  selector: 'manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class ManageEventsComponent  implements OnInit {
  view: CalendarView = CalendarView.Day;

  viewDate: Date = new Date();

  locale: string = 'en';

  // exclude weekends
  excludeDays: number[] = [0, 6];
  
  resources: Resource[] = [];

  events: CalendarEvent[] = [];

  selectedEventId: number;

  showEvent = false;

  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;

  componentID = 0;

  scheduleEventComponent: ScheduleEventComponent;
  @ViewChild('scheduleEvent', { static: false }) set scheduleEventContent(comp: ScheduleEventComponent) {
    if (comp) {
      this.scheduleEventComponent = comp;
    }
  }

  @ViewChild(AvailabilityReportComponent) availabilityReportComponent: AvailabilityReportComponent;

  CalendarView = CalendarView;

  constructor(
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly schedulerService: SchedulerService,
    private readonly reportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.loadResources();
    this.loadResourceView();
  }

  onViewChange(newView: CalendarView) {
    this.view = newView;
    this.loadResourceView();
  }

  onAvailabilityReportClick() {
    this.componentID = 2;
    this.showEvent = !this.showEvent;
    this.initAvailabilityReport();
  }

  onNewEventClick() {
    this.componentID = 1;
    this.selectedEventId = 0;
    this.showEvent = !this.showEvent;
    this.loadScheduledEvent();
  }

  onCloseEventClick() {
    this.showEvent = false;
  }

  onEventClose(e) {
    this.showEvent = false;
    if (e != null) {
      this.loadResourceView();
    }
  }

  onEventClicked({ event }: { event: CalendarEvent }): void {
    if (this.selectedEventId != +event.meta?.e.scheduledEventId || this.showEvent == false) {
      this.componentID = 1;
      this.showEvent = true;
      this.selectedEventId = +event.meta?.e.scheduledEventId;
      this.loadScheduledEvent();
    } else {
      this.showEvent = false;
    }
  }

  loadResources() {
    this.localStorageService.getAdjudicators().forEach(u => {
      if (u.isDisabled != true) {
        this.resources.push({
          id: u.userId,
          name: `${u.lastName}, ${u.firstName}`
        });
      }
    });

    this.resources = this.resources.sort((a: any, b: any) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
  }

  loadResourceView() {
    this.spinner.show();

    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];
  
    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    this.schedulerService.getResourceTimelineViewAsync(getStart(this.viewDate), getEnd(this.viewDate))
      .subscribe((result: ScheduledEvent[]) => {
        this.events = this.getCalendarEvents(result);
        
        this.spinner.hide();
      }, (error: any) => this.spinner.hide());
  }

  getCalendarEvents(scheduledEvents: ScheduledEvent[]) {
    var calendarEvents = [];

    scheduledEvents.forEach(e => {
      let calendarEvent: any = {
          title: e.title,
          start: new Date(e.startDate),
          end: new Date(e.endDate),
          color: CommonMethods.getEventColorByMeetingType(e.meetingTypeId, e.availabilityTypeId),
          cssClass: e.meetingTypeId > 0 ? 'app-cal-event' : '',          
          meta: {
            resource: this.resources.find(r => r.id == e.userId),
            e: e
          }
      };

      if (e.meetingTypeId) {
        calendarEvent.actions = [
          {
            label: e.meetingTypeId == 2 ? '<img src="assets/images/camera-black.svg">' : '<img src="assets/images/pencil-black.svg">',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              this.onEventClicked({event});
            },
          }
        ];
      }

      calendarEvents.push(calendarEvent);
    });

    return calendarEvents;
  }

  loadScheduledEvent() {
    setTimeout(() => {
      this.scheduleEventComponent.loadScheduledEvent(this.selectedEventId);
    });
  }

  initAvailabilityReport() {
    setTimeout(() => {
      this.availabilityReportComponent.init();
    });
  }
}
