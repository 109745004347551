<div class="d-flex flex-column h-100">
    <div class="header">
        <div class="top-header">
            <div class="d-flex">Contravention {{contraventionNumber}} 
                <span [ngClass]="getContraventionClass(contravention?.contraventionStatusTypeId)"> {{localStorageService.getTypeItemDescriptionById(contravention?.contraventionStatusTypeId, 5)}}
                <img src="assets/images/chain-link.svg" /> </span>
                <span *ngIf="contravention?.isRecipientYouth" class="youth-flag">YOUTH</span>
                <div *ngIf="contravention?.judicialReviews.length > 0" class="jr-info">
                    <img class="jr-info-flag" src="assets/images/triangular-flag-white.svg" />
                    JR Served
                </div>
            </div>
            <select id="manageContravention" class="manage-contravention form-control" (change)="onManageContraventionChange($event)"> 
                <option value="0">Manage Contravention...</option>
                <option value="1" *ngIf="canImmediateCancel">Cancel Contravention</option>
                <option value="2" *ngIf="canReviewRequestExtension" [disabled]="disableExtendReviewDeadline">Review Request Extension</option>
                <option value="3" *ngIf="canEnableLateReview" [disabled]="disableEnableLateReview">Enable Late Review</option>
                <option value="4" *ngIf="canSetFineUncollectable" [disabled]="disableFineUncollectable">Fine Uncollectable</option>
                <option value="5" *ngIf="canEnableReview" [disabled]="disableEnableReview">Enable Review</option>
            </select>
        </div>
        <div *ngIf="isRedactionRequired" class="contravention-alert">
            <div class="alert-icon">                
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="flex-fill pl-4">This contravention contains supporting documents that may require redaction</div>
            <a  (click)="onViewClick(scrolltarget)">View</a>
        </div>             
    </div>
    <div class="main">
        <div class="summary-section" *ngIf="contravention != null">            
            <div class="summary-header">Summary</div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <!-- <i class="far fa-list-alt fa-lg"></i> -->
                        <img src="assets/images/contravention-white.svg" />                      
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Contravention Type</div>
                        <div class="summary-item-value">{{localStorageService.getTypeItemDescriptionById(contravention?.contraventionTypeId, 6)}}</div>
                    </div>                    
                </div>               
            </div>            
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <!-- <i class="far fa-user fa-lg"></i> -->
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Recipient</div>
                        <div class="summary-item-value">
                            {{contravention.recipient?.firstName}} {{contravention.recipient?.lastName}}                           
                        </div>
                    </div>                    
                </div>            
            </div> 
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/badge-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Issuing Detachment</div>
                        <div class="summary-item-value">{{detachmentName}}</div>
                    </div>                    
                </div>             
            </div>  
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Issuance Date</div>
                        <div class="summary-item-value">
                            {{contravention.issueDate | date:'mediumDate'}}
                        </div>
                    </div>  
                </div>               
            </div>
            <div class="form-group" *ngIf="contravention.reServeDate != null">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Re-Service Date</div>
                        <div class="summary-item-value">
                            {{contravention.reServeDate | date:'mediumDate'}}
                        </div>
                    </div>  
                </div>               
            </div>
            
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/money-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Balance Owing</div>
                        <div class="summary-item-value">{{amountDue | currency}}</div>
                   
                        <div class="summary-item-label mt-4">Payment Due</div>
                        <div class="summary-item-value">{{paymentDueInfo}}</div>
                        <div class="summary-item-value" *ngIf="isDueDateExtended">(extended)</div>
                    </div>  
                </div>       
            </div>  
            <div class="form-group" *ngIf="contravention?.vehicleSeizure?.seizureNumber != null || contravention?.review?.reviewNumber != null || (isBusinessSuperUser && contravention.noticeCancellationRequestMappings.length > 0)">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <i class="fas fa-link fa-lg"></i>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Linked files</div>
                        <div class="summary-item-value" *ngIf="contravention?.vehicleSeizure?.seizureNumber != null"><a [routerLink]="['/seizure', contravention.vehicleSeizure.seizureNumber]">Vehicle Seizure {{contravention.vehicleSeizure.seizureNumber}}</a></div>
                        <div class="summary-item-value" *ngIf="isBusinessSuperUser">
                            <div *ngFor="let noticeCancellationRequestMapping of contravention.noticeCancellationRequestMappings">
                                <a [routerLink]="['/requests', noticeCancellationRequestMapping.noticeCancellationRequestNumber]" [queryParams]="{type: RequestTypes.LEACancellationRequest}">Request {{noticeCancellationRequestMapping.noticeCancellationRequestNumber}}</a>
                            </div>
                        </div>
                        <div class="summary-item-value" *ngFor="let lateReviewRequestItem of contravention.lateReviewRequestItems">
                            <a [routerLink]="['/requests', lateReviewRequestItem.lateReviewRequest.lateReviewRequestNumber]" [queryParams]="{type: RequestTypes.LateReview}">Request {{lateReviewRequestItem.lateReviewRequest.lateReviewRequestNumber}}</a>
                        </div>
                        <div class="summary-item-value" *ngFor="let reviewItem of contravention.reviewItems">
                            <a [routerLink]="['/review', reviewItem.review.reviewNumber]">{{reviewItem.review.isReReview ? 'Re-review' : 'Review' }} {{reviewItem.review.reviewNumber}}</a>
                        </div>
                        <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.JUDICIAL_REVIEWS">
                            <div class="summary-item-value" *ngFor="let judicialReview of contravention.judicialReviews">
                                <a [routerLink]="['/judicial-reviews', judicialReview.judicialReviewNumber]">Judicial Review {{judicialReview.judicialReviewNumber}}</a>
                            </div>
                        </app-access-control>
                    </div> 
                </div>
            </div>
            <div class="recent-activity-summary-header">
                Recent Activity                
                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="add-note-icon">
                            <i class="fas fa-plus-circle"></i>
                        </div>
                        <div class="add-note-link"  (click)="onAddNoteLinkClick()">Add a note</div>
                    </div>
                </app-access-control>
            </div>
            <div class="form-group recent-activity-group"> 
                
                <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
                    <div class="recent-activity-connector"></div>
                    <div *ngFor="let recentActivity of recentActivities; let i=index;" class="recent-activity-container">
                        <div class="recent-activity-dot" [ngClass]="{ 'recent-activity-dot-submitted': i==0}"></div>
                        <div class="recent-activity-header">
                            <div>{{recentActivity.date}}</div>
                            <div>by {{recentActivity.by}}</div>
                        </div>
                        <div class="recent-activity-title">{{recentActivity.title}}</div>
                        <div *ngIf="recentActivity.body != null && isCancelledByLEArequest(recentActivity)" class="recent-activity-content">
                            This contravention has been cancelled as the result of   
                            <a [routerLink]="['/requests', recentActivity.body]"
                                    [queryParams]="{type: requestType}">
                                    {{recentActivity.body}}
                            </a>                       
                        </div>                        
                        <div *ngIf="recentActivity.body != null && !isCancelledByLEArequest(recentActivity)" class="recent-activity-content">{{recentActivity.body}}</div>
                        <ng-container *ngIf="recentActivity.documents?.length > 0">
                            <div class="recent-activity-document" *ngFor="let document of recentActivity.documents">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onRecentActivityDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                            </div>
                        </ng-container>
                        <app-access-control 
                            *ngIf="canModifyNote(recentActivity)"
                            [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.ACTIVITY_NOTE">
                            <div class="recent-activity-edit">
                                <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                                <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                            </div>
                        </app-access-control>
                    </div>                    
                </ng-container>     
                
            </div> 
        </div>

        <div class="tabs-container">            
            <div class="tabs">
                <div class="tab-header" (click)="onTabHeaderClick($event)">
                    <div id="tabHeader_ContraventionInformation" class="tab-header-item selected">Contravention Information</div>
                    <div id="tabHeader_RecipientInformation" class="tab-header-item">Recipient Information</div>
                    <div id="tabHeader_PaymentInformation" class="tab-header-item">Payment Information</div>
                    <div id="tabHeader_ContraventionActivity" class="tab-header-item" (click)="contraventionActivityTabSelected()">Activity</div>
                    <div class="tab-header-item-fill"></div>
                </div>
                <div class="tab-content">
                    <div #scrolltarget id="tab_ContraventionInformation" class="tab-content-item selected">
                        <contravention-information (getContraventionChange)="contraventionInformationCallback()"></contravention-information>
                    </div>
                    <div id="tab_RecipientInformation" class="tab-content-item">
                        <recipient-information (getContraventionChange)="contraventionInformationCallback()"></recipient-information>
                    </div>
                    <div id="tab_PaymentInformation" class="tab-content-item">
                        <payment-information (viewReceiptClicked)="onViewReceiptClicked($event)" (paymentInfoEdited)="onPaymentInfoEdited($event)"></payment-information>
                    </div>
                    <div id="tab_ContraventionActivity" class="tab-content-item">
                        <contravention-activity (activityNoteSubmitted)="refreshContravention($event)"></contravention-activity>
                    </div>
                </div>
            </div>               
        </div>                
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>         