import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { AdjudicationService } from '../../../services/adjudication.service';

@Component({
  selector: 'app-enable-late-review-modal',
  templateUrl: './enable-late-review-modal.component.html',
  styleUrls: ['./enable-late-review-modal.component.scss'],
})
export class EnableLateReviewModalComponent implements OnInit {
  contraventionId: number;
  vehicleSeizureId: number;

  reviewStatusTypeId: number = 0;
  selectedReviewStatusTypeId: number;
  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;

  confirmButton: JQuery<HTMLElement>;
  reason: string = "";
  isSubmitClicked: boolean = false;
  errorMessage: string;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private readonly adjudicationService: AdjudicationService) {}

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass('overflow-hidden');
    this.modalOverlay = $('.modal-overlay');
    this.modalTextarea = $('.modal-textarea');
    this.confirmButton = $('.confirm-button');
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;
  }

  onCloseIconClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onConfirmModalClick(isValid: boolean): void {
    if (this.confirmButton.hasClass('disabled')) {
      return;
    }

    this.isSubmitClicked = true;

    if (isValid) {
      this.confirmButton.addClass('disabled');
      this.confirmButton.addClass('saving');

      if (this.contraventionId != null) {
        this.adjudicationService
          .enableContraventionLateReview(this.contraventionId, this.reason)
          .subscribe((result: Contravention) => {
            if (result != null) {
              this.removeOverflow();
              this.close.emit(result);
            }
          }, (error: any) => {
            this.confirmButton.removeClass("saving");
            this.confirmButton.removeAttr("disabled");

            if (error.status == 401 || error.status == 403) {
              this.errorMessage = "You are unauthorized to perform this operation";
            }          
            else  {
              this.showErrors(error);
            }                    
          });
      } else if (this.vehicleSeizureId != null) {
        this.adjudicationService
          .enableVehicleSeizureLateReview(this.vehicleSeizureId, this.reason)
          .subscribe((result: VehicleSeizure) => {
            if (result != null) {
              this.removeOverflow();
              this.close.emit(result);
            }
          }, (error: any) => {
            this.confirmButton.removeClass("saving");
            this.confirmButton.removeAttr("disabled");

            if (error.status == 401 || error.status == 403) {
              this.errorMessage = "You are unauthorized to perform this operation";
            }          
            else  {
              this.showErrors(error);
            }                    
          });
      }
    }
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  removeOverflow(): void {
    this.bodyElement.removeClass('overflow-hidden');
  }
}
