import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentTypes, StayOrderStatusTypes } from "@apis/shared/enums/app.enum";
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { Document } from '@apis/shared/models/document.model';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { StayOrder } from '@apis/shared/models/stay-order.model';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-stay-order',
  templateUrl: './stay-order.component.html',
  styleUrls: ['./stay-order.component.scss']
})
export class StayOrderComponent implements OnInit {

  judicialReview: JudicialReview
  stayOrder: StayOrder;
  isRevoke: boolean;
  isEdit: boolean;
  isAdd: boolean;

  stayOrderUploadsDocumentTypeId: number = DocumentTypes.CourtOrder;

  stayOrderUploads: Document[];
  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  document: Document;

  errorMessage: string = "";
  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;

  submitButton: JQuery<HTMLElement>;

  disableFileUpload: boolean = false;
  formSubmitted: boolean = false;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];  

  isCourtActionNumberReadOnly: boolean = false;
  isEffectiveDateReadOnly: boolean = false;

  stayOrderEffectiveDate: Date;
  stayOrderExpirationDate: Date;
  cancelledDate: Date;
  stayOrderCourtActionNumber: string = "";
  validFileExtensions: string[];
  newDocuments: Document[] = [];

  @Output() close: EventEmitter<JudicialReview> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private readonly adjudicationService: AdjudicationService) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
  }

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");
    this.submitButton = $(".submit-button");

    if (this.judicialReview && this.judicialReview.courtActionNumber) {
      this.stayOrderCourtActionNumber = this.judicialReview.courtActionNumber;
      this.isCourtActionNumberReadOnly = true;
    }

    if (this.stayOrder) {
      if (this.stayOrder?.stayOrderCourtActionNumber != null) {
        this.isCourtActionNumberReadOnly = true;
        this.stayOrderCourtActionNumber = this.stayOrder?.stayOrderCourtActionNumber;
      }

      if (this.stayOrder?.effectiveDate != null) {
        this.isEffectiveDateReadOnly = true;
        this.stayOrderEffectiveDate = new Date(this.stayOrder.effectiveDate);
      }

      if(this.stayOrder?.expirationDate != null)
      {
        this.stayOrderExpirationDate =  new Date(this.stayOrder.expirationDate);
      }      

    }
    this.tempFileFolder = Guid.create().toString();
    this.documentRefTypeName = "JudicialReviews/StayOrders";
    this.documentRefTypeNumber = this.stayOrder?.stayOrderId.toString();
    this.validFileExtensions = this.localStorageService.getDocumentTypes()?.find(x => x.id == this.stayOrderUploadsDocumentTypeId).acceptedFileTypes.split(',').map(item => `.${item}`);
    this.populateStayOrderUploads();
  }

  private populateStayOrderUploads(): void {
    if (this.isAdd) {
      this.stayOrderUploads = this.newDocuments;
    }
    else {
      this.stayOrderUploads = this.stayOrder?.documents?.filter(d => +d.documentTypeId == DocumentTypes.CourtOrder);
    }
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.documentId > 0) //Document at permanent location
    {
      this.documentService.downloadDocument("", this.documentRefTypeName , this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
          fileSaver.saveAs(result, document.documentName);
        },
        (error: any) => {});
    }
    else //Document at temporary location
    {
      this.documentService.downloadDocument(this.tempFileFolder, "", "", storageFileName, document.documentName)
      .subscribe((result: Blob) => {
          fileSaver.saveAs(result, document.documentName);
        },
        (error: any) => {});
    }
  }

  onRemoveUploadClick(document: Document): void {
    if (this.disableFileUpload) {
      return;
    }

    if (this.isAdd) {
      this.newDocuments?.splice(this.newDocuments.findIndex(x => x == document), 1);
    }
    else {
      let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
      this.documentService.deleteFinalizedDocument(storageFileName, this.documentRefTypeName, this.documentRefTypeNumber)
        .subscribe((result: any) => { });

      this.adjudicationService.deleteStayOrderDocument(this.stayOrder.stayOrderId, document.documentId)
        .subscribe((result: StayOrder) => {
          if (result != null) {
            this.stayOrder = result;
            this.populateStayOrderUploads();
          }
        });
    }
  }

  onStayOrderUploadStarted(): void {
    this.submitButton.addClass("disabled");
  }

  onStayOrderUploadAdded(document: Document): void {
    document.isPublished = false;
    if (this.isAdd) {
      this.newDocuments.push(document);
      this.submitButton.removeClass("disabled");
    }
    else {      
      this.documentService.finalizeDocuments(this.tempFileFolder, this.documentRefTypeName, this.documentRefTypeNumber)
        .subscribe((result: any) => { });

      this.adjudicationService.postStayOrderDocument(this.stayOrder?.stayOrderId, document)
        .subscribe((result: StayOrder) => {
          this.stayOrder = result;
          this.populateStayOrderUploads();
          this.submitButton.removeClass("disabled");
        });
    }
  }


  onCloseIconClick(): void {
    this.newDocuments = [];  
    this.removeOverflow();
    this.close.emit();
  }

  onSubmitClick(ev: any): void {
    this.formSubmitted = true;
    if (this.submitButton.hasClass("disabled")) {
      return;
    }

    if ((this.isAdd && this.stayOrderEffectiveDate) || (this.isRevoke && this.cancelledDate) || this.isEdit) {
      this.submitButton.addClass("disabled");
      this.submitButton.addClass("saving");
      this.disableFileUpload = true;

      if (this.isAdd) {
        this.stayOrder = new StayOrder({
          stayOrderCourtActionNumber: this.stayOrderCourtActionNumber,
          judicialReviewId: this.judicialReview.judicialReviewId,
          effectiveDate: this.stayOrderEffectiveDate,
          expirationDate: this.stayOrderExpirationDate,
          stayOrderStatusTypeid: StayOrderStatusTypes.Active,
          documents: this.newDocuments,
          tempFolderName:this.tempFileFolder
        });

        //service call and add stay order
        this.adjudicationService.addStayOrder(this.stayOrder)
          .subscribe(
            (result: JudicialReview) => {
              this.removeOverflow();
              this.close.emit(result);
            },
            (error: any) => { });
      }
      else {
        if (this.isRevoke) {        
          this.stayOrder.stayOrderCancelledDate = new Date(this.cancelledDate.getFullYear(),this.cancelledDate.getMonth(), this.cancelledDate.getDate());
        }

        if (this.isEdit) {
          if (this.stayOrderExpirationDate) {
            this.stayOrder.expirationDate = new Date(this.stayOrderExpirationDate.getFullYear(),this.stayOrderExpirationDate.getMonth(),this.stayOrderExpirationDate.getDate());           
          } else {
            this.stayOrder.expirationDate = null;
          }
        }

        //service call and refresh stay order
        this.adjudicationService.putStayOrder(this.stayOrder)
          .subscribe(
            (result: JudicialReview) => {
              this.removeOverflow();
              this.close.emit(result);
            },
            (error: any) => { });
      }
    }
  }

  validateDocuments(): boolean { 
    if(this.isAdd && this.newDocuments.length == 0) 
    {
      this.errorMessage = "A Court Order is required.";
      return false;
    }  
    else if (this.stayOrderUploads.filter(x => x && x.documentTypeId == DocumentTypes.CourtOrder && x.documentName != null && x.documentName.trim().length > 0).length == 0) {
      this.errorMessage = "A Court Order is required.";
      return false;
    }
    return true;
  }

  removeOverflow(): void {
    this.bodyElement.removeClass("overflow-hidden");
  }

}
