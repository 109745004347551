


export class ContraventionPagedSearch {
    public pageNumber: number;
    public pageSize: number;
    public searchTerm: string;
    public orderBy: string;
    public sortDirection: string
    public contraventionTypeId: number;
    public contraventionStatusTypeIds: string;
    public checkRequiresRedaction: boolean;
    public checkPendingSubmissions: boolean;

    constructor(init?: Partial<ContraventionPagedSearch>) {
        
        Object.assign(this, init);
    }
}
