import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { parseJSON } from 'date-fns';

@Component({
  selector: 'app-review-last-viewed-modal',
  templateUrl: './review-last-viewed-modal.component.html',
  styleUrls: ['./review-last-viewed-modal.component.scss']
})
export class ReviewLastViewedModalComponent implements OnInit {
  review: Review;

  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;

  lastViewedName: string;
  lastViewedMinutesString: string;

  @Output() close: EventEmitter<Review> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");

    this.lastViewedName = this.review.lastViewedAdjudicatorName;
    this.lastViewedMinutesString = this.getMinutesAgoString(this.review.lastViewedAdjudicatorDate);
  }

  getMinutesAgoString(lastViewedDate: Date): string {
    const millisecondsSinceLastViewed = new Date().getTime() - parseJSON(lastViewedDate).getTime();
    const minutesSinceLastViewed = millisecondsSinceLastViewed / 60000;

    if (minutesSinceLastViewed < 1) {
      return 'less than a minute'
    }
    else if (minutesSinceLastViewed >= 1 && minutesSinceLastViewed < 2) {
      return '1 minute'
    }
    else {
      return Math.floor(minutesSinceLastViewed).toString() + ' minutes';
    }
  }
 
  onCloseIconClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onOkayButtonClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  removeOverflow(): void {
    this.bodyElement.removeClass("overflow-hidden");
  }
}
