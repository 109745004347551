import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';
import { EventTypes, DocumentTypes, SeizureStatusTypes } from '@apis/shared/enums/app.enum';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { Document } from '@apis/shared/models/document.model';
import { Event } from '@apis/shared/models/event.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';

@Component({
  selector: 'app-cancel-seizure-modal',
  templateUrl: './cancel-seizure-modal.component.html',
  styleUrls: ['./cancel-seizure-modal.component.scss']
})
export class CancelSeizureModalComponent implements OnInit, AfterViewInit {
  vehicleSeizure: VehicleSeizure
  vehicleSeizureNumber: string;
  isSubmitClicked: boolean = false;
  maxCharacters: number = 250;
  bodyElement: JQuery<HTMLElement>;
  cancellationReason: string = "";
  isSeizureCancelled: boolean = false;
  errorMessage: string;
  errorMessageHeader: string;
  requiredDocumentUploadError: string;
  additionalDocumentUploadError: string;
  additionalDocumentName: string;
  requiredDocumentName: string;  
  requiredDocument: Document;
  additionalDocument: Document;
  uploadingRequiredDocument: boolean = false;
  uploadingAdditionalDocument: boolean = false;
  confirmButton: JQuery<HTMLElement>;

  requiredDocumentContainer: HTMLElement;
  requiredDocumentUploadContainer: HTMLElement;
  additionalDocumentUploadContainer: HTMLElement;  
  additionalDocumentButton: JQuery<HTMLElement>;

  cancellationReasonTextarea: JQuery<HTMLElement>;
  documents: Document[] = [];

  tempFileFolder: string;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;

  close: EventEmitter<VehicleSeizure> = new EventEmitter<VehicleSeizure>();

  @ViewChild("cancellationReasonModel") cancellationReasonModel: NgModel;

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly adjudicationService: AdjudicationService,
    private readonly documentService: DocumentService) { }

  ngAfterViewInit(): void {    
    this.cancellationReasonModel.control.setValidators(Validators.required);

    this.requiredDocumentContainer = document.getElementById("requiredDocumentContainer");
    this.requiredDocumentUploadContainer = document.getElementById("requiredDocumentUploadContainer");
    this.additionalDocumentUploadContainer = document.getElementById("additionalDocumentUploadContainer");
 
    this.additionalDocumentButton = $(".additional-document-button");
    this.cancellationReasonTextarea = $("#cancellationReasonTextarea");
    this.confirmButton = $(".confirm-button");

    if (this.vehicleSeizure?.seizureStatusTypeId == SeizureStatusTypes.Cancelled) {            
      this.cancellationReasonTextarea.attr("disabled", ""); 
      this.additionalDocumentButton.attr("disabled", "");            
    } 
  }

  ngOnInit(): void {        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");

    this.vehicleSeizureNumber = this.vehicleSeizure.seizureNumber;
    

    this.tempFileFolder = Guid.create().toString();          
    this.username = this.localStorageService.getAdjudicationUser()?.userName;
    
    if (this.vehicleSeizure.documents?.length > 0) {
      this.documents = this.vehicleSeizure.documents.filter(d => 
        +d.documentTypeId == DocumentTypes.VehicleSeizureCancellationAuthorization
        || +d.documentTypeId == DocumentTypes.VehicleSeizureCancellationAdditional);
    }
       
    if (+this.vehicleSeizure.seizureStatusTypeId == SeizureStatusTypes.Cancelled) {
      this.cancellationReason = this.vehicleSeizure?.events.find(x => +x.eventTypeId == EventTypes.SeizureCancelled)?.eventDetails;
      this.maxCharacters = 250 - this.cancellationReason.length;
      this.isSeizureCancelled = true; 
      this.errorMessageHeader = "Vehicle Seizure cancelled";
    }    
  }
  
  onCloseIconClick(): void {        
    this.bodyElement.removeClass("overflow-hidden");
    this.removeFilesBeforeClose();
    this.close.emit(null);
  }

  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.removeFilesBeforeClose();
    this.close.emit(null);
  }

  private removeFilesBeforeClose(): void {
    if (this.requiredDocument != null) {
      this.onRemoveFileLinkClick(this.requiredDocument);
    } 

    if (this.additionalDocument != null) {
      this.onRemoveFileLinkClick(this.additionalDocument);
    }
  }
 
  onCancelTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }

  onConfirmCancellationClick(isValid: boolean) {
    if (this.confirmButton.hasClass("disabled")) {
      return;
    }

    this.isSubmitClicked = true;
    
    if (isValid 
        && !this.isSeizureCancelled 
        && this.maxCharacters >= 0) {
          this.showHideConfirmModal(true);
    }
  } 

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onUploadRequiredDocumentClick(fileElement: HTMLInputElement): void {
    fileElement.value = null;
    fileElement.click();
  }
  
  onRequiredDocumentChange(ev: any): void {    
    let files: FileList = ev.target.files;            
    let uploadBarProgress = document.getElementById("requiredDocumentUploadProgress");
 
    this.requiredDocumentUploadError = "";
    for (let index = 0; index < files.length; index++) {
      let file = files.item(index) as File;
                  
      let lastIndex = file.name.lastIndexOf(".");
      let documentExtension = file.name.substring(lastIndex + 1);     

      if (!this.isValidRequiredDocumentMimeType(file)) {
        this.requiredDocumentUploadError = "Invalid file type. PDF only. ";
      }
           
      if (!this.isSizeLessThan5MB(file)) {
        this.requiredDocumentUploadError += "Maximum file size is 5 MB."
      }
      
      if (this.requiredDocumentUploadError.length > 0) {
        return;
      }
      
      this.requiredDocumentContainer.classList.add("hide");
      this.requiredDocumentUploadContainer.classList.add("show");
      this.requiredDocumentName = file.name;
      this.uploadingRequiredDocument = true;

      let storageFileName = Guid.create().toString();
      this.documentService.uploadDocumentAsync(file, this.tempFileFolder, storageFileName)
        .subscribe((result: any) => {
          if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;         
          } else if (+result.type == 4) {           
              this.requiredDocument = new Document({
                contentGuid: storageFileName,
                documentTypeId: DocumentTypes.VehicleSeizureCancellationAuthorization,
                documentName: file.name,
                documentExtension: documentExtension,
                documentSize: CommonUtil.getDocumentSize(file),
                uploadedBy: this.username,
                isSubmitLater: false,
                isPublished: false                            
              });
            
              uploadBarProgress.innerHTML = "Upload Complete";
              this.uploadingRequiredDocument = false;
          }            
        });
    }
  }

  onUploadAdditionalDocumentClick(fileElement: HTMLInputElement): void {
    fileElement.value = null;
    fileElement.click();
  }

  onAdditionalDocumentUploadChange(ev: any): void {
    let files: FileList = ev.target.files;
    let uploadBarProgress = document.getElementById("additionalDocumentUploadProgress");

    this.additionalDocumentUploadError = "";
    for (let index = 0; index < files.length; index++) {
      let file = files.item(index);
      let lastIndex = file.name.lastIndexOf(".");
      let documentExtension = file.name.substring(lastIndex + 1);     

      if (!this.isSizeLessThan5MB(file)) {
        this.additionalDocumentUploadError = "Maximum file size is 5 MB.";
      }

      if (!this.isValidAdditionalDocumentMimeType(file)) {
        this.additionalDocumentUploadError += " Invalid file type.";
      }
      
      if (this.additionalDocumentUploadError.length > 0) {
        return;
      }
      
      this.additionalDocumentUploadContainer.classList.add("show");
      this.additionalDocumentName = file.name;
      this.uploadingAdditionalDocument = true;

      let storageFileName = Guid.create().toString();
      this.documentService.uploadDocumentAsync(file, this.tempFileFolder, storageFileName)
        .subscribe((result: any) => {
          if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;
         
          } else if (+result.type == 4) {           
              this.additionalDocument = new Document({
                contentGuid: storageFileName,
                documentTypeId: DocumentTypes.VehicleSeizureCancellationAdditional,
                documentName: file.name,
                documentExtension: documentExtension,
                documentSize: CommonUtil.getDocumentSize(file),
                uploadedBy: this.username,
                isSubmitLater: false,
                isPublished: false 
              });
            
              uploadBarProgress.innerHTML = "Upload Complete";
              this.additionalDocumentButton.attr("disabled", "");
              this.uploadingAdditionalDocument = false;
          }            
        });
    }
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "VehicleSeizures", this.vehicleSeizure.seizureNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onTempDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument(this.tempFileFolder, "", "", storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onRemoveFileLinkClick(document: Document): void {
    if (!document) { // If the "Remove File" link is clicked before the document has finished uploading
      return;
    }
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
    this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
      .subscribe((result: any) => {
        if (+document.documentTypeId == DocumentTypes.VehicleSeizureCancellationAuthorization) {
          this.requiredDocument = null;
          this.requiredDocumentUploadContainer.classList.remove("show");
          this.requiredDocumentContainer.classList.remove("hide");

        } else if (+document.documentTypeId == DocumentTypes.VehicleSeizureCancellationAdditional) {
          this.additionalDocument = null;
          this.additionalDocumentUploadContainer.classList.remove("show");
          this.additionalDocumentButton.removeAttr("disabled");
        }
      },
      (error: any) => {});
  }

  isValidRequiredDocumentMimeType(file: File): boolean {
    return ["application/pdf"].includes(file.type.toLowerCase());
  }

  isValidAdditionalDocumentMimeType(file: File): boolean {
    return ["application/pdf", 
      "image/jpeg", 
      "image/png", 
      "image/gif", 
      "text/plain", 
      "application/msword", 
      "application/vnd.openxmlformats"].includes(file.type.toLowerCase());
  }

  isSizeLessThan2MB(file: File): boolean {
    return file.size / (Math.pow(1024, 2)) <= 2;
  }

  isSizeLessThan5MB(file: File): boolean {
    return file.size / (Math.pow(1024, 2)) <= 5;
  }

  getDocumentType(documentTypeId: number): string {
    if (documentTypeId == DocumentTypes.VehicleSeizureCancellationAuthorization) return "Cancellation Authorization";

    if (documentTypeId == DocumentTypes.VehicleSeizureCancellationAdditional) return "Additional Document";
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.vehicleSeizure.events.push(new Event({
      eventTypeId: EventTypes.SeizureCancelled,
      eventDetails: this.cancellationReason,
      userName: this.username
    }));

    if (this.requiredDocument != null) {
      this.vehicleSeizure.documents.push(this.requiredDocument);
    }

    if (this.additionalDocument != null) {
      this.vehicleSeizure.documents.push(this.additionalDocument);
    }
    
    this.adjudicationService.cancelVehicleSeizure(this.vehicleSeizure)
      .subscribe((seizure: VehicleSeizure) => {
        this.errorMessage = ""; 
        
        if (this.requiredDocument != null || this.additionalDocument != null)
        {
        this.documentService.finalizeDocuments(this.tempFileFolder, "VehicleSeizures", this.vehicleSeizure.seizureNumber)
          .subscribe((result: any) => {
            this.bodyElement.removeClass("overflow-hidden");
            this.spinner.hide();
            this.close.emit(seizure);     
          });              
        }
        else
        {
          this.bodyElement.removeClass("overflow-hidden");
          this.spinner.hide();
          this.close.emit(seizure);     
        }        
      }, (error: any) => {
        this.showHideConfirmModal(false);
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else  {
          this.showErrors(error);
        }                    
        this.spinner.hide();
      });
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      this.confirmModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
    }
  }
}
