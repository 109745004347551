import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Event } from "@apis/shared/models/event.model";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContactMethodTypes, EventTypes, NotificationTypes } from "@apis/shared/enums/app.enum";
import { NgModel, Validators } from '@angular/forms';
import { Notification } from '@apis/shared/models/notification.model';

import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { RequestService } from '../../../shared/services/request.service';

@Component({
  selector: 'app-cancel-request-modal',
  templateUrl: './cancel-request-modal.component.html',
  styleUrls: ['./cancel-request-modal.component.scss']
})
export class CancelRequestModalComponent implements OnInit, AfterViewInit {
  request: any;  
  requestType: any;
    
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;
    
  confirmCancellationButton: JQuery<HTMLElement>;
  eventDetails: string;  
  
  formSubmitted: boolean = false;
  @ViewChild("eventDetailsModel") eventDetailsModel: NgModel;
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly requestService: RequestService) { }

  ngAfterViewInit(): void {
    this.eventDetailsModel.control.setValidators(Validators.required);
  }

  ngOnInit(): void {            
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.confirmCancellationButton = $(".confirm-cancellation-button");      
  }
  
  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onConfirmCancellationClick(ev: any): void {
    this.formSubmitted = true;
    if (this.eventDetailsModel.invalid) {
      return;
    }
  
    this.confirmCancellationButton.addClass("disabled");
    this.confirmCancellationButton.addClass("saving");

    let event = new Event({      
      eventTypeId: EventTypes.RequestCancelled,
      eventDetails: this.eventDetails      
    });
        
    let notification = new Notification({      
      notificationTypeId: NotificationTypes.RequestIsCancelled,
      contactMethodTypeId: +this.request.contactMethodTypeId    
    })
    
      const lateReviewRequest = new LateReviewRequest({
        lateReviewRequestNumber: this.request.lateReviewRequestNumber,
      });
  
      lateReviewRequest.events.push(event);
      lateReviewRequest.notifications.push(notification);
                
      this.requestService.cancelLateReviewRequest(lateReviewRequest)
        .subscribe(
          (result: LateReviewRequest) => { 
            this.confirmCancellationButton.removeClass("disabled");
              this.removeOverflow();
              this.close.emit(result);
          },
          (error: any) => {});    
        
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  
}