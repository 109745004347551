import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestStatusTypes, ReviewStatusTypes } from '@apis/shared/enums/app.enum';
import * as $ from "jquery";

@Component({
  selector: 'status-checkboxes',
  templateUrl: './status-checkboxes.component.html',
  styleUrls: ['./status-checkboxes.component.scss']
})
export class StatusCheckboxesComponent implements OnInit {
  @Input() statusTypes: any[];  
  @Output() change = new EventEmitter<number[]>();

  ReviewStatusTypes = ReviewStatusTypes;
  RequestStatusTypes = RequestStatusTypes;
  constructor() { }

  ngOnInit(): void { }

  init(initialValue: number[]): void {
    $(".status-checkbox-container").each((index: number, element: HTMLElement) => {
      var statusTypeId = parseInt(element.id.split("_")[1]);
      if (initialValue.includes(statusTypeId)) {
        element.classList.add("active");
      }
    });
  }

  selectAll(): void {
    $(".status-checkbox-container").addClass("active");
    this.change.emit(this.getActiveStatusTypeIds());
  }

  clearAll(): void {
    $(".status-checkbox-container").removeClass("active");    
  }
  
  onStatusCheckboxClick(ev: any): void {
    if (ev.target.parentElement.classList.contains("active")) {
      ev.target.parentElement.classList.remove("active");
    } else {
      ev.target.parentElement.classList.add("active");
    }

    this.change.emit(this.getActiveStatusTypeIds());
  }

  private getActiveStatusTypeIds(): number[] {
    return $(".status-checkbox-container.active").map((index: number, element: HTMLElement) => parseInt(element.id.split("_")[1])).toArray();
  }
  
}
