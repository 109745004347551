import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscriber, throwError } from 'rxjs';
import { map, find, catchError } from "rxjs/operators";
import { environment } from "apps/adjudication/src/environments/environment";
import { Contravention } from '@apis/shared/models/contravention.model';
import { Review } from '@apis/shared/models/review.model';
import { Document } from '@apis/shared/models/document.model';
import { Event } from '@apis/shared/models/event.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Notification } from '@apis/shared/models/notification.model';
import { ReviewOutcome } from '@apis/shared/models/review-outcome.model';
import { ReviewSchedule } from '@apis/shared/models/review-schedule.model';
import { ReviewView } from '@apis/shared/models/review-view.model';
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { PaymentDataEditRequest } from '@apis/shared/models/payment-data-edit-request.model';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';

@Injectable()
export class ReviewService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }
  
  //Reviews
  getReviews(): Observable<Review[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/reviews`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => {
          if (Array.isArray(response)) {
            return (Array.from<Review>(response));
          }
          
          return [new Review(response)];
        })
      );
  }

  getReviewByNumber(reviewNumber: string): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/reviews/${reviewNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => {
          if (Array.isArray(response)) {
            return response.find(review => +review.reviewNumber === +reviewNumber);
          }

          return new Review(response);
        })
      );
  }

  postReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/reviews`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new Review(response))
      );
  }

  putReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new Review(response)));
  }

  putReviewStatus(review: any): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/reviewstatus`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }

  putDecision(review: any): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/decision`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }    
  
  deleteDecision(reviewId: number): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/reviews/decision`, 
    { 
      params: {
        reviewId: reviewId.toString()
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => response)
    );
  }

  postDocument(reviewId: number, document: Document): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/reviews/document`, document,
      {
        params: {
          reviewId: reviewId.toString()
        },
        headers: {'Authorization': `Bearer ${this.user.token}`}
      }).pipe(
        map(response => new Review(response)));
  }

  publishDocument(reviewId: number, documentId: number): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/document/publish`, null, {
      params: {
        reviewId: reviewId.toString(),
        documentId: documentId.toString()
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new Review(response)));
  }

  unPublishDocument(reviewId: number, documentId: number): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/document/unpublish`, null, {
      params: {
        reviewId: reviewId.toString(),
        documentId: documentId.toString()
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new Review(response)));
  }

  deleteDocument(reviewId: number, documentId: number): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/reviews/document`, {
      params: {
        reviewId: reviewId.toString(),
        documentId: documentId.toString()
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new Review(response)));
  }

  getReviewAvailability(reviewId: number, reviewTypeId: number, reviewMethodTypeId: number, currentScheduledEventId: number): Observable<AvailabilityView> {
    this.user = this.localStorageService.getUser();
    
    let params = new HttpParams();
    params = params.append('reviewId', reviewId.toString());
    params = params.append('reviewTypeId', reviewTypeId.toString());
    params = params.append('reviewMethodTypeId', reviewMethodTypeId.toString());
    if (currentScheduledEventId) {
      params = params.append('currentScheduledEventId', currentScheduledEventId.toString());
    }
    
    return this.httpClient.get(`${this.apiUrl}/reviews/availability`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params
    }).pipe(
        map(response => new AvailabilityView(response))
      );
  }

  scheduleReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/schedulereview`, review,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      );
  }

  reScheduleReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/reschedulereview`, review,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      );
  }

  putNotification(notification: Notification): Observable<Notification> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/notification`, notification,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Notification(response))
      );
  }

  reassignAdjudicator(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser()
    return this.httpClient.put(`${this.apiUrl}/reviews/reassignadjudicator`, review,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      )
  }

  putContactMethod(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/contactmethod`, review,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      );
  }

  putDecisionDueDate(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/decisionduedate`, review,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      );
  }

  cancelReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/cancelreview`, review,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      );
  }

  putOutcome(reviewOutcome: ReviewOutcome): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/outcome`, reviewOutcome,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      });
  }

  putReviewMethod(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/reviewmethod`, review, { 
        headers: { "Authorization": this.user.token }
      }).pipe(
        map(response => new Review(response))
      );
  }

  getConflictingReviewTime(reviewSchedule: ReviewSchedule): Observable<string> {
    return this.httpClient.put(`${this.apiUrl}/reviews/conflictingreviewtime`, reviewSchedule)
      .pipe(
        map(response => response?.toString())
      );
  }

  getFeePaymentReceipt(reviewNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/reviews/${reviewNumber}/payment-receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  getPendingDecisionReviews(userId: number, startDate: Date, endDate: Date): Observable<ReviewView[]> {
    this.user = this.localStorageService.getUser();

    let params = new HttpParams();
    params = params.append('userId', userId.toString());
    params = params.append('startDate', startDate.toISOString());
    params = params.append('endDate', endDate.toISOString());

    return this.httpClient.get(`${this.apiUrl}/reviews/pending-decisions`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params
    }).pipe(
        map(response => {
          if (Array.isArray(response)) {
            return (Array.from<ReviewView>(response));
          }
          
          return [new ReviewView(response)];
        })
      );
  }

  updateReviewPaymentInformation(reviewNumber: string, paymentDataEditRequest: PaymentDataEditRequest): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/${reviewNumber}/edit-payment`, paymentDataEditRequest,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Review(response))
    );
  }

  updateFileClosureFlagAsync(reviewId: number): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/fileclosurecomplete`, null, {
      params: {
        reviewId: reviewId.toString(),
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new Review(response)));
  }

  updateLastViewedAdjudicator(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/lastviewed`, review,
      {
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new Review(response))
      );
  }

  logRefund(logRefundRequest: LogRefundRequest): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/reviews/${logRefundRequest.recordNumber}/payments/${logRefundRequest.sourceTransactionId}/log-refund`,
     logRefundRequest, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
      .pipe(
        map(response => new Review(response)));
  }

  updateReviewDecisionDate(reviewId: number, decisionDate: string): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/reviews/decision/${reviewId}/decisionDate/${decisionDate}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Review(response))
    );
  }
}
