<div class="d-flex flex-column h-100">
    <div class="header">
        Manage Towing Companies
    </div>
    <div class="main">
        <div class="main-content w-100">
            <div class="content-header">Search for a towing company</div>

            <form #validateTowSearchForm="ngForm">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="search-box">
                        <input id="towSearch" required type="text" class="form-control" placeholder="Search by towing company name or city" 
                            name="towSearch" #towSearchControl="ngModel"
                            [ngClass]="{ 'is-invalid': (towSearchControl.touched || towSearchControl.submitted) && towSearchControl.invalid }"
                            [(ngModel)]="towSearchText"
                            (keydown)="onTowSearchKeyDown($event, validateTowSearchForm.valid)" />                
                        <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick(validateTowSearchForm.valid)"><i class="fas fa-search fa-lg"></i></button>                
                    </div>
                </div>
                <input required type="hidden" id="towSearchHidden" name="towSearchHidden" #towSearchHiddenControl="ngModel" [value]="null" [(ngModel)]="towSearchText" 
                    [ngClass]="{'is-invalid': (towSearchControl.touched || validateTowSearchForm.submitted) && towSearchHiddenControl.invalid}">
                <div class="invalid-feedback">
                    <div>Invalid search</div>
                </div>
            </form>

            <div *ngIf="towCompanies" class="result-container">
                <div>
                    <table class="table">
                        <thead>
                            <th>Towing company name</th>
                            <th>Tow lot address</th>
                            <th>City/town/village</th>
                            <th>Postal code</th>
                            <th>Telephone</th>
                            <th>Fax</th>
                            <th>Email</th>
                            <th *ngIf="hasEditPermissions">Actions</th>
                        </thead>
                        <tr *ngFor="let tow of towCompanies" [class]="getTableRowClass(tow)">
                            <td *ngIf="!tow.isEditing">{{tow.towCompanyName}}</td>
                            <td *ngIf="!tow.isEditing">{{tow.towLotAddress}}</td>
                            <td *ngIf="!tow.isEditing">{{tow.city}}</td>
                            <td *ngIf="!tow.isEditing">{{tow.postalCode}}</td>
                            <td *ngIf="!tow.isEditing">{{tow.phoneNumber}}</td>
                            <td *ngIf="!tow.isEditing">{{tow.faxNumber}}</td>
                            <td *ngIf="!tow.isEditing">{{tow.towLotEmailAddress}}</td>

                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towCompanyName" required type="text" #towCompanyNameControl="ngModel" [(ngModel)]="tow.towCompanyName" [ngClass]="{'is-invalid':(towCompanyNameControl.touched || isSubmitClicked) && towCompanyNameControl.invalid}"/></td>
                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towLotAddress" required type="text" #towLotAddressControl="ngModel" [(ngModel)]="tow.towLotAddress" [ngClass]="{'is-invalid':(towLotAddressControl.touched || isSubmitClicked) && towLotAddressControl.invalid}"/></td>
                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towCity" required type="text" #towCityControl="ngModel" [(ngModel)]="tow.city" [ngClass]="{'is-invalid':(towCityControl.touched || isSubmitClicked) && towCityControl.invalid}"/></td>
                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towPostalCode" required type="text" #towPostalControl="ngModel" [(ngModel)]="tow.postalCode" [ngClass]="{'is-invalid':(towPostalControl.touched || isSubmitClicked) && towPostalControl.invalid}"/></td>
                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towPhoneNumber" required type="text" #towPhoneControl="ngModel" [(ngModel)]="tow.phoneNumber" [ngClass]="{'is-invalid':(towPhoneControl.touched || isSubmitClicked) && towPhoneControl.invalid}"/></td>
                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towFaxNumber" type="text" #towFaxControl="ngModel" [(ngModel)]="tow.faxNumber" [ngClass]="{'is-invalid':(towFaxControl.touched || isSubmitClicked) && towFaxControl.invalid}"/></td>
                            <td *ngIf="tow.isEditing"><input class="form-control table-textbox" id="towLotEmailAddress" type="text" #towEmailControl="ngModel" [(ngModel)]="tow.towLotEmailAddress" [ngClass]="{'is-invalid':(towEmailControl.touched || isSubmitClicked) && towEmailControl.invalid}"/></td>

                            <td *ngIf="hasEditPermissions">
                                <div class="d-flex" *ngIf="!tow.isEditing">
                                    <div title="Edit Tow Company" class="data-edit-button" (click)="onEditTowCompanyClick(tow)"></div>
                                    <div title="Delete Tow Company" class="data-delete-button" (click)="onDeleteTowCompanyClick(tow)"></div>
                                </div>
                                <div class="d-flex" *ngIf="tow.isEditing">
                                    <div title="Save Changes" class="data-edit-ok-button" (click)="onSaveTowCompanyClick(tow)"></div>
                                    <div title="Discard Changes" class="data-edit-cancel-button" (click)="onCancelTowCompanyClick(tow)"></div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="towCompanies.length == 0">
                            <td colspan="6">No towing company found</td>
                        </tr>
                    </table>
                    <div *ngIf="errorMessage">
                        <span class="error">{{errorMessage}}</span>
                    </div>
                    <div class="d-flex justify-content-end" *ngIf="hasEditPermissions">
                        <button class="btn button-primary" type="button" (click)="onAddTowCompanyClick()" [disabled]="isAnyTowCompanyInEditMode()">
                            Add new towing company
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>