

import { CountryType } from './types/country-type.model';

export class ContactAddress {
    public contactAddressId: number = 0;
    public addressLine1: string;
    public addressLine2: string;
    public city: string;
    public provinceId: number;
    public province: string;
    public countryId: number;
    public postalCode: string;
    public country: CountryType;

    constructor(init?: Partial<ContactAddress>) {
        
        Object.assign(this, init);
    }
}
