import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@apis/shared/shared.module";
import { NgxMaskDirective } from 'ngx-mask';
import { DashboardComponent } from './dashboard.component';
import { ManageAdjudicatorsComponent } from './components/manage-adjudicators/manage-adjudicators.component';
import { EventCalendarComponent } from './components/event-calendar/event-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScheduleEventComponent } from './components/modals/schedule-event/schedule-event.component';
import { ManageEventsComponent } from './components/manage-events/manage-events.component'
import { ResourceTimeLineViewComponent } from './components/resource-time-line-view/resource-time-line-view.component';
import { ResourceTimeLineWeekViewComponent } from './components/resource-time-line-week-view/resource-time-line-week-view.component';
import { AvailabilityReportComponent } from './components/modals/availability-report/availability-report.component'
@NgModule({
  declarations: [
    DashboardComponent,
    ManageAdjudicatorsComponent,
    EventCalendarComponent,
    ScheduleEventComponent,
    ManageEventsComponent,
    ResourceTimeLineViewComponent,
    ResourceTimeLineWeekViewComponent,
    AvailabilityReportComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    NgxMaskDirective,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ]
})
export class DashboardModule { }
