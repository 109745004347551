import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes, NotificationTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes } from "@apis/shared/enums/app.enum";
import { KeycloakService } from 'keycloak-angular';
import { ReviewService } from '../../../shared/services/review.service';
import { ReviewMethodType } from '@apis/shared/models/types/review-method-type.model';
import { NgForm, NgModel, Validators } from '@angular/forms';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { User } from "@apis/shared/models/user.model";
import { Notification } from '@apis/shared/models/notification.model';
import { DatePipe } from "@angular/common";
import { TimePipe } from "@apis/shared/pipes/time.pipe";
import { ReviewSchedule } from '@apis/shared/models/review-schedule.model';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'reschedule-review-modal',
  templateUrl: './reschedule-review-modal.component.html',
  styleUrls: ['./reschedule-review-modal.component.scss']
})
export class RescheduleReviewModalComponent implements OnInit, AfterViewInit {
  review: Review;  
    
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;  
  rescheduleButton: JQuery<HTMLElement>;  
  username: string;
  eventDetails: string;
  formSubmitted: boolean = false;
  reviewDateUtc: Date;
  failedAttendanceDeclarationActive: boolean = false;

  datePickerConfig: Partial<BsDatepickerConfig>;  
  adjudicators: User[] = [];    
  dateCustomClasses: DatepickerDateCustomClasses[];
  
  reviewMethodTypes: ReviewMethodType[];
  enumReviewMethodType = ReviewMethodTypes;
  adjudictors: User[];

  datePipe: DatePipe = new DatePipe("en-US");
  timePipe: TimePipe = new TimePipe();

  conflictingReviewTime: string;
  selectedAdjudicator: string;
  isAvailabilityRequestFailed: boolean;
  isRescheduleRequestFailed: boolean = false;
  rescheduleErrorMessage: string;

  availabilityView: AvailabilityView;

  @Output() close: EventEmitter<Review> = new EventEmitter();
      
  @ViewChild("rescheduleReviewForm") rescheduleReviewForm: NgForm;
  @ViewChild("reviewDate") reviewDate: NgModel;
  @ViewChild("reviewTime") reviewTime: NgModel;
  @ViewChild("userId") adjudicator: NgModel;
  @ViewChild("note") note: NgModel;
  @ViewChild("videoConferenceUrl") videoConferenceUrl: NgModel;
  
  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { 

      this.datePickerConfig = Object.assign({}, 
        {
          containerClass: 'theme-dark-blue', 
          showWeekNumbers: false,
          dateInputFormat: 'MMM DD, YYYY',
          isAnimated: true
        });
        
      this.dateCustomClasses = [
        { date: new Date(), classes: ["bg-secondary", "text-white"] }
      ];
    }

  ngOnInit(): void {     
    this.adjudicators = this.localStorageService.getAdjudicators();
    this.reviewMethodTypes = this.localStorageService.getReviewMethodTypes()
      .sort((a: ReviewMethodType, b: ReviewMethodType) => {
        return +b.displayOrder - (+a.displayOrder);
      });

    this.username = this.keycloakService.getUsername();
    
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.rescheduleButton = $(".reschedule-button");      
    this.failedAttendanceDeclarationActive = new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10));
    this.getReviewSchedule();
  }

  ngAfterViewInit(): void {
    if (this.reviewDate != null) {
      this.reviewDate.control.setValidators(Validators.required);
      this.reviewDate.valueChanges.subscribe((value: any) => {
        this.conflictingReviewTime = null;
      });
    }
    
    if (this.reviewTime != null) {
      this.reviewTime.control.setValidators(Validators.required);
      this.reviewTime.valueChanges.subscribe((value: any) => {
        this.conflictingReviewTime = null;
      });
    }
    
    if (this.adjudicator != null) {
      this.adjudicator.control.setValidators(Validators.required);
      this.adjudicator.control.valueChanges.subscribe((value: any) => {
        this.conflictingReviewTime = null;
        this.selectedAdjudicator = this.adjudicators.find(a => +a.userId == +value)?.firstName;        
      });
    }

    if (this.videoConferenceUrl != null) {
      this.videoConferenceUrl.control.setValidators(Validators.required);
    }

    if (this.note != null) {
      this.note.control.setValidators(Validators.maxLength(250));
    }    
  }
 
  onTextareaInput(): void {    
    this.maxCharacters = 250 - this.eventDetails.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onReviewMethodChange(): void {
    this.review.failedAttendanceDeclarationTypeId = null;
    this.getReviewSchedule();
  }

  onScheduleRefreshClick(): void {
    this.getReviewSchedule();
  }

  onScheduleDateSelected(e): void {
    this.review.reviewTime = this.datePipe.transform(e, "HH:mm:ss", "UTC");
    this.reviewDateUtc = new Date(e.setUTCHours(0, 0, 0, 0));
  }

  onRescheduleClick(ev: any): void {
    this.formSubmitted = true;

    const declarationRequiredAndNotSelected = this.failedAttendanceDeclarationActive
      && this.review.reviewMethodTypeId == ReviewMethodTypes.Oral
      && this.review.failedAttendanceDeclarationTypeId == null
      && !this.isReviewMethodTypeDisabled(this.review.reviewMethodTypeId);

    if (this.rescheduleReviewForm.invalid || declarationRequiredAndNotSelected) {
      return;
    }

    this.rescheduleButton.addClass("saving");
    this.rescheduleButton.attr("disabled", "disabled");

    const review = new Review({
      reviewId: +this.review.reviewId,
      reviewDate: this.reviewDateUtc,
      reviewTime: this.review.reviewTime,
      reviewTypeId: this.review.reviewTypeId,
      reviewMethodTypeId: +this.review.reviewMethodTypeId,
      failedAttendanceDeclarationTypeId: this.review.failedAttendanceDeclarationTypeId, // Do not convert to an int using "+". This will convert null to 0, which causes an error.
      scheduledEventId: +this.review.scheduledEventId
    });

    review.notifications.push(new Notification({
      notificationTypeId: NotificationTypes.ReviewRescheduled,
      contactMethodTypeId: this.review.contactMethodTypeId        
    }));

    let reviewDate = this.datePipe.transform(this.reviewDateUtc, "mediumDate", "UTC");
    let reviewTime = this.review.reviewTime.substring(0,5);

    let eventDetails = `Review rescheduled to ${reviewDate} at ${reviewTime}`
    if (this.eventDetails?.length > 0) {
      eventDetails = this.eventDetails;
    }

    review.events.push(new Event({
      eventTypeId: EventTypes.ReviewRescheduled,
      eventDetails: eventDetails
    }));
  
    // *** Adjudicator will be assigned by the Auto-scheduler *** //
    // *** Moved the event to server side *** //
    // *** :> ;> ;< :< *** //
    // review.events.push(new Event({
    //   eventTypeId: EventTypes.AdjudicatorReassigned,
    //   eventDetails: this.getAdjudicatorAssignedName()
    // })); 
          
    this.reviewService.reScheduleReview(review)
      .subscribe(
        (result: Review) => {           
          this.removeOverflow();
          this.close.emit(result);
        },
        (error: any) => {
          if (error.status === 400 && error.error?.errors) {
            this.rescheduleErrorMessage = error.error.errors;
          }
          else {
            this.rescheduleErrorMessage = "Your request can't be completed right now. Please try again later.";
          }
          this.isRescheduleRequestFailed = true;
        });  
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  

  isChangeButtonDisabled(): boolean {
    return this.maxCharacters < 0;
  }

  isReviewMethodTypeDisabled(reviewMethodTypeId): boolean {
    // Disable oral review method type for seizure reviews
    if (reviewMethodTypeId == ReviewMethodTypes.Oral &&
      (this.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview
      || this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview)) {
        return true;
    }

    // Senior Adjudicator cannot change review method (oral to written or vice versa)
    if (this.keycloakService.isUserInRole(Constants.Role.SENIOR_ADJUDICATOR)) {
      return true;
    }

    return null;
  }

  private getReviewSchedule() {
    this.spinner.show();
    this.isAvailabilityRequestFailed = false;

    this.reviewService.getReviewAvailability(
      this.review.reviewId,
      this.review.reviewTypeId,
      this.review.reviewMethodTypeId,
      this.review.scheduledEventId)
    .subscribe((result: AvailabilityView) => {
      this.availabilityView = result;
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      this.isAvailabilityRequestFailed = true;
    });
  }

}
