import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Guid } from 'guid-typescript';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { DocumentUploadComponent } from '../review-details/components/document-upload/document-upload.component';
import { AdjudicationService } from '../shared/services/adjudication.service';
import { Document } from '@apis/shared/models/document.model';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Review } from '@apis/shared/models/review.model';
import * as fileSaver from "file-saver";
import { addDays } from 'date-fns';
@Component({
  selector: 'app-re-review-details',
  templateUrl: './re-review-details.component.html',
  styleUrls: ['./re-review-details.component.scss']
})
export class ReReviewDetailsComponent implements OnInit {
  judicialReviewNumber: string;
  judicialReview: JudicialReview;
  review = new Review();

  submitButton: JQuery<HTMLElement>;

  formSubmitted: boolean = false;
  hasNoReviewHeldByDate: boolean = false;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];
  tempFolderName: string;
  errorMessage: string = "";
  courtDocument: Document;
  ReviewTypes = ReviewTypes;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private readonly adjudicationService: AdjudicationService,
    private readonly spinner: NgxSpinnerService,
    public router: Router) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((data: Params) => {
      this.judicialReviewNumber = data.judicialReviewNumber;
      this.spinner.show();
      this.adjudicationService.getJudicialReviewByNumber(this.judicialReviewNumber)
        .subscribe((judicialReview: JudicialReview) => {
          this.judicialReview = judicialReview;
          this.courtDocument = this.judicialReview.documents.find(d => d.documentTypeId == DocumentTypes.CourtDecision);
          this.spinner.hide();
        });
    });

  }

  onReviewHeldByDateChkChanged() {
    if (this.hasNoReviewHeldByDate) {
      this.review.reviewHeldByDate = addDays(new Date(this.judicialReview.judicialReviewDecisionSubmittedDate), 21);
    }
  }

  onDocumentNameClick() {
    let storageFileName = `${this.courtDocument.contentGuid}.${this.courtDocument.documentExtension}`;    
    let documentRefTypeName = "JudicialReviews";

    this.documentService.downloadDocument("", documentRefTypeName, this.judicialReviewNumber, storageFileName, this.courtDocument.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, this.courtDocument.documentName);          
        },
        (error: any) => {});
  }

  onCancelClick() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute});
  }

  onCreateClick(isValid: boolean) {
    this.formSubmitted = true;

    if(!isValid) { return; }

    this.submitButton = $(".submit-button");
    this.submitButton.prop("disabled", true);
    this.submitButton.addClass("disabled");
    
    this.judicialReview.reReview = this.review;

    this.adjudicationService.addReReview(this.judicialReview)
        .subscribe(
          (result: JudicialReview) => {
            this.router.navigate(['..'], {relativeTo: this.activatedRoute});
          },
          (error: any) => {
            this.submitButton.prop("disabled", false);
            this.submitButton.removeClass("disabled");
            if (error.status === 400 && error.error?.errors) {
              this.errorMessage = "Unable to create re-review: " + error.error.errors;
            }
            else {
              this.errorMessage = "Unable to create re-review. Please try later.";
            }
          });
    }
}
