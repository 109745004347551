import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JudicialReviewDetailsComponent } from './judicial-review-details.component';
import { SharedModule } from '@apis/shared/shared.module';
import { SupportingDocumentsModule } from '../shared/components/supporting-documents/supporting-documents.module';
import { JudicialReviewActivityComponent } from './components/judicial-review-activity/judicial-review-activity.component';
import { JudicialReviewComponent } from './components/judicial-review/judicial-review.component';
import { StayOrderComponent } from './modals/stay-order/stay-order.component';
import { JudicialReviewDecisionComponent } from './modals/judicial-review-decision/judicial-review-decision.component';
import { DocumentUploadComponent } from '../shared/components/document-upload/document-upload.component';


@NgModule({
  declarations: [
    JudicialReviewDetailsComponent,
    JudicialReviewActivityComponent,    
    JudicialReviewComponent,
    StayOrderComponent,
    JudicialReviewDecisionComponent,
    DocumentUploadComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SupportingDocumentsModule
  ]
})
export class JudicialReviewDetailsModule { }
