import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@apis/shared/shared.module";
import { RequestDetailsComponent } from './request-details.component';
import { RequestInformationComponent } from './components/request-information/request-information.component';
import { DecisionInformationComponent } from './components/decision-information/decision-information.component';
import { RequestActivityComponent } from "./components/request-activity/request-activity.component";
import { AssignAdjudicatorModalComponent } from './modals/assign-adjudicator-modal/assign-adjudicator-modal.component';
import { CancelRequestModalComponent } from './modals/cancel-request-modal/cancel-request-modal.component';
import { ChangeContactMethodModalComponent } from './modals/change-contact-method-modal/change-contact-method-modal.component';
import {ApproveOrDenyRequestComponent} from './components/approve-or-deny-request/approve-or-deny-request.component';
import { DriverLogModule } from '../shared/components/driver-log/driver-log.module';

@NgModule({
  declarations: [
    RequestDetailsComponent,
    RequestInformationComponent,
    DecisionInformationComponent,
    RequestActivityComponent,
    AssignAdjudicatorModalComponent,
    CancelRequestModalComponent,
    ChangeContactMethodModalComponent,
    ApproveOrDenyRequestComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    DriverLogModule
  ]
})
export class RequestDetailsModule { }
