<div class="d-flex flex-column">
    <div class="heading">Seizure Details</div>
    <div class="expand-all">
        <div class="custom-control custom-checkbox" >
            <input type="checkbox" class="custom-control-input" id="expandAll" (change)="onExpandAllChange($event)">
            <label class="custom-control-label section-label" for="expandAll">Expand all</label>
        </div>
    </div>
    <div class="accordion">
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgIssuingPoliceService', 'divIssuingPoliceService')">
                <div>Issuing Police Service</div>
                <div class="plus-minus-icon">
                    <img id="imgIssuingPoliceService" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divIssuingPoliceService" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
                    <div class="row">
                        <div class='col-12 col-sm-8'>
                            <div class="label">Detachment</div>
                            <div class="value">{{detachmentName}}</div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Police File Number</div>
                                <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editPoliceFileNumberClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isPoliceFileNumberEdited" class="value">{{vehicleSeizure?.policeFileNumber?vehicleSeizure?.policeFileNumber:"-"}}</div>
                            <div *ngIf="isPoliceFileNumberEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required #policeFileNumberControl="ngModel" type="text" maxlength="20" class="form-control form-control-sm custom-text-box"
                                    id="policeFileNumber" name="policeFileNumber" [(ngModel)]="vehicleSeizure.policeFileNumber"
                                        [ngClass]="{'is-invalid':policeFileNumberControl.dirty && policeFileNumberControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editPoliceFileNumberSaveClick(!policeFileNumberControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                        <div *ngIf="policeFileNumberControl.hasError('required')">Police File Number is required</div>
                                        <div *ngIf="!policeFileNumberControl.hasError('required')">Invalid Police File Number</div>
                                    </div>
                                </div>
                                <span class="error">{{policeFileNumberErrorMessage}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgSeizureInformation', 'divSeizureInformation')">
                <div>Seizure Information</div>
                <div class="plus-minus-icon">
                    <img id="imgSeizureInformation" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divSeizureInformation" class="collapse">
                <div class="card-body">
                    <div class="row">
                        <div class='col-12 col-sm-4'>
                            <div class="label">Seizure Number</div>
                            <div class="value">{{ vehicleSeizure?.seizureNumber }}</div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="label">Seizure Type</div>
                            <div class="value">{{ seizureType }}</div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Seizure Date</div>
                                <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editSeizureDateClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isSeizureDateEdited" class="value">{{ vehicleSeizure?.seizureDate | date:"mediumDate"}}</div>
                            <div *ngIf="isSeizureDateEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required #seizureDateControl="ngModel" type="text" bsDatepicker [bsConfig]="seizureDatePickerConfig" maxlength="10" class="form-control form-control-sm custom-text-box" id="seizureDate" name="seizureDate" placeholder="yyyy/mm/dd" [(ngModel)]="vehicleSeizure.seizureDate" [ngClass]="{'is-invalid':seizureDateControl.dirty && seizureDateControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editSeizureDateSaveClick(!seizureDateControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                        <div *ngIf="seizureDateControl.hasError('required')">Seizure Date is required</div>
                                        <div *ngIf="!seizureDateControl.hasError('required')">Invalid Seizure Date</div>
                                    </div>
                                </div>
                                <span class="error">{{seizureDateErrorMessage}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Seizure Time</div>
                                <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editSeizureTimeClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isSeizureTimeEdited" class="value">{{ vehicleSeizure?.seizureTime?.substring(0,5) }}</div>
                            <div *ngIf="isSeizureTimeEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required #seizureTimeControl="ngModel" type="text" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" mask="{{Constants.Mask.TIME_MASK}}" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control form-control-sm custom-text-box" id="seizureTime" name="seizureTime" placeholder="--:--" [(ngModel)]="vehicleSeizure.seizureTime" [ngClass]="{'is-invalid':seizureTimeControl.dirty && seizureTimeControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editSeizureTimeSaveClick(!seizureTimeControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                      <div *ngIf="seizureTimeControl.hasError('required')">Seizure Time is required</div>
                                        <div *ngIf="!seizureTimeControl.hasError('required')">Invalid Seizure Time</div>
                                    </div>
                                </div>
                                <span class="error">{{seizureTimeErrorMessage}}</span>
                            </div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="label">Immediate Seizure Duration</div>
                            <div class="value">{{ vehicleSeizure?.seizureDuration}} {{vehicleSeizure?.seizureDurationType }}</div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="label">Vehicle Release Date</div>
                            <div class="value">{{ vehicleSeizure?.vehicleReleaseDate | date:"mediumDate"}}</div>
                        </div>
                    </div>
                    <app-section-details sectionTitle="" [sectionDetails]="occurrenceLocationDetails"></app-section-details>
                    <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgVehicleInformation', 'divVehicleInformation')">
                <div>Vehicle Information</div>
                <div class="plus-minus-icon">
                    <img id="imgVehicleInformation" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divVehicleInformation" class="collapse">
              <div class="card-body">
                <label class="bold-text" *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicleSeizure?.vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicleSeizure?.vehicle?.movesLookupDate | date:'HH:mm'}}</label>
                <ng-container *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate && this.vehicleSeizure?.vehicle?.isStolenPlate">
                  <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                  <label class="bold-text">The following information is a description of the vehicle involved in the contravention</label>
                  <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                </ng-container>
                <ng-container *ngIf="(this.vehicleSeizure?.vehicle?.movesLookupDate && !this.vehicleSeizure?.vehicle?.isStolenPlate) || !this.vehicleSeizure?.vehicle?.movesLookupDate">
                  <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                </ng-container>
                <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>
              </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgRegisteredOwner', 'divRegisteredOwner')">
                <div>Registered Owner</div>
                <div class="plus-minus-icon">
                    <img id="imgRegisteredOwner" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divRegisteredOwner" class="collapse">
                <div class="card-body">
                    <label class="bold-text" *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate && 
                    !this.vehicleSeizure?.vehicle?.registeredOwner?.isSameAsDriver && 
                    !this.vehicleSeizure?.vehicle?.isNoRegisteredOwner && !this.vehicleSeizure?.vehicle?.isStolenPlate">
                    The following registered owner information was generated from the Motor Vehicle System (MOVES) on 
                    {{vehicleSeizure?.vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicleSeizure?.vehicle?.movesLookupDate | date:'HH:mm'}}</label>
                    <form #vehicleRegisteredOwnerForm="ngForm">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="error">{{errorMessageRegisteredOwner}}</span>
                            </div>
                        </div>
                        <div class="d-flex col-6 col-sm-6">
                            <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                                <div class="justify-content-start">
                                    <button *ngIf="!isRegisteredOwnerEdited && isAllowEditForBusinessSuperUser" class="btn-sm"
                                        (click)="editRegisteredOwnerClick()">Edit</button>
                                    <button *ngIf="isRegisteredOwnerEdited" class="btn-sm"
                                        (click)="saveRegisteredOwnerClick(vehicleRegisteredOwnerForm.valid)">Save</button>
                                    <button *ngIf="isRegisteredOwnerEdited" class="btn-sm ml-1"
                                        (click)="cancelRegisteredOwnerClick()">Cancel</button>
                                </div>
                            </app-access-control>
                        </div>
                        <div *ngIf="isRegisteredOwnerEdited">
                            <ng-container>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Same as Driver?</label>
                                            <div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input required type="radio" class="custom-control-input" id="sameAsDriverYes" name="sameAsDriver"
                                                        #sameAsDriverYesControl="ngModel" [value]="true"
                                                        [(ngModel)]="vehicle.registeredOwner.isSameAsDriver"
                                                        [ngClass]="{'is-invalid': vehicle.registeredOwner.isSameAsDriver==null && (sameAsDriverYesControl.touched || sameAsDriverNoControl.touched || isSubmitClicked)}"
                                                        (change)="onSameAsDriverChange()">
                                                    <label class="custom-control-label" for="sameAsDriverYes">Yes</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input required type="radio" class="custom-control-input" id="sameAsDriverNo" name="sameAsDriver"
                                                        #sameAsDriverNoControl="ngModel" [value]="false"
                                                        [(ngModel)]="vehicle.registeredOwner.isSameAsDriver"
                                                        [ngClass]="{'is-invalid': registeredOwner.isSameAsDriver==null && (sameAsDriverYesControl.touched || sameAsDriverNoControl.touched || isSubmitClicked)}"
                                                        (change)="onSameAsDriverChange()">
                                                    <label class="custom-control-label" for="sameAsDriverNo">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="label">
                                                Driver's Licence
                                                Number 
                                            </div>
                                            <div class="mt-10">
                                                <input [appAlbertaLicence]="isAlbertaDriverLicense"
                                                    [appAlbertaLicenceValidator]="isAlbertaDriverLicense"
                                                    maxlength="16"
                                                    #driversLicenceNumberControl="ngModel" type="text"
                                                    [(ngModel)]="vehicle.registeredOwner.driverLicenceNumber"
                                                    class="form-control" id="driverLicenceNumber" name="driverLicenceNumber"
                                                    placeholder="Driver's Licence Number"
                                                    [ngClass]="{'is-invalid':(driversLicenceNumberControl.touched || isSubmitClicked) && driversLicenceNumberControl.invalid}">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="driversLicenceNumberControl.errors?.required && driverLicenceClassification == 1">
                                                        Driver's Licence Number is required</div>
                                                    <div *ngIf="driversLicenceNumberControl.errors?.required && driverLicenceClassification == 2">
                                                        Driver's Licence Number or M.V.I.D is required</div>
                                                    <div
                                                        *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberLength && !driversLicenceNumberControl.errors?.required">
                                                        Atleast 4 digits are required</div>
                                                    <div
                                                        *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberFormat && !driversLicenceNumberControl.errors?.required">
                                                        Only digits are allowed</div>
                                                    <div *ngIf="driversLicenceNumberControl.errors?.invalidCheckDigit && !driversLicenceNumberControl.errors?.required">
                                                        Invalid Alberta Driver's Licence Number.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="label">
                                                M.V.I.D
                                            </div>
                                            <div class="mt-10">
                                                <input [appMVIDNumber]="isAlbertaDriverLicense"
                                                    [appMVIDNumberValidator]="isAlbertaDriverLicense"
                                                    maxlength="10"
                                                    #motorVehicleIDControl="ngModel" type="text"
                                                    [(ngModel)]="vehicle.registeredOwner.motorVehicleIdentificationNumber"
                                                    class="form-control" id="motorVehicleID" name="motorVehicleID" placeholder="M.V.I.D Number"
                                                    [ngClass]="{'is-invalid':(motorVehicleIDControl.touched || isSubmitClicked) && motorVehicleIDControl.invalid}">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="motorVehicleIDControl.errors?.required && driverLicenceClassification == 1">M.V.I.D
                                                        is required</div>
                                                    <div *ngIf="motorVehicleIDControl.errors?.required && driverLicenceClassification == 2">Driver's
                                                        Licence Number or M.V.I.D is required</div>
                                                    <div
                                                        *ngIf="motorVehicleIDControl.errors?.invalidMvidNumberLength && !motorVehicleIDControl.errors?.required">
                                                        M.V.I.D Number must be 10 digits long</div>
                                                    <div
                                                        *ngIf="motorVehicleIDControl.errors?.invalidMvidNumberFormat && !motorVehicleIDControl.errors?.required">
                                                        Only digits are allowed</div>
                                                    <div
                                                        *ngIf="motorVehicleIDControl.errors?.invalidCheckDigit && !motorVehicleIDControl.errors?.required">
                                                        Invalid M.V.I.D Number.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Surname<span class="label-hint">(Required)</span>
                                        </div>
                                        <div class="mt-10">
                                            <input [required]="true" #surnameControl="ngModel"
                                                maxlength="50" type="text" class="form-control" id="surname" name="surname"
                                                placeholder="Surname" [(ngModel)]="vehicle.registeredOwner.lastName"
                                                (ngModelChange)="vehicle.registeredOwner.lastName=this.moddedTitleCasePipe.transform($event)"
                                                [ngClass]="{'is-invalid':(surnameControl.touched || isSubmitClicked) && surnameControl.invalid}">
                                            <div class="invalid-feedback">
                                                <div>Surname is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="label">
                                            First Name<span class="label-hint">(Required)</span>
                                        </div>
                                        <div class="mt-10">
                                            <input [required]="true" #firstNameControl="ngModel" maxlength="50" type="text" class="form-control"
                                                id="firstName" name="firstName" placeholder="First Name"
                                                [(ngModel)]="vehicle.registeredOwner.firstName"
                                                (ngModelChange)="vehicle.registeredOwner.firstName=this.moddedTitleCasePipe.transform($event)"
                                                
                                                [ngClass]="{'is-invalid':(firstNameControl.touched || isSubmitClicked) && firstNameControl.invalid}">
                                            <div class="invalid-feedback">
                                                <div>First Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Middle Name
                                        </div>
                                        <div class="mt-10">
                                            <input type="text" class="form-control" id="middleName" maxlength="50" name="middleName"
                                                placeholder="Middle Name" 
                                                [(ngModel)]="vehicle.registeredOwner.otherName"
                                                (ngModelChange)="vehicle.registeredOwner.otherName=this.moddedTitleCasePipe.transform($event)">
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Gender<span class="label-hint">(Required)</span>
                                        </div>
                                        <div class="mt-10">
                                            <select [appSelectValidator]="true" #genderControl="ngModel" class="form-control" id="gender"
                                                name="gender" [(ngModel)]="genderId" 
                                                [ngClass]="{'is-invalid':(genderControl.touched || isSubmitClicked) && genderControl.errors?.defaultSelected}">
                                                <option value="null">Select a Gender</option>
                                                <option *ngFor="let genderType of genderTypes" [ngValue]="genderType.id">
                                                    {{genderType.name}}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback">
                                                <div>Gender is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Date of Birth
                                        </div>
                                        <div class="mt-10">
                                            <input isBirthDate maxlength="10" #dateOfBirthControl="ngModel" type="text"
                                                bsDatepicker [bsConfig]="dateOfBirthPickerConfig" class="form-control" id="dateOfBirth"
                                                name="dateOfBirth" placeholder="yyyy/mm/dd" [(ngModel)]="dateOfBirth"
                                                [ngClass]="{'is-invalid':(dateOfBirthControl.touched || isSubmitClicked) && dateOfBirthControl.invalid}">
                                            <div class="invalid-feedback">
                                                <div *ngIf="!dateOfBirthControl.errors?.required && dateOfBirthControl.hasError('FutureDate')">
                                                    Date of Birth
                                                    can't be a future date</div>
                                                <div
                                                    *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && dateOfBirthControl.hasError('InvalidYear')">
                                                    Invalid Year. Only 19xx and 20xx years allowed.</div>
                                                <div
                                                    *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && !dateOfBirthControl.hasError('InvalidYear')">
                                                    Invalid Date of Birth</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Telephone Number
                                        </div>
                                        <div class="mt-10">
                                            <input type="text" class="form-control" maxlength="15" id="telephoneNumber" name="telephoneNumber"
                                                placeholder="+1 (XXX)-XXX-XXXX"
                                                [(ngModel)]="vehicle.registeredOwner.registeredOwnerContact.phoneNumber1"
                                                >
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Address 1
                                        </div>
                                        <div class="mt-10">
                                            <input type="text" maxlength="44" class="form-control" id="address1" name="address1"
                                                
                                                placeholder="Ex. 123 Street or PO Box, Range Road or Rural Route"
                                                [(ngModel)]="vehicle.registeredOwner.registeredOwnerContact.addressLine1">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Address 2
                                        </div>
                                        <div class="mt-10">
                                            <input type="text" maxlength="44" class="form-control" id="address2" name="address2"
                                                placeholder="Ex. Unit Number"
                                                [(ngModel)]="vehicle.registeredOwner.registeredOwnerContact.addressLine2">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-12 col-sm-4">
                                        <div class="label">
                                            Country <span class="label-hint">(Required)</span>
                                        </div>
                        
                                        <div class="mt-10">
                                            <select required #countryControl="ngModel" class="form-control" id="country" name="country"
                                                (change)="onCountryChange()" [(ngModel)]="countryId"
                                                [ngClass]="{'is-invalid':(countryControl.touched || isSubmitClicked) && countryControl.invalid }">
                                                <option [ngValue]="null">Select a Country</option>
                                                <option *ngFor="let country of countryTypes" [ngValue]="country.id">
                                                    {{country.name}}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback">
                                                <div *ngIf="countryControl.errors?.required">Country is required</div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="col-12 col-sm-4">
                                        <div class="label">
                                            Province <span class="label-hint">(Required)</span>
                                        </div>
                        
                                        <div class="mt-10">
                                            <select [required]="true" #provinceControl="ngModel" class="form-control" id="province"
                                                *ngIf="countryId == 40 || countryId == 236"
                                                [disabled]="!countryId" name="province"
                                                (change)="onCountryChange()" [(ngModel)]="provinceId"
                                                [ngClass]="{'is-invalid':(provinceControl.touched || isSubmitClicked) && provinceControl.invalid }">
                                                <option [ngValue]="null">Select a Province/State</option>
                                                <option *ngFor="let province of provinceTypes" [ngValue]="province.id">
                                                    {{province.name}}</option>
                                            </select>
                                            <input [disabled]="!countryId" [required]="true" maxlength="30"
                                                #otherProvinceControl="ngModel" *ngIf="!(countryId == 40 || countryId == 236)" type="text"
                                                [(ngModel)]="vehicle.registeredOwner.registeredOwnerContact.province"
                                                class="form-control" name="otherprovince" id="otherprovince" placeholder="Province/State"
                                                (keyup)="onProvinceTextChange($event)"
                                                [ngClass]="{'is-invalid': (otherProvinceControl.touched || isSubmitClicked) && otherProvinceControl.invalid }">
                                            <div class="invalid-feedback">
                                                <div *ngIf="provinceControl?.errors?.required">Province is required</div>
                                            </div>
                                            <div class="invalid-feedback">
                                                <div *ngIf="otherProvinceControl?.errors?.required">Province is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="form-group row">
                                    <div class="col-12 col-sm-4">
                                        <div class="label">
                                            City/Town/Village <span class="label-hint">(Required)</span>
                                        </div>
                                        <div class="mt-10">
                                            <input [required]="true" #cityControl="ngModel" maxlength="30" type="text" class="form-control"
                                                id="city" name="city" placeholder="City/Town/Village"
                                                [(ngModel)]="vehicle.registeredOwner.registeredOwnerContact.city"
                                                (ngModelChange)="vehicle.registeredOwner.registeredOwnerContact.city=this.moddedTitleCasePipe.transform($event)"
                                                [ngClass]="{'is-invalid':(cityControl.touched || isSubmitClicked) && cityControl.invalid}"
                                                >
                                            <div class="invalid-feedback">
                                                <div>City/Town/Village is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="label">
                                            Postal Code <span class="label-hint">(Required)</span>
                                        </div>
                                        <div class="mt-10">
                                            <input [required]="true" #postalCodeControl="ngModel" maxlength="10" type="text"
                                                class="form-control text-uppercase" id="postalCode" name="postalCode"
                                                
                                                [(ngModel)]="vehicle.registeredOwner.registeredOwnerContact.postalCode"
                                                [ngClass]="{'is-invalid':(postalCodeControl.touched || isSubmitClicked) && postalCodeControl.invalid}">
                                            <div class="invalid-feedback">
                                                <div>Postal Code is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    
                        <ng-container *ngIf="!isRegisteredOwnerEdited">
                            <app-section-details sectionTitle="" [sectionDetails]="registeredOwner"></app-section-details>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgTowLotInformation', 'divTowLotInformation')">
                <div>Vehicle Location & Tow Lot Information</div>
                <div class="plus-minus-icon">
                    <img id="imgTowLotInformation" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divTowLotInformation" class="collapse">
                <div class="card-body">
                  <form #towLotInfoForm="ngForm">
                    <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.DATA_EDITS">
                      <div class="form-group row">
                        <div class="d-flex col-6 col-sm-6">
                          <button *ngIf="!isTowLotInfoEdited" class="btn-sm" (click)="editTowLotInfoClick()">Edit</button>
                          <button [disabled]="isSaving || !towLotInfoForm.valid" *ngIf="isTowLotInfoEdited" class="btn-sm custom-button"  (click)="saveEditedTowLotInfo(towLotInfoForm.valid)">Save</button>
                          <button *ngIf="isTowLotInfoEdited" class="btn-sm ml-1" (click)="cancelEditedTowLotInfo()">Cancel</button>
                        </div>
                      </div>
                    </app-access-control>
                    <app-section-details *ngIf="!isTowLotInfoEdited" sectionTitle="" [sectionDetails]="towLotInformation"></app-section-details>

                    <ng-container *ngIf="isTowLotInfoEdited">
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <div class="label">Towing Company</div>
                          <select [disabled]="towCompanies==null" #towingCompanyControl="ngModel"  class="form-control" id="towingCompany" name="towingCompany" [(ngModel)]="selectedTowCompany" (ngModelChange)="onTowLotChange($event)">
                            <option [ngValue]="null">Select a Towing Company</option>
                            <option *ngFor="let towCompany of towCompanies" [ngValue]="towCompany">{{towCompany.towCompanyName}}</option>
                          </select>
                        </div>
                        <div class="col-lg-4">
                          <div class="label">Name of Towing Company <span class="label-hint">(Required)</span></div>
                          <input required #towingCompanyNameControl="ngModel" maxlength="50" type="text" class="form-control" id="towingCompanyName" name="towingCompanyName" placeholder="Enter Name of Company" [(ngModel)]="towCompany.towCompanyName" [ngClass]="{'is-invalid':(towingCompanyNameControl.touched || isSubmitClicked) && towingCompanyNameControl.invalid}">
                          <div class="invalid-feedback">
                            <div>Name of Towing Company is required</div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="label">Tow Lot Address <span class="label-hint">(Required)</span></div>
                          <input required #towLotAddressControl="ngModel" maxlength="100" type="text" class="form-control" id="towLotAddress" name="towLotAddress" placeholder="Street Address" [(ngModel)]="towCompany.towLotAddress" [ngClass]="{'is-invalid':(towLotAddressControl.touched || isSubmitClicked) && towLotAddressControl.invalid}">
                          <div class="invalid-feedback">
                            <div>Tow Lot Address is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <div class="label">City/Town/Village <span class="label-hint">(Required)</span></div>
                          <input required #towCityControl="ngModel" maxlength="30" type="text" class="form-control" id="towCity" name="towCity" placeholder="City/Town/Village"
                                 [(ngModel)]="towCompany.city" (ngModelChange)="vehicleSeizure.towLotCity=this.moddedTitleCasePipe.transform($event)"
                                 [ngClass]="{'is-invalid':(towCityControl.touched || isSubmitClicked) && towCityControl.invalid}">
                          <div class="invalid-feedback">
                            <div>City/Town/Village is required</div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="label">Postal Code</div>
                          <input maxlength="10" type="text" class="form-control text-uppercase" id="towPostalCode" name="towPostalCode" placeholder="Enter Postal Code" [(ngModel)]="towCompany.postalCode">
                        </div>
                        <div class="col-lg-4">
                          <div class="label">Telephone Number</div>
                          <input type="text" maxlength="15" class="form-control" id="towTelephoneNumber" name="towTelephoneNumber" placeholder="Enter Telephone Number" [(ngModel)]="towCompany.phoneNumber">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <div class="label">Fax Number</div>
                          <input type="text" maxlength="15" class="form-control" id="towFaxNumber" name="towFaxNumber" placeholder="Enter Fax Number" [(ngModel)]="towCompany.faxNumber">
                        </div>
                        <div class="col-lg-4">
                          <div class="label">Received By (Towing Company Representative)</div>
                          <input type="text" maxlength="50" class="form-control" id="towRepresentative" name="towRepresentative" placeholder="Enter Tow Lot Representative Name" [(ngModel)]="towCompany.towLotRepresentative">
                        </div>
                        <div class="col-lg-4">
                          <div class="label">Email Address</div>
                          <input type="text" email #towEmailControl="ngModel" maxlength="100" class="form-control" id="towLotEmailAddress" name="towLotEmailAddress" placeholder="Enter Tow Lot Email Address" [(ngModel)]="towCompany.towLotEmailAddress" [ngClass]="{'is-invalid':(towEmailControl.touched || isSubmitClicked) && towEmailControl.invalid}">
                          <div class="invalid-feedback">
                            <div>Invalid Email Address</div>
                          </div>
                        </div>
                      </div>
                      <span class="error">{{towLotErrorMessage}}</span>
                    </ng-container>
                  </form>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgDeclarations', 'divDeclarations')">
                <div>Declarations</div>
                <div class="plus-minus-icon">
                    <img id="imgDeclarations" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divDeclarations" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="" [sectionDetails]="declarations"></app-section-details>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="showSupportingDocumentDeclarations">
            <div class="card-header" (click)="onPlusMinusIconClick('imgDeclarations', 'divSupportingDocumentDeclarations')">
                <div>Supporting Document Declarations</div>
                <div class="plus-minus-icon">
                    <img id="imgDeclarations" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divSupportingDocumentDeclarations" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarations"></app-section-details>
                </div>
            </div>
        </div>
    </div>
    <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.VIEW_SUPPORTING_DOCUMENTS">
        <supporting-documents [vehicleSeizure]="vehicleSeizure"></supporting-documents>
    </app-access-control>
</div>
