
<div class="d-flex flex-column">
    <div *ngIf="isContraventionReview" class="heading d-flex justify-content-between">
        Contravention Summary
    </div>
    <div *ngIf="!isContraventionReview" class="heading d-flex justify-content-between">
        Seizure Summary
    </div>
    
    <div class="info-container form-group row mt-1">
        <div *ngIf="!isThirdPartySeizure" class="info-container-item col-12 col-lg-4">
            <label class="info-label">Recipient Name</label>
            <div class="info-text">{{applicantName}}</div>
        </div>
        <div *ngIf="isThirdPartySeizure" class="info-container-item col-12 col-lg-4">
            <label class="info-label">Registered Owner</label>
            <div class="info-text">{{registeredOwnerName}}</div>
        </div>
        <div *ngIf="contraventionTypeName" class="info-container-item col-12 col-lg-4">
            <label class="info-label">Contravention Type</label>
            <div class="info-text">{{contraventionTypeName}}</div>
        </div>
        <div *ngIf="vehicleSeizureTypeName" class="info-container-item col-12 col-lg-4">
            <label class="info-label">Seizure Type</label>
            <div class="info-text">{{vehicleSeizureTypeName}}</div>
        </div>
        <div class="info-container-item col-12 col-lg-4" *ngIf="isContraventionReview">
            <label class="info-label">Issuance Date</label>
            <div class="info-text">
                {{contravention?.issueDate | date:"mediumDate"}}</div>
        </div>
        <div class="info-container-item col-12 col-lg-4" *ngIf="!isContraventionReview">
            <label class="info-label">Seizure Date & Time</label>
            <div class="info-text">
                {{seizureDateTime | date:"mediumDate"}} at {{seizureDateTime | date: "shortTime"}}</div>
        </div>
     
        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Name of Police Officer</label>
            <div class="info-text">{{policeOfficerName}}</div>
        </div>

        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Police File Number</label>
            <div class="info-text">{{policeFileNumber}}</div>
        </div>

        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Regimental Number</label>
            <div class="info-text">{{regimentalNumber}}</div>
        </div>

        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Issuing Police Service</label>
            <div class="info-text">{{agencyName}}</div>
        </div>

        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Issuing Detachment</label>
            <div class="info-text">{{detachmentName}}</div>
        </div>                  
        
        <div class="info-container-item col-12 col-lg-4" *ngIf="isContraventionReview && contravention?.vehicleSeizure?.seizureNumber != null">        
            <label class="info-label">Linked Penalty</label>
            <div class="info-text">
                <a class="info-link" [routerLink]="['/seizure', contravention?.vehicleSeizure?.seizureNumber]">
                    Vehicle Seizure {{contravention?.vehicleSeizure?.seizureNumber}}
                </a>
            </div>    
        </div>    
        <div class="info-container-item col-12 col-lg-4" *ngIf="!isContraventionReview && vehicleSeizure?.contraventionNumber != null">        
            <label class="info-label">Linked Penalty</label>
            <div class="info-text">
                <a class="info-link" [routerLink]="['/contravention', vehicleSeizure?.contraventionNumber]">
                    Contravention {{vehicleSeizure?.contraventionNumber}}
                </a>
            </div>    
        </div>     
    </div>      
</div>

<div class="expand-all">
    <div class="custom-control custom-checkbox" >
        <input type="checkbox" class="custom-control-input" id="expandAll" (change)="onExpandAllChange($event)">
        <label class="custom-control-label section-label" for="expandAll">Expand all</label>
    </div>
</div>
<div class="accordion">
    <div class="card" *ngIf="!isThirdPartySeizure">
        <div class="card-header" (click)="onPlusMinusIconClick('imgRecipientInformation', 'divRecipientInformation')">
            <div>Recipient Information</div>                                
            <div class="plus-minus-icon">
                <img id="imgRecipientInformation" src="assets/images/plus.svg" />
            </div>                               
        </div>            
        <div id="divRecipientInformation" class="collapse">
            <div class="card-body">                    
                <label class="bold-text" *ngIf="this.recipient?.movesLookupDate">The following recipient information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
                <app-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientInformation"></app-section-details>
                
                <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                    <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                    <label class="bold-text">The following contact information was provided by the recipient to the officer</label>
                    <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>    
                </ng-container>

                <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                    <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="isThirdPartySeizure">
        <div class="card-header" (click)="onPlusMinusIconClick('imgRecipientMinInformation', 'divRecipientMinInformation')">
            <div>Recipient Information</div>                                
            <div class="plus-minus-icon">
                <img id="imgRecipientMinInformation" src="assets/images/plus.svg" />
            </div>                               
        </div>            
        <div id="divRecipientMinInformation" class="collapse">
            <div class="card-body">  
                <label class="bold-text" *ngIf="this.recipient?.movesLookupDate">The following recipient information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
                <app-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientMinimumInformation"></app-section-details>
                
                <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                    <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                    <label class="bold-text">The following contact information was provided by the recipient to the officer</label>
                    <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>    
                </ng-container>

                <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                    <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                </ng-container>                  
            </div>
        </div>
    </div>        
    <div class="card" *ngIf="isContraventionReview">
        <div class="card-header" (click)="onPlusMinusIconClick('imgContraventionType', 'divContraventionType')">
            <div>Contravention Information</div>
            <div class="plus-minus-icon">
                <img id="imgContraventionType" src="assets/images/plus.svg" />
            </div>  
        </div>
        <div id="divContraventionType" class="collapse">
            <div class="card-body">
                <label class="bold-text" *ngIf="showAdditionalConditionsMessage">There may be additional conditions for reinstatement of the recipient's operator's licence.</label>
                <app-section-details sectionTitle="" [sectionDetails]="contraventionType"></app-section-details>
            </div>
        </div>
    </div>     
    <div class="card" *ngIf="isContraventionReview">
        <div class="card-header" (click)="onPlusMinusIconClick('imgOccurrenceInformation', 'divOccurrenceInformation')">
            <div>Occurrence Details</div>  
            <div class="plus-minus-icon">
                <img id="imgOccurrenceInformation" src="assets/images/plus.svg" />
            </div>                                
        </div>            
        <div id="divOccurrenceInformation" class="collapse">
            <div class="card-body">    
                <app-section-details sectionTitle="" [sectionDetails]="occurrenceInformation"></app-section-details>     
                <app-section-details sectionTitle="Witness Information" [sectionDetails]="witnessInformation"></app-section-details>                                                       
                <div class="row" *ngIf="isPhase2Submission && contravention?.recipient?.recipientTypeId!=RecipientTypes.NotInVehicle">
                    <div class="col-12 col-sm-4">
                        <div class="label">Passengers</div>
                        <div class="value">{{stopInformation?.isPassengerInVehicle? 'Yes': 'No'}}</div>
                    </div>
                    <ng-container *ngIf="stopInformation?.isPassengerInVehicle">
                        <div class="col-12 col-sm-3">
                            <div class="label">Passenger Positions</div>
                            <div class="value pl-2 pr-4">
                                <app-passenger-position [stopInformation]="stopInformation" [isReadOnly]="true"></app-passenger-position>
                            </div>
                        </div>
                        <div class="col-12 col-sm-1"></div>
                        <div class="col-12 col-sm-4">
                            <div class="label">Additional Passenger Location Details</div>
                            <div class="value">{{stopInformation?.vehicleOccupantsNotes}}</div>
                        </div>
                    </ng-container>
                </div>
                <div class="row" *ngIf="isBodycamVideoActive">
                    <div class="col-12 col-sm-4">
                        <div class="label">Is there any video in relation to this event?</div>
                        <div class="value" *ngIf="stopInformation?.hasBodycamVideo == null; else bodycamVideoHasValue">-</div>
                        <ng-template #bodycamVideoHasValue>
                            <div class="value">{{stopInformation?.hasBodycamVideo? 'Yes': 'No'}}</div>
                        </ng-template>
                    </div>
                </div>
                <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>
            </div>
        </div>
    </div>  
    <div class="card" *ngIf="isContraventionReview">
        <div class="card-header" (click)="onPlusMinusIconClick('imgImpairmentScreening', 'divImpairmentScreening')">
            <div>Impairment Screening</div>
            <div class="plus-minus-icon">
                <img id="imgImpairmentScreening" src="assets/images/plus.svg" />
            </div>
        </div>
        <div id="divImpairmentScreening" class="collapse">
            <div class="card-body">
                <app-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds"></app-section-details>
                <app-section-details sectionTitle="Impairment Screening Information" [sectionDetails]="impairmentScreeningInformation"></app-section-details>
                <app-section-details sectionTitle="Impairment Screening Details" [sectionDetails]="impairmentScreeningDetails"></app-section-details>
                <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                    <app-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}" [sectionDetails]="additionalTest"></app-section-details>
                </div>
                <app-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds"></app-section-details>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="!isContraventionReview || !contravention?.isNoVehicleSeizureMade">
        <div class="card-header" (click)="onPlusMinusIconClick('imgSeizureInformation', 'divSeizureInformation')">
            <div>Seizure Information</div>
            <div class="plus-minus-icon">
                <img id="imgSeizureInformation" src="assets/images/plus.svg" />
            </div>  
        </div>
        <div id="divSeizureInformation" class="collapse">
            <div class="card-body">
                <app-section-details sectionTitle="" [sectionDetails]="vehicleSeizureInformation"></app-section-details>
                <app-section-details *ngIf="!isContraventionReview" sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>
            </div>
        </div>
    </div>            
    <ng-container *ngIf="vehicle">
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgVehicleDetails', 'divVehicleDetails')">
                <div>Vehicle Information</div>    
                <div class="plus-minus-icon">
                    <img id="imgVehicleDetails" src="assets/images/plus.svg" />
                </div>                              
            </div>            
            <div id="divVehicleDetails" class="collapse">
                <div class="card-body">                    
                    <label class="bold-text" *ngIf="this.vehicle?.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicle?.movesLookupDate | date:'HH:mm'}}</label>               
                    
                    <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                        <label class="bold-text">The following information is a description of the vehicle involved in the contravention</label>
                        <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>

                    <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>
                    <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="(isChangeDisplayedInformationActive && isThirdPartySeizure) || (!isChangeDisplayedInformationActive && !(isSDPReview && !vehicle?.registeredOwner?.isSameAsDriver))">
            <div class="card-header" (click)="onPlusMinusIconClick('imgRODetails', 'divRODetails')">
                <div>Registered Owner</div>    
                <div class="plus-minus-icon">
                    <img id="imgRODetails" src="assets/images/plus.svg" />
                </div>                              
            </div>            
            <div id="divRODetails" class="collapse">
                <div class="card-body">     
                    <label class="bold-text" *ngIf="this.vehicle?.movesLookupDate && !this.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicle?.isNoRegisteredOwner && !vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicle?.movesLookupDate | date:'HH:mm'}}</label>               
                    <app-section-details sectionTitle="" [sectionDetails]="registeredOwnerInformation"></app-section-details>                    
                </div>
            </div>
        </div>
    </ng-container>
    <div *ngIf="isContraventionReview && contravention?.roadsideAppeal" class="card">
        <div class="card-header" (click)="onPlusMinusIconClick('imgRoadsideAppeal', 'divRoadsideAppeal')">
            <div>Roadside Appeal</div>
            <div class="plus-minus-icon">
                <img id="imgRoadsideAppeal" src="assets/images/plus.svg" />
            </div>
        </div>
        <div id="divRoadsideAppeal" class="collapse">
            <div class="card-body">
                <app-section-details sectionTitle="Roadside Appeal Information" [sectionDetails]="roadsideAppealInformation"></app-section-details>
                <app-section-details sectionTitle="Roadside Appeal Details" [sectionDetails]="roadsideAppealDetails"></app-section-details>
            </div>
        </div>
    </div>        
    <div class="card" *ngIf="isContraventionReview && isPhase2Submission">
        <div class="card-header" (click)="onPlusMinusIconClick('imgOfficerInformation', 'divOfficerInformation')">
            <div>Officer Information</div>
            <div class="plus-minus-icon">
                <img id="imgOfficerInformation" src="assets/images/plus.svg" />
            </div>  
        </div>
        <div id="divOfficerInformation" class="collapse">
            <div class="card-body">
                <app-section-details sectionTitle="" [sectionDetails]="officerInformation"></app-section-details>                    
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" (click)="onPlusMinusIconClick('imgDeclarations', 'divDeclarations')">
            <div>Declarations</div>
            <div class="plus-minus-icon">
                <img id="imgDeclarations" src="assets/images/plus.svg" />
            </div>
        </div>
        <div id="divDeclarations" class="collapse">
            <div class="card-body">
                <app-section-details *ngIf="contravention?.isReServeRequired == true" sectionTitle="" [sectionDetails]="reServe"></app-section-details>
                <app-section-details sectionTitle="" [sectionDetails]="declarations"></app-section-details>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="showSupportingDocumentDeclarations">
        <div class="card-header" (click)="onPlusMinusIconClick('imgDeclarations', 'divSupportingDocumentDeclarations')">
            <div>Supporting Document Declarations</div>
            <div class="plus-minus-icon">
                <img id="imgDeclarations" src="assets/images/plus.svg" />
            </div>
        </div>
        <div id="divSupportingDocumentDeclarations" class="collapse">
            <div class="card-body">
                <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarations"></app-section-details>
            </div>
        </div>
    </div>
</div>

<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.VIEW_DOWNLOAD_LEA_SUBMISSIONS">
    <div #supportingDocumentsDiv>
        <supporting-documents *ngIf="isContraventionReview"
            [contravention]="contravention"></supporting-documents>
        <supporting-documents *ngIf="!isContraventionReview"
            [vehicleSeizure]="vehicleSeizure"></supporting-documents>
    </div>
</app-access-control>