<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Submit a decision</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">     
            <div class="modal-form-group">
                <table class="table">
                    <thead>
                        <tr>
                            <th *ngIf="review?.reviewItems[0]?.contraventionId != null" style="width: 230px;">Contravention Number</th>
                            <th *ngIf="review?.reviewItems[0]?.vehicleSeizureId != null" style="width: 230px;">Seizure Number</th>
                            <th style="width: 250px;">Type</th>
                            <th>Decision <span style="font-weight: normal;">(Required)</span></th>                                                
                        </tr>
                    </thead>                            
                    <tbody>                                            
                        <tr *ngFor="let reviewItem of review.reviewItems">
                            <ng-container *ngIf="reviewItem?.contraventionId != null">
                                <td><a [routerLink]="['/contravention', reviewItem?.recordNumber]">{{reviewItem?.recordNumber}}</a></td>
                            </ng-container>
                            <ng-container *ngIf="reviewItem?.vehicleSeizureId != null">
                                <td><a [routerLink]="['/seizure', reviewItem?.recordNumber]">{{reviewItem?.recordNumber}}</a></td>
                            </ng-container>
                            <td>{{getContraventionTypeName(reviewItem?.contraventionTypeId)}}</td>
                            
                            <td>
                                <select id="decisionTypeId" 
                                    name="decisionTypeId"                                     
                                    [(ngModel)]="reviewItem.decisionTypeId"  
                                    [disabled]="decisionAlreadyExists"                                  
                                    class="form-control modal-select"
                                    (change)="onDecisionChanged(reviewItem)">
                                    <option class="modal-select-option" [ngValue]="0">--</option>
                                    <option class="modal-select-option"                        
                                        *ngFor="let decisionType of decisionTypes"                                                 
                                        [value]="decisionType.id">{{decisionType.name}}</option>
                                </select>
                            </td>
                        </tr>                      
                    </tbody>               
                </table>
                <div *ngIf="+review?.reviewItems[0].decisionTypeId == 2">
                    <div class="my-4" *ngFor="let cancellationReason of review?.reviewItems[0].reviewItemCancellationReasons">
                        <review-cancellation-reason [reviewItemCancellationReason]="cancellationReason"
                            [reviewTypeId]="review.reviewTypeId">
                        </review-cancellation-reason>
                    </div>
                </div>
                <div *ngIf="+review?.reviewItems[0].decisionTypeId == 4">
                    <label class="modal-body-label">Vary to <span style="font-weight: normal;">(Required)</span></label>
                    <select                                                      
                        class="form-control modal-select vary-to-select"
                        [(ngModel)]="varyToTypeId"
                        [disabled]="decisionAlreadyExists">
                        <option *ngIf="decisionAlreadyExists" class="modal-select-option" [ngValue]="varyToTypeId">{{getChargeTypeName(varyToTypeId)}}</option>
                        <option class="modal-select-option" [ngValue]="0">--</option>
                        <option class="modal-select-option" name="varyToType"
                        *ngFor="let varyToType of varyToTypes"
                        [value]="varyToType">{{getChargeTypeName(varyToType)}}</option>
                    </select>
                </div>
            </div>                                                                        
            <label class="modal-body-label" for="uploadDecisionLetter">Upload decision letter (required)</label>
            <document-upload      
                [tempFileFolder]="tempFileFolder"           
                documentRefTypeName="Reviews"
                [documentRefTypeNumber]="review?.reviewNumber"
                [documentTypeId]="decisionLetterDocumentTypeId"
                (documentAdded)="onDocumentAdded($event)"
                (documentDeleted)="onDocumentDeleted()"
                [disableRemoveDocument]="disableRemoveDocument"
                [validFileExtensions]="validFileExtensions"
            ></document-upload>        
            <div class="error" *ngIf="!isDocumentNameValid"><span style="font-weight: bold;">WARNING:</span> This decision letter does not match the notice associated with this review</div>
            <div class="document-upload-hint">Allowed: {{this.validFileExtensions.join(', ')}}</div>             
        </div>
        
        <div class="button-container">            
            <button id="saveSubmitButton" class="save-submit-button" [ngClass]="{'disabled': isSaveSubmitButtonDisabled()}"  (click)="onSaveSubmitClick()">
                Save and Submit
                <i class="fas fa-cog fa-spin"></i>
            </button>
        </div>
    </div>
</div>
