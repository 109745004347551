


export class RequestPagedSearch {
    public pageNumber: number;
    public pageSize: number;
    public searchTerm: string;
    public orderBy: string;
    public sortDirection: string
    public requestTypeId: number;
    public requestStatusTypeIds: string; 
    public requestDecisionTypeIds: string;
    public checkRequiresCommunication: boolean;    
    public userId : number;   
        
    constructor(init?: Partial<RequestPagedSearch>) {        
        Object.assign(this, init);
    }
}
