<div class="main-content">
    <div class="heading">Manage Adjudicators</div>
    <div>
        <form #manageAdjForm="ngForm">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Adjudicator</th>
                        <th>IRS</th>
                        <th>Seizures</th>
                        <th>Late Review</th>
                        <th>Meeting Account</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let adj of adjudicatorScheduleList; let i = index; trackBy: trackAdj">
                        <td class="adj-name">{{adj.lastName}}, {{adj.firstName}}</td>
                        <td class="max-bookings">
                            <div class="d-flex">
                                <div class="max-bookings-input">
                                    <input [disabled]="isDisabled" type="text" [(ngModel)]="adj.irsWrittenReviewMaxBookings"
                                    [mask]="maxCountMask" name="irsWrittenReviewMaxBookings{{adj.userId}}">
                                    <div class="written"></div>
                                </div>
                                <div class="max-bookings-input">
                                    <input [disabled]="isDisabled" type="text" [(ngModel)]="adj.irsOralReviewMaxBookings"
                                    [mask]="maxCountMask" name="irsOralReviewMaxBookings{{adj.userId}}">
                                    <div class="oral"></div>
                                </div>
                            </div>
                        </td>
                        <td class="max-bookings">
                            <div class="d-flex">
                                <div class="max-bookings-input">
                                    <input [disabled]="isDisabled" type="text" [(ngModel)]="adj.seizureReviewMaxBookings"
                                    [mask]="maxCount99Mask" name="seizureReviewMaxBookings{{adj.userId}}">
                                    <div class="written"></div>
                                </div>
                            </div>
                        </td>
                        <td class="max-bookings">
                            <div class="d-flex">
                                <div class="max-bookings-input">
                                    <input [disabled]="isDisabled" type="text" [(ngModel)]="adj.lateReviewMaxBookings"
                                    [mask]="maxCountMask" name="lateReviewMaxBookings{{adj.userId}}">
                                    <div class="written"></div>
                                </div>
                            </div>
                        </td>
                        <td class="meeting-account">
                            <input type="text" [(ngModel)]="adj.meetingAccount"
                                name="meetingAccount{{adj.userId}}" #meetingAccount="ngModel"
                                [required]="adj.irsOralReviewMaxBookings > 0"
                                [disabled]="!canEditAdjudicators"
                                [ngClass]="{ 'alert-border': meetingAccount.invalid }">
                            <div class="alert-text" *ngIf="meetingAccount?.errors?.required">
                                Meeting account is required
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <span class="error">{{errorMessage}}</span>
            </div>
            <div class="d-flex justify-content-end">
                <app-access-control [Resource]="Resource.DASHBOARD" [Permission]="Permission.MANAGE_ADJUDICATORS_EDIT">
                    <button class="goa-button goa--secondary reset-button" (click)="onResetClick()">Reset</button>
                    <button class="goa-button submit-button" (click)="onSubmitClick(manageAdjForm.valid)">Submit</button>
                </app-access-control>
            </div>
        </form>
    </div>
</div>
