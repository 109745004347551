<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Add Supporting Document</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="row">  
                <div class="col-12">
                    <label *ngIf="documentRefTypeName != 'Requests'" class="required">File Category</label>
                    <label *ngIf="documentRefTypeName == 'Requests'" class="required">Document Type</label>
                </div>
            </div>   
            <div class="row">
                <div class="col-6">
                    <select [appSelectValidator]="true" #additionalDocumentTypeControl="ngModel" class="form-control" id="additionalDocumentType" name="additionalDocumentType" [(ngModel)]="selectedDocumentType" (ngModelChange)="onFileCtegoryChange()" [ngClass]="{'is-invalid':(additionalDocumentTypeControl.touched || isSubmitClicked) && additionalDocumentTypeControl.errors?.defaultSelected}">
                        <option *ngIf="documentRefTypeName != 'Requests'" [ngValue]="null">Select a File Category</option>
                        <option *ngIf="documentRefTypeName == 'Requests'" [ngValue]="null">Select a Method</option>
                        <option *ngFor="let documentType of additionalDocumentTypes" [ngValue]="documentType">{{documentType.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div *ngIf="documentRefTypeName != 'Requests'">File Category is required</div>
                        <div *ngIf="documentRefTypeName == 'Requests'">Document Type is required</div>
                    </div>
                </div>
            </div> 

            <div class="drop-zone" (click)="uploadFile.click()">
                <input style="display: none;" type="file" [disabled]="this.disableFileUpload" (change)="onUploadFileChanged($event)" #uploadFile>
                <div><i class="fas fa-upload fa-lg"></i></div>
                Drag and drop or click to upload
            </div>

            <div class="uploaded-documents">
                <div class="goa-table documents-table mt-0">
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th class="border-0">File Name</th>
                                <th class="border-0">Status</th>
                                <th class="border-0">Format</th>
                                <th class="border-0">Size</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let document of documents; index as i">
                                <td>{{getDocumentTypeName(document.documentTypeId)}}</td>
                                <td>
                                    <img class="download-file-icon" src="assets/images/download-file.png" />
                                    <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                                </td>
                                <td>
                                    <div *ngIf="document.documentId <=0" class="upload-bar">
                                        <div id="uploadBarProgress_{{i}}" class="upload-bar-progress"></div>              
                                    </div>    
                                </td>
                                <td>{{document.documentExtension | lowercase}}</td>
                                <td>{{document.documentSize}}</td>
                                <td><a id="removeFileLink_{{i}}" class="remove-file-link" [ngClass]="{ 'disabled': this.disableFileUpload || isUploadInProgress() }" (click)="onRemoveFileLinkClick(document)">Delete</a></td>
                            </tr>
                            <tr *ngIf="documents?.length == 0">
                                <td colspan="6">No documents uploaded.</td>
                            </tr>  
                        </tbody>                        
                    </table>
                </div>
            </div>
        </div>
        <div class="button-container">
            <div class="d-flex flex-column">
                <span class="hint-text">{{allowedFileTypes}}</span>
                <span class="error mt-2">{{errorMessage}}</span>
            </div>
            <div class="d-flex">            
                <div id="cancelButton" class="cancel-button" (click)="onCancelClick()" >Cancel</div>
                <div id="uploadButton" [ngClass]="{ 'disabled': documents?.length == 0 || isUploadInProgress() }" class="upload-button" (click)="onUploadClick($event)">
                    Upload
                    <i class="fas fa-cog fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</div>
