

import { PaymentRequest } from './payment-request.model';
import { Representation } from './representation.model';
import { LateReviewRequestPayment } from './late-review-request-payment.model';
import { ReviewApplicant } from './review-applicant.model';
import { Notification } from './notification.model';
import { ContactAddress } from './contact-address.model';
import { Event } from './event.model';
import { Document } from './document.model';
import { LateReviewRequestItem } from './late-review-request-item.model';
import { User } from './user.model';
import { RegistryAgentInformation } from './registry-agent-information.model';

export class LateReviewRequest {
    public paymentRequest: PaymentRequest;
    public driverLastName: string;
    public driverFirstName: string;
    public lateReviewRequestId: number = 0;
    public lateReviewRequestNumber: string;
    public contraventionId: number;
    public vehicleSeizureId: number;
    public requestStatusTypeId: number = 0;
    public reviewTypeId: number = 0;
    public isSelfSubmit: boolean = false;
    public represenationId: number;
    public reviewApplicantId: number;
    public feeAmount: number = 0;
    public requestReason: string;
    public contactMethodTypeId: number = 0;
    public phoneNumber: string;
    public email: string;
    public contactAddressId: number;
    public requestDate: Date = new Date();
    public requestDecisionTypeId: number;
    public decisionDate: Date;
    public cancelReason: string;
    public notes: string;
    public decisionDueDate: Date;
    public representation: Representation;
    public payments: LateReviewRequestPayment[] = [];
    public reviewApplicant: ReviewApplicant;
    public notifications: Notification[] = [];
    public contactAddress: ContactAddress;
    public events: Event[] = [];
    public documents: Document[] = [];
    public driverEmail: string;
    public tempFolderName: string;
    public termsConditionsVersion: string;
    public isRecipientYouth: boolean = false;
    public lateReviewRequestItems: LateReviewRequestItem[] = [];
    public reviewRequestDeadline: Date;
    public canRequestReview: boolean = false;    
    public adjudicatorLastName:string;
    public adjudicatorFirstName:string;
    public userId: number;
    public user: User;
    public isPhoneContactRequired:boolean = false;
    public stopInformationId: number;
    public contraventionOccurrenceDate: Date;
    public contraventionIssuanceDate: Date;
    public vehicleSeizureDate: Date;
    public registryAgentInformation: RegistryAgentInformation[] = [];
    public isSubmittedByRegistry: boolean;

    constructor(init?: Partial<LateReviewRequest>) {
        
        Object.assign(this, init);
    }
}
