<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Judicial Review Served</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="form-group row">
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label" for="courtActionNumber">Court Action Number</label>
                    <input type="text" class="form-control" id="courtActionNumber" name="courtActionNumber"
                        placeholder="Enter if available" [(ngModel)]="courtActionNumber" />
                </div>
                <div class="col-12 col-lg-4" style="position: relative;">                    
                    <label class="modal-body-label" for="judicialReviewReceivedDate">JR Served Date <span class="label-hint">(Required)</span></label>
                    <input type="text" required class="form-control" id="judicialReviewReceivedDate"
                        name="judicialReviewReceivedDate" #dp="bsDatepicker" bsDatepicker [bsConfig]="datePickerConfig"
                        [dateCustomClasses]="dateCustomClasses" placeholder="mm/dd/yyyy" [(ngModel)]="receivedDate" #judicialReviewReceivedDate="ngModel"
                        [ngClass]="{'alert-border': judicialReviewReceivedDate.invalid && formSubmitted}"/>                                        
                    <span class="calendar-icon" (click)="dp.toggle()"><i class="far fa-calendar"></i></span>                    
                </div>
            </div>
            <div>
                <div class="label">Upload Documents <span class="label-hint">(Required)</span></div>
            </div>
            <div *ngFor="let doc of documents; let i = index; trackBy: trackDocument">
                <file-upload [document]="doc" [fileUploadModel]="fileUploadModel" [isDisplayMaxFileSize]="true"
                    [isDisabled]="disableFileUpload" (onCancelEvent)="onCancelDcoument($event)"></file-upload>
            </div>
            <div *ngIf="errorMessage.length > 0">
                <span class="error">{{errorMessage}}</span>
            </div>
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
            <div id="proceedButton" class="proceed-button" [ngClass]="{ 'disabled': isUploadInProgress() }" (click)="onProceedClick($event)">
                Proceed
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>