<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">This Review is Currently Being Viewed</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            This review was last viewed by <strong class="modal-body-bold">{{lastViewedName}}</strong>, <strong class="modal-body-bold">{{lastViewedMinutesString}}</strong> ago.
        </div>
        <div class="button-container">
            <div id="okayButton" class="okay-button" (click)="onOkayButtonClick()">Okay</div>
        </div>
    </div>
</div>