import { Component, Input, OnInit } from '@angular/core';
import { ReviewItemCancellationReason } from '@apis/shared/models/review-item-cancellation-reason.model';
import { ReviewCancellationReasonType } from '@apis/shared/models/types/review-cancellation-reason-type.model';
import { ReviewCancellationSecondaryReasonType } from '@apis/shared/models/types/review-cancellation-secondary-reason-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'review-cancellation-reason',
  templateUrl: './review-cancellation-reason.component.html',
  styleUrls: ['./review-cancellation-reason.component.scss']
})
export class ReviewCancellationReasonComponent implements OnInit {
  @Input() reviewItemCancellationReason: ReviewItemCancellationReason;
  @Input() reviewTypeId: number;

  reviewCancellationReasonTypes: ReviewCancellationReasonType[];
  reviewCancellationSecondaryReasonTypes: ReviewCancellationSecondaryReasonType[];

  constructor(
    private readonly localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.reviewCancellationReasonTypes = this.localStorageService.getReviewCancellationReasonTypes().filter(r => r.reviewTypeId == this.reviewTypeId);
    this.reviewCancellationSecondaryReasonTypes = this.getSecondaryReasons(this.reviewItemCancellationReason); // Secondary reasons must be initialized on init, in case the review was previously cancelled (meaning a cancellation reason is already selected)
  }

  onCancellationReasonChanged(): void {
    this.reviewItemCancellationReason.reviewCancellationSecondaryReasonTypeId = null;
    this.reviewCancellationSecondaryReasonTypes = this.getSecondaryReasons(this.reviewItemCancellationReason);
  }

  getSecondaryReasons(reviewItemCancellationReason: ReviewItemCancellationReason): ReviewCancellationSecondaryReasonType[] {
    return this.localStorageService.getReviewCancellationSecondaryReasonTypes().filter(s =>
      reviewItemCancellationReason.reviewCancellationReasonTypeId == 76 ? s.id == 5 : s.id != 5 // JTI-5026, "PU" cancellation reason has a different secondary reason: "Smit v Alberta"
    );
  }

  isSecondaryReasonApplicable(reviewCancellationReasonTypeId: number): boolean {
    return this.reviewCancellationReasonTypes.find(t => t.id == reviewCancellationReasonTypeId)?.hasSecondaryReason;
  }

  getCancellationReasonDescription(reviewCancellationReasonTypeId: number): string {
    return this.reviewCancellationReasonTypes.find(t => t.id == reviewCancellationReasonTypeId)?.description;
  }

  getContraventionDetailsName(contraventionDetailTypeId: number): string {
    return this.localStorageService.getContraventionDetailTypes().find(x => x.id == contraventionDetailTypeId).name;
  }
}
