import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'adjudication-footer',
  templateUrl: './adjudication-footer.component.html',
  styleUrls: ['./adjudication-footer.component.scss']
})
export class AdjudicationFooterComponent implements OnInit {
  year: number;

  constructor() { }

  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
  }
}
