import { formatDate } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import * as fileSaver from "file-saver";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from '../shared/services/report.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent {
    constructor(private readonly reportService: ReportService,
        private readonly spinner: NgxSpinnerService) { }

    onDownloadReviewRepresentationsReport() {
        this.spinner.show();
        setTimeout(() => {
            let element = $(".loading-text > p");
            element.html(`<span style='font-size: 17px !important;'>Please be patient while APIS generates your report</span>`);
        }, 100);

        this.reportService.downloadReviewRepresentationsReport()
            .subscribe((result: HttpEvent<Blob>) => {
                if (result.type == HttpEventType.Response) {
                    this.spinner.hide();
                    fileSaver.saveAs(result.body, "APIS-Review-Representation-Report.csv");
                }
            }, (error: any) => this.spinner.hide());
    }
}