import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AdjudicationService } from "../shared/services/adjudication.service";
import { SeizureInformationComponent } from './components/seizure-information/seizure-information.component';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SeizureRecipientInformationComponent } from './components/seizure-recipient-information/seizure-recipient-information.component';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DocumentTypes, EventTypes, RepresentationTypes, RequestTypes, SeizureStatusTypes, SeizureTypes } from '@apis/shared/enums/app.enum';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { Event } from '@apis/shared/models/event.model';
import { Document } from '@apis/shared/models/document.model';
import * as fileSaver from "file-saver";
import { DocumentService } from '@apis/shared/services/document.service';
import { KeycloakService } from 'keycloak-angular';
import { CommonMethods } from '../shared/helpers/common-methods';
import { Review } from '@apis/shared/models/review.model';
import { ReviewRequestExtensionModalComponent } from '../shared/components/modals/review-request-extension-modal/review-request-extension-modal.component';
import { CancelSeizureModalComponent } from './modals/cancel-seizure-modal/cancel-seizure-modal.component';
import { NgxSpinnerService } from "ngx-spinner";
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { SeizureActivityComponent } from './components/seizure-activity/seizure-activity.component';
import { SeizureStatusType } from '@apis/shared/models/types/seizure-status-type.model';

@Component({
  selector: 'app-seizure-details',
  templateUrl: './seizure-details.component.html',
  styleUrls: ['./seizure-details.component.scss']
})
export class SeizureDetailsComponent implements OnInit {
  TypeTable = TypeTable;
  contraventionNumber: string;
  seizureNumber: string;
  vehicleSeizure: VehicleSeizure;

  contravention: Contravention;
  supportingDocuments: any;
  recentActivities: any[] = [];  
  vehicleSeizureStatusTypes: SeizureStatusType[] = [];  
  bodyElement: JQuery<HTMLElement>;
  noteModalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  noteModalTextarea: JQuery<HTMLElement>;
  datePipe: DatePipe;
  review: Review;
  amountPaid = 0;
  amountDue = 0;
  paymentDueInfo: string = "";
  isDueDateExtended: boolean = false;
  isRedactionRequired: boolean = false;
  detachmentName: string;
  disableExtendReviewDeadline: boolean = false;
  manageSeizure: HTMLSelectElement;
  RequestTypes = RequestTypes;
  SeizureTypes = SeizureTypes;
  EventTypes = EventTypes;
  loggedUserName: string;
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;
  canImmediateCancel:boolean=false;
  canReviewRequestExtension:boolean=false;
  isAnotherSeizureExists: boolean = false;
  anotherSeizureTypeId: number;
  anotherSeizureStatus: string;
  isBusinessSuperUser: boolean = false;

  @ViewChild(SeizureInformationComponent) seizureInformation: SeizureInformationComponent;
  @ViewChild(SeizureRecipientInformationComponent) seizureRecipientInformation: SeizureRecipientInformationComponent;
  requestType:number;
  @ViewChild(SeizureActivityComponent) seizureActivity: SeizureActivityComponent;
  
  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly adjudicationService: AdjudicationService,
    private readonly documentService: DocumentService,
    private readonly viewContainerRef: ViewContainerRef,
    readonly localStorageService: LocalStorageService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {    
    this.manageSeizure = document.getElementById("manageSeizure") as HTMLSelectElement;


    this.bodyElement = $(document.body);
    this.noteModalOverlay = $(".note-modal-overlay");
    this.noteModalTextarea = $(".note-modal-textarea");
    this.datePipe = new DatePipe("en-US");
    this.loggedUserName = this.keycloakService.getUsername()

    this.activatedRoute.params.subscribe((data: Params) => {
      this.seizureNumber = data.seizureNumber;
      
      this.spinner.show();
      this.adjudicationService.getSeizureByNumber(this.seizureNumber)
        .subscribe((result: VehicleSeizure) => {
          this.vehicleSeizure = result;            
          this.loadVehicleSeizureData();
          this.spinner.hide();
        }, error => this.spinner.hide());
    });

    this.localStorageService.hasLocalStorageReady$.subscribe(val => {        
      this.canImmediateCancel = this.localStorageService.hasPermission(Constants.Resource.SEIZURE, Constants.Permission.IMMEDIATE_CANCEL);
      this.canReviewRequestExtension = this.localStorageService.hasPermission(Constants.Resource.SEIZURE,Constants.Permission.REVIEW_REQUEST_EXTENSION);

      // Check for the BusinessSuperUser role. Determines whether the user can view notice cancellation requests.
      let rolePermissionKey = this.localStorageService.getRolePermissions();
      if (rolePermissionKey !== null && rolePermissionKey !== undefined) {
        rolePermissionKey.forEach(p => {
          if (p.roleName === 'BusinessSuperUser') {
            this.isBusinessSuperUser = true;
          }
        });
      }
    });
  }

  loadVehicleSeizureData(): void {
    this.isAnotherSeizureExists = this.vehicleSeizure.isAnotherSeizureExists;
    if(this.isAnotherSeizureExists)
    {
      this.anotherSeizureTypeId = this.vehicleSeizure.anotherSeizureTypeId;
      this.anotherSeizureStatus = this.localStorageService.getTypeItemDescriptionById(this.vehicleSeizure.anotherSeizureStatusTypeId, TypeTable.SeizureStatusType)?.toUpperCase();
    }

    if (this.vehicleSeizure.policeServiceInformation?.length > 0) {
      this.detachmentName = this.vehicleSeizure.policeServiceInformation[0].detachment.detachmentName;
    }
    
    if (this.vehicleSeizure.reviewItems != null && this.vehicleSeizure.reviewItems.length > 0) {
      this.review = this.vehicleSeizure.reviewItems[0].review;
    }

    this.seizureInformation.vehicleSeizure = this.vehicleSeizure;
    this.seizureRecipientInformation.vehicleSeizure = this.vehicleSeizure;

    if (this.vehicleSeizure.seizureTypeId == 1) { // IRS Contravention Seizure          
      this.getAssociatedContravention(this.vehicleSeizure.contraventionId);
    } else {
      this.isRedactionRequired = this.vehicleSeizure.documents.filter(x => [DocumentTypes.PoliceNarrative, DocumentTypes.WitnessStatements, DocumentTypes.Other].includes(+x.documentTypeId) && x.isPublished == null && !x.isSubmitLater).length > 0
      this.populateRecentActivity();
      this.seizureInformation.PopulateSeizureInformation();
      this.seizureRecipientInformation.PopulateRecipientInformation();
    } 
    
    this.setReviewRequestExtensionAvailability();
  }

  setReviewRequestExtensionAvailability(): void {
    if (this.review != null) {
      this.disableExtendReviewDeadline = true;
      return;
    }

    if (+this.vehicleSeizure.seizureStatusTypeId != SeizureStatusTypes.Seized) {
      this.disableExtendReviewDeadline = true;
      return;
    }
   
    let today = new Date();    
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    
    let seizureDate = new Date(this.vehicleSeizure.seizureDate);
    let reviewRequestDeadline = new Date(seizureDate);
    reviewRequestDeadline = new Date(reviewRequestDeadline.setDate(reviewRequestDeadline.getDate() + 7));

    let extendedReviewRequestDeadline = new Date(seizureDate);
    extendedReviewRequestDeadline = new Date(extendedReviewRequestDeadline.setDate(extendedReviewRequestDeadline.getDate() + 9));

    let reviewRequestExtensionDate = new Date(this.vehicleSeizure.reviewRequestExtensionDate);
    
    if (today.valueOf() > extendedReviewRequestDeadline.valueOf()) {
      this.disableExtendReviewDeadline = true;
    }

    if (reviewRequestExtensionDate.valueOf() > extendedReviewRequestDeadline.valueOf()) {
      this.disableExtendReviewDeadline = true;
    }
  }

  getAssociatedContravention(contraventionId: number)
  {
    this.adjudicationService.getContraventionById(contraventionId)
        .subscribe((result: any) => {
          this.contravention = result;  
          
          this.isRedactionRequired = this.contravention.documents.filter(x => [DocumentTypes.PoliceNarrative, DocumentTypes.WitnessStatements, DocumentTypes.Other].includes(+x.documentTypeId) && x.isPublished == null && !x.isSubmitLater).length > 0
          this.populateRecentActivity();
          this.seizureInformation.contravention = this.contravention;
          this.seizureInformation.PopulateSeizureInformation();
          this.seizureRecipientInformation.contravention = this.contravention;
          this.seizureRecipientInformation.PopulateRecipientInformation();

        });
  }

  populateRecentActivity(): void {
    this.recentActivities = [];

    this.setSeizureSubmittedRecentActivity();

    let events = this.vehicleSeizure.events;

    for (let event of events) {               
      let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
      eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());

      let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
      let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
      let username = event.userName;
      let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
      let body = event.eventDetails;

      let documents = event.documents;

      if (+event.eventTypeId == EventTypes.SeizureCancelled) {
        documents = this.vehicleSeizure.documents.filter(d => 
          +d.documentTypeId == DocumentTypes.VehicleSeizureCancellationAuthorization
          ||  +d.documentTypeId == DocumentTypes.VehicleSeizureCancellationAdditional)
      }
      
      this.recentActivities.push({
        date: `${eventDate} at ${eventTime}`,
        dateTime: eventDateTime,
        by: username,
        title: title,
        body: body,
        documents: documents,
        id: event.eventId,
        eventTypeId:event.eventTypeId,
        noticeCancellationRequestId: event.noticeCancellationRequestId,
        isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
      });
    }
    
    this.recentActivities = this.recentActivities.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });    

    this.recentActivities = this.recentActivities.length > 3 ? this.recentActivities.slice(0,3) : this.recentActivities;

    setTimeout(() => {
      if ($(".recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".recent-activity-container:last-child")[0].offsetTop;    
        $(".recent-activity-connector").height(offsetTop);
      }     
    }, 100);            
  }  
  
  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.loggedUserName
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  isCancelledByLEArequest(recentActivity):Boolean
  {
      if(recentActivity.eventTypeId == EventTypes.SeizureCancelled && recentActivity.noticeCancellationRequestId)
      {
        this.requestType = RequestTypes.LEACancellationRequest;
         return true;           
      }
      return false;
  }


  private setSeizureSubmittedRecentActivity(): void {
    
    let submissionDateTime = new Date(this.vehicleSeizure.submissionDate);
    let timeComponents = this.vehicleSeizure.submissionTime.split(":");        
    timeComponents.forEach((c: string, index: number) => {
      if (index == 0) submissionDateTime.setHours(parseInt(c));
      if (index == 1) submissionDateTime.setMinutes(parseInt(c));
      if (index == 2) submissionDateTime.setSeconds(parseInt(c));      
    });

    let submissionDate = this.datePipe.transform(submissionDateTime.toString(), "mediumDate");
    let submissionTime = this.datePipe.transform(submissionDateTime.toString(), "HH:mm");
        
    let submittedBy = this.vehicleSeizure?.policeOfficerFullName;
    let policeService = this.localStorageService.getAgencies().find(x => x.agencyId == this.vehicleSeizure.policeServiceInformation[0]?.detachment?.agencyId)?.agencyName;
                
    this.recentActivities.push({ 
      date: `${submissionDate} at ${submissionTime}`, 
      dateTime: new Date(`${submissionDate} ${submissionTime}`),
      by: `${submittedBy} (${policeService})`, 
      title: "Seizure Submitted", 
      body: null
    });
  }

  onViewClick(element: any): void {
    element.scrollIntoView(false)
  }

  onTabHeaderClick(ev: any): void {    
    if (ev.target.classList.contains("selected")) {
      return;
    }
   
    let tabId = ev.target.id.split("_")[1];

    $(".tab-header-item").removeClass("selected");
    $(".tab-content-item").removeClass("selected");
    
    $(`#tabHeader_${tabId}`).addClass("selected");
    $(`#tab_${tabId}`).addClass("selected");
  }

  onCloseIconClick(): void {
    this.showHideModal();
  }

  onCancelModalClick(): void {
    this.showHideModal();
  }

  onAddModalClick(ev: any): void {
    if (this.maxCharacters < 0) {
      return;
    }

    this.showHideModal();
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.vehicleSeizureId = this.vehicleSeizure.vehicleSeizureId;
    componentRef.instance.documentRefTypeNumber = this.vehicleSeizure.seizureNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        
        this.vehicleSeizure.events.push(result);
        this.populateRecentActivity();        
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.vehicleSeizure.seizureNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.vehicleSeizure.events = this.vehicleSeizure.events.filter(x=>x.eventId !== eventId);
        this.vehicleSeizure.events.push(result);
        this.populateRecentActivity();        
      }
      this.viewContainerRef.clear();
    });
  }

  seizureActivityTabSelected() {
    this.seizureActivity.setOffSetTopForContraventionRecentActivity();
  }

  refreshSeizure(vehicleSeizure: VehicleSeizure)
  {
    this.vehicleSeizure = vehicleSeizure;
    this.loadVehicleSeizureData();
  }

  loadVehicleSeizureSeizureData() {
    this.seizureActivity.populateRecentActivity();

    this.populateRecentActivity();

    this.vehicleSeizureStatusTypes = this.localStorageService.getSeizureStatusTypes();   
  }

  onDeleteNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.vehicleSeizure.seizureNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.vehicleSeizure.events = this.vehicleSeizure.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();        
      }
      this.viewContainerRef.clear();
    });
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }

  private showHideModal(show?: boolean): void {
    if (show) {
      this.bodyElement.addClass("overflow-hidden");
      this.noteModalOverlay.addClass("modal-show");
    } else {
      this.maxCharacters = 250;
      this.noteModalTextarea.val("");
      this.bodyElement.removeClass("overflow-hidden");
      this.noteModalOverlay.removeClass("modal-show");
    }
  }

  getSeizureClass(statusId: any): string {
    var returnClass: string = "seizure-status"

    if (statusId == null)
      return returnClass;

    switch (statusId.toString())
    {
      case "1": //Seized
      {
        returnClass = `${returnClass} seizure-status-seized`;
        break;
      }
      case "2": //In Review
      {
        returnClass = `${returnClass} seizure-status-inreview`;
        break;
      }
      case "3": //Released
      {
        returnClass = `${returnClass} seizure-status-released`;
        break;
      }
      case "4": //Cancelled
      {
        returnClass = `${returnClass} seizure-status-cancelled`;
        break;
      }
    }
    return returnClass;
  }

  onRecentActivityDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    let documentRefTypeName = "Events";

    if ([DocumentTypes.VehicleSeizureCancellationAuthorization, DocumentTypes.VehicleSeizureCancellationAdditional].includes(+document.documentTypeId)) {
      documentRefTypeName = "VehicleSeizures";
    }

    this.documentService.downloadDocument("", documentRefTypeName, this.vehicleSeizure.seizureNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  seizureInformationCallback()
  {
    this.ngOnInit();
  }

  onManageSeizureChange(ev: any): void {
    if(ev.target.value == "1")  {
      this.showCancelSeizureModal();
    } else if (ev.target.value == "2") {
      this.showReviewRequestExtensionModal();
    }
  }

  showCancelSeizureModal() {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(CancelSeizureModalComponent);

    componentRef.instance.vehicleSeizure = Object.assign({}, this.vehicleSeizure);
    componentRef.instance.close.subscribe((result: VehicleSeizure) => {
      if (result != null) {
        this.vehicleSeizure = result;
        this.loadVehicleSeizureData();
      }
                  
      if (this.manageSeizure != null) {
        this.manageSeizure.selectedIndex = 0;
      }
      
      componentRef.destroy();
    });    
  }

  private showReviewRequestExtensionModal(): void {
   this.viewContainerRef.clear();

   let componentRef = this.viewContainerRef.createComponent(ReviewRequestExtensionModalComponent);
   componentRef.instance.vehicleSeizureId = this.vehicleSeizure.vehicleSeizureId;
   componentRef.instance.close.subscribe((result: VehicleSeizure) => {
     if (result != null) {
       this.vehicleSeizure = result;
       this.loadVehicleSeizureData();
     }

     this.manageSeizure.selectedIndex = 0;
     componentRef.destroy();
   })
  }
}