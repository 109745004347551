import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TowCompany } from '@apis/shared/models/tow-company.model';
import { TowCompanyService } from '@apis/shared/services/tow-company.service';

@Component({
  selector: 'app-delete-tow-companies-modal',
  templateUrl: './delete-tow-companies-modal.component.html',
  styleUrls: ['./delete-tow-companies-modal.component.scss']
})
export class DeleteTowCompaniesModalComponent implements OnInit {
  towCompany: TowCompany;
  close: EventEmitter<TowCompany> = new EventEmitter<TowCompany>();
  body: JQuery<HTMLElement>;

  errorMessage: string;

  constructor(
    private towCompanyService: TowCompanyService,
    private readonly spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.body = $(document.body);
    this.body.addClass("overflow-hidden");
  }

  onCloseIconClick(): void {
    this.close.emit(null);
    this.hideModal();
  }

  onCancelClick(): void {
    this.close.emit(null);
    this.hideModal();
  }

  private hideModal() {
    this.body.removeClass("overflow-hidden");
  }

  onDeleteClick(): void {
    this.spinner.show();

    this.towCompanyService.deleteTowCompanyAsync(this.towCompany.towCompanyId)
      .subscribe((response: any) => {
          this.spinner.hide();
          if (response) {
            this.close.emit(response);
            this.hideModal();
          }
          else {
            this.errorMessage = "Unable to delete the tow company. Please try again later.";
          }
        },
        (error: any) => {
          this.errorMessage = "Unable to delete the tow company. Please try again later.";
          this.spinner.hide();
        }); 
  }
}
