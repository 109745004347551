

import { Representation } from './representation.model';
import { Notification } from './notification.model';
import { ContactAddress } from './contact-address.model';
import { Event } from './event.model';
import { Document } from './document.model';

export class PaymentAdditionalTimeRequest {
    public contraventionNumber: string;
    public driverLastName: string;
    public driverFirstName: string;
    public paymentAdditionalTimeRequestId: number = 0;
    public additionalTimeToPayRequestNumber: string;
    public contraventionId: number = 0;
    public isSelfSubmit: boolean = false;
    public representationId: number;
    public isEmployed: boolean = false;
    public incomeLevelTypeId: number;
    public hasDependents: boolean = false;
    public hasSpousalSupportPay: boolean = false;
    public hasChildSupportPay: boolean = false;
    public hasDebts: boolean = false;
    public debtsDetails: string;
    public requestNotes: string;
    public contactMethodTypeId: number = 0;
    public phoneNumber: string;
    public email: string;
    public contactAddressId: number;
    public requestDate: Date = new Date();
    public requestStatusTypeId: number = 0;
    public requestDecisionTypeId: number;
    public decisionDate: Date;
    public decisionDueDate: Date;
    public representation: Representation;
    public notifications: Notification[] = [];
    public contactAddress: ContactAddress;
    public events: Event[] = [];
    public documents: Document[] = [];
    public termsConditionsVersion: string;
    public isRecipientYouth: boolean = false;    

    constructor(init?: Partial<PaymentAdditionalTimeRequest>) {
        
        Object.assign(this, init);
    }
}
