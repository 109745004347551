import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ContraventionDetailsComponent } from './contravention-details.component';
import { ContraventionInformationComponent } from './components/contravention-information/contravention-information.component';
import { RecipientInformationComponent } from './components/recipient-information/recipient-information.component';
import { PaymentInformationComponent } from './components/payment-information/payment-information.component';
import { SharedModule } from "@apis/shared/shared.module";
import { SupportingDocumentsModule } from "../shared/components/supporting-documents/supporting-documents.module";
import { DriverLogModule } from "../shared/components/driver-log/driver-log.module";
import { CancelContraventionModalComponent } from './modals/cancel-contravention-modal/cancel-contravention-modal.component';
import { NgxMaskDirective } from 'ngx-mask';
import { ContraventionActivityComponent } from './components/contravention-activity/contravention-activity.component';
import { FineUncollectableComponent } from './modals/fine-uncollectable/fine-uncollectable.component';

@NgModule({
  declarations: [
    ContraventionDetailsComponent,
    ContraventionInformationComponent,
    RecipientInformationComponent,
    PaymentInformationComponent,
    CancelContraventionModalComponent,
    ContraventionActivityComponent,
    FineUncollectableComponent    
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    SupportingDocumentsModule,
    DriverLogModule,
    NgxMaskDirective
  ]
})
export class ContraventionDetailsModule { }
