import { Component, OnInit } from '@angular/core';
import { Constants } from '@apis/shared/helpers/constants';
import { Setting } from '@apis/shared/models/setting.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SettingService } from '@apis/shared/services/setting.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'adjudication-header',
  templateUrl: './adjudication-header.component.html',
  styleUrls: ['./adjudication-header.component.scss']
})
export class AdjudicationHeaderComponent implements OnInit {
  username: string;
  environmentName: String = "";
  maintenanceMessageHeader: String = "";
  maintenanceMessage: String = "";

  constructor(private readonly keycloakService: KeycloakService,
            private readonly localStorageService: LocalStorageService,
            private readonly settingService: SettingService) { }

  ngOnInit(): void {
    this.environmentName = environment.name;
    this.username = this.keycloakService.getUsername();
    this.getMaintenanceMessage();
  }

  onLogoutClick()
  {
    this.localStorageService.clearAll();  
    this.keycloakService.logout(window.location.origin);
  }

  private getMaintenanceMessage()
  {
    this.settingService.getSettings()
    .subscribe((settings: Setting[]) => {
      if (settings) {
        this.maintenanceMessage  = settings.find(s => s.settingName == Constants.Settings.ADMIN_MAINTENANCE_MESSAGE)?.settingValue;
        this.maintenanceMessageHeader  = settings.find(s => s.settingName == Constants.Settings.ADMIN_MAINTENANCE_MESSAGE_HEADER)?.settingValue;
      }
    });
  }
}
