import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes, ReviewMethodTypes, ReviewStatusTypes } from "@apis/shared/enums/app.enum";
import { KeycloakService } from 'keycloak-angular';
import { ReviewService } from '../../../shared/services/review.service';
import { ReviewMethodType } from '@apis/shared/models/types/review-method-type.model';
import { NgForm, NgModel, Validators } from '@angular/forms';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'change-review-method-modal',
  templateUrl: './change-review-method-modal.component.html',
  styleUrls: ['./change-review-method-modal.component.scss']
})
export class ChangeReviewMethodModalComponent implements OnInit, AfterViewInit {
  review: Review;  
    
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;  
  changeButton: JQuery<HTMLElement>;  
  username: string;
  eventDetails: string;
  failedAttendanceDeclarationActive: boolean = false;
  
  reviewMethodTypes: ReviewMethodType[];
  enumReviewMethodType = ReviewMethodTypes;
  
  @Output() close: EventEmitter<Review> = new EventEmitter();

  @ViewChild("videoConferenceUrl") videoConferenceUrl: NgModel;
  @ViewChild("note") note: NgModel;
  @ViewChild("changeReviewMethodForm") changeReviewMethodForm: NgForm;

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService,
    private readonly keycloakService: KeycloakService) { }

  ngOnInit(): void {     
    this.reviewMethodTypes = this.localStorageService.getReviewMethodTypes()
      .sort((a: ReviewMethodType, b: ReviewMethodType) => {
        return +b.displayOrder - (+a.displayOrder);
      });

    this.username = this.keycloakService.getUsername();
    
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.changeButton = $(".change-button");

    this.failedAttendanceDeclarationActive = new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10));
  }

  ngAfterViewInit(): void {
    if (this.videoConferenceUrl != null) {
      this.videoConferenceUrl.control.setValidators(Validators.required);
    }

    if (this.note != null) {
      this.note.control.setValidators(Validators.maxLength(250));
    }
  }

  onReviewMethodChange(): void {
    this.review.failedAttendanceDeclarationTypeId = null;
  }

  onTextareaInput(): void {    
    this.maxCharacters = 250 - this.eventDetails.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onChangeModalClick(ev: any): void {         
    const declarationRequiredAndNotSelected = this.failedAttendanceDeclarationActive
      && this.review.reviewMethodTypeId == ReviewMethodTypes.Oral
      && this.review.failedAttendanceDeclarationTypeId == null;

    if (this.changeReviewMethodForm.invalid || declarationRequiredAndNotSelected) {
      return;
    }

    this.changeButton.attr("disabled", "");
    this.changeButton.addClass("saving");
    if(+this.review.reviewMethodTypeId == this.enumReviewMethodType.Written)
    {
      this.review.scheduledEvent.meetingKey = null;
    }
    
    const review = new Review({
      reviewId: +this.review.reviewId,
      reviewMethodTypeId: +this.review.reviewMethodTypeId,
      failedAttendanceDeclarationTypeId: this.review.failedAttendanceDeclarationTypeId, // Do not convert to an int using "+". This will convert null to 0, which causes an error.
      videoConferenceUrl: +this.review.reviewMethodTypeId == 2 ? this.review.videoConferenceUrl : null,
      scheduledEvent: this.review.scheduledEvent

    });
     
    review.events.push(new Event({
      eventTypeId: EventTypes.ReviewMethodChanged,
      eventDetails: this.eventDetails != null && this.eventDetails.length > 0 ? this.eventDetails : null,
      userName: this.username
    }));
            
    this.reviewService.putReviewMethod(review)
      .subscribe(
        (result: Review) => {           
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {});
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  

  isChangeButtonDisabled(): boolean {
    return this.maxCharacters < 0;
  }
}
