<div class="d-flex flex-column h-100">
    <div class="header">
        Reports
    </div>
    <div class="secondary-header"><a [routerLink]="['/contraventions-extract-report']">Contraventions Extract</a></div>
    <p>Complete list of contraventions submitted and actions taken including fine payment, immediate cancellation, review requested, and review decision.</p>
    
    <div class="secondary-header"><a [routerLink]="['/seizures-extract-report']">Seizures Extract</a></div>
    <p>Complete list of SDP seizures submitted and actions taken including immediate cancellation, review requested, and review decision.</p>

    <div class="secondary-header report"><a [routerLink]="['/review-extract-report']">Review Extract Report</a></div>
    <p>Reviews scheduled by review type, contravention type, review method, review date, adjudicator, review outcome and review decision.</p>

    <div class="secondary-header"><a [routerLink]="['/review-cancellation-report']">Review Cancellation Report</a></div>
    <p>Cancel review decisions by reason for cancellation</p>

    <div class="secondary-header report"><a (click)="onDownloadReviewRepresentationsReport()">Review Representation Report</a></div>
    <p>Reviews requested by representation type</p>
</div>