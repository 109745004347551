import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from "@angular/common";
import { AdjudicationService } from '../shared/services/adjudication.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { ContraventionStatusType } from '@apis/shared/models/types/contravention-status-type.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { WorkBook, WorkSheet, utils, writeFile } from "xlsx";
import { StatusCheckboxesComponent } from '../shared/components/status-checkboxes/status-checkboxes.component';
import { AdjudicationUser } from '../shared/models/adjudication-user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionView } from '@apis/shared/models/contravention-view.model';
import { ActionCheckboxesComponent } from '../shared/components/action-checkboxes/action-checkboxes.component';
import { PagedResult } from '@apis/shared/models/paged-result.model';
import { ContraventionPagedSearch } from '@apis/shared/models/contravention-paged-search.model';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import * as fileSaver from "file-saver";
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ActivatedRoute, ParamMap, Router, UrlSerializer } from '@angular/router';

@Component({
  selector: 'app-contravention-listing',
  templateUrl: './contravention-listing.component.html',
  styleUrls: ['./contravention-listing.component.scss']
})
export class ContraventionListingComponent implements OnInit {  

  contraventions: any[] = [];
  filteredContraventions: any[] = [];    
  pageNumber: number = 1;
  totalPages: number;
  pageNumbers: number[] = [];
  pageRange: number = 5;
  pageSize: number = 25;
  searchText: string;
  totalCount: number;
  sortDirection = "desc";
  orderBy = "issueDate";
  selectedContraventionTypeId: number = 0;
  selectedContraventionStatusTypeIds: number[] = [];
  contraventionTypes: ContraventionType[];
  contraventionStatusTypes: ContraventionStatusType[];
  previousLink: JQuery<HTMLElement>;
  nextLink: JQuery<HTMLElement>;
  contraventionTypesDropdown: any;
  clearFiltersLink: JQuery<HTMLElement>;
  contraventionsTable: HTMLElement;
  actionsChecked: string[] = [];
  datePipe: DatePipe = new DatePipe("en-US");
  isLastRange: boolean = false;  
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  
  @ViewChild(StatusCheckboxesComponent) statusCheckboxesComponent: StatusCheckboxesComponent;
  @ViewChild(ActionCheckboxesComponent) actionCheckboxesComponent: ActionCheckboxesComponent;
  
  private columns = [{
    propertyName: "issueDate",
    ascending: false    
  }, {
    propertyName: "reServeDate",
    ascending: false    
  }, {
    propertyName: "contraventionNumber",
    ascending: false    
  }, {
    propertyName: "contraventionTypeName",
    ascending: false  
  }, {
    propertyName: "recipientName",
    ascending: false    
  }, {
    propertyName: "dueAmount",
    ascending: false
  }, {
    propertyName: "contraventionStatusTypeName",
    ascending: false  
  }
];
         
  constructor(private readonly adjudicationService: AdjudicationService,
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly urlSerializer: UrlSerializer) { }

  ngOnInit(): void {  
    this.contraventionsTable = document.getElementById("contraventionsTable");
    this.previousLink = $(".previous-link");
    this.nextLink = $(".next-link");
    this.clearFiltersLink = $(".clear-filters");

    this.contraventionTypesDropdown = document.getElementById("contraventionTypesDropdown");
    
    this.contraventionTypes = this.localStorageService.getContraventionTypes().filter(t => t.isIRS);     
    this.contraventionStatusTypes = this.localStorageService.getContraventionStatusTypes();
    
    this.route.queryParamMap.subscribe(paramMap => {
      setTimeout(() => {              
        this.initFilters(paramMap);
        this.setFilteredContraventions(); 
      });
    });
  }

  initFilters(paramMap: ParamMap) {
    paramMap.keys.forEach(key => {
      var value = paramMap.get(key);
      switch (key) {
        case "pageNumber":
          this.pageNumber = +value;
          break;
        case "pageSize":
          this.pageSize = +value;
          break;
        case "sortDirection":
          this.sortDirection = value;
          break;
        case "orderBy":
          this.orderBy = value;
          break;
        case "searchTerm":
          this.searchText = value;
          break;
        case "contraventionTypeId":
          this.selectedContraventionTypeId = +value;
          break;
        case "contraventionStatusTypeIds":
          this.selectedContraventionStatusTypeIds = value.split(',').map(x => +x);          
          break; 
        case "checkRequiresRedaction":
          this.actionsChecked.push('requires redaction');
          break;
        case "checkPendingSubmissions":
          this.actionsChecked.push('pending submissions');
          break;
      }
    });

    this.statusCheckboxesComponent.init(this.selectedContraventionStatusTypeIds);
    this.actionCheckboxesComponent.init(this.actionsChecked);
  }

  setFilteredContraventions(): void {
    let contraventionPagedSearch = new ContraventionPagedSearch({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      orderBy: this.orderBy
    });

    if (this.selectedContraventionTypeId > 0) {
      contraventionPagedSearch.contraventionTypeId = this.selectedContraventionTypeId
    }

    if (this.selectedContraventionStatusTypeIds.length > 0) {
      contraventionPagedSearch.contraventionStatusTypeIds = this.selectedContraventionStatusTypeIds.join(",");
    }

    if (this.searchText?.length > 0) {
      contraventionPagedSearch.searchTerm = this.searchText.trim().toLowerCase();      
    }

    if (this.actionsChecked.includes("requires redaction")) {
      contraventionPagedSearch.checkRequiresRedaction = true;      
    }

    if (this.actionsChecked.includes("pending submissions")) {
      contraventionPagedSearch.checkPendingSubmissions = true;
    }
    
    this.setWindowHistory(contraventionPagedSearch);

    this.spinner.show();
    this.adjudicationService.getContraventionsByPagedSearch(contraventionPagedSearch)
      .subscribe((result: PagedResult) => {        
        this.contraventions = result.items;
        this.totalCount = result.totalCount;     
      
        this.filteredContraventions = Array.from(this.contraventions);
        this.setPageNumbers();
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
      });
  }

  setPageNumbers(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    if (+this.totalPages === 0) {
      this.totalPages = 1;
      this.pageNumbers = [1];
      return;
    }

    let totalPageRanges = Math.ceil(this.totalPages / this.pageRange);        
    let totalPagesAsArray = Array(this.totalPages).fill(1).map((value: any, index: number) => value + index);
    let totalPageRangesAsArray = Array(totalPageRanges).fill(0).map((value: any, index: number) => value + index);    
    let pageRangeIndex = totalPageRangesAsArray.find((value: any, index: number) => this.pageNumber <= (value * this.pageRange) + this.pageRange);
    
    this.isLastRange = totalPageRangesAsArray.length - 1 == pageRangeIndex;
    this.pageNumbers = totalPagesAsArray.slice(pageRangeIndex * this.pageRange, (pageRangeIndex * this.pageRange) + this.pageRange);                
  }
 
  onPreviousLinkClick(ev: any): void {  
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.pageNumber -= 1;    
    if (+this.pageNumber <= 1) {
      this.pageNumber = 1;
    }

    this.setFilteredContraventions();
  }

  onNextLinkClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    this.pageNumber += 1;
  
    if (+this.pageNumber >= this.totalPages) {
      this.pageNumber = this.totalPages;     
    }
    
    this.setFilteredContraventions();
  }

  onPageNumberClick(ev: any, pageNumber: number): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }
        
    this.pageNumber = pageNumber;

    this.setFilteredContraventions();
  }

  private setWindowHistory(pagedSearch): void {
    const urlTree = this.router.createUrlTree(['/contraventions'], { queryParams: pagedSearch });
    window.history.replaceState({}, '', this.urlSerializer.serialize(urlTree));
  }
  
  private clearFilters(): void {        
    this.selectedContraventionTypeId = 0;      
    this.selectedContraventionStatusTypeIds  = [];
    this.searchText = '';
    this.pageNumber = 1;
    this.contraventionTypesDropdown.selectedIndex = 0;

    this.setFilteredContraventions();    
  }

  onContraventionTypeChange(ev: any): void {
    this.selectedContraventionTypeId = ev.target.selectedOptions[0].value;    
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");

    this.setFilteredContraventions();    
  }
  
  onContraventionStatusCheckboxClick(contraventionStatusTypeIds: number[]): void {          
    this.selectedContraventionStatusTypeIds = contraventionStatusTypeIds;
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");

    this.setFilteredContraventions();    
  }
  
  onSearchTextboxKeyDown(ev: KeyboardEvent): void {
    if (ev.key.toLowerCase() === "enter") {
      this.onSearchButtonClick();
    }
  }

  onSearchButtonClick(): void {  
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");  
    
    this.setFilteredContraventions();    
  }
 
  onSelectAllFiltersClick(ev: any): void {
    this.statusCheckboxesComponent.selectAll();    
    this.actionCheckboxesComponent.selectAll();
    
    this.clearFiltersLink.removeClass("disabled");

    this.pageNumber = 1;

    this.setFilteredContraventions();     
  }

  onClearAllFiltersClick(ev: any): void {
    this.statusCheckboxesComponent.clearAll();
    this.actionCheckboxesComponent.clearAll();

    if (ev.target.classList.contains("disabled")) {
      return;
    }    
    this.clearFilters();

    ev.target.classList.add("disabled");
  }

  onExportListClick(ev: any): void {
    
    this.spinner.show();
    setTimeout(() => {
      let element = $(".loading-text > p");      
      element.html(`<span style='font-size: 17px !important;'>Please be patient while APIS generates your report</span>`); 
    }, 100);
    
    
    this.adjudicationService.downloadContraventionsCSVExport()
      .subscribe((result: HttpEvent<Blob>) => {       
        if (result.type == HttpEventType.Response) {
          this.spinner.hide();
          fileSaver.saveAs(result.body, "contraventions.csv");          
        }        
      }, (error: any) => this.spinner.hide());                     
  }

  getStatusCssClass(contraventionStatusTypeId: number): string {
    let contraventionStatusName = this.localStorageService.getTypeItemDescriptionById(contraventionStatusTypeId, 5);
    if (contraventionStatusName == null) {
      return;
    }

    return contraventionStatusName.toLowerCase().replace(" ", "-");
  }  

  onActionCheckboxesChange(actionsChecked: string[]): void {
    this.actionsChecked = actionsChecked;
    this.clearFiltersLink.removeClass("disabled");
    this.pageNumber = 1;
    
    this.setFilteredContraventions();    
  }

  getReserveDate(contravention: ContraventionView): string {
    if (contravention.reServeDate != null) {
      return this.datePipe.transform(contravention.reServeDate, "mediumDate");
    }

    return "-";
  }

  onSortIconClick(propertyName: string): void {  
    let column = this.columns.find(c => c.propertyName == propertyName);
    column.ascending = !column.ascending;

    this.sortDirection = "desc";
    if (column.ascending) {
      this.sortDirection = "asc";
    }

    this.orderBy = propertyName;
    
    this.setFilteredContraventions();        
  }  

  onPageSizeChange(ev: any): void {
    let target = ev.target as HTMLSelectElement;    
    this.pageSize = parseInt(target.selectedOptions[0].value);
    this.pageNumber = 1;

    this.setFilteredContraventions();
  }
}
