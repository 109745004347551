<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Review Request Extension</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">           
            <div class="form-group">
                <label class="modal-body-label required" for="eventDetails">Reason for extension</label>
                <textarea 
                    id="eventDetails" 
                    name="eventDetails"
                    [(ngModel)]="eventDetails"
                    class="modal-textarea"                                   
                    (input)="onTextareaInput($event)"></textarea>
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            </div>            
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
            <div id="confirmButton" class="confirm-button" [ngClass]="{'disabled': [null, 0].includes(eventDetails?.length)  || +maxCharacters < 0 }" 
                (click)="onConfirmModalClick($event)">
                Confirm Extension
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>