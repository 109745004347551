<div class="main-content">
  <div class="content-header">
    <!-- <div class="w-100 mb-4">My Calendar</div> -->
    <div class="d-flex">
      <div class="d-flex flex-grow-1 align-items-center">
        <div class="cal-date-nav" 
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="loadData()"
          [excludeDays]="excludeDays">
          <img src="assets/images/chevron-left.svg">
        </div>
        <div class="cal-date-nav"
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="loadData()">
          <img class="calendar" src="assets/images/calendar-white.svg">
        </div>
        <div class="cal-date-nav"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="loadData()"
          [excludeDays]="excludeDays">
          <img src="assets/images/chevron-right.svg">
        </div>
        <div class="ml-3">
          {{ viewDate | calendarDate: view + 'ViewTitle':locale }}
        </div>
      </div>
      <div>
        <div class="btn-group mr-2">
          <div
            class="btn btn-primary"
            (click)="onViewChange(CalendarView.Week)"
            [class.active]="view === 'week'"
          >
            Week
          </div>
          <div
            class="btn btn-primary"
            (click)="onViewChange(CalendarView.Day)"
            [class.active]="view === 'day'"
          >
            Day
          </div>
        </div>

        <button class="button-primary custom-button" (click)="showEventSection()">New Event</button>
      </div>       
    </div> 
  </div>
  <div [ngSwitch]="view">
    <mwl-calendar-week-view class="calendar-view"
      *ngSwitchCase="'week'"
      [excludeDays]="excludeDays"
      [viewDate]="viewDate"
      [events]="events"
      [dayStartHour]="8"
      [dayEndHour]="16"
      [dayEndMinute]="30"
      (beforeViewRender)="beforeWeekViewRender($event)"
      (eventClicked)="onEventClicked($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view class="calendar-view"
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="events"
      [dayStartHour]="8"
      [dayEndHour]="16"
      [dayEndMinute]="30"
      (beforeViewRender)="beforeWeekViewRender($event)"
      (eventClicked)="onEventClicked($event)"
    >
    </mwl-calendar-day-view>
  </div>   
  <div class="d-flex flex-grow-1 align-items-center legend">
    <span class="irs-review-written">
      <img src="assets/images/pencil-black.svg"/>
      IRS Review Written
    </span>
    <span class="irs-review-oral">
      <img src="assets/images/camera-black.svg"/>
      IRS Review Oral
    </span>
    <span class="seizure-review">
      <img src="assets/images/pencil-black.svg"/>
      Seizure Review
    </span>
    <span class="admintime-event">
      Admin Time
    </span>
    <span class="unavailable-event">
      Unavailable
    </span>
  </div>
  <div class="section-header">
    Pending Decisions
  </div>
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Recipient</th>
          <th>File</th>
          <th>Type</th>
          <th>Method</th>
          <th>Due Date</th>
        </tr>
      </thead>
      <tbody *ngIf="reviews.length > 0">
        <tr *ngFor="let r of reviews">
          <td>{{ r.applicantName }}</td>
          <td>
            <a class="review-number" [routerLink]="['/review', r.reviewNumber]">{{r.reviewNumber}}</a>
          </td>
          <td>{{ r.reviewTypeName }}</td>
          <td>{{ r.reviewMethodTypeName }}</td>
          <td>{{ r.decisionDueDate | date:"mediumDate" }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="reviews.length === 0">
        <tr>
            <td colspan="5">No reviews found.</td>
        </tr>
    </tbody>
    </table>
  </div>
</div>   
<div class="comments-sidebar" *ngIf="showEvent" [@slideInOut]>
  <span
    class="close-button"
    (click)="closeEventSidebar()"
    iesIconButton
    aria-label="Collapse Comments"
    iesIconButton
    data-cy="comments-collapse-panel-button"
  >
  <i class="far fa-times-circle"></i>
  </span>
  <app-schedule-event #scheduleEvent (close)="onEventClose($event)"></app-schedule-event>
</div>