import { Component, OnInit, HostListener } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LookupService } from '@apis/shared/services/lookup.service';
import { KeycloakService } from 'keycloak-angular';
import { AdjudicationUser } from './shared/models/adjudication-user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@apis/shared/helpers/constants';
import { SettingService } from '@apis/shared/services/setting.service';
import { Setting } from '@apis/shared/models/setting.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private readonly lookupService: LookupService,
    private readonly localStorageService: LocalStorageService,
    private readonly settingService: SettingService,
    private keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if (this.localStorageService.getContraventionStatusTypes().length == 0) {

      this.settingService.getSettings()
        .subscribe((settings: Setting[]) => {
          if (settings) {
            const lookupVersion = settings.find(s => s.settingName == Constants.Settings.LOOKUP_VERSION)?.settingValue;

            // Compare the version of lookups in client and server
            //  If the versions are different, clear localstorage and get latest lookups.
            if (this.localStorageService.get(Constants.Settings.LOOKUP_VERSION) != lookupVersion) {
              // Clear localstorage          
              localStorage.clear();

              // Get lookups
              this.lookupService.setLookups();

              // Update the lookup version in client
              this.localStorageService.set(Constants.Settings.LOOKUP_VERSION, lookupVersion);

              if (this.keycloakService.isLoggedIn()) {
                this.spinner.show();
                let user: AdjudicationUser = new AdjudicationUser();
                user.userName = this.keycloakService.getUsername();
                user.displayName = user.userName;
                this.keycloakService.getToken().then(token => {
                  user.token = token;
                  this.keycloakService.loadUserProfile().then(profile => {
                    user.firstName = profile.firstName;
                    user.lastName = profile.lastName;
                    user.emailAddress = profile.email;
                    this.localStorageService.setAdjudicationUser(user);
                  });
                });
              }

              // hide spinner if user role permission already loaded in local storage in case if user has no apis role
              this.lookupService.getUserRolePermission().toPromise().then(x => {
                this.spinner.hide();
              });
            }
          }
        },
          error => {
            // TODO: Display Error Page
          });
    }
  }

  @HostListener("window:onbeforeunload", ["$event"])
  clearLocalStorage(ev: any) {
    localStorage.clear();
  }
}
