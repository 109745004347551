
export class PaymentDataEditRequest {
    public financialTransactionId: number = 0;
    public transactionId: string;
    public payerFirstName: string;
    public payerLastName: string;
    public payerMVID: string;

    constructor(init?: Partial<PaymentDataEditRequest>) {
        
        Object.assign(this, init);
    }
}
