import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from "@angular/common";
import { SeizureType } from '@apis/shared/models/types/seizure-type.model';
import { SeizureStatusType } from '@apis/shared/models/types/seizure-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { VehicleSeizureService } from '../shared/services/vehicle-seizure.service';
import { PageEvent } from '../shared/components/pagination/pagination.component';
import { SeizureView } from '@apis/shared/models/seizure-view.model';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { SeizureGroupType } from '@apis/shared/models/types/seizure-group-type.model';
import { SeizureGroupTypes, SeizureTypes } from '@apis/shared/enums/app.enum';
import { ActionCheckboxesComponent } from '../shared/components/action-checkboxes/action-checkboxes.component';
import { VehicleSeizurePagedSearch } from '@apis/shared/models/vehicle-seizure-paged-search.model';
import { PagedResult } from '@apis/shared/models/paged-result.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as fileSaver from "file-saver";
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { StatusCheckboxesComponent } from '../shared/components/status-checkboxes/status-checkboxes.component';
import { ActivatedRoute, ParamMap, Router, UrlSerializer } from '@angular/router';

@Component({
  selector: 'app-seizure-listing',
  templateUrl: './seizure-listing.component.html',
  styleUrls: ['./seizure-listing.component.scss']
})
export class SeizureListingComponent implements OnInit {
  TypeTable = TypeTable;

  
  SeizureColDef = {
    SeizureDate: { property: "seizureDate", dataType: "date" },
    ReServeDate: { property: "reServeDate", dataType: "date" },
    SeizureNumber: { property: "seizureNumber", dataType: "string" },
    SeizureType: { property: "seizureGroupTypeId", dataType: "typeTable", typeTable: TypeTable.SeizureGroupType },
    AffectedThirdParty: { property: "seizureGroupTypeId", dataType: "string" },
    RecipientName: { property: "recipientName", dataType: "string" },
    ReleaseDate: { property: "vehicleReleaseDate", dataType: "date" },
    Status: { property: "seizureStatusTypeId", dataType: "typeTable", typeTable: TypeTable.SeizureStatusType }
  };

  pageNumbers: number[] = [];
  pageNumber: number = 1;
  pageRange: number = 5;
  totalPages: number;
  pageSize: number = 25;
  searchText: string;
  sortDirection: string = "desc";
  orderBy: string = "seizureDate";  
  totalCount: number;
  isLastRange: boolean = false;

  selectedSeizureTypeId: number = null;
  selectedSeizureStatusTypeIds: number[] = [];

  seizures: SeizureView[] = [];
  filteredSeizures: SeizureView[] = [];

  seizureTypes: SeizureType[];
  seizureGroupTypes: SeizureGroupType[];
  seizureStatusTypes: SeizureStatusType[];
  adjudicationSeizureTypes: any[] = [];
  actionsChecked: string[] = [];  

  currentSortColumn = '';
  currentSortOrder = '';
  clearFiltersLink: JQuery<HTMLElement>;

  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;  
  datePipe: DatePipe = new DatePipe("en-US");

  private columns = [{
    propertyName: "seizureDate",
    ascending: false      
  }, {
    propertyName: "reServeDate",
    ascending: false      
  }, {
    propertyName: "seizureNumber",
    ascending: false      
  }, {
    propertyName: "seizureTypeId",
    ascending: false      
  }, {
    propertyName: "seizureGroupTypeId",
    ascending: false    
  }, {
    propertyName: "recipientName",
    ascending: false    
  }, {
    propertyName: "vehicleReleaseDate",
    ascending: false
  }, {
    propertyName: "seizureStatusTypeId",
    ascending: false    
  }
];

  @ViewChild(StatusCheckboxesComponent) statusCheckboxesComponent: StatusCheckboxesComponent;
  @ViewChild(ActionCheckboxesComponent) actionCheckboxesComponent: ActionCheckboxesComponent;

  constructor(
    private readonly seizureService: VehicleSeizureService,
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly urlSerializer: UrlSerializer
  ) {}

  ngOnInit(): void {   
    this.adjudicationSeizureTypes = [{ id: 1, name: "IRS Seizure"}, { id: 2, name: "Suspended Driver Program Seizure"}];
    this.clearFiltersLink = $(".clear-filters");

    this.seizureTypes = this.localStorageService.getSeizureTypes();
    this.seizureStatusTypes = this.localStorageService.getSeizureStatusTypes();
    this.seizureGroupTypes = this.localStorageService.getSeizureGroupTypes();    

    this.route.queryParamMap.subscribe(paramMap => {
      setTimeout(() => {              
        this.initFilters(paramMap);
        this.setFilteredSeizures(); 
      });
    });
  }

  onSeizureTypeChange(ev: any): void {
    this.selectedSeizureTypeId = ev.target.selectedOptions[0].value;    
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");

    this.setFilteredSeizures();    
  }

  onSeizureStatusTypeChange(seizureStatusTypeId: number[]): void {          
    this.selectedSeizureStatusTypeIds = seizureStatusTypeId;
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");

    this.setFilteredSeizures();    
  }

  onSearchTextboxKeyDown(ev: KeyboardEvent): void {
    if (ev.key.toLowerCase() === 'enter') {
      this.onSearchButtonClick();
    }
  }

  onSearchButtonClick(): void {
    this.pageNumber = 1;
    this.setFilteredSeizures();
  }

  onClearFiltersClick(ev: any): void {
    this.statusCheckboxesComponent.clearAll();
    this.actionCheckboxesComponent.clearAll();

    if (ev.target.classList.contains("disabled")) {
      return;
    }

    this.clearFilters();

    ev.target.classList.add("disabled");    
  }

  onSelectAllLinkClick(ev: any) {
    this.statusCheckboxesComponent.selectAll();
    this.actionCheckboxesComponent.selectAll();

    this.clearFiltersLink.removeClass("disabled");
    
    this.pageNumber = 1;

    this.setFilteredSeizures();
  }

  onSortClick(colDef: any) {
    this.sortData(colDef);
  }

  sortData(colDef: any) {
    this.filteredSeizures = this.filteredSeizures.sort((a: any, b: any) => {
      let obj1 = a[colDef.property];
      let obj2 = b[colDef.property];

      if (colDef.dataType === 'string') {
        obj1 = obj1.toLowerCase();
        obj2 = obj2.toLowerCase();
      }

      if (colDef.dataType === 'typeTable') {
        obj1 = this.localStorageService.getTypeItemDescriptionById(obj1, colDef.typeTable).toLowerCase();
        obj2 = this.localStorageService.getTypeItemDescriptionById(obj2, colDef.typeTable).toLowerCase();
      }


      if (this.currentSortColumn != colDef.property) {
        this.currentSortColumn = colDef.property;
        this.currentSortOrder = 'desc';
      } else {
        this.currentSortOrder = (this.currentSortOrder == 'asc') ? 'desc' : 'asc';
      }

      if (this.currentSortOrder == 'asc') {
        return obj1 < obj2 ? -1 : (obj1 > obj2) ? 1 : 0;
      } else {
        return obj1 < obj2 ? 1 : (obj1 > obj2) ? -1 : 0;
      }
    });
  }

  onSortIconClick(propertyName: string): void {  
    let column = this.columns.find(c => c.propertyName == propertyName);    
    column.ascending = !column.ascending;

    this.sortDirection = "desc";
    if (column.ascending) {
      this.sortDirection = "asc";
    }

    this.orderBy = propertyName;
    
    this.setFilteredSeizures();        
  }  

  initFilters(paramMap: ParamMap) {
    paramMap.keys.forEach(key => {
      var value = paramMap.get(key);
      switch (key) {
        case "pageNumber":
          this.pageNumber = +value;
          break;
        case "pageSize":
          this.pageSize = +value;
          break;
        case "sortDirection":
          this.sortDirection = value;
          break;
        case "orderBy":
          this.orderBy = value;
          break;
        case "searchTerm":
          this.searchText = value;
          break;
        case "seizureTypeId":
          this.selectedSeizureTypeId = +value;
          break;
        case "seizureStatusTypeIds":
          this.selectedSeizureStatusTypeIds = value.split(',').map(x => +x);          
          break;
        case "checkRequiresRedaction":
          this.actionsChecked.push('requires redaction');
          break;
        case "checkPendingSubmissions":
          this.actionsChecked.push('pending submissions');
          break;
      }
    });

    this.statusCheckboxesComponent.init(this.selectedSeizureStatusTypeIds);
    this.actionCheckboxesComponent.init(this.actionsChecked);
  }

  private setFilteredSeizures(): void {
    let vehicleSeizurePagedSearch = new VehicleSeizurePagedSearch({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      orderBy: this.orderBy
    });

    if (this.selectedSeizureTypeId > 0) {
      vehicleSeizurePagedSearch.seizureTypeId = this.selectedSeizureTypeId;
    }
    
    if (this.selectedSeizureStatusTypeIds.length > 0) {
      vehicleSeizurePagedSearch.seizureStatusTypeIds = this.selectedSeizureStatusTypeIds.join(",");
    }

    if (this.searchText?.length > 0) {
      vehicleSeizurePagedSearch.searchTerm = this.searchText.trim().toLowerCase();      
    }

    if (this.actionsChecked.includes("requires redaction")) {
      vehicleSeizurePagedSearch.checkRequiresRedaction = true;      
    }

    if (this.actionsChecked.includes("pending submissions")) {
      vehicleSeizurePagedSearch.checkPendingSubmissions = true;
    }
    
    this.spinner.show();    
    this.setWindowHistory(vehicleSeizurePagedSearch);

    this.seizureService.getSeizuresByPagedSearch(vehicleSeizurePagedSearch)
      .subscribe((result: PagedResult) => {        
        this.seizures = result.items;
        this.totalCount = result.totalCount;     
      
        this.filteredSeizures = Array.from(this.seizures);
        this.setPageNumbers();
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
      });
  }

  clearFilters() {
    this.selectedSeizureTypeId = null;
    this.selectedSeizureStatusTypeIds = [];
    this.searchText = '';
    this.pageNumber = 1;

    this.actionCheckboxesComponent.clearAll();

    this.setFilteredSeizures();
  }
  
  getAdjudicationSeizureTypeName(seizureView: SeizureView): string {
    if (+seizureView.seizureTypeId == SeizureTypes.SuspendedDriversProgramSecondOccurrence) {
      return this.adjudicationSeizureTypes[1].name;
    }

    return this.adjudicationSeizureTypes.find(a => +a.id == +seizureView.seizureTypeId)?.name;    
  }

  getAffectedThirdParty(seizureView: SeizureView): string {
    if (seizureView.seizureGroupTypeId == SeizureGroupTypes.AffectedThirdPartySeizure) {
      return seizureView.thirdPartyName;
    }

    return "-";
  }

  getReServeDate(seizure: SeizureView): string {
    if (seizure.reServeDate != null) {
      return this.datePipe.transform(seizure.reServeDate, "mediumDate");
    }

    return "-";
  }

  OnActionCheckboxesChange(actionsChecked: string[]): void {
    this.actionsChecked = actionsChecked;
    this.clearFiltersLink.removeClass("disabled");
    this.pageNumber = 1;

    this.setFilteredSeizures();
  }

  setPageNumbers(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    if (+this.totalPages === 0) {
      this.totalPages = 1;
      this.pageNumbers = [1];
      return;
    }

    let totalPageRanges = Math.ceil(this.totalPages / this.pageRange);        
    let totalPagesAsArray = Array(this.totalPages).fill(1).map((value: any, index: number) => value + index);
    let totalPageRangesAsArray = Array(totalPageRanges).fill(0).map((value: any, index: number) => value + index);    
    let pageRangeIndex = totalPageRangesAsArray.find((value: any, index: number) => this.pageNumber <= (value * this.pageRange) + this.pageRange);
    
    this.isLastRange = totalPageRangesAsArray.length - 1 == pageRangeIndex;
    this.pageNumbers = totalPagesAsArray.slice(pageRangeIndex * this.pageRange, (pageRangeIndex * this.pageRange) + this.pageRange);                  
  }
  
  onPreviousLinkClick(ev: any): void {  
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.pageNumber -= 1;    
    if (+this.pageNumber <= 1) {
      this.pageNumber = 1;
    }
     
    this.setFilteredSeizures();
  }

  onNextLinkClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    this.pageNumber += 1;
  
    if (+this.pageNumber >= this.totalPages) {
      this.pageNumber = this.totalPages;     
    }            
    
    this.setFilteredSeizures();
  }

  onPageNumberClick(ev: any, pageNumber: number): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }
        
    this.pageNumber = pageNumber;    
    this.setFilteredSeizures();
  }

  onPageSizeChange(ev: any): void {
    let target = ev.target as HTMLSelectElement;    
    this.pageSize = parseInt(target.selectedOptions[0].value);
    this.pageNumber = 1;

    this.setFilteredSeizures();
  }

  private setWindowHistory(pagedSearch: VehicleSeizurePagedSearch): void {    
    const urlTree = this.router.createUrlTree(['/seizures'], { queryParams: pagedSearch });
    window.history.replaceState({}, '', this.urlSerializer.serialize(urlTree));
  }

  onExportListClick(ev: any): void {
    
    this.spinner.show();
    setTimeout(() => {
      let element = $(".loading-text > p");      
      element.html(`<span style='font-size: 17px !important;'>Please be patient while APIS generates your report</span>`); 
    }, 100);
    
    
    this.seizureService.downloadVehicleSeizuresCSVExport()
      .subscribe((result: HttpEvent<Blob>) => {       
        if (result.type == HttpEventType.Response) {
          this.spinner.hide();
          fileSaver.saveAs(result.body, "seizures.csv");          
        }        
      }, (error: any) => this.spinner.hide());                     
  }

}
