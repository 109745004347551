import { Component, Input, OnInit, ViewContainerRef, OnChanges, SimpleChanges } from '@angular/core';
import { Contravention} from "@apis/shared/models/contravention.model";
import { Document } from "@apis/shared/models/document.model";
import { DocumentTypes, EventTypes } from "@apis/shared/enums/app.enum"; 
import { LocalStorageService } from "@apis/shared/services/local-storage.service";
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { KeycloakService } from 'keycloak-angular';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Event } from '@apis/shared/models/event.model';
import { PublishOriginalModalComponent } from './modals/publish-original-modal/publish-original-modal.component';
import { UploadRedactedModalComponent } from './modals/upload-redacted-modal/upload-redacted-modal.component';
import { AdjudicationService } from '../../services/adjudication.service';
import { DeleteDocumentModalComponent } from './modals/delete-document-modal/delete-document-modal.component';
import { CommonMethods } from '../../helpers/common-methods';
import { AdditionalDocumentModalComponent } from './modals/additional-document-modal/additional-document-modal.component';
import { UploadDocumentModalComponent } from './modals/upload-document-modal/upload-document-modal.component';
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { Constants } from "@apis/shared/helpers/constants";
import { Observable } from 'rxjs';

@Component({
  selector: 'supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrls: ['./supporting-documents.component.scss']
})
export class SupportingDocumentsComponent implements OnInit, OnChanges {
  @Input() contravention: Contravention;
  @Input() vehicleSeizure: VehicleSeizure;

  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  documentRefId: number;
  supportingDocuments: Document[] = [];
  invalidDocumentTypes: DocumentTypes[] = [
    DocumentTypes.Note, 
    DocumentTypes.ContraventionCancellationAuthorization,
    DocumentTypes.ContraventionCancellationAdditional,
    DocumentTypes.VehicleSeizureCancellationAuthorization,
    DocumentTypes.VehicleSeizureCancellationAdditional
  ];
  DocumentTypes = DocumentTypes;
  hasUpdateAccess: boolean = false;
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;
  Adjudication:any=Constants.Adjudication;
  canUploadRedatedDocument: boolean = false;
  canUpdateDocument: boolean = false;
  showPublishedDate: boolean = false;

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private readonly adjudicationService: AdjudicationService,
    private keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService,
    private readonly viewContainerRef: ViewContainerRef
    ) { }

  ngOnInit(): void {      
    this.tempFileFolder = Guid.create().toString(); 
    this.hasUpdateAccess = CommonMethods.hasUpdateAccess(this.keycloakService.getUserRoles());
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canUploadRedatedDocument = this.localStorageService.hasPermission(Constants.Resource.SUPPORTING_DOCUMENT, Constants.Permission.UPLOAD_REDATED_DOCUMENT);      
      this.canUpdateDocument = this.localStorageService.hasPermission(Constants.Resource.SUPPORTING_DOCUMENT, Constants.Permission.UPDATE_DOCUMENT);
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {    
    if (simpleChanges.contravention?.currentValue != null) {
      this.documentRefTypeName = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
      this.documentRefTypeNumber = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 
        ? this.contravention.contraventionNumber 
        : this.contravention.stopInformationId.toString();
      this.documentRefId = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
        ? this.contravention.contraventionId
        : this.contravention.stopInformationId;

      this.showPublishedDate = new Date(this.contravention.submissionDate) >= new Date(this.Adjudication.DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE);
      this.supportingDocuments = this.contravention.documents.filter(d => !this.invalidDocumentTypes.includes(+d.documentTypeId));
      this.sortSupportingDocuments();
    }

    if (simpleChanges.vehicleSeizure?.currentValue != null) {      
      this.supportingDocuments = this.vehicleSeizure.documents.filter(d => !this.invalidDocumentTypes.includes(+d.documentTypeId));

      if (this.vehicleSeizure.contraventionId != null) {        
        this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
        this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? this.vehicleSeizure.contraventionNumber : this.vehicleSeizure.stopInformationId.toString();
        this.documentRefId = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
          ? this.vehicleSeizure.contraventionId
          : this.vehicleSeizure.stopInformationId;
      } else {        
        this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "VehicleSeizures" : "Stop";
        this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? this.vehicleSeizure.seizureNumber : this.vehicleSeizure.stopInformationId.toString();
        this.documentRefId = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
          ? this.vehicleSeizure.vehicleSeizureId
          : this.vehicleSeizure.stopInformationId;
      }
            
      this.showPublishedDate = new Date(this.vehicleSeizure.submissionDate) >= new Date(this.Adjudication.DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE);
      this.sortSupportingDocuments();
    }
  }
 
  getRedactionType(supportingDocument: Document): string {
    let redactionType = "";
    
    if (this.isRedactionRequired(supportingDocument)) {
      redactionType = `(Original)`;
    }
    
    return redactionType;
  }

  hasAlertWarning(supportingDocument: Document): boolean {    
    return ([DocumentTypes.PoliceNarrative, DocumentTypes.WitnessStatements, DocumentTypes.Other].includes(+supportingDocument.documentTypeId) && supportingDocument.isPublished == null) 
      || (supportingDocument.documentTypeId == DocumentTypes.LaboratoryResults && (supportingDocument.documentName == null || supportingDocument.documentName == ""));
  }

  isOriginalHidden(supportingDocument: Document): boolean {
    return [DocumentTypes.PoliceNarrative, DocumentTypes.WitnessStatements, DocumentTypes.Other].includes(+supportingDocument.documentTypeId) && supportingDocument.isPublished == false;
  }

  isRedactionRequired(supportingDocument: Document): boolean {
    return [DocumentTypes.PoliceNarrative, DocumentTypes.WitnessStatements, DocumentTypes.Other].includes(+supportingDocument.documentTypeId) && supportingDocument.isDeleted != true && supportingDocument.isPublished == null && !this.isPendingResults(supportingDocument);
  }

  isPendingResults(supportingDocument: Document): boolean {
    return this.localStorageService.getDocumentTypes().filter(x => x.isSupplemental == false && x.isSubmittableAtLaterDate == true && x.id == supportingDocument.documentTypeId).length > 0 && supportingDocument.isSubmitLater;
  }

  getDocumentTypeName(supportingDocument: Document, isFullName: boolean): string {
    var documentTypeName = `${this.localStorageService.getDocumentTypes().find(x => x.id == supportingDocument.documentTypeId)?.name}
      ${(supportingDocument.documentDescription == null || supportingDocument.documentDescription == "")? "" : " " +  supportingDocument.documentDescription} ${this.getRedactionType(supportingDocument)}`;

    return documentTypeName;
    //return isFullName ? documentTypeName : (documentTypeName.length > 30? `${documentTypeName.substr(0, 30)}...` : documentTypeName);
  }

  getDocumentName(supportingDocument: Document, isFullName: boolean): string {
    if (supportingDocument.documentName == null) { return ""; }
    return isFullName? supportingDocument.documentName : (supportingDocument.documentName?.length > 20? `${supportingDocument.documentName.substr(0, 20)}...`: supportingDocument.documentName);
  }

  onDownloadDocumentClick(document: Document) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    
    //Override the document reference type/number to contravention always if the document type is Overdue Fine Notice.
    var documentRefTypeName = document.documentTypeId == DocumentTypes.OverdueFineNotice? "Contraventions" : this.documentRefTypeName;
    var documentRefTypeNumber = document.documentTypeId == DocumentTypes.OverdueFineNotice? this.contravention?.contraventionNumber : this.documentRefTypeNumber;

    if (document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });
      
      return;
    }

    this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName, document.documentName, document.documentLocation)
    .subscribe((result: Blob) => {
      if (result) {         
          fileSaver.saveAs(result, document.documentName);
      }
    });
  }
 
  onRedactionDropdownChange(ev: any, document: Document): void {    
    let selectedOptions = ev.target.selectedOptions;
                
    if (selectedOptions != null && selectedOptions.length > 0) {
      let selectedOption = selectedOptions[0].innerHTML.toLowerCase();

      if (selectedOption.includes("hidden")) {
        return;
      }

      if (selectedOption.includes("publish")) {
        this.showPublishOriginalModal(document, (ev.target as HTMLSelectElement));
      } else if (selectedOption.includes("upload")) {    
        this.showUploadRedactedModal(document, (ev.target as HTMLSelectElement));
      }                        
    }        
  }

  onDeleteDocumentLinkClick(ev: any, document: Document): void {                            
    this.viewContainerRef.clear();
    let componentRef = this.viewContainerRef.createComponent(DeleteDocumentModalComponent);

    componentRef.instance.document = Object.assign({}, document);
    componentRef.instance.createPlaceholderOnDelete = this.isPlaceholderRequired(document);
    componentRef.instance.close.subscribe((result: Document) => {        
      this.viewContainerRef.clear();
      if (result != null) {
        let index = this.supportingDocuments.findIndex(s => +s.documentId == +result.documentId);
        if (index != -1) {          
          this.supportingDocuments.splice(index, 1, result);
        } 
      }
    });   
  }

  private isPlaceholderRequired(document: Document): boolean {
    const documentType = this.localStorageService.getDocumentTypes().find(x => x.id === +document.documentTypeId);
    if (!documentType.isSupplemental && documentType.isSubmittableAtLaterDate) {
      switch (documentType.id) {
        case DocumentTypes.PoliceNarrative: {
          let isPoliceNarrativeDocumentRequired = false;
          if (this.contravention) {
            if (this.contravention.additionalNotes == null || this.contravention.additionalNotes?.trim().length == 0) {
              isPoliceNarrativeDocumentRequired = true;
            }
          }
          else if (this.vehicleSeizure) {
            if (this.vehicleSeizure.additionalNotes == null || this.vehicleSeizure.additionalNotes?.trim().length == 0) {
              isPoliceNarrativeDocumentRequired = true;
            }
          }

          if (isPoliceNarrativeDocumentRequired) {
            if (this.supportingDocuments.filter(x => +x.documentTypeId === DocumentTypes.PoliceNarrative && !x.isDeleted && x != document).length === 0) // If no police narrative would exist, after deleting the current document
            {
              return true;
            }
          }
          return false;
        }

        case DocumentTypes.RoadsideAppeal: {
          if (this.contravention?.roadsideAppeal?.hasDriverRequestedRoadsideAppeal) {
            if (this.supportingDocuments.filter(x => +x.documentTypeId === DocumentTypes.RoadsideAppeal && !x.isDeleted && x != document).length === 0) // If no roadside appeal would exist, after deleting the current document
            {
              return true;
            }
          }
          return false;
        }

        default: {
          return true;
        }
      }
    }

    return false;
  }

  getRowCssClass(document: Document): string {    
    if (this.isOriginalHidden(document) && document.isDeleted) {
      return "text-muted-row text-deleted-row";
    }

    if (document.isDeleted) {
      return "text-deleted-row";
    }

    if (this.hasAlertWarning(document)) {
      return "alert-warning-row";
    }
    
    return "";
  }

  private showPublishOriginalModal(document: Document, targetDropdown: HTMLSelectElement): void {
    this.viewContainerRef.clear();
    let componentRef = this.viewContainerRef.createComponent(PublishOriginalModalComponent);

    componentRef.instance.document = Object.assign({}, document);
    componentRef.instance.close.subscribe((result: Document) => {
      targetDropdown.selectedIndex = 0;
      this.viewContainerRef.clear();

      if (result != null) {
        this.addEvents(result, EventTypes.DocumentPublished);
        let index = this.supportingDocuments.findIndex(s => +s.documentId == +result.documentId);
        this.supportingDocuments.splice(index, 1, result);
        this.sortSupportingDocuments();
      }
    });    
  }

  private showUploadRedactedModal(selectedDocument: Document, targetDropdown: HTMLSelectElement): void {
    this.viewContainerRef.clear();
    let componentRef = this.viewContainerRef.createComponent(UploadRedactedModalComponent);

    componentRef.instance.document = Object.assign({}, selectedDocument);
    componentRef.instance.contravention = Object.assign({}, this.contravention);
    componentRef.instance.vehicleSeizure = Object.assign({}, this.vehicleSeizure);
    componentRef.instance.tempFileFolder = Guid.create().toString();
    componentRef.instance.close.subscribe((result: Document[]) => {
      this.viewContainerRef.clear();
      targetDropdown.selectedIndex = 0;      

      if (result != null) {        
        this.addEvents(result[1], EventTypes.DocumentPublished);
        result.forEach((document: Document) => {
          let index = this.supportingDocuments.findIndex(s => +s.documentId == +document.documentId);
          if (index != -1) {
            this.supportingDocuments.splice(index, 1, document);
          } else {
            this.supportingDocuments.push(document);
          }          
        });
        this.sortSupportingDocuments();
      }
    });
  }

  onUploadDocumentLinkClick(ev: any, document: Document): void {   
    this.viewContainerRef.clear();
    let componentRef = this.viewContainerRef.createComponent(UploadDocumentModalComponent);

    componentRef.instance.document = Object.assign({}, document);
    componentRef.instance.contravention = Object.assign({}, this.contravention);
    componentRef.instance.vehicleSeizure = Object.assign({}, this.vehicleSeizure);
    componentRef.instance.tempFileFolder = Guid.create().toString();
    componentRef.instance.close.subscribe((result: Document) => {
      this.viewContainerRef.clear();

      if (result != null) {   
        this.addEvents(result, EventTypes.DocumentUploaded);     
        let index = this.supportingDocuments.findIndex(s => +s.documentId == +document.documentId);
        if (index != -1) {
          this.supportingDocuments[index] = result;
        }          
      }
    });
  }

  addEvents(document, eventType: EventTypes) {
    var events = [];

    if (this.contravention?.contraventionId != null) {
      var documentEvent = this.createEvent(document, eventType);
      documentEvent.contraventionId = this.contravention.contraventionId;

      events.push(documentEvent);

      if (this.contravention?.review != null) {
        var documentEvent = this.createEvent(document, eventType);
        documentEvent.reviewId = this.contravention.review.reviewId;
          
        events.push(documentEvent);
      }
    }

    if (this.vehicleSeizure?.vehicleSeizureId != null) {
      var documentEvent = this.createEvent(document, eventType);
      documentEvent.vehicleSeizureId = this.vehicleSeizure.vehicleSeizureId;

      events.push(documentEvent);

      if (this.vehicleSeizure?.review != null) {
        var documentEvent = this.createEvent(document, eventType);
        documentEvent.reviewId = this.vehicleSeizure.review.reviewId;
  
        events.push(documentEvent);
      }
    }

    this.spinner.show();

    this.adjudicationService.postEvents(events).subscribe((events: Event[]) => {
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });      
  }

  createEvent(document, eventType: EventTypes) {
    var documentTypes = this.localStorageService.getDocumentTypes();
    var documentEvent = new Event();

    documentEvent.eventTypeId = eventType;
    documentEvent.eventDetails = `${documentTypes.find(d => d.id == document.documentTypeId).name} - ${document.documentName}`
    documentEvent.userName = this.localStorageService.getUser().userName;

    return documentEvent;
  }

  private sortSupportingDocuments(): void {
    this.supportingDocuments.sort((a: Document, b: Document) => {
      if (+a.documentTypeId > +b.documentTypeId) {
            return 1;
      }

      if (+a.documentTypeId < +b.documentTypeId) {
          return -1
      }

      return 0;
    });
  }

  public AddOptionalDocument()
  {
    this.viewContainerRef.clear();
    let componentRef = this.viewContainerRef.createComponent(AdditionalDocumentModalComponent);

    componentRef.instance.documentRefTypeName = this.documentRefTypeName;
    componentRef.instance.documentRefTypeNumber = this.documentRefTypeNumber;
    componentRef.instance.documentRefId = this.documentRefId;
    
    componentRef.instance.close.subscribe((result: Document[]) => {
      this.viewContainerRef.clear();
      if (result != null) {        
        result.forEach((document: Document) => {
            this.addEvents(document, EventTypes.DocumentUploaded);
            this.supportingDocuments.push(document);
        });
        this.sortSupportingDocuments();
      }
    });
  }
}
