import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportingDocumentsComponent } from "./supporting-documents.component";
import { SharedModule } from '@apis/shared/shared.module';
import { UploadRedactedModalComponent } from './modals/upload-redacted-modal/upload-redacted-modal.component';
import { PublishOriginalModalComponent } from './modals/publish-original-modal/publish-original-modal.component';
import { DeleteDocumentModalComponent } from "./modals/delete-document-modal/delete-document-modal.component";
import { AdditionalDocumentModalComponent } from "./modals/additional-document-modal/additional-document-modal.component";
import { UploadDocumentModalComponent } from "./modals/upload-document-modal/upload-document-modal.component";

@NgModule({
  declarations: [
    SupportingDocumentsComponent, 
    UploadRedactedModalComponent, 
    PublishOriginalModalComponent,
    DeleteDocumentModalComponent,
    AdditionalDocumentModalComponent,
    UploadDocumentModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    SupportingDocumentsComponent
  ]
})
export class SupportingDocumentsModule { }
