import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'action-checkboxes',
  templateUrl: './action-checkboxes.component.html',
  styleUrls: ['./action-checkboxes.component.scss']
})
export class ActionCheckboxesComponent implements OnInit {
  @Input() showRequiresRedaction: boolean = true;
  @Input() showPendingSubmissions: boolean = true;
  @Input() showContact: boolean = true;
  @Input() showClosure: boolean = true;  
  @Output() change: EventEmitter<string[]> = new EventEmitter<string[]>();
 
  constructor() { }

  ngOnInit(): void {
  }

  init(initialValue: string[]): void {
    $(".action-checkbox-container").each((index: number, element: HTMLElement) => {
      var actionName = element.innerText.toLowerCase();
      if (initialValue.includes(actionName)) {
        element.classList.add("active");
      }
    });
  }
 
  selectAll(): void {
    $(".action-checkbox-container").addClass("active");    
    this.change.emit(this.getCheckedActions());
  }

  clearAll(): void {
    $(".action-checkbox-container").removeClass("active");
    this.change.emit(this.getCheckedActions());
  }

  onActionCheckboxClick(containerId: string): void {
    let container = document.getElementById(containerId);

    if (container.classList.contains("active")) {
      container.classList.remove("active");
    } else {
      container.classList.add("active");
    }  
    
    this.change.emit(this.getCheckedActions());
  }

  private getCheckedActions(): string[] {
    return $(".action-checkbox-container.active").map((index: number, element: HTMLElement) => element.innerText.toLowerCase()).toArray();
  }
}
