

import { VehicleSeizure } from './vehicle-seizure.model';
import { Contravention } from './contravention.model';
import { LateReviewRequest } from './late-review-request.model';

export class LateReviewRequestItem {
    public lateReviewRequesItemtId: number = 0;
    public contraventionId: number;
    public vehicleSeizureId: number;
    public lateReviewRequestId: number = 0;
    public lateReviewRequest: LateReviewRequest;
    public recordNumber: string;
    public ContraventionTypeId :number = 0;
    public SecondaryContraventionTypeId :number;
    public TertiaryContraventionTypeId :number; 

    constructor(init?: Partial<LateReviewRequestItem>) {
        
        Object.assign(this, init);
    }
}
