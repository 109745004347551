import { JudicialReview } from './../../../../../../../libs/shared/models/judicial-review.model';
import { Component, OnInit, Input, Output, ViewContainerRef, OnChanges, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Notification } from '@apis/shared/models/notification.model';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReviewService } from '../../../shared/services/review.service';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { ContactMethodTypes, EventTypes } from '@apis/shared/enums/app.enum';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { KeycloakService } from 'keycloak-angular';
import { Event } from "@apis/shared/models/event.model";
import { CommonMethods } from '../../../shared/helpers/common-methods';
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { Constants } from "@apis/shared/helpers/constants";
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { LogEntry } from '@apis/shared/models/log-entry.model';
import { LogSearchRequestModel } from '../../../shared/models/log-search-request.model';

@Component({
  selector: 'review-activity',
  templateUrl: './review-activity.component.html',
  styleUrls: ['./review-activity.component.scss']
})
export class ReviewActivityComponent implements OnInit, OnChanges {
  @Input() review: Review;
  @Input() contravention: Contravention;
  @Input() vehicleSeizure: VehicleSeizure;
  @Output() activityNoteSubmitted = new EventEmitter<Review>();
  
  recentActivities: any[] = [];  
  judicialReview: JudicialReview;
  TypeTable = TypeTable;
  EventTypes = EventTypes;
  username: string;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
 
  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService,
    private readonly adjudicationService: AdjudicationService,
    readonly viewContainerRef: ViewContainerRef,
    private readonly keycloakService: KeycloakService,
    private readonly documentService: DocumentService) { }

  ngOnInit(): void {         
    this.username = this.keycloakService.getUsername();
  }

  ngOnChanges(): void {    
     if(this.review != null) {
      this.populateRecentActivity();
     }
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType) 
    && (recentActivity.by == this.username 
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  getNotificationTypeName(notificationTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(notificationTypeId, TypeTable.NotificationType)
  }

  getContactedBy(contactedBy: string): string {
    return contactedBy != null ? contactedBy : "-";
  }
  
  getContactMethodTypeName(contactMethodTypeId: number): string {   
    let contactMethodTypeName = this.localStorageService.getTypeItemDescriptionById(contactMethodTypeId, TypeTable.ContactMethodType);    
    return contactMethodTypeName != null && contactMethodTypeName.length > 0 ? contactMethodTypeName : "-";
  }

  getNotificationDate(notificationDate: Date): string {    
    if(notificationDate != null) {
      notificationDate = new Date(notificationDate.toString().replace(/[zZ]/, ""));

      // Offset the timezone
      notificationDate.setMinutes(notificationDate.getMinutes() - notificationDate.getTimezoneOffset()); 

      return new DatePipe("en-US").transform(notificationDate, "mediumDate")
    } else {
      return "-";
    }
  }
  
  onNotificationCheckboxClick(ev: any, notification: Notification): void {
    if (!ev.target.parentElement.classList.contains("active")) {
      ev.target.parentElement.classList.add("active");

      this.reviewService.putNotification(notification)
        .subscribe((result: Notification) => {
          if (result != null) {            
            let notificationDate = new Date(result.notificationDate.toString().replace(/[zZ]/, ""));
            notificationDate.setMinutes(notificationDate.getMinutes() - notificationDate.getTimezoneOffset());
            
            let index = this.review.notifications.findIndex(n => +n.notificationId == +result.notificationId);
            this.review.notifications.splice(index, 1, result);

            this.review.isPhoneContactRequired = this.review.notifications.findIndex(n => +n.contactMethodTypeId == ContactMethodTypes.Phone && n.notificationDate == null) != -1;
          }
        });
    } 
  }

  onDriverLogsSelectionChanged(result: any): void {
    this.populateRecentActivity(result);
  }

  getDriverLogSearchRequest = () => {
    var searchRequest = new LogSearchRequestModel();

    var searchCriteria = this.review.documents.map(d => d.contentGuid);
    searchCriteria.push(this.review.reviewNumber);

    searchRequest.searchCriteria = searchCriteria;
    searchRequest.fromDate = this.review.requestDate;

    return searchRequest;
  }

  public populateRecentActivity(logs?: LogEntry[]): void {
    this.recentActivities = [];
    
    //Add all review events
    let events = this.review.events;    
    this.pushEventsToActivities(events);

    this.pushDriverLogsToActivities(logs);

    this.recentActivities = this.recentActivities?.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });    

    this.setOffSetTopForReviewRecentActivity();
  }

  private pushDriverLogsToActivities(logs: LogEntry[])
  {
    let datePipe = new DatePipe("en-US");
    if (logs) {
      for (let log of logs) {          
        let eventDateTime = new Date(log.timeStamp.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = datePipe.transform(eventDateTime, "HH:mm");
        let username = log.userId;
        let title = log.title;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          id: log.id,
          isFromDriverLogs: true
        });
      }
    }
  }

  private pushEventsToActivities(events: Event[])
  {
    let datePipe = new DatePipe("en-US");
    if (events)
    {
      for (let event of events) {          
        let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = datePipe.transform(eventDateTime, "HH:mm");
        let username = event.userName;
        let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
        let body = event.eventDetails;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          body: body,
          documents: event.documents,
          id: event.eventId,
          eventTypeId: event.eventTypeId,
          isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
        });
      }
    }
  }

  public setOffSetTopForReviewRecentActivity() {
    setTimeout(() => {  
      if ($(".review-recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".review-recent-activity-container:last-child")[0].offsetTop;    
          $(".review-recent-activity-connector").height(offsetTop);
          $(".review-recent-activity-group").animate({ scrollTop: 0 }, "fast");
        }
      }, 100);      
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.reviewId = this.review.reviewId;    
    componentRef.instance.documentRefTypeNumber = this.review.reviewNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.review.events.push(result);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.review);
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.review.reviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.review.events = this.review.events.filter(x=>x.eventId !== eventId);
        this.review.events.push(result);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.review);
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.review.reviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.review.events = this.review.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.review);
      }
      this.viewContainerRef.clear();
    });
  }

  onEventDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Events", this.review.reviewNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }
}
