<div class="modal-overlay modal-show" *ngIf="scheduledEvent && scheduledEvent.meetingTypeId == null">
  <form #scheduledEventForm="ngForm" (ngSubmit)="onSubmit(scheduledEventForm.valid)">
    <div class="modal-container">
      <div class="modal-container-header">
        <div class="modal-title">{{ scheduledEvent.scheduledEventId > 0 ? 'Event Details' : 'New Event' }}</div>
      </div>
      <div class="modal-container-body d-flex flex-column">
        <div class="form-group" *ngIf="showAdjudicator">
          <label class="modal-body-label required" for="adjudicatorDropdown">Adjudicator</label>
          <select id="adjudicatorDropdown" 
              name="userId"                                                                                 
              #userId="ngModel"                                        
              class="form-control modal-select"
              required
              [ngClass]="{'alert-border': userId.errors?.required && scheduledEventForm.submitted }"
              [(ngModel)]="scheduledEvent.userId">
              <option *ngFor="let adjudicator of adjudicators" [value]="adjudicator.userId">
                  {{adjudicator.lastName}}, {{adjudicator.firstName}}
              </option>
          </select>
          <span
            class="alert-text"
            [ngClass]="{'show': userId.errors?.required && scheduledEventForm.submitted}"
          >
            Adjudicator is required
          </span>
        </div>
        <div class="form-group">
          <label class="modal-body-label required" for="title">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            name="title"
            required
            #title="ngModel"
            [(ngModel)]="scheduledEvent.title"
            [ngClass]="{'alert-border': title.errors?.required && scheduledEventForm.submitted}"
          />
          <span
            class="alert-text"
            [ngClass]="{'show': title.errors?.required && scheduledEventForm.submitted}"
          >
            Title is required
          </span>
        </div>
        <div class="form-group">
          <label class="modal-body-label required" for="availabilityType">Availability</label>
          <select class="form-control modal-select" id="availabilityType" name="availabilityType"
            required #availabilityType="ngModel"
            [ngClass]="{'alert-border': availabilityType.errors?.required && scheduledEventForm.submitted}"
            [(ngModel)]="scheduledEvent.availabilityTypeId"
          >
            <option class="modal-select-option"                        
                *ngFor="let avaType of availabilityTypes"                                                 
                [ngValue]="avaType.id.toString()">{{avaType.name}}</option>
          </select>
          <span
            class="alert-text"
            [ngClass]="{'show': availabilityType.errors?.required && scheduledEventForm.submitted}"
          >
          Availability is required
          </span>
        </div>
        <div class="form-group">
          <label class="modal-body-label required" for="eventStartDate"
            >Start date</label
          >
          <div class="d-flex">
            <div class="">
              <input
                type="text"
                required
                class="form-control"
                id="eventStartDate"
                name="eventStartDate"
                #eventStartDate="ngModel"
                [(ngModel)]="scheduledEvent.startDate"
                [ngClass]="{'alert-border': eventStartDate.errors?.required && scheduledEventForm.submitted}"
                bsDatepicker
                [bsConfig]="datePickerConfig"
                placeholder="MMM DD, YYYY"
              />
            </div>
            <div class="ml-2">
              <input type="time" required id="startTime" name="eventStartTime" required
                #eventStartTime="ngModel"
                [(ngModel)]="startTime"
                [disabled]="scheduledEvent.isAllDay"
                [ngClass]="{'alert-border': eventStartTime.errors?.required && scheduledEventForm.submitted}"
              />
            </div>
            <div class="ml-3 all-day-section">
              <input
                #inputAllDay
                type="checkbox"
                class="mr-2 all-day-check"
                id="allDay"
                name="allDay"
                [(ngModel)]="scheduledEvent.isAllDay"
                (change)="onAllDayCheckClicked(inputAllDay.checked)"
              />
              <label class="all-day-label" for="allDay">All day</label>
            </div>            
          </div>
          <span
              class="alert-text"
              [ngClass]="{'show': eventStartDate.errors?.required && scheduledEventForm.submitted}"
            >
            Start date is required
            </span>
            <span
              class="alert-text"
              [ngClass]="{'show': eventStartTime.errors?.required && scheduledEventForm.submitted}"
            >
            Start time is required
            </span>
        </div>
        <div class="form-group" *ngIf="!scheduledEvent.isAllDay">
          <label class="modal-body-label required" for="eventEndDate">End date</label>
          <div class="d-flex">
            <div class="">
              <input
                type="text"
                required
                class="form-control"
                id="eventEndDate"
                name="eventEndDate"
                #eventEndDate="ngModel"
                [(ngModel)]="scheduledEvent.endDate"
                [ngClass]="{'alert-border': eventEndDate.errors?.required && scheduledEventForm.submitted}"
                bsDatepicker
                [bsConfig]="datePickerConfig"
                placeholder="MMM DD, YYYY"
              />
            </div>
            <div class="ml-2">
              <input type="time" required id="eventEndTime" name="eventEndTime" required
                #eventEndTime="ngModel"
                [(ngModel)]="endTime"
                [ngClass]="{'alert-border': eventEndTime.errors?.required && scheduledEventForm.submitted}"
              />
            </div>            
          </div>
          <span
              class="alert-text"
              [ngClass]="{'show': eventEndDate.errors?.required && scheduledEventForm.submitted}"
            >
            End date is required
            </span>
            <span
              class="alert-text"
              [ngClass]="{'show': eventEndTime.errors?.required && scheduledEventForm.submitted}"
            >
            End time is required
            </span>
        </div>
      </div>
      <div class="button-container">
        <div>
          <button type="button" class="goa-button" (click)="onDeleteClick()"
            *ngIf="+scheduledEvent.scheduledEventId > 0">Delete</button>
        </div>
        <div>
          <button type="button" class="goa-button" (click)="onCancelClick()">Cancel</button>
          <button type="submit" class="goa-button">Save</button>
        </div>
      </div>
      <div>
        <p *ngIf="scheduledEvent.createdOn">Submitted by {{scheduledEvent.createdBy}} on {{scheduledEvent.createdOn | date}} at {{scheduledEvent.createdOn | date:'HH:mm'}}</p>
        <p *ngIf="scheduledEvent.updatedOn">Last updated by {{scheduledEvent.updatedBy}} on {{scheduledEvent.updatedOn | date}} at {{scheduledEvent.updatedOn | date:'HH:mm'}}</p>
      </div>
    </div>
  </form>
</div>
<div class="modal-overlay modal-show" *ngIf="scheduledEvent && scheduledEvent.meetingTypeId != null">
  <div class="modal-container">
    <div class="modal-container-header">
      <div class="modal-title">{{ scheduledEvent.meetingType.name }}</div>
    </div>
    <div class="modal-container-body d-flex flex-column">
      <div class="form-group" *ngIf="showAdjudicator">
        <label class="modal-body-label required" for="availableAdjudicator">Adjudicator</label>
        <select id="availableAdjudicator" 
            name="availableAdjudicator"                                                                                 
            #availableAdjudicator="ngModel"                                        
            class="form-control modal-select"
            required
            [ngClass]="{'alert-border': availableAdjudicator.errors?.required }"
            [(ngModel)]="scheduledEvent.userId"
            (change)="onAdjudicatorChange($event)">
            <option *ngFor="let adjudicator of availableAdjudicators; trackBy: trackAvailableAdjudicator" [value]="adjudicator.userId">
                {{adjudicator.lastName}}, {{adjudicator.firstName}}
            </option>
        </select>
        <span
          class="alert-text"
          [ngClass]="{'show': availableAdjudicator.errors?.required}"
        >
          Adjudicator is required
        </span>
      </div>
      <div class="form-group">
        <label class="modal-body-label">Recipient/Applicant</label>
        <div>{{ scheduledEvent.attendees }}</div>
      </div>
      <div class="form-group" *ngIf="scheduledEvent.meetingTypeId == meetingTypes.SeizureReview; else notVehicleSeizure">
        <label class="modal-body-label">Seizure #</label>
        <div>
          <a class="linked-number" [routerLink]="['/seizure', scheduledEvent.itemNumber]">{{scheduledEvent.itemNumber}}</a>
        </div>
      </div>
      <ng-template #notVehicleSeizure>
        <div class="form-group">
          <label class="modal-body-label">Contravention #</label>
          <div>
            <a class="linked-number" [routerLink]="['/contravention', scheduledEvent.itemNumber]">{{scheduledEvent.itemNumber}}</a>
          </div>
        </div>
      </ng-template>
      <div class="form-group">
        <label class="modal-body-label">Review</label>
        <div>
          <a class="linked-number" [routerLink]="['/review', scheduledEvent.recordNumber]">{{scheduledEvent.recordNumber}}</a>
        </div>
      </div>
      <div class="form-group">
        <label class="modal-body-label">Review Date & Time</label>
        <div>{{ scheduledEvent.startDate | date:'medium' }}</div>
      </div>
      <div class="form-group" *ngIf="+scheduledEvent.meetingTypeId == 2">
        <label class="modal-body-label">Webex Link</label>
        <div class="text-break">
          <a target="_blank" href="{{scheduledEvent.meetingUrl}}">{{scheduledEvent.meetingUrl}}</a>
        </div>
      </div>      
    </div>
    <div class="form-group" *ngIf="scheduledEvent.decisionDueDate">
      <label class="modal-body-label">Decision Due Date</label>
      <div>{{ scheduledEvent.decisionDueDate | date }}</div>
    </div>
    <div class="button-container" *ngIf="scheduledEvent && scheduledEvent.meetingTypeId">
      <div></div>    
      <div>
        <button type="button" class="goa-button" (click)="onCloseClick()">Close</button>
        <app-access-control [Resource]="Resource.DASHBOARD" [Permission]="Permission.MANAGE_REVIEWS_EDIT">
          <button type="button" class="goa-button" 
          [ngClass]="{'goa-button-disabled':!isAdjudicatorChanged}" 
          *ngIf="showAdjudicator" 
          [disabled]="!isAdjudicatorChanged" (click)="onSaveClick()">Save</button>
        </app-access-control>
      </div>
    </div>
  </div>
</div>
