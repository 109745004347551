import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DocumentService } from '@apis/shared/services/document.service';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentUploadComponent } from '../../../document-upload/document-upload.component';
import { DecisionType } from '@apis/shared/models/types/decision-type.model';
import { ReviewService } from 'apps/adjudication/src/app/shared/services/review.service';
import { Document } from '@apis/shared/models/document.model';
import { ContactMethodTypes, ContraventionTypes, DecisionTypes, DocumentTypes, EventTypes, NotificationTypes, ReviewOutcomeTypes, ReviewStatusTypes, ReviewTypes, SeizureTypes } from '@apis/shared/enums/app.enum';
import { Event } from '@apis/shared/models/event.model';
import { EventType } from '@apis/shared/models/types/event-type.model';
import { KeycloakService } from 'keycloak-angular';
import { Notification } from '@apis/shared/models/notification.model';
import { CommonMethods } from 'apps/adjudication/src/app/shared/helpers/common-methods';
import { ReviewCancellationReasonType } from '@apis/shared/models/types/review-cancellation-reason-type.model';
import { ReviewCancellationSecondaryReasonType } from '@apis/shared/models/types/review-cancellation-secondary-reason-type.model';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { ReviewItemCancellationReason } from '@apis/shared/models/review-item-cancellation-reason.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { SeizureType } from '@apis/shared/models/types/seizure-type.model';

@Component({
  selector: 'app-submit-decision-modal',
  templateUrl: './submit-decision-modal.component.html',
  styleUrls: ['./submit-decision-modal.component.scss']
})
export class SubmitDecisionModalComponent implements OnInit {

  @Output() close: EventEmitter<Review> = new EventEmitter();

  decisionTypeId: number = 0;
  decisionTypes: DecisionType[];
  varyToTypes: number[] = [];
  varyToTypeId: number = 0;
  contraventionTypeId: number = 0;
  decisionLetterDocumentTypeId = DocumentTypes.ReviewDecisionLetter;
  reviewCancellationReasonTypes: ReviewCancellationReasonType[];
  reviewCancellationSecondaryReasonTypes: ReviewCancellationSecondaryReasonType[];

  username: string;

  reviewTypeName: string;
  review: Review;
  selectedDocument: Document;
  tempFileFolder: string;
  isDocumentNameValid: boolean = true;
  contravention: Contravention;
  disableRemoveDocument: boolean = false;
  decisionAlreadyExists: boolean = false;

  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  decisionTypeDropdown: JQuery<HTMLElement>;
  saveSubmitButton: JQuery<HTMLElement>;
  validFileExtensions: string[];
  contraventionTypes: ContraventionType[];
  seizureTypes: SeizureType[];

  @ViewChild(DocumentUploadComponent) documentUploadComponent: DocumentUploadComponent;

  constructor(private readonly documentService: DocumentService,
    private readonly reviewService: ReviewService,
    private readonly localStorageService: LocalStorageService,
    private readonly keycloakService: KeycloakService) { }

  ngOnInit(): void {
    this.validFileExtensions = this.localStorageService.getDocumentTypes()?.find(x => x.id == this.decisionLetterDocumentTypeId).acceptedFileTypes.split(',').map(item => `.${item}`);
    this.tempFileFolder = Guid.create().toString();
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.seizureTypes = this.localStorageService.getSeizureTypes();

    if (this.review.reviewItems[0].decisionTypeId == null) {
      if (this.review.outcome && this.review.outcome.reviewOutcomeTypeId == ReviewOutcomeTypes.DeemedAbandoned) {
        // If review outcome is deemed abandoned then show only decision type "Confirm"
        this.decisionTypes = this.localStorageService.getDecisionTypes()?.filter(d => d.id == DecisionTypes.Confirm);
      } else {

        this.setVaryToTypes();

        this.decisionTypes = this.localStorageService.getDecisionTypes()?.filter(d => d.id != DecisionTypes.CancelledByRecipient && d.id != DecisionTypes.VaryAndConfirm);

        if (this.varyToTypes.length > 0) {
          this.decisionTypes = this.localStorageService.getDecisionTypes()?.filter(d => d.id != DecisionTypes.CancelledByRecipient);

          this.decisionTypes.sort((d1, d2) => d1.displayOrder - d2.displayOrder);
        }
      }
    }

    else {
      this.decisionAlreadyExists = true;
      this.decisionTypes = this.localStorageService.getDecisionTypes()?.filter(d => d.id != DecisionTypes.CancelledByRecipient);

      if (this.review.reviewItems[0].decisionTypeId == DecisionTypes.VaryAndConfirm) {
        this.varyToTypeId = this.review.reviewItems[0].contraventionId != null
          ? this.review.reviewItems[0].contraventionTypeId
          : this.review.reviewItems[0].vehicleSeizureId != null
          ? this.review.reviewItems[0].seizureTypeId
          : 0;
      }
    }

    this.decisionTypes.forEach(d => d.name = CommonMethods.renameDecisionByReviewTypeId(d.name, this.review.reviewTypeId));

    this.reviewCancellationReasonTypes = this.localStorageService.getReviewCancellationReasonTypes().filter(r => r.reviewTypeId == this.review.reviewTypeId);
    this.reviewCancellationSecondaryReasonTypes = this.localStorageService.getReviewCancellationSecondaryReasonTypes();

    this.username = this.keycloakService.getUsername();

    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.saveSubmitButton = $(".save-submit-button");
  }

  private getEventTypeId(): number {
    let eventTypeId = EventTypes.ReviewComplete;

    switch (+this.decisionTypeId) {
      case DecisionTypes.Confirm:
        eventTypeId = EventTypes.ReviewDecisionIssued;
        break;
      case DecisionTypes.Cancel:
        eventTypeId = EventTypes.ReviewDecisionCancelled;
        break;
    }

    return eventTypeId;
  }

  setVaryToTypes() {
    if (this.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview) {
      if (this.review.reviewItems[0].seizureTypeId > 0) {
        if (this.review.reviewItems[0].seizureTypeId 
          == this.seizureTypes?.find(s => s.id == SeizureTypes.SuspendedDriversProgramSecondOccurrence).id) {
          this.varyToTypes = [SeizureTypes.SuspendedDriversProgramFirstOccurrence];
        }
      }

      else {     
        if (this.review.reviewItems[0].contraventionTypeId > 0) {
          switch (parseInt(this.review.reviewItems[0].contraventionTypeId.toString())) {
            case ContraventionTypes.IRSFail3rd: {
              this.varyToTypes = [ContraventionTypes.IRSFail2nd, ContraventionTypes.IRSFail1st];
              break;
            }
            case ContraventionTypes.IRSFail2nd: {
              this.varyToTypes = [ContraventionTypes.IRSFail1st];
              break;
            }
            case ContraventionTypes.IRSWarn3rd: {
              this.varyToTypes = [ContraventionTypes.IRSWarn2nd, ContraventionTypes.IRSWarn1st];
              break;
            }
            case ContraventionTypes.IRSWarn2nd: {
              this.varyToTypes = [ContraventionTypes.IRSWarn1st];
              break;
            }
            case ContraventionTypes.IRSZeroCommercial3rd: {
              this.varyToTypes = [ContraventionTypes.IRSZeroCommercial2nd, ContraventionTypes.IRSZeroCommercial1st];
              break;
            }
            case ContraventionTypes.IRSZeroCommercial2nd: {
              this.varyToTypes = [ContraventionTypes.IRSZeroCommercial1st];
              break;
            }
          }
        }
      }
    }
  }

  onSaveSubmitClick(): void {
    if (this.saveSubmitButton.hasClass("disabled")) {
      return;
    }

    this.saveSubmitButton.addClass("disabled");
    this.saveSubmitButton.addClass("saving");

    if (this.review.reviewItems[0].seizureTypeId > 0) {
      this.review.reviewItems[0].newSeizureTypeId = this.varyToTypeId;
    }
    
    else {
      if (this.review.reviewItems[0].contraventionTypeId > 0) {
        this.review.reviewItems[0].newContraventionTypeId = this.varyToTypeId;
      }
    }

    const review = new Review({
      reviewId: this.review.reviewId,
      reviewItems: this.review.reviewItems,
      tempFolderName: this.tempFileFolder
    });

    if (this.selectedDocument != null) {
      review.documents.push(this.selectedDocument);
      this.disableRemoveDocument = true;
    }
    this.reviewService.putDecision(review)
      .subscribe((result: Review) => {
        this.removeOverflow();
        this.close.emit(result);
      },
        (error: any) => { });
  }

  onDocumentAdded(document: Document): void {
    this.selectedDocument = document;

    if (this.review.reviewItems[0].seizureTypeId > 0) { // Seizure review
      this.isDocumentNameValid = this.selectedDocument?.documentName.slice(0, 17) === this.review.reviewItems[0].recordNumber; // If document name starts with correct seizure number
    }
    else { // Contravention review
      this.isDocumentNameValid = this.selectedDocument?.documentName.slice(0, 10) === this.review.reviewItems[0].recordNumber; // If document name starts with correct contravention number
    }
  }

  onDocumentDeleted(): void {
    this.selectedDocument = null;
  }

  onCloseIconClick(): void {
    this.documentUploadComponent.removeDocument();
    this.removeOverflow();
    this.close.emit();
  }

  onDecisionChanged(reviewItem: ReviewItem): void {
    if (+reviewItem.decisionTypeId == DecisionTypes.Cancel) {
      this.initCancellationReasons(reviewItem);
    } else {
      reviewItem.reviewItemCancellationReasons = null;
    }
  }

  getChargeTypeName(id) {
    if (this.review.reviewItems[0].seizureTypeId > 0) {
      return this.seizureTypes?.find(s => s.id == id).name;
    }
    else 
    {
      if (this.review.reviewItems[0].contraventionTypeId > 0) {
        return this.contraventionTypes?.find(s => s.id == id).name;
      }
    }
  }

  removeOverflow(): void {
    this.bodyElement.removeClass("overflow-hidden");
  }

  isSaveSubmitButtonDisabled(): boolean {
    return this.review.reviewItems.some(i => [null, 0].includes(+i.decisionTypeId)
      || (+i.decisionTypeId == +DecisionTypes.Cancel && i.reviewItemCancellationReasons.some(r => [null, 0].includes(+r.reviewCancellationReasonTypeId))))
      || this.selectedDocument == null
      || (this.review.reviewItems[0].decisionTypeId == DecisionTypes.VaryAndConfirm && this.varyToTypeId == 0)
      || !this.isDocumentNameValid;
  }

  getContraventionTypeName(contraventionTypeId: number): string {
    var contraventionType = this.localStorageService.getContraventionTypes().find(t => t.id == contraventionTypeId);
    if (contraventionType && this.review.reviewTypeId == ReviewTypes.IrsReview) {
      return `${contraventionType.name}`;
    }
    else {
      return this.reviewTypeName;
    }
  }

  initCancellationReasons(reviewItem: ReviewItem) {
    reviewItem.reviewItemCancellationReasons = [];
    // If IRS Fail add reason place holders for each contravention detail
    if (this.contravention
      && this.review.reviewTypeId == ReviewTypes.IrsReview
      && (reviewItem.contraventionTypeId == ContraventionTypes.IRS24
        || reviewItem.contraventionTypeId == ContraventionTypes.IRSFail1st
        || reviewItem.contraventionTypeId == ContraventionTypes.IRSFail2nd
        || reviewItem.contraventionTypeId == ContraventionTypes.IRSFail3rd)) {

      this.contravention.contraventionDetails.split(',').forEach(
        function (contraventionDetailTypeId) {
          reviewItem.reviewItemCancellationReasons.push(new ReviewItemCancellationReason({
            contraventionDetailTypeId: +contraventionDetailTypeId
          }));
        }
      );
    } else {
      reviewItem.reviewItemCancellationReasons.push(new ReviewItemCancellationReason());
    }
  }
}
