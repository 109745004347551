import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';

export class PageEvent {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex?: number;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() length: number;
  @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter();

  pageRangeLowerBound: number;
  pageRangeUpperBound: number;
  pageNumbers: number[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges ) {
    this.pageRangeLowerBound = 1;
    this.pageRangeUpperBound = 5;

    if (this.pageIndex > 5) {
      this.pageRangeLowerBound = this.pageIndex - 4;
      this.pageRangeUpperBound = this.pageIndex;
    } else {
      this.pageRangeUpperBound = this.length < 5 ? this.length : 5;
    }

    this.setPageNumbers();
  }

  onPageNumberClick(pageNumber: number){
    this.changePage(pageNumber);    
  }

  onNextLinkClick() {
    this.changePage(this.pageIndex + 1);    
  }

  onPreviousLinkClick(){
    this.changePage(this.pageIndex - 1);    
  }

  changePage(pageNumber: number){
    if (pageNumber < 1 || pageNumber > this.length) {
      return;
    }
    this.pageIndex = pageNumber;
    this.updatePageRange();
    this.emitPageChangeEvent();
  }

  updatePageRange() {
    if(this.pageIndex < this.pageRangeLowerBound) {
      this.pageRangeLowerBound = this.pageIndex;
      this.pageRangeUpperBound = this.pageRangeLowerBound + 4;
    } else if (this.pageIndex > this.pageRangeUpperBound) {
      this.pageRangeUpperBound = this.pageIndex;
      this.pageRangeLowerBound = this.pageRangeUpperBound - 4;
    }

    this.setPageNumbers();
  }

  setPageNumbers() {
    this.pageNumbers = [];
    for (let index = this.pageRangeLowerBound; index <= this.pageRangeUpperBound; index++) {
      this.pageNumbers.push(index)
    }
  }

  emitPageChangeEvent() {
    var pageEvent = new PageEvent();
    
    pageEvent.pageIndex = this.pageIndex
    
    this.pageChange.emit(pageEvent);
  }

}
