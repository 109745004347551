

<div class="d-flex flex-column">                    
    <ng-container *ngIf="requestType != RequestTypes.LEACancellationRequest">
        <div class="d-flex justify-content-between align-items-center" style="margin-top: 28px;">
            <div class="heading-table">Notification History</div>        
        </div>
        <div class="d-flex mt-3 mb-3 flex-column">        
            <table class="table">
                <thead>
                    <tr>                    
                        <th>Notification Type</th>
                        <th>Contacted by</th>
                        <th>Method</th>
                        <th>Date Complete</th>
                        <th></th>                                        
                    </tr>
                </thead>
                            
                <tbody *ngIf="request?.notifications?.length > 0">                    
                    <tr *ngFor="let notification of request?.notifications">
                        <td>{{getNotificationTypeName(notification.notificationTypeId)}}</td>
                        <td>{{getContactedBy(notification.contactedBy)}}</td>
                        <td>{{getContactMethodTypeName(notification.contactMethodTypeId)}}</td>
                        <td>{{getNotificationDate(notification.notificationDate)}}</td>
                        <td>
                            <div class="notification-checkbox-container" 
                                [ngClass]="{'active': notification.notificationDate != null }"
                                (click)="onNotificationCheckboxClick($event, notification)">
                                <div class="notification-checkbox"></div>                        
                            </div>
                        </td>
                    </tr>                      
                </tbody>
                <tbody *ngIf="request?.notifications?.length == 0">
                    <tr>
                        <td colspan="5">Notification history not found.</td>
                    </tr>
                </tbody>         
            </table>       
        </div>
        <div class="form-group divider"></div>  
    </ng-container>          
    <div class="request-recent-activity-summary-header">
        <div>
            All Activity
            <app-access-control *ngIf="requestType == RequestTypes.LateReview"
                    [Resource]="Resource.REQUEST" [Permission]="Permission.VIEW_DRIVER_LOGS">
                <driver-log [getSearchRequestCallback]="getDriverLogSearchRequest" (onSelectionChanged)="onDriverLogsSelectionChanged($event)"></driver-log>
            </app-access-control>
        </div>
        <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.ACTIVITY_NOTE">
            <div class="d-flex justify-content-end align-items-center">
                <div class="add-note-icon">
                    <i class="fas fa-plus-circle"></i>
                </div>
                <button class="add-note-link"  (click)="onAddNoteLinkClick()">                        
                    Add a note
                </button>
            </div>
        </app-access-control>
    </div>
    <div class="form-group request-recent-activity-group">                 
        <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
            <div class="request-recent-activity-connector"></div>
            <div *ngFor="let recentActivity of recentActivities; let i=index;" class="request-recent-activity-container">
                <div class="request-recent-activity-dot" [ngClass]="{ 'request-recent-activity-dot-submitted': i==0}"></div>
                <div class="request-recent-activity-header">
                    <div>{{recentActivity.date}}</div>
                    <div class="d-flex">by {{recentActivity.by}} <span class="log-flag" *ngIf="recentActivity.isFromDriverLogs">Log</span></div>
                </div>
                <div *ngIf="recentActivity.title != null" class="request-recent-activity-title">{{recentActivity.title}}</div>
                <div *ngIf="recentActivity.body != null" class="request-recent-activity-content">{{recentActivity.body}}</div>
                <ng-container *ngIf="recentActivity.documents?.length > 0">
                    <div class="request-recent-activity-document" *ngFor="let document of recentActivity.documents">
                        <img class="download-file-icon" src="assets/images/download-file.png" />
                        <a (click)="onEventDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                    </div>
                </ng-container>
                <app-access-control
                    *ngIf="canModifyNote(recentActivity)"
                    [Resource]="Resource.REQUEST" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="request-recent-activity-edit">
                        <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                        <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                    </div>
                </app-access-control>
            </div>                    
        </ng-container>        
    </div> 
</div>
