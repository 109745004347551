import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "apps/adjudication/src/environments/environment";
import { User } from '@apis/shared/models/user.model';
import { UserSchedule } from '@apis/shared/models/user-schedule.model';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { UserCalendarViewRequest } from '@apis/shared/models/user-calendar-view-request.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Injectable()
export class SchedulerService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }

  getSchedules(): Observable<UserSchedule[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/schedules`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<UserSchedule>(response));
        }
        
        return null;
      })
    );
  }

  postSchedules(userScheduleList: UserSchedule[]): Observable<UserSchedule[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/schedules`, userScheduleList, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<UserSchedule>(response));
        }
        
        return null;
      })
    );
  }

  getCalendarView(userCalendarViewRequest: UserCalendarViewRequest): Observable<ScheduledEvent[]> {
    this.user = this.localStorageService.getUser();

    let params = new HttpParams();
    Object.entries(userCalendarViewRequest).forEach((pair: [string, any]) => {
      params = params.append(pair[0], Object.prototype.toString.call(pair[1]) === '[object Date]' ? pair[1].toISOString() : pair[1]);
    });

    return this.httpClient.get(`${this.apiUrl}/calendar`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params      
    }).pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<ScheduledEvent>(response));
        }
        
        return null;
      })
    );
  }

  getResourceTimelineViewAsync(startDate: Date, endDate: Date): Observable<ScheduledEvent[]> {
    this.user = this.localStorageService.getUser();

    let params = new HttpParams();
    params = params.append('startDate', startDate.toISOString());
    params = params.append('endDate', endDate.toISOString());

    return this.httpClient.get(`${this.apiUrl}/resource-timeline`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params      
    }).pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<ScheduledEvent>(response));
        }
        
        return null;
      })
    );
  }

  getScheduledEvent(scheduledEventId: number): Observable<ScheduledEvent> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/scheduled-events/${scheduledEventId}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => {
        return new ScheduledEvent(response);
      })
    );
  }

  postScheduledEvent(scheduledEvent: ScheduledEvent): Observable<ScheduledEvent> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/scheduled-events`, scheduledEvent, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new ScheduledEvent(response))
      );
  }

  putScheduledEvent(scheduledEvent: ScheduledEvent): Observable<ScheduledEvent> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/scheduled-events`, scheduledEvent, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new ScheduledEvent(response)));
  }

  deleteScheduledEvent(scheduledEventId: number): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/scheduled-events/${scheduledEventId}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map(response => response)
    );
  }

  getAvailableAdjudicators(startDate: Date, endDate: Date, meetingTypeId: number, complexity: number): Observable<number[]> {
    this.user = this.localStorageService.getUser();

    let params = new HttpParams();
    params = params.append('startDate', startDate.toISOString());
    params = params.append('endDate', endDate.toISOString());
    params = params.append('meetingTypeId', meetingTypeId.toString());
    if (complexity) {
      params = params.append('complexity', complexity.toString());
    }

    return this.httpClient.get(`${this.apiUrl}/adjudicators/availability`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params      
    }).pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<number>(response));
        }
        
        return null;
      })
    );
  }
}