import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LogEntry } from '@apis/shared/models/log-entry.model';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { map } from 'rxjs/operators';
import { LogSearchRequestModel } from '../models/log-search-request.model';
import * as moment from 'moment';

@Injectable()
export class LogService {
    private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
    private user: User;

    constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
    }

    getDriverLogs(searchRequest: LogSearchRequestModel): Observable<LogEntry[]> {
      this.user = this.localStorageService.getUser();
      const filtersString = searchRequest.searchCriteria.join(',');
      const fromDateString = (moment(searchRequest.fromDate)).format('YYYY-MM-DD');

      return this.httpClient.get(`${this.apiUrl}/logs/driver?searchFilters=${filtersString}&fromDate=${fromDateString}`, { 
        headers: {'Authorization': `Bearer ${this.user.token}`},
      }).pipe(
        map((response) => {  
                        
          if (Array.isArray(response)) {
            return (Array.from<LogEntry>(response));
          }
          
          return [new LogEntry(response)];
        })
      );
    }
}
