<div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center" style="margin-top: 28px;">
        <div class="heading-table">Notification History</div>
    </div>
    <div class="d-flex mt-3 mb-3 flex-column">
        <table class="table">
            <thead>
                <tr>
                    <th>Notification Type</th>
                    <th>Contacted by</th>
                    <th>Method</th>
                    <th>Date Complete</th>
                    <th></th>
                </tr>
            </thead>

            <tbody *ngIf="review?.notifications?.length > 0">
                <tr *ngFor="let notification of review?.notifications">
                    <td>{{getNotificationTypeName(notification.notificationTypeId)}}</td>
                    <td>{{getContactedBy(notification.contactedBy)}}</td>
                    <td>{{getContactMethodTypeName(notification.contactMethodTypeId)}}</td>
                    <td>{{getNotificationDate(notification.notificationDate)}}</td>
                    <td>
                        <div class="notification-checkbox-container"
                            [ngClass]="{'active': notification.notificationDate != null }"
                            (click)="onNotificationCheckboxClick($event, notification)">
                            <div class="notification-checkbox"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="review?.notifications?.length == 0">
                <tr>
                    <td colspan="5">Notification history not found.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="form-group divider"></div>
    <div class="review-recent-activity-summary-header">
        <div>
            All Activity
            <app-access-control
                    [Resource]="Resource.REVIEW" [Permission]="Permission.VIEW_DRIVER_LOGS">
                <driver-log [getSearchRequestCallback]="getDriverLogSearchRequest" (onSelectionChanged)="onDriverLogsSelectionChanged($event)"></driver-log>
            </app-access-control>
        </div>
        <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.ACTIVITY_NOTE" class="d-flex">
            <div class="d-flex justify-content-end align-items-center">
                <div class="add-note-icon">
                    <i class="fas fa-plus-circle"></i>
                </div>
                <div class="add-note-link" (click)="onAddNoteLinkClick()">Add a note</div>
            </div>
        </app-access-control>
    </div>

    <div class="form-group review-recent-activity-group">
        <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
            <div class="review-recent-activity-connector"></div>
            <div *ngFor="let recentActivity of recentActivities; let i=index;" class="review-recent-activity-container">
                <div class="review-recent-activity-dot" [ngClass]="{ 'review-recent-activity-dot-submitted': i==0}">
                </div>
                <div class="review-recent-activity-header">
                    <div>{{recentActivity.date}}</div>
                    <div class="d-flex">by {{recentActivity.by}} <span class="log-flag" *ngIf="recentActivity.isFromDriverLogs">Log</span></div>
                </div>
                <div class="review-recent-activity-title">{{recentActivity.title}}</div>
                <div *ngIf="recentActivity.body != null && recentActivity.eventTypeId != EventTypes.ReReviewGranted"
                    class="review-recent-activity-content">{{recentActivity.body}}</div>
                <ng-container *ngIf="recentActivity.eventTypeId == EventTypes.ReReviewGranted">
                    <ul>
                        <li *ngIf="review.isLEAAllowedToAddSupportingDocs"
                            class="review-recent-activity-content conditions">
                            <div>Allow law enforcement to add
                                supporting documents</div>
                        </li>
                        <li *ngIf="review.isAllowedToEditReviewGrounds"
                            class="review-recent-activity-content conditions">
                            <div>Allow recipient to edit grounds for
                                review</div>
                        </li>
                        <li *ngIf="review.isAllowedToAmendSupportingDocs"
                            class="review-recent-activity-content conditions">
                            <div>Allow recipient to add or amend
                                supporting documents</div>
                        </li>
                        <li *ngIf="review.isAllowedToEditReviewMethod"
                            class="review-recent-activity-content conditions">
                            <div>Allow recipient to edit review method
                            </div>
                        </li>
                        <li *ngIf="review.isReReviewBySameAdjudicator"
                            class="review-recent-activity-content conditions">
                            <div>To be re-reviewed by the same
                                adjudicator</div>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="recentActivity.documents?.length > 0">
                    <div class="review-recent-activity-document" *ngFor="let document of recentActivity.documents">
                        <img class="download-file-icon" src="assets/images/download-file.png" />
                        <a (click)="onEventDocumentNameClick(document)"
                            class="download-file-link">{{document.documentName}}</a>
                    </div>
                </ng-container>
                <app-access-control
                    *ngIf="canModifyNote(recentActivity)"
                    [Resource]="Resource.REVIEW" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="review-recent-activity-edit">
                        <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                        <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                    </div>
                </app-access-control>
            </div>
        </ng-container>
    </div>

</div>