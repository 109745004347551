import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscriber, throwError } from 'rxjs';
import { map, find, catchError } from "rxjs/operators";
import { environment } from "apps/adjudication/src/environments/environment";
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionView } from '@apis/shared/models/contravention-view.model';
import { ReviewView } from '@apis/shared/models/review-view.model';
import { Review } from '@apis/shared/models/review.model';
import { Document } from '@apis/shared/models/document.model';
import { Event } from '@apis/shared/models/event.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { PagedResult } from "@apis/shared/models/paged-result.model";
import { ContraventionPagedSearch } from "@apis/shared/models/contravention-paged-search.model";
import { ReviewPagedSearch } from "@apis/shared/models/review-paged-search.model";
import { Recipient } from '@apis/shared/models/recipient.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { PaymentDataEditRequest } from '@apis/shared/models/payment-data-edit-request.model';
import { JudicialReviewPagedSearch } from '@apis/shared/models/judicial-review-paged-search.model';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { StayOrder } from '@apis/shared/models/stay-order.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { RegisteredOwner } from '@apis/shared/models/registered-owner.model';

@Injectable()
export class AdjudicationService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }

  //Contraventions
  getContraventions(): Observable<ContraventionView[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/contraventions`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map((response) => {  
                        
          if (Array.isArray(response)) {
            return (Array.from<ContraventionView>(response));
          }
          
          return [new ContraventionView(response)];
        })
      );
  }

  getContraventionsByPagedSearch(contraventionPagedSearch: ContraventionPagedSearch): Observable<PagedResult> {
    this.user = this.localStorageService.getUser();
    let params = new HttpParams();
    Object.entries(contraventionPagedSearch).forEach((pair: [string, any]) => {
      params = params.append(pair[0], pair[1]);
    });
    
    return this.httpClient.get(`${this.apiUrl}/contraventions/findall`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params
    }).pipe(
        map(response => new PagedResult(response))
      );
  }

  getContraventionByNumber(contraventionNumber: string): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/contraventions/find`, {
      params: {
        contraventionNumber: contraventionNumber
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    })
      .pipe(
        map(response => new Contravention(response))
      );
  }

  getStopInformation(contraventionNumber: string): Observable<StopInformation> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get<StopInformation>(`${this.apiUrl}/contraventions/stop-information/${contraventionNumber}`,
    {
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new StopInformation(response))
    );    
  }

  getStopInformationById(stopInformationId: number): Observable<StopInformation> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get<StopInformation>(`${this.apiUrl}/contraventions/stop-information/id/${stopInformationId}`,
    {
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new StopInformation(response))
    );    
  }
 
  getContraventionById(contraventionId: number): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/contraventions/find`, {
        params: {
          contraventionId: contraventionId.toString()
        },
        headers: {'Authorization': `Bearer ${this.user.token}`}
      })
      .pipe(        
          map(response => new Contravention(response))
      );
  }

  cancelContravention(contravention: Contravention): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/contraventions/cancel`, contravention, 
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  setContraventionAsUncollectable(contraventionNumber: string): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/contraventions/${contraventionNumber}/uncollectable`, null, 
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  updateContraventionIssuanceDate(contraventionNumber: string, issuanceDate: string): any {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/contraventions/${contraventionNumber}/issuancedate/${issuanceDate}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    });
  }

  updateContraventionOccurrenceDate(contraventionNumber: string, occurrenceDate: string): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/contraventions/${contraventionNumber}/occurrencedate/${occurrenceDate}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  updateContraventionOccurrenceTime(contraventionNumber: string, occurrenceTime: string): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/contraventions/${contraventionNumber}/occurrencetime/${occurrenceTime}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  updateContraventionPoliceFileNumber(contraventionNumber: string, policeFileNumber: string): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/contraventions/${contraventionNumber}/policefilenumber/${policeFileNumber}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  updateContraventionRecipientIdentification(contraventionNumber: string, recipientIdentification: RecipientIdentification): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/contraventions/${contraventionNumber}/recipientidentification`, recipientIdentification,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  updateContraventionRecipientContact(contraventionNumber: string, recipient: Recipient): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/contraventions/${contraventionNumber}/recipient`, recipient,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  updateContraventionPaymentInformation(contraventionNumber: string, paymentDataEditRequest: PaymentDataEditRequest): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/contraventions/${contraventionNumber}/edit-payment`, paymentDataEditRequest,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  //Vehicle Seizures
  getSeizureByNumber(seizureNumber: string): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/vehicleseizures/find`, {
      params: {
        SeizureNumber: seizureNumber
      }, 
      headers: {'Authorization': `Bearer ${this.user.token}`} 
    })
      .pipe(
        map(response => new VehicleSeizure(response))
      );
  }

  updateVehicleSeizureDate(seizureNumber: string, seizureDate: string): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/vehicleseizures/${seizureNumber}/seizuredate/${seizureDate}`, null,
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  updateVehicleSeizureTime(seizureNumber: string, seizureTime: string): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/vehicleseizures/${seizureNumber}/seizuretime/${seizureTime}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  updateVehicleSeizurePoliceFileNumber(seizureNumber: string, policeFileNumber: string): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/vehicleseizures/${seizureNumber}/policefilenumber/${policeFileNumber}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  updateVehicleSeizureTowLotInfo(vehicleSeizure: VehicleSeizure): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/${vehicleSeizure.seizureNumber}/towlot`, vehicleSeizure,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  updateVehicleSeizureRecipientIdentification(seizureNumber: string, recipientIdentification: RecipientIdentification): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/${seizureNumber}/recipientidentification`, recipientIdentification,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  updateVehicleSeizureRecipientContact(seizureNumber: string, recipient: Recipient): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/${seizureNumber}/recipient`, recipient,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  updateVehicleSeizureRegisteredOwner(seizureNumber: string, registeredOwner: RegisteredOwner): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/${seizureNumber}/registeredOwner`, registeredOwner,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  cancelVehicleSeizure(seizure: VehicleSeizure): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/cancel`, seizure, 
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  //Reviews
  getReviews(): Observable<ReviewView[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/reviews`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => {
          if (Array.isArray(response)) {
            return (Array.from<ReviewView>(response));
          }
          
          return [new ReviewView(response)];
        })
      );
  }

  getReviewsByPagedSearch(reviewPagedSearch: ReviewPagedSearch): Observable<PagedResult> {
    this.user = this.localStorageService.getUser();
    let params = new HttpParams();
    Object.entries(reviewPagedSearch).forEach((pair: [string, any]) => {
      params = params.append(pair[0], pair[1]);
    });
    
    return this.httpClient.get(`${this.apiUrl}/reviews/findall`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params
    }).pipe(
        map(response => new PagedResult(response))
      );
  }


  getReviewByNumber(reviewNumber: string): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/reviews/${reviewNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => {
          if (Array.isArray(response)) {
            return response.find(review => +review.reviewNumber === +reviewNumber);
          }

          return new Review(response);
        })
      );
  }

  postReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/reviews`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new Review(response))
      );
  }

  putReview(review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new Review(response)));
  }

  putReviewStatus(review: any): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/reviewstatus`, review, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }

  updateReviewRepresentationInformation(reviewNumber: string, review: Review): Observable<Review> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/reviews/${reviewNumber}/representationinformation`, review,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Review(response))
    );
  }

  // Document and File related methods
  uploadFile(file: File, tmpFileFolder: string, documentRefTypeName: string,  documentRefTypeNumber: string, newFileName: string): Observable<any> {
    let formData = new FormData();
    formData.append("file", file, file.name);
    this.user = this.localStorageService.getUser();
    
    return this.httpClient.post(`${this.apiUrl}/file`, formData, 
      {        
        observe: "events", 
        reportProgress: true,
        params:{
          "tmpFileFolder": tmpFileFolder,
          "documentRefTypeName": documentRefTypeName,
          "documentRefTypeNumber": documentRefTypeNumber,
          "newFileName": newFileName
        },
        headers: {'Authorization': `Bearer ${this.user.token}`}
      }
    );
  }

  downloadFile(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string, storageFileName: string, applicationFileName: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/file`, {
        params: {
            "tempFileFolder": tempFileFolder,
            "documentRefTypeName": documentRefTypeName,
            "documentRefTypeNumber": documentRefTypeNumber,
            "storageFileName": storageFileName,
            "applicationFileName": applicationFileName
        },
        headers: {'Authorization': `Bearer ${this.user.token}`},
        responseType: 'blob'
      });
  }

  deleteFile(fileName: string, tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/file`, {
      params: {
        "fileName": fileName,
        "tempFileFolder": tempFileFolder,
        "documentRefTypeName": documentRefTypeName,
        "documentRefTypeNumber": documentRefTypeNumber
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}  
    });
  }

  public updateDocumentAsync(document: Document): Observable<any> {
      return this.put('document', {document});
  }

  put(action: string, body: Object = {}): Observable<any> {
      const httpOptions = {
          headers: new HttpHeaders(
              {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${this.user.token}`
              })
        }

      for (var key in body) {
          if (!Object.prototype.hasOwnProperty.call(body, key)) continue;
          body = body[key];
          break;
      }
      var newJson = JSON.stringify(body);
      
      return this.httpClient
        .put(this.apiUrl + '/' + action, newJson, httpOptions)
        .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any) {
    return throwError(error);
  }  
 
  postEvent(event: Event): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/events`, event, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }

  postEvents(events: Event[]): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/events/list`, events, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }

  extendContraventionReviewRequestDeadline(contraventionId: number, eventDetails: string): Observable<Contravention> {    
    return this.httpClient.put(`${this.apiUrl}/contraventions/extendreviewrequestdeadline`, null, 
      { 
        params: { 
          "contraventionId": contraventionId.toString(),
          "eventDetails": eventDetails
        }
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  extendVehicleSeizureReviewRequestDeadline(vehicleSeizureId: number, eventDetails: string): Observable<VehicleSeizure> {    
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/extendreviewrequestdeadline`, null, 
      { 
        params: { "vehicleSeizureId": vehicleSeizureId.toString(),
          "eventDetails": eventDetails
        }
      }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  enableContraventionLateReview(contraventionId: number, reason: string): Observable<Contravention> {
    return this.httpClient.put(`${this.apiUrl}/contraventions/late-review/enable`, null, 
      { 
        params: { 
          "contraventionId": contraventionId.toString(),
          "reason": reason
        }
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  enableVehicleSeizureLateReview(vehicleSeizureId: number, reason: string): Observable<VehicleSeizure> {
    return this.httpClient.put(`${this.apiUrl}/vehicleseizures/late-review/enable`, null, 
      { 
        params: { 
          "vehicleSeizureId": vehicleSeizureId.toString(),
          "reason": reason
        }
    }).pipe(
      map(response => new VehicleSeizure(response))
    );
  }

  enableContraventionReview(contraventionNumber: string, reason: string): Observable<Contravention> {
    return this.httpClient.put(`${this.apiUrl}/contraventions/${contraventionNumber}/review/enable`, null, 
      { 
        params: { 
          "reason": reason
        }
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  downloadContravetionsExcelExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/exportexcel`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }

  downloadContraventionsCSVExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/exportcsv`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }

  downloadReviewsCSVExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/reviews/exportcsv`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }

  downloadJudicialReviewsCSVExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/judicial-reviews/exportcsv`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }

  getFinePaymentReceipt(contraventionNumber: string, transactionId: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/contraventions/${contraventionNumber}/payments/${transactionId}/receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  refundFinePayment(contraventionNumber: string, transactionId: string): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/contraventions/${contraventionNumber}/payments/${transactionId}/refund`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new Contravention(response))
    );
  }

  getJudicialReviewsByPagedSearch(judicialReviewPagedSearch: JudicialReviewPagedSearch): Observable<PagedResult> {
    this.user = this.localStorageService.getUser();
    let params = new HttpParams();
    Object.entries(judicialReviewPagedSearch).forEach((pair: [string, any]) => {
      params = params.append(pair[0], pair[1]);
    });
    
    return this.httpClient.get(`${this.apiUrl}/judicial-reviews/findall`, { 
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: params
    }).pipe(
        map(response => new PagedResult(response))
      );
  }

  getJudicialReviewByNumber(judicialReviewNumber: string): Observable<JudicialReview> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/judicial-reviews/${judicialReviewNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => {
          if (Array.isArray(response)) {
            return response.find(judicialReview => +judicialReview.judicialReviewNumber === +judicialReviewNumber);
          }

          return new JudicialReview(response);
        })
      );
  }

  postJudicialReview(judicialReview: JudicialReview): Observable<JudicialReview> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/judicial-reviews`, judicialReview, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new JudicialReview(response))
      );
  }

  postDocument(judicialReviewId: number, document: Document): Observable<JudicialReview> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/judicial-reviews/document`, document,
      {
        params: {
          judicialReviewId: judicialReviewId.toString()
        },
        headers: {'Authorization': `Bearer ${this.user.token}`}
      }).pipe(
        map(response => new JudicialReview(response)));
  }

  deleteDocument(judicialReviewId: number, documentId: number): Observable<JudicialReview> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/judicial-reviews/document`, {
      params: {
        judicialReviewId: judicialReviewId.toString(),
        documentId: documentId.toString()
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new JudicialReview(response)));
  }

  postStayOrderDocument(stayOrderId: number, document: Document): Observable<StayOrder> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/stay-orders/document`, document,
      {
        params: {
          stayOrderId: stayOrderId.toString()
        },
        headers: {'Authorization': `Bearer ${this.user.token}`}
      }).pipe(
        map(response => new StayOrder(response)));
  }

  deleteStayOrderDocument(stayOrderId: number, documentId: number): Observable<StayOrder> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/stay-orders/document`, {
      params: {
        stayOrderId: stayOrderId.toString(),
        documentId: documentId.toString()
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => new StayOrder(response)));
  }

  updateJudicialReviewDecision(judicialReview: any): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/judicial-reviews/judicial-review-decision`, judicialReview, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }

  addReReview(judicialReview: any): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/judicial-reviews/re-review`, judicialReview, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => response));
  }

  addStayOrder(stayOrder: StayOrder): Observable<JudicialReview> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/stay-orders`, stayOrder, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new JudicialReview(response))
      );
  }

  putStayOrder(stayOrder: StayOrder): Observable<JudicialReview> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/stay-orders`, stayOrder, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new JudicialReview(response)));
  }
  
  getSeizureById(vehicleSeizureId: number): Observable<VehicleSeizure> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/vehicleseizures/find`, {
      params: {
        VehicleSeizureId: vehicleSeizureId.toString()
      },
      headers: { 'Authorization': `Bearer ${this.user.token}` }
    })
      .pipe(
        map(response => new VehicleSeizure(response))
      );
  }

  downloadDocumentsPackage(documents: Document[], urls: any[], submissionDate: string, termsAndConditionsDate: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    var body = {
      documents: documents,
      urls: urls
    };

    return this.httpClient.post(`${this.apiUrl}/judicial-reviews/download-documents-package`, body, {
      params: {
        termsAndConditionsDate: termsAndConditionsDate,
        submissionDate: submissionDate
      },
      headers: { 'Authorization': `Bearer ${this.user.token}` },
      responseType: 'blob'
    });
  }

  addDownloadPackageEvent(judicialReview: any): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/judicial-reviews/judicial-review-event`, judicialReview, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
      .pipe(
        map(response => response));
  }

  logRefund(logRefundRequest: LogRefundRequest): Observable<Contravention> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/contraventions/${logRefundRequest.recordNumber}/payments/${logRefundRequest.sourceTransactionId}/log-refund`,
     logRefundRequest, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
      .pipe(
        map(response => new Contravention(response)));
  }
}
