
import { DeclarationType } from './../../../../../../../libs/shared/models/types/declaration-type.model';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { DocumentTypes, DriverDeclinedToTestTypes, HighwayCharacteristicsTypes, IdentificationTypes, OfficerTransportationTypes, OfficerUniformTypes, RecipientTypes, RefusalOrFailedClassification, ReviewTypes, RoadConditionTypes, RoadSurfaceTypes, SampleClassification, TestTypes, VisibilityConditionTypes, WeatherConditionTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Review } from '@apis/shared/models/review.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Constants } from "@apis/shared/helpers/constants";
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import { MovesVehicle } from '@apis/shared/models/moves-vehicle.model';
import { TimePipe } from '@apis/shared/pipes/time.pipe';

@Component({
  selector: 'law-enforcement-submissions',
  templateUrl: './law-enforcement-submissions.component.html',
  styleUrls: ['./law-enforcement-submissions.component.scss']
})
export class LawEnforcementSubmissionsComponent implements OnInit, OnChanges {
  @Input() review: Review;
  @Input() contravention: Contravention;
  @Input() vehicleSeizure: VehicleSeizure;
  @Input() applicantName: string;
  @Input() stopInformation: StopInformation;

  recipient: Recipient;
  vehicle: Vehicle;
  declarationTypes: DeclarationType[];
  movesVehicle: MovesVehicle;
  registeredOwnerName: string;
  contraventionTypeName: string;
  vehicleSeizureTypeName: string;

  detachmentName: string;
  policeOfficerName: string;
  policeFileNumber: string;
  regimentalNumber: string;
  agencyName: string;
  recipientType: string;
  location: string = "";

  isThirdPartySeizure: boolean;
  isContraventionReview: boolean;
  supportingDocumentDeclarations: SectionDetailsModel[] = [];
  showSupportingDocumentDeclarations: boolean;
  isSDPReview: boolean;
  isBodycamVideoActive: boolean = false;
  isChangeDisplayedInformationActive: boolean = false;
  seizureDateTime: Date;
  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;

  recipientInformation: SectionDetailsModel[] = [];
  recipientContactInformation: SectionDetailsModel[] = [];
  recipientMinimumInformation: SectionDetailsModel[] = [];
  movesRecipientContactInformation: SectionDetailsModel[] = [];
  contraventionType: SectionDetailsModel[] = [];
  stopInformationSection: SectionDetailsModel[] = [];
  witnessInformation: SectionDetailsModel[] = [];
  contraventionGrounds: SectionDetailsModel[] = [];
  impairmentScreeningInformation: SectionDetailsModel[] = [];
  impairmentScreeningDetails: SectionDetailsModel[] = [];
  additionalScreeningInformation: SectionDetailsModel[][] = [];
  reasonableGrounds: SectionDetailsModel[] = [];
  policeNarrativeDetails: SectionDetailsModel[] = [];
  roadsideAppealInformation: SectionDetailsModel[] = [];
  roadsideAppealDetails: SectionDetailsModel[] = [];
  declarations: SectionDetailsModel[] = [];
  reServe: SectionDetailsModel[] = [];
  vehicleSeizureInformation: SectionDetailsModel[] = [];
  occurrenceInformation: SectionDetailsModel[] = [];
  locationConditions: SectionDetailsModel[] = [];
  stopInformationDetails: SectionDetailsModel[] = [];
  officerInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  trailerInformation: SectionDetailsModel[] = [];
  movesVehicleInformation: SectionDetailsModel[] = [];
  registeredOwnerInformation: SectionDetailsModel[] = [];

  isPhase2Submission: boolean = false;
  RecipientTypes = RecipientTypes;
  timePipe: TimePipe;
  showAdditionalConditionsMessage: boolean = false;

  @ViewChild("supportingDocumentsDiv") supportingDocumentsDiv: ElementRef;

  constructor(private readonly localStorageService: LocalStorageService,
    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.declarationTypes = this.localStorageService.getDeclarationTypes();
    this.timePipe = new TimePipe();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.contravention?.currentValue != null) {
      this.recipient = this.contravention.recipient;
      this.contraventionTypeName = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.formattedName
      this.policeOfficerName = this.contravention.policeOfficerFullName;
      this.policeFileNumber = this.contravention.policeFileNumber;
      this.isPhase2Submission = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE2;

      if (this.contravention.policeServiceInformation?.length > 0) {
        this.detachmentName = this.contravention.policeServiceInformation[0].detachment?.detachmentName;
        this.agencyName = this.contravention.policeServiceInformation[0].detachment?.agency?.agencyName;
        this.regimentalNumber = this.contravention.policeServiceInformation[0].regimentalNumber;
      }

      this.populateContraventionInformation();
    }

    if (simpleChanges.vehicleSeizure?.currentValue != null) {
      if (this.vehicleSeizure.seizureTypeId == 1) { //Part of Contravention    
        this.recipient = this.contravention?.recipient;
      }
      else {
        this.recipient = this.vehicleSeizure?.recipient;
      }
      this.vehicle = this.vehicleSeizure?.vehicle;

      this.vehicleSeizureTypeName = this.localStorageService.getTypeItemDescriptionById(this.vehicleSeizure.seizureTypeId, TypeTable.SeizureType);
      let dateOnly = new DatePipe("en-US").transform(this.vehicleSeizure.seizureDate.toString(), "yyyy-MM-dd");
      this.seizureDateTime = new Date(`${dateOnly} ${this.vehicleSeizure.seizureTime}`);
      this.policeOfficerName = this.vehicleSeizure.policeOfficerFullName;
      this.policeFileNumber = this.vehicleSeizure.policeFileNumber;

      if (this.vehicleSeizure.policeServiceInformation?.length > 0) {
        this.detachmentName = this.vehicleSeizure.policeServiceInformation[0].detachment?.detachmentName;
        this.agencyName = this.vehicleSeizure.policeServiceInformation[0].detachment?.agency?.agencyName;
        this.regimentalNumber = this.vehicleSeizure.policeServiceInformation[0].regimentalNumber;
      }

      this.populateSeizureInformation();
    }

    if (simpleChanges.review?.currentValue != null) {
      this.isThirdPartySeizure = this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview;
      this.isContraventionReview = this.review.reviewTypeId == ReviewTypes.IrsReview;
      this.isSDPReview = this.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview;
    }

    if (this.isThirdPartySeizure) {
      this.registeredOwnerName = `${this.review.registeredOwnerFirstName ?? ""} ${this.review.registeredOwnerLastName}`;
    }

    if (simpleChanges.stopInformation?.currentValue != null) {
      this.populateStopInformation();
    }

    if (this.isContraventionReview && this.contravention) {
      this.isChangeDisplayedInformationActive = new Date(this.contravention.submissionDate) >= new Date(+Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(0, 4), +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(8, 10));
    }
    else if ((this.isSDPReview || this.isThirdPartySeizure) && this.vehicleSeizure) {
      this.isChangeDisplayedInformationActive = new Date(this.vehicleSeizure.submissionDate) >= new Date(+Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(0, 4), +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Adjudication.CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE.substring(8, 10));
    }

    if (this.isChangeDisplayedInformationActive) {
      this.filterDocuments();
    }
  }

  populateSeizureInformation() {
    this.populateCommonInformation();
    //Seizure Information
    this.location = this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure.locationTypeId)?.name;

    this.vehicleSeizureInformation = [];
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Number', this.vehicleSeizure.seizureNumber));
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Type', this.localStorageService.getContraventionTypes().find(x => x.id == this.vehicleSeizure.contraventionTypeId)?.formattedName ?? this.vehicleSeizureTypeName));
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.vehicleSeizure.seizureDate, 'MMM dd, yyyy')));
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Time', this.vehicleSeizure.seizureTime.substring(0, 5)));
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Immediate Seizure Duration', `${this.vehicleSeizure.seizureDuration} ${this.vehicleSeizure.seizureDurationType}`));
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.vehicleSeizure.vehicleReleaseDate, 'MMM dd, yyyy')));
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Location of Seizure', this.location));

    //Additional location details
    var additionalLocationDetails = this.vehicleSeizure.additionalLocationDetails?.trim();
    if (additionalLocationDetails == null || additionalLocationDetails == '') {
      additionalLocationDetails = this.vehicleSeizure.occurrenceLocation?.additionalLocationDetails?.trim();
    }
    this.vehicleSeizureInformation.push(new SectionDetailsModel('Additional Location Information', additionalLocationDetails, true));

    //Police Narrative Details
    var policeNarrative = this.vehicleSeizure.additionalNotes;
    if (this.contravention != null && (policeNarrative == null || policeNarrative?.trim() == '')) {
      policeNarrative = this.contravention.additionalNotes;
    }

    this.policeNarrativeDetails = [];
    this.policeNarrativeDetails.push(new SectionDetailsModel('', policeNarrative, true));

    var declarationTypes = this.localStorageService.getDeclarationTypes();

    var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.vehicleSeizure.officerSubmissionDeclarationTypeId).description

    //Declarations
    this.declarations = [];
    this.declarations.push(new SectionDetailsModel('Full Name of Officer', this.vehicleSeizure.policeOfficerFullName));
    this.declarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.vehicleSeizure.submissionDate, 'dd MMM, yyyy')));
    this.declarations.push(new SectionDetailsModel(officerSubmissionDeclaration, this.vehicleSeizure.isTrueInformation ? "Yes" : "No", true));

    if (this.vehicleSeizure.officerAdditionalDetailsDeclarationTypeId) {
      const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.vehicleSeizure.officerAdditionalDetailsDeclarationTypeId).description
      this.declarations.push(new SectionDetailsModel('Full Name of Officer', this.vehicleSeizure.policeOfficerFullName));
      this.declarations.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.vehicleSeizure.additionalDetailsDate, 'dd MMM, yyyy')));
      this.declarations.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
    }

    // Supporting Document Declarations

    this.vehicleSeizure.policeServiceInformation.forEach(p => {
      if (p.declarationInformation != null) {
        var declaration = this.declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
        this.supportingDocumentDeclarations.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
        this.supportingDocumentDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
        this.supportingDocumentDeclarations.push(new SectionDetailsModel(declaration.description, 'Yes', true));
        this.showSupportingDocumentDeclarations = true;
      }
    });

    this.populateVehicleInformation();
  }

  populateContraventionInformation() {
    this.populateCommonInformation();
    // Contravention Information Section
    var contraventionType = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId);
    
    this.contraventionType.push(new SectionDetailsModel('Contravention Type', contraventionType?.formattedChargeCode));
    this.contraventionType.push(new SectionDetailsModel('Contravention Penalty Amount', this.contravention?.fine?.fineAmount > 0 ? `$${this.contravention?.fine?.fineAmount.toString()} + $${this.contravention?.fine?.victimFineSurchargeAmount.toString()} VFS` : `$${this.contravention?.fine?.fineAmount.toString()}`));
    if (this.contravention.contraventionTypeId == 1 || this.contravention.contraventionTypeId == 9 || this.contravention.contraventionTypeId == 10 || this.contravention.contraventionTypeId == 11)
      this.contraventionType.push(new SectionDetailsModel('Contravention Details', Array.prototype.map.call(this.localStorageService.getContraventionDetailTypes().filter(x => this.contravention.contraventionDetails.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ")));
    else
      this.contraventionType.push(new SectionDetailsModel('Contravention Details', this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.description, true));

    if (contraventionType?.isIRS) {
      this.showAdditionalConditionsMessage = true;
      this.contraventionType.push(new SectionDetailsModel('Immediate Licence Suspension', `${this.contravention.suspensionDuration} ${this.contravention.suspensionDurationType}` + (this.contravention.additionalSuspension ? " + " + this.contravention.additionalSuspension : "")));
      this.contraventionType.push(new SectionDetailsModel('Licence Seized', this.contravention.isLicenceSeized ? 'Yes' : 'No'));
      this.contraventionType.push(new SectionDetailsModel('Licence Destroyed', this.contravention.isLicenceDestroyed ? 'Yes' : 'No'));

      if (this.contravention.returnLicenceByMail) {
        this.contraventionType.push(new SectionDetailsModel('Licence Pick-Up', Constants.Resource.RETURN_LICENCE_BY_MAIL_TEXT, true));
      }
      else if (this.contravention.licencePickupAddress) {
        const licencePickupLocation = this.contravention.licencePickupDetachment + ': ' + this.contravention.licencePickupAddress;
        this.contraventionType.push(new SectionDetailsModel('Licence Pick-Up', licencePickupLocation, true));
      }

      if (this.contravention.remedialEducation)
        this.contraventionType.push(new SectionDetailsModel('Remedial Education', this.contravention.remedialEducation));
    }

    //Impairment Screening Information
    //Contravention Grounds
    this.contraventionGrounds.push(new SectionDetailsModel('Suspected Impairment Type', this.localStorageService.getSuspectedImpairmentTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.suspectedImpairmentTypeId)?.name));
    this.contraventionGrounds.push(new SectionDetailsModel('Was a Screening Method Used?', this.localStorageService.getContraventionGroundsTypes().find(x => x.id == this.contravention.contraventionGroundsTypeId)?.name, true));

    if (this.contravention.contraventionGroundsTypeId == 1) //Impairment Screening Information
    {
      this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion or Mas?', this.localStorageService.getImpairmentScreeningTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId)?.name));

      if (this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 1) //Reasonable Suspicion
      {
        this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion Based On', Array.prototype.map.call(this.localStorageService.getSuspicionTypes().filter(x => this.contravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes("5") ? "-" + this.contravention.impairmentScreeningInformation?.suspicionOther : "")));
      }

      this.impairmentScreeningInformation.push(new SectionDetailsModel('Impairment Screening Method', this.localStorageService.getTestTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.impairmentTests[0]?.testTypeId)?.name));

      this.DisplayTestResults(this.contravention.impairmentScreeningInformation?.impairmentTests[0], this.impairmentScreeningDetails, false);
    }
    else if (this.contravention.contraventionGroundsTypeId == 2) // Reasonable Grounds
    {
      this.reasonableGrounds.push(new SectionDetailsModel('Describe in Detail What Led to Your Grounds', this.contravention.reasonableGroundsDetails, true));
      this.reasonableGrounds.push(new SectionDetailsModel('Evidence of Consumption', this.contravention.consumptionEvidence, true));
    }

    if (this.contravention.isReasonableGroundsToBelieve) {
      this.reasonableGrounds.push(new SectionDetailsModel('By checking this box, the undersigned officer has reasonable grounds to believe that the recipient named in this Notice of Administrative Penalty did contravene the section of the Traffic Safety Act specified herein', "Yes", true));
    }

    this.contravention.impairmentScreeningInformation?.impairmentTests.forEach((test, index) => {
      if (test.testSequence != 0) //Avoid first test as that is impairment screening test
      {
        var sectionDtails: SectionDetailsModel[] = [];
        this.DisplayTestResults(test, sectionDtails, true);
        this.additionalScreeningInformation.push(sectionDtails);
      }
    });

    if (this.contravention?.roadsideAppeal) {
      this.roadsideAppealInformation.push(new SectionDetailsModel('Was Recipient Presented with Roadside Appeal Information?', this.contravention.roadsideAppeal?.isRecipientPresentedWithInformation ? "Yes" : "No"));
      if (!this.contravention.roadsideAppeal?.isRecipientPresentedWithInformation)
        this.roadsideAppealInformation.push(new SectionDetailsModel('If No, Explain', this.contravention.roadsideAppeal?.appealOptionsNotPresentedReason, true));

      this.roadsideAppealInformation.push(new SectionDetailsModel('Did Driver Request a Roadside Appeal?', this.contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal ? "Yes" : "No"));

      if (this.contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal && this.contravention.roadsideAppeal?.roadsideTests?.length > 0) {
        this.roadsideAppealInformation.push(new SectionDetailsModel('Roadside Appeal Method', this.localStorageService.getTestTypes().find(x => x.id == this.contravention.roadsideAppeal?.roadsideTests[0].testTypeId)?.name));

        if (this.contravention.roadsideAppeal?.roadsideTests[0].testTypeId == 8) //No Appeal Provided
          this.roadsideAppealInformation.push(new SectionDetailsModel('If Appeal Was Requested But Not Provided, Explain Why Not', this.contravention.roadsideAppeal?.roadsideTests[0].appealNotProvidedReason, true));

        this.DisplayTestResults(this.contravention.roadsideAppeal?.roadsideTests[0], this.roadsideAppealDetails, false, true);
      }
    }
    
    var declarationTypes = this.localStorageService.getDeclarationTypes();

    var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.contravention.officerSubmissionDeclarationTypeId).description;

    //Declarations
    this.declarations = [];
    this.declarations.push(new SectionDetailsModel('Full Name of Officer', this.contravention.policeOfficerFullName));
    this.declarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.contravention.submissionDate, 'dd MMM, yyyy')));
    this.declarations.push(new SectionDetailsModel(officerSubmissionDeclaration, this.contravention.isTrueInformation ? "Yes" : "No", true));

    if (this.contravention.officerAdditionalDetailsDeclarationTypeId) {
      const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.contravention.officerAdditionalDetailsDeclarationTypeId).description;
      this.declarations.push(new SectionDetailsModel('Full Name of Officer', this.contravention.policeOfficerFullName));
      this.declarations.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.contravention.additionalDetailsDate, 'dd MMM, yyyy')));
      this.declarations.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
    }

    // Supporting Document Declarations

    this.contravention.policeServiceInformation.forEach(p => {
      if (p.declarationInformation != null) {
        var declaration = this.declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
        this.supportingDocumentDeclarations.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
        this.supportingDocumentDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
        this.supportingDocumentDeclarations.push(new SectionDetailsModel(declaration.description, 'Yes', true));
        this.showSupportingDocumentDeclarations = true;
      }
    });

    //Re-Serve
    if (this.contravention.isReServeRequired == true) {
      this.reServe = [];
      let reServeMethodTypeName = this.localStorageService.getTypeItemDescriptionById(this.contravention.reServeMethodTypeId, TypeTable.ReServeMethodType);
      this.reServe.push(new SectionDetailsModel('Is Re-Service Required?', "Yes"));
      this.reServe.push(new SectionDetailsModel('Re-Service Date', this.datePipe.transform(this.contravention.reServeDate, "mediumDate")));
      this.reServe.push(new SectionDetailsModel("Re-Service Method", reServeMethodTypeName));
      this.reServe.push(new SectionDetailsModel("Reason for Re-Service", this.contravention.reServeReason, true));
    }

    //IRS Seizure Information
    if (!this.contravention.isNoVehicleSeizureMade) {
      this.location = this.localStorageService.getLocationTypes().find(x => x.id == this.contravention.vehicleSeizure.locationTypeId)?.name;

      this.vehicleSeizureInformation = [];
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Number', this.contravention.vehicleSeizure.seizureNumber));
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Type', this.contraventionTypeName));
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.contravention.vehicleSeizure.seizureDate, 'MMM dd, yyyy')));
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Seizure Time', this.contravention.vehicleSeizure.seizureTime.substring(0, 5)));
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Immediate Seizure Duration', `${this.contravention.vehicleSeizure.seizureDuration} ${this.contravention.vehicleSeizure.seizureDurationType}`));
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.contravention.vehicleSeizure.vehicleReleaseDate, 'MMM dd, yyyy')));
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Location of Seizure', this.location));

      //Additional location details
      var additionalLocationDetails = this.contravention.vehicleSeizure.additionalLocationDetails?.trim();
      if (additionalLocationDetails == null || additionalLocationDetails == '') {
        additionalLocationDetails = this.contravention.vehicleSeizure.occurrenceLocation?.additionalLocationDetails?.trim();
      }

      // Occurrence Information      
      if (this.contravention.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE2) {
        this.vehicleSeizureInformation.push(new SectionDetailsModel('Address or Highway Number', this.contravention.vehicleSeizure.occurrenceLocation?.addressOrHighwayNumber));
        this.vehicleSeizureInformation.push(new SectionDetailsModel('GPS Coordinates', `${this.contravention.vehicleSeizure.occurrenceLocation?.latitude ?? ""} ${this.contravention.vehicleSeizure.occurrenceLocation?.longitude ?? ""}`));
        this.vehicleSeizureInformation.push(new SectionDetailsModel('At Intersection of', this.contravention.vehicleSeizure.occurrenceLocation?.atStreet));
        this.vehicleSeizureInformation.push(new SectionDetailsModel('Relative location', `${this.contravention.vehicleSeizure.occurrenceLocation?.directionTypeId ? this.localStorageService.getDirectionTypes().find(x => x.id == this.contravention.vehicleSeizure.occurrenceLocation?.directionTypeId)?.name + " of " : ""} ${this.contravention.vehicleSeizure.occurrenceLocation?.relativeLocation ?? ""}`));
      }
      this.vehicleSeizureInformation.push(new SectionDetailsModel('Additional Location Information', additionalLocationDetails, true));

      //Police Narrative Details
      var policeNarrative = this.contravention.vehicleSeizure.additionalNotes;
      if (this.contravention != null && (policeNarrative == null || policeNarrative == '')) {
        policeNarrative = this.contravention.additionalNotes;
      }

      this.policeNarrativeDetails = [];
      this.policeNarrativeDetails.push(new SectionDetailsModel('', policeNarrative, true));
    }
  }

  populateCommonInformation() {
    // Populate Recipient information section
    this.recipientInformation = [];
    this.recipientContactInformation = [];
    this.recipientMinimumInformation = [];
    switch (+this.recipient?.recipientTypeId) {
      case RecipientTypes.Driver:
        this.recipientType = "Driver";
        break;
      case RecipientTypes.RegisteredOwner:
        this.recipientType = "Registered Owner";
        break;
      case RecipientTypes.Passenger:
        this.recipientType = "Passenger";
        break;
      case RecipientTypes.NotInVehicle:
        this.recipientType = "Not In Vehicle";
        break;
    }

    this.recipientInformation.push(new SectionDetailsModel('Name', `${this.recipient?.firstName ?? ""} ${this.recipient?.lastName}`));
    this.recipientInformation.push(new SectionDetailsModel('Recipient Type', this.recipientType));
    if (this.recipient?.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence)
      this.recipientInformation.push(new SectionDetailsModel("Identity verified by", "Driver's Licence"));
    else {
      this.recipientInformation.push(new SectionDetailsModel("Identity verified by", this.localStorageService.getIdentificationTypes().find(x => x.id == this.recipient?.recipientIdentification.identificationTypeId)?.name));
      if (this.recipient?.recipientIdentification.identificationTypeId == IdentificationTypes.Other)
        this.recipientInformation.push(new SectionDetailsModel("Identification Type", this.recipient?.recipientIdentification?.identificationOther));
    }
    if (this.recipient?.recipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
      this.recipientInformation.push(new SectionDetailsModel('ID Number', this.recipient?.recipientIdentification?.identificationNumber));
      if (this.recipient?.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
        this.recipientInformation.push(new SectionDetailsModel('MVID', this.recipient?.recipientIdentification?.motorVehicleIdentificationNumber));
      }
      this.recipientInformation.push(new SectionDetailsModel('Issuing Country', this.recipient?.recipientIdentification?.issuingCountryId == null ? "" : this.localStorageService.getCountryTypes().find(x => x.id == this.recipient?.recipientIdentification?.issuingCountryId)?.name));
      this.recipientInformation.push(new SectionDetailsModel('Issuing State/Province', (this.recipient?.recipientIdentification?.issuingCountryId == 40 || this.recipient?.recipientIdentification?.issuingCountryId == 236) ? (this.recipient?.recipientIdentification?.issuingProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient?.recipientIdentification?.issuingProvinceId)?.name) : this.recipient?.recipientIdentification?.issuingProvinceOther));
    }

    if (this.recipient?.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
      this.recipientInformation.push(new SectionDetailsModel('Licence Class', this.recipient?.recipientIdentification?.driversLicenceClassTypeId == null ? this.recipient?.recipientIdentification?.driversLicenceClass : this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.recipient?.recipientIdentification?.driversLicenceClassTypeId)?.name));
      this.recipientInformation.push(new SectionDetailsModel('Licence Status', this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.recipient?.recipientIdentification?.driversLicenceStatusTypeId)?.name));
      this.recipientInformation.push(new SectionDetailsModel('Licence Expiry', this.datePipe.transform(this.recipient?.recipientIdentification?.identificationExpiryDate, 'MMM dd, yyyy')));
      if (this.recipient?.recipientIdentification?.isGDLDriver != null) {
        this.recipientInformation.push(new SectionDetailsModel('Is GDL/Novice Driver', this.recipient?.recipientIdentification?.isGDLDriver ? "Yes" : "No"));
      }
    }

    if (this.recipient?.recipientIdentification?.identificationTypeId != IdentificationTypes.DriversLicence && this.recipient?.recipientIdentification?.identificationTypeId != IdentificationTypes.Verbal) {
      this.recipientInformation.push(new SectionDetailsModel('Expiry Date', this.datePipe.transform(this.recipient?.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
    }

    this.recipientInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient?.recipientIdentification?.dateOfBirth, 'MMM dd, yyyy')));
    this.recipientInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.recipient?.recipientIdentification?.genderId)?.name));
    this.recipientInformation.push(new SectionDetailsModel('Condition Codes', Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.recipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join("")));
    this.recipientInformation.push(new SectionDetailsModel('Eye Color', this.recipient?.eyeColor));
    this.recipientInformation.push(new SectionDetailsModel('Hair Color', this.recipient?.hairColor));
    this.recipientInformation.push(new SectionDetailsModel('Weight (kg)', this.recipient?.weight));
    this.recipientInformation.push(new SectionDetailsModel('Height (cm)', this.recipient?.height));
    this.recipientInformation.push(new SectionDetailsModel('Restrictions', this.recipient?.recipientIdentification?.restrictions, true));

    this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient?.recipientContact?.addressLine1));
    this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient?.recipientContact?.addressLine2));
    this.recipientContactInformation.push(new SectionDetailsModel('Province', (this.recipient?.recipientContact?.countryId == 40 || this.recipient?.recipientContact?.countryId == 236) ? (this.recipient?.recipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient?.recipientContact?.provinceId)?.name) : this.recipient?.recipientContact?.province));
    this.recipientContactInformation.push(new SectionDetailsModel('Country', this.recipient?.recipientContact?.countryId == null ? "" : this.localStorageService.getCountryTypes().find(x => x.id == this.recipient?.recipientContact?.countryId)?.name));
    this.recipientContactInformation.push(new SectionDetailsModel('City', this.recipient?.recipientContact?.city));
    this.recipientContactInformation.push(new SectionDetailsModel('Postal Code', this.recipient?.recipientContact?.postalCode));
    this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.recipient?.recipientContact?.isAddressDifferentFromMOVES ? "Yes" : "No"));
    this.recipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.recipient?.recipientContact?.isNoFixedAddress ? "Yes" : "No"));

    this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient?.movesRecipientContact?.addressLine1));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient?.movesRecipientContact?.addressLine2));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('City', this.recipient?.movesRecipientContact?.city));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient?.movesRecipientContact?.countryId == 40 || this.recipient?.movesRecipientContact?.countryId == 236) ? (this.recipient?.movesRecipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient?.movesRecipientContact?.provinceId)?.name) : this.recipient?.movesRecipientContact?.province));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.recipient?.movesRecipientContact?.countryId)?.name));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code', this.recipient?.movesRecipientContact?.postalCode));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient?.movesRecipientContact?.phoneNumber1));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient?.movesRecipientContact?.phoneNumber3));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient?.movesRecipientContact?.phoneNumber2));

    //Recipient Information for 3rd Party Seizure Review
    this.recipientMinimumInformation.push(new SectionDetailsModel('Name', `${this.recipient?.firstName ?? ""} ${this.recipient?.lastName}`));
  }

  populateStopInformation() {
    this.occurrenceInformation = [];
    const hideWitnessAndPayCentreActive = new Date(this.review.requestDate) >= new Date(+Constants.Adjudication.HIDE_WITNESS_AND_PAY_CENTRE_EFFECTIVE_DATE.substring(0, 4), +Constants.Adjudication.HIDE_WITNESS_AND_PAY_CENTRE_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Adjudication.HIDE_WITNESS_AND_PAY_CENTRE_EFFECTIVE_DATE.substring(8, 10));

    // Populate Occurrence Details
    if (this.isContraventionReview) {
      this.occurrenceInformation.push(new SectionDetailsModel('Occurrence Date', this.datePipe.transform(this.contravention.occurrenceDate, 'MMM dd, yyyy')));
      this.occurrenceInformation.push(new SectionDetailsModel('Occurrence Time', this.contravention.occurrenceTime.substring(0, 5)));
      this.occurrenceInformation.push(new SectionDetailsModel('Issuance Date', this.datePipe.transform(this.contravention.issueDate, 'MMM dd, yyyy')));
    }

    if (this.stopInformation) {
      if (this.isPhase2Submission)
        this.vehicle = this.stopInformation.contraventions.length > 0 ? this.stopInformation.contraventions[0].vehicle : (this.stopInformation.vehicleSeizures.length > 0 ? this.stopInformation.vehicleSeizures[0].vehicle : null);
      else
        this.vehicle = this.isContraventionReview ? this.contravention?.vehicleSeizure?.vehicle : this.vehicleSeizure?.vehicle;

      // Occurrence Information      
      this.occurrenceInformation.push(new SectionDetailsModel('Municipality At or Near', this.localStorageService.getLocationTypes().find(x => x.id == this.stopInformation?.occurrenceLocation?.locationTypeId)?.name));
      if (this.isPhase2Submission) {
          this.occurrenceInformation.push(new SectionDetailsModel('Address or Highway Number', this.stopInformation?.occurrenceLocation?.addressOrHighwayNumber));
          this.occurrenceInformation.push(new SectionDetailsModel('GPS Coordinates', `${this.stopInformation?.occurrenceLocation?.latitude ?? ""} ${this.stopInformation?.occurrenceLocation?.longitude ?? ""}`));
          this.occurrenceInformation.push(new SectionDetailsModel('At Intersection of', this.stopInformation?.occurrenceLocation?.atStreet));
          this.occurrenceInformation.push(new SectionDetailsModel('Relative location', `${this.stopInformation?.occurrenceLocation?.directionTypeId ? this.localStorageService.getDirectionTypes().find(x => x.id == this.stopInformation?.occurrenceLocation?.directionTypeId)?.name + " of " : ""} ${this.stopInformation?.occurrenceLocation?.relativeLocation ?? ""}`));

        if (!hideWitnessAndPayCentreActive) {
            this.occurrenceInformation.push(new SectionDetailsModel('Pay Centre Location', this.localStorageService.getPayCentreTypes().find(x => x.id == this.stopInformation?.occurrenceLocation?.payCentreTypeId)?.name));
        }
      }
        this.occurrenceInformation.push(new SectionDetailsModel('Additional Location Information', this.stopInformation?.occurrenceLocation?.additionalLocationDetails, true));

      //Location Conditions
      if (this.isPhase2Submission) {
        this.occurrenceInformation.push(new SectionDetailsModel('Traffic Conditions', this.stopInformation?.trafficCondition));
        this.occurrenceInformation.push(new SectionDetailsModel('Weather Conditions', Array.prototype.map.call(this.localStorageService.getWeatherConditionTypes().filter(x => this.stopInformation?.weatherConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.stopInformation?.weatherConditionTypes?.split(',').includes(WeatherConditionTypes.Other.toString()) ? "-" + this.stopInformation?.weatherConditionOther : "")));
        this.occurrenceInformation.push(new SectionDetailsModel('Road Surface', Array.prototype.map.call(this.localStorageService.getRoadSurfaceTypes().filter(x => this.stopInformation?.roadSurfaceTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.stopInformation?.roadSurfaceTypes?.split(',').includes(RoadSurfaceTypes.Other.toString()) ? "-" + this.stopInformation?.roadSurfaceOther : "")));
        this.occurrenceInformation.push(new SectionDetailsModel('Road Conditions', Array.prototype.map.call(this.localStorageService.getRoadConditionTypes().filter(x => this.stopInformation?.roadConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.stopInformation?.roadConditionTypes?.split(',').includes(RoadConditionTypes.Other.toString()) ? "-" + this.stopInformation?.roadConditionOther : "")));
        this.occurrenceInformation.push(new SectionDetailsModel('Visibility', Array.prototype.map.call(this.localStorageService.getVisibilityConditionTypes().filter(x => this.stopInformation?.visibilityConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.stopInformation?.visibilityConditionTypes?.split(',').includes(VisibilityConditionTypes.Other.toString()) ? "-" + this.stopInformation?.visibilityConditionOther : "")));
        this.occurrenceInformation.push(new SectionDetailsModel('Highway Characteristics', Array.prototype.map.call(this.localStorageService.getHighwayCharacteristicsTypes().filter(x => this.stopInformation?.highwayCharacteristicsTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.stopInformation?.highwayCharacteristicsTypes?.split(',').includes(HighwayCharacteristicsTypes.Other.toString())? "-" + this.stopInformation?.highwayCharacteristicsOther:"")));
      }

      // Stop Information
      this.occurrenceInformation.push(new SectionDetailsModel('Observed by', this.localStorageService.getObservedByTypes().find(x => x.id == this.stopInformation?.observedByTypeId)?.name));
      //Witness Name, Witness DOB
      this.occurrenceInformation.push(new SectionDetailsModel('Time 1st Observed', this.timePipe.transform(this.stopInformation?.firstObservedTime, 'shortTime24h')));
      this.occurrenceInformation.push(new SectionDetailsModel('1st Observed Location', this.stopInformation?.firstObservedLocation));

      //Does the contravention relate to a vehicle collision?
      if (this.stopInformation.isNoTrafficStop != true) {
        this.occurrenceInformation.push(new SectionDetailsModel('Time of Stop', this.timePipe.transform(this.stopInformation?.stopTime, 'shortTime24h')));
        this.occurrenceInformation.push(new SectionDetailsModel('Circumstances for Stop', this.stopInformation?.circumstanceTypeId == 4 ? this.stopInformation?.circumstanceOther : this.localStorageService.getCircumstanceTypes().find(x => x.id == this.stopInformation?.circumstanceTypeId)?.name));
      }

      //Witness Information
      if (!hideWitnessAndPayCentreActive) {
        this.witnessInformation = [];
        this.stopInformation?.witnesses?.forEach(witness => {
          this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' First Name', witness.firstName));
          this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' Last Name', witness.lastName));
          this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' DOB', witness.dateOfBirth == null ? "-" : this.datePipe.transform(witness.dateOfBirth, 'dd MMM, yyyy')));
        });
      }

      //Bodycam video question is only active when contravention was issued between Aug 1 and Aug 4 (inclusive), or details were added while the feature was active (meaning hasBodycamVideo is not null)
      if (this.contravention) {
        const issuedAfterBodycamVideoEffective = new Date(this.contravention.issueDate) > new Date(+Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(8, 10));
        const issuedBeforeBodycamVideoRemoved = new Date(this.contravention.issueDate) < new Date(+Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(8, 10));
        if ((issuedAfterBodycamVideoEffective && issuedBeforeBodycamVideoRemoved) || this.stopInformation?.hasBodycamVideo != null) {
          this.isBodycamVideoActive = true;
        }
      }

      //Police Narrative Details
      this.policeNarrativeDetails = [];
      this.policeNarrativeDetails.push(new SectionDetailsModel('', this.isContraventionReview ? this.contravention.additionalNotes : this.vehicleSeizure.additionalNotes, true));

      // Officer Information
      this.officerInformation = [];
      if (this.stopInformation?.officerTransportationTypeId) {
        this.officerInformation.push(new SectionDetailsModel('Peace Officer Transportation', this.localStorageService.getOfficerTransportationTypes().find(x => x.id == this.stopInformation?.officerTransportationTypeId)?.name));
        if (this.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.InMarkedPatrolVehicle) {
          this.officerInformation.push(new SectionDetailsModel('Type of Vehicle', this.localStorageService.getOfficerVehicleTypes().find(x => x.id == this.stopInformation?.officerVehicleTypeId)?.name));
          this.officerInformation.push(new SectionDetailsModel('Vehicle Number', this.stopInformation?.officerVehicleNumber));
        }
        else if (this.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.Other || this.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.InUnMarkedPatrolVehicle)
          this.officerInformation.push(new SectionDetailsModel('Other Transportation', this.stopInformation?.officerTransportationOther));

        this.officerInformation.push(new SectionDetailsModel('Officer Uniform', this.localStorageService.getOfficerUniformTypes().find(x => x.id == this.stopInformation?.officerUniformTypeId)?.name));
        if (this.stopInformation?.officerUniformTypeId == OfficerUniformTypes.Other)
          this.officerInformation.push(new SectionDetailsModel('Other Uniform', this.stopInformation?.officerUniformOther));
      }
    }

    this.populateVehicleInformation();
  }

  populateVehicleInformation() {
    //Vehicle Information
    this.vehicleInformation = [];

    this.movesVehicle = this.vehicle?.movesVehicle;
    this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicle?.licencePlateNumber));
    this.vehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.vehicle?.isStolenPlate ? "Yes" : "No"));
    this.vehicleInformation.push(new SectionDetailsModel("No Plate", this.vehicle?.isNoPlate ? "Yes" : "No"));
    this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicle?.vehicleIdentificationNumber));
    this.vehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.vehicle?.vehicleRegistrationStatusTypeId)?.name));
    this.vehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.vehicle?.clientTypeId)?.name));
    this.vehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicle?.licensedCountryId)?.name));
    this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicle?.licensedCountryId == 40 || this.vehicle?.licensedCountryId == 236) ? (this.vehicle?.licensedProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicle?.licensedProvinceId)?.name) : this.vehicle?.licensedProvince));
    this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicle?.make));
    this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicle?.model));
    this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicle?.year?.toString()));
    this.vehicleInformation.push(new SectionDetailsModel('Colour', this.vehicle?.colour));

    this.vehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.vehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
    if (!(this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate)) {
      this.vehicleInformation.push(new SectionDetailsModel('Class of Plate', this.vehicle?.licencePlateClass));
      this.vehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.vehicle?.vehicleStyle));
      this.vehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.vehicle?.vehicleWeight));
      this.vehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.vehicle?.vehicleSeatingCapacity));
      this.vehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.vehicle?.vehicleStatusTypeId)?.name));
      this.vehicleInformation.push(new SectionDetailsModel('Last Service Type', this.vehicle?.lastServiceType));
      this.vehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.vehicle?.lastServiceDate, 'dd MMM, yyyy')));
    }

    //Moves Vehicle Information
    this.movesVehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.movesVehicle?.licencePlateNumber));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.movesVehicle?.vehicleIdentificationNumber));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.movesVehicle?.vehicleRegistrationStatusTypeId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.movesVehicle?.clientTypeId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.movesVehicle?.licensedCountryId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.movesVehicle?.licensedCountryId == 40 || this.movesVehicle?.licensedCountryId == 236) ? (this.movesVehicle?.licensedProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.movesVehicle?.licensedProvinceId)?.name) : this.movesVehicle?.licensedProvince));
    this.movesVehicleInformation.push(new SectionDetailsModel('Make', this.movesVehicle?.make));
    this.movesVehicleInformation.push(new SectionDetailsModel('Model', this.movesVehicle?.model));
    this.movesVehicleInformation.push(new SectionDetailsModel('Year', this.movesVehicle?.year?.toString()));
    this.movesVehicleInformation.push(new SectionDetailsModel('Colour', this.movesVehicle?.colour));
    this.movesVehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.movesVehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
    this.movesVehicleInformation.push(new SectionDetailsModel('Class of Plate', this.movesVehicle?.licencePlateClass));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.movesVehicle?.vehicleStyle));
    this.movesVehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.movesVehicle?.vehicleWeight));
    this.movesVehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.movesVehicle?.vehicleSeatingCapacity));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.movesVehicle?.vehicleStatusTypeId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Type', this.movesVehicle?.lastServiceType));
    this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.movesVehicle?.lastServiceDate, 'dd MMM, yyyy')));

    // Trailer Information
    if (this.isPhase2Submission) {
      this.trailerInformation.push(new SectionDetailsModel("Trailer Involved", this.vehicle?.isTrailerInvolved ? "Yes" : "No"));
    }
    if (this.vehicle?.isTrailerInvolved) {
      this.trailerInformation.push(new SectionDetailsModel('Trailer Licence Plate Number', this.vehicle?.trailerLicencePlateNumber));
      this.trailerInformation.push(new SectionDetailsModel('Additional Trailer Information', this.vehicle?.additionalTrailerInformation));
      if (this.vehicle?.isSecondTrailerInvolved) {
        this.trailerInformation.push(new SectionDetailsModel('Second Trailer Licence Plate Number', this.vehicle?.secondTrailerLicencePlateNumber));
        this.trailerInformation.push(new SectionDetailsModel('Additional Second Trailer Information', this.vehicle?.additionalSecondTrailerInformation));
      }
    }

    //Registered Owner Information
    if (this.vehicle?.isNoRegisteredOwner)
      this.registeredOwnerInformation.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
    else
    {
      this.registeredOwnerInformation.push(new SectionDetailsModel('Same as Driver', this.vehicle?.registeredOwner?.isSameAsDriver ? "Yes" : "No", true));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Driver’s Licence Number', this.vehicle?.registeredOwner?.driverLicenceNumber));
      this.registeredOwnerInformation.push(new SectionDetailsModel('M.V.I.D', this.vehicle?.registeredOwner?.motorVehicleIdentificationNumber));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Registered Owner Name', this.vehicle?.registeredOwner?.otherName == null ? `${this.vehicle?.registeredOwner?.firstName} ${this.vehicle?.registeredOwner?.lastName}` : `${this.vehicle?.registeredOwner?.firstName} ${this.vehicle?.registeredOwner?.otherName}, ${this.vehicle?.registeredOwner?.lastName}`));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.vehicle?.registeredOwner?.genderId)?.name));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.vehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Telephone Number', this.vehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Address 1', this.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Address 2', this.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Country', this.vehicle?.registeredOwner?.registeredOwnerContact?.country?.name));
      if (this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236) // Canada OR U. S. A.
      {
        this.registeredOwnerInformation.push(new SectionDetailsModel('Province', this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name));
      }
      else {
        this.registeredOwnerInformation.push(new SectionDetailsModel('Province', this.vehicle?.registeredOwner?.registeredOwnerContact?.province));
      }
      this.registeredOwnerInformation.push(new SectionDetailsModel('City', this.vehicle?.registeredOwner?.registeredOwnerContact?.city));
      this.registeredOwnerInformation.push(new SectionDetailsModel('Postal Code', this.vehicle?.registeredOwner?.registeredOwnerContact?.postalCode));
    }
  }

  DisplayTestResults(test: ContraventionTest, section: SectionDetailsModel[], isTypeRequired: boolean, isRoadsideAppeal: boolean = false) {
    if (test == null) return;

    var newFormatDate = this.localStorageService.getDriverDeclinedToTestTypes().find(x => x.id == DriverDeclinedToTestTypes.FailedToComply)?.effectiveDate;
    var isNewFormat = new Date(this.contravention.submissionDate) >= new Date(newFormatDate);
    var isMas = this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 2 && !isRoadsideAppeal;
    var isNewFormatV2 = new Date(this.contravention.submissionDate) > new Date(+Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(0, 4), +Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(5, 7) - 1, +Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(8, 10));
    var isAIThirdTestVisible = new Date(this.contravention.submissionDate) >= new Date(+Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(8, 10));
    var isNewDREProcess = new Date(this.contravention.submissionDate) >= new Date(+Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(8, 10));

    if (isTypeRequired)
      section.push(new SectionDetailsModel('Screening Method', this.localStorageService.getTestTypes().find(x => x.id == test.testTypeId)?.name));

    switch (test.testTypeId.toString()) {
      case "1": //SFST
        if (isNewFormatV2) {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "SFST Demand"}`, this.timePipe.transform(test.standardizedFieldSobrietyTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "SFST"} Test Started`, this.timePipe.transform(test.standardizedFieldSobrietyTest?.testStartedTime, 'shortTime24h')));
        }
        else {
          section.push(new SectionDetailsModel(`Time of SFST ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, this.timePipe.transform(test.standardizedFieldSobrietyTest?.testDemandTime)));
          section.push(new SectionDetailsModel('Time SFST Test Started', this.timePipe.transform(test.standardizedFieldSobrietyTest?.testStartedTime, 'shortTime24h')));
        }
        section.push(new SectionDetailsModel('SFST Results', this.localStorageService.getTestResultTypes().find(x => x.id == test.standardizedFieldSobrietyTest?.testResultTypeId)?.name));
        break;
      case "2": //ASD
        section.push(new SectionDetailsModel('ASD Model', test.approvedScreeningDeviceTest?.testModelTypeId == 2 ? test.approvedScreeningDeviceTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testModelTypeId)?.name));
        if (isNewFormatV2) {
          if (test.approvedScreeningDeviceTest?.testDemandTime) {
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : ((isMas && isNewFormat) ? "MAS" : "ASD") + " Demand"}`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testDemandTime, 'shortTime24h')));
          }
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : ((isMas && isNewFormat) ? "MAS" : "ASD")} Test Started`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testStartedTime, 'shortTime24h')));
        }
        else {
          if (!isRoadsideAppeal || test.approvedScreeningDeviceTest?.testDemandTime) {
            section.push(new SectionDetailsModel(`Time of ${(isMas && isNewFormat) ? "MAS" : "ASD"} Demand`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testDemandTime, 'shortTime24h')));
          }
          section.push(new SectionDetailsModel(`Time ${(isMas && isNewFormat) ? "MAS" : "ASD"} Test Started`, this.timePipe.transform(test.approvedScreeningDeviceTest?.testStartedTime, 'shortTime24h')));
        }
        section.push(new SectionDetailsModel(`${(isMas && isNewFormat) ? "MAS" : "ASD"} Results`, this.localStorageService.getTestResultTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testResultTypeId)?.name));
        break;
      case "3": //ADSE
        section.push(new SectionDetailsModel('ADSE Model', test.drugScreeningEquipmentTest?.testModelTypeId == 5 ? test.drugScreeningEquipmentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.drugScreeningEquipmentTest?.testModelTypeId)?.name));
        if (isNewFormatV2) {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "ADSE Demand"}`, this.timePipe.transform(test.drugScreeningEquipmentTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "ADSE"} Test Started`, this.timePipe.transform(test.drugScreeningEquipmentTest?.testStartedTime, 'shortTime24h')));
        }
        else {
          section.push(new SectionDetailsModel(`Time of ADSE ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, this.timePipe.transform(test.drugScreeningEquipmentTest?.testDemandTime)));
          section.push(new SectionDetailsModel('Time ADSE Test Started', this.timePipe.transform(test.drugScreeningEquipmentTest?.testStartedTime, 'shortTime24h')));
        }
        section.push(new SectionDetailsModel('ADSE Results', Array.prototype.map.call(this.localStorageService.getTestResultTypes().filter(x => test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes("10") ? "-" + test.drugScreeningEquipmentTest?.testResultOther : "")));
        break;
      case "4": //DRE
        if (isNewDREProcess) {
          section.push(new SectionDetailsModel(`Time of DRE ${isRoadsideAppeal ? "Request" : "Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(isRoadsideAppeal ? "Decline?" : "Refusal", this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));

          if (test.driverDeclinedToTestTypeId != DriverDeclinedToTestTypes.No) {
            var fieldLabel = test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal ? (isRoadsideAppeal ? "Declined?" : "Refused") : "Failed to Comply With";
            switch (+test.drugRecognitionEvaluationTest?.refusalOrFailedClassificationId) {
              case RefusalOrFailedClassification.BreathSample:
                section.push(new SectionDetailsModel(fieldLabel, "Breath Sample"));
                break;
              case RefusalOrFailedClassification.DREProcess:
                section.push(new SectionDetailsModel(fieldLabel, "DRE Process"));
                break;
              case RefusalOrFailedClassification.ToxicologicalSample:
                section.push(new SectionDetailsModel(fieldLabel, "Toxicological Sample"));
                break;
            }
          }

          section.push(new SectionDetailsModel(`Breath ${isRoadsideAppeal ? "Request" : "Demand"} (Step 1) Made?`, test.drugRecognitionEvaluationTest?.isBreathDemandMade ? "Yes" : "No"));

          if (test.drugRecognitionEvaluationTest?.isBreathDemandMade) {
            section.push(new SectionDetailsModel(`Time of Breath ${isRoadsideAppeal ? "Request" : "Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.breathDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel('Breath Test Results', test.drugRecognitionEvaluationTest?.breathTestResult));
          }

          if (test.drugRecognitionEvaluationTest?.testStartedTime)
            section.push(new SectionDetailsModel('Time DRE Test Started', this.timePipe.transform(test.drugRecognitionEvaluationTest?.testStartedTime, 'shortTime24h')));

          if (test.drugRecognitionEvaluationTest.sampleClassificationId) {
            var sampleType = test.drugRecognitionEvaluationTest?.sampleClassificationId == SampleClassification.Blood ? "Blood" : "Urine";
            section.push(new SectionDetailsModel(`Type of Sample ${isRoadsideAppeal ? "Requested" : "Demanded"}`, sampleType));
            section.push(new SectionDetailsModel(`Sample ${isRoadsideAppeal ? "Request" : "Demand"} Time`, this.timePipe.transform(test.drugRecognitionEvaluationTest.sampleDemandTime)));

            if (test.drugRecognitionEvaluationTest.sampleCollectionTime) {
              section.push(new SectionDetailsModel('Sample Time', this.timePipe.transform(test.drugRecognitionEvaluationTest.sampleCollectionTime, 'shortTime24h')));
              section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.drugRecognitionEvaluationTest?.labTypeId)?.name));
            }
          }

          if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Decline" : "Refusal"}`, this.timePipe.transform(test.refusalTime, 'shortTime24h')));
        }
        else {
          if (isNewFormatV2) {
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "DRE Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "DRE"} Test Started`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testStartedTime, 'shortTime24h')));
          }
          else {
            section.push(new SectionDetailsModel(`Time of DRE ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, this.timePipe.transform(test.drugRecognitionEvaluationTest?.testDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel('Time DRE Test Started', this.timePipe.transform(test.drugRecognitionEvaluationTest?.testStartedTime, 'shortTime24h')));
          }
        }
        break;
      case "5": //AI
        section.push(new SectionDetailsModel('AI Model', test.approvedInstrumentTest?.testModelTypeId == 7 ? test.approvedInstrumentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedInstrumentTest?.testModelTypeId)?.name));

        if (isAIThirdTestVisible) {
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "AI"} Test Started`, this.timePipe.transform(test.approvedInstrumentTest?.testStartedTime, 'shortTime24h')));
        }

        if (isNewFormatV2) {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "1st Breath AI Demand"}`, this.timePipe.transform(test.approvedInstrumentTest?.firstBreathDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`${isRoadsideAppeal ? "" : "1st "}Evidentiary Test Result`, test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        }
        else {
          section.push(new SectionDetailsModel(`Time of 1st Breath AI ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, this.timePipe.transform(test.approvedInstrumentTest?.firstBreathDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel('1st Evidentiary Test Result', test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        }

        if (!isRoadsideAppeal) {
          section.push(new SectionDetailsModel(`Time of 2nd Breath AI ${isAIThirdTestVisible ? "Request" : "Demand"}`, this.timePipe.transform(test.approvedInstrumentTest?.secondBreathDemandTime, 'shortTime24h')));
          section.push(new SectionDetailsModel(`2nd Evidentiary Test Result`, test.approvedInstrumentTest?.secondEvidentiaryTestResult));

          if (isAIThirdTestVisible) {
            section.push(new SectionDetailsModel(`Time of 3rd Breath AI Request`, this.timePipe.transform(test.approvedInstrumentTest?.thirdBreathDemandTime, 'shortTime24h')));
            section.push(new SectionDetailsModel(`3rd Evidentiary Test Result`, test.approvedInstrumentTest?.thirdEvidentiaryTestResult));
          }
        }

        break;
      case "6": //Blood Sample
        if (isNewDREProcess && !isRoadsideAppeal) {
          if (test.bloodSampleTest.isBloodDemand) {
            section.push(new SectionDetailsModel('Type of Sample', 'Blood Demand (320.28)'));
            section.push(new SectionDetailsModel("Refusal?", this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));
            section.push(new SectionDetailsModel('Time of Demand', this.timePipe.transform(test.bloodSampleTest?.bloodSampleDemandTime, 'shortTime24h')));
            if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No) {
              section.push(new SectionDetailsModel('Blood Sample Time', this.timePipe.transform(test.bloodSampleTest?.bloodSampleTime, 'shortTime24h')));
              section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
            }

            if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
              section.push(new SectionDetailsModel('Time of Refusal', this.timePipe.transform(test.refusalTime, 'shortTime24h')));
          }

          if (test.bloodSampleTest.isBloodWarrant) {
            section.push(new SectionDetailsModel('Type of Sample', 'Blood Warrant (320.29)', true));
            section.push(new SectionDetailsModel('Blood Sample Time', this.timePipe.transform(test.bloodSampleTest?.bloodWarrantSampleTime, 'shortTime24h')));
            section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.bloodWarrantLabTypeId)?.name));
          }

          if (test.bloodSampleTest.isSearchWarrant)
            section.push(new SectionDetailsModel('Type of Sample', 'Search Warrant (487)', true));

          if (test.bloodSampleTest.isHospitalRecord)
            section.push(new SectionDetailsModel('Type of Sample', 'Hospital Records (Production Order or Consent)', true));
        }
        else {
          if (isNewDREProcess)
            section.push(new SectionDetailsModel(isRoadsideAppeal ? "Time of Roadside Appeal Request" : "Blood Sample Demand Time", this.timePipe.transform(test.bloodSampleTest?.bloodSampleDemandTime, 'shortTime24h')));

          section.push(new SectionDetailsModel('Blood Sample Time', this.timePipe.transform(test.bloodSampleTest?.bloodSampleTime, 'shortTime24h')));
          section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
          section.push(new SectionDetailsModel('Blood Sample Result', test.bloodSampleTest?.bloodSampleResult));
        }
        break;
      case "9": //Urine Sample
        if (isNewDREProcess)
          section.push(new SectionDetailsModel(isRoadsideAppeal ? "Time of Roadside Appeal Request" : "Urine Sample Demand Time", this.timePipe.transform(test.urineSampleTest?.urineSampleDemandTime, 'shortTime24h')));

        section.push(new SectionDetailsModel('Urine Sample Time', this.timePipe.transform(test.urineSampleTest?.urineSampleTime, 'shortTime24h')));
        section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.urineSampleTest?.labTypeId)?.name));
        section.push(new SectionDetailsModel('Urine Sample Result', test.urineSampleTest?.urineSampleResult));
        break;
    }

    if (test.testTypeId != TestTypes.NoScreening && test.testTypeId != TestTypes.NoAppeal && (test.testTypeId != TestTypes.DRE || !isNewDREProcess) && (test.testTypeId != TestTypes.Blood || !isNewDREProcess || isRoadsideAppeal)) //No Screening Method and No Appeal
    {
      section.push(new SectionDetailsModel((isRoadsideAppeal && isNewFormat) ? "Decline?" : "Refusal?", ((isRoadsideAppeal || !isNewFormat) && test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal) ? "Yes" : this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));

      if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
        section.push(new SectionDetailsModel(`Time of ${(isRoadsideAppeal && isNewFormat) ? "Decline" : "Refusal"}`, this.timePipe.transform(test.refusalTime, 'shortTime24h')));
    }
  }

  filterDocuments() {
    if (this.isContraventionReview) {
      if (!this.contravention.vehicle?.registeredOwner?.isSameAsDriver) {
        this.contravention.documents = this.contravention.documents.filter(d => d.documentTypeId != DocumentTypes.SeizureNoticeRegisteredOwnerCopy);
      }
      this.contravention.documents = this.contravention.documents.filter(d => d.documentTypeId != DocumentTypes.SeizureNoticePoliceCopy
                                                                            && d.documentTypeId != DocumentTypes.SeizureNoticeTowLotCopy
                                                                            && !(d.documentTypeId == DocumentTypes.WitnessStatements && !d.isPublished) //hide unpublished witness statements
                                                                            && !d.isDeleted
                                                                            );
    }
    else if (this.isSDPReview) {
      if (!this.vehicleSeizure.vehicle.registeredOwner?.isSameAsDriver) {
        this.vehicleSeizure.documents = this.vehicleSeizure.documents.filter(d => d.documentTypeId != DocumentTypes.SeizureNoticeRegisteredOwnerCopy);
      }
      this.vehicleSeizure.documents = this.vehicleSeizure.documents.filter(d => d.documentTypeId != DocumentTypes.SeizureNoticePoliceCopy
                                                                            && d.documentTypeId != DocumentTypes.SeizureNoticeTowLotCopy
                                                                            && !(d.documentTypeId == DocumentTypes.WitnessStatements && !d.isPublished) //hide unpublished witness statements
                                                                            && !d.isDeleted
                                                                            );
    }
    else if (this.isThirdPartySeizure) {
      this.vehicleSeizure.documents = this.vehicleSeizure.documents.filter(d => (
                                                                                d.documentTypeId == DocumentTypes.SeizureNoticeRegisteredOwnerCopy
                                                                                || d.documentTypeId == DocumentTypes.OriginalPaperSeizure
                                                                                || d.documentTypeId == DocumentTypes.PoliceNarrative
                                                                                || d.documentTypeId == DocumentTypes.PoliceNotes
                                                                              )
                                                                            && !d.isDeleted);
    }
  }

  public scrollToDocumentsSection() {
    this.supportingDocumentsDiv.nativeElement.scrollIntoView(false)
  }

  onPlusMinusIconClick(imgId: string, divId: string): void {
    let div = document.getElementById(divId);
    let img = document.getElementById(imgId);

    if (div.classList.contains("show")) {
      div.classList.remove("show");
      img.setAttribute("src", "assets/images/plus.svg");
    } else {
      div.classList.add("show");
      img.setAttribute("src", "assets/images/minus.svg");
    }
  }

  onExpandAllChange(ev: any): void {
    if ($(".collapse").hasClass("show")) {
      $(".plus-minus-icon img").attr("src", "assets/images/plus.svg");
      $(".collapse").removeClass("show");
    } else {
      $(".plus-minus-icon img").attr("src", "assets/images/minus.svg");
      $(".collapse").addClass("show");
    }
  }
}