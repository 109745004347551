<div class="modal-overlay">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-container-title">
                <span>Delete </span>
                {{towCompany.towCompanyName}}
            </div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="alert-icon">
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="body-text">
                Are you sure you wish to delete {{towCompany.towCompanyName}}?<br/> This action cannot be undone.
            </div>
        </div>
        <div class="error mb-3">{{errorMessage}}</div>
        <div class="d-flex justify-content-end">
            <div class="cancel-button" (click)="onCancelClick()" id="cancelButton">Cancel</div>
            <div id="deleteButton" class="delete-button" (click)="onDeleteClick()">Delete</div>
        </div>
    </div>
</div>
