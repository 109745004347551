<div class="d-flex flex-column h-100">
    <div class="header">
        Traffic Disclosure
    </div>
    <div class="main">
        <div class="main-content w-100">
            <div class="content-header">Search for Violation Ticket</div>

            <form #validateTicketForm="ngForm">
                <div class="label">Violation ticket number</div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="search-box">
                        <input id="ticketNumber" required type="text" class="form-control" placeholder="e.g. B12345678Q" 
                            name="ticketNumber" #ticketNumberControl="ngModel"
                            appTicketNumberValidator=true
                            minlength="10" maxlength="10"
                            [ngClass]="{ 'is-invalid': (ticketNumberControl.touched || validateTicketForm.submitted) && ticketNumberControl.invalid }"
                            [(ngModel)]="searchText"
                            (keydown)="onTxtTicketNumberKeyDown($event, validateTicketForm.valid)" />                
                        <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick(validateTicketForm.valid)"><i class="fas fa-search fa-lg"></i></button>                
                    </div>
                </div>
                <input required appTicketNumberValidator=true type="hidden" id="ticketNumberHidden" name="ticketNumberHidden" #ticketNumberHiddenControl="ngModel" [value]="null" [(ngModel)]="searchText" 
                    [ngClass]="{'is-invalid': (ticketNumberControl.touched || validateTicketForm.submitted) && ticketNumberHiddenControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="ticketNumberHiddenControl.errors?.invalidLength">A valid 10-digit ticket number is required</div>
                    <div *ngIf="ticketNumberHiddenControl.errors?.invalidTicketNumber">Invalid ticket number</div>
                </div>
            </form>

            <div *ngIf="disclosuresResult && disclosuresResult.ticketNumber != null" class="result-container">
                <div class="title">Violation Ticket {{disclosuresResult.ticketNumber}} Disclosure</div>
                <div>
                    <table class="table">
                        <thead>
                            <th>
                                <input type="checkbox" (change)="onSelectAllChanged($event)" [(ngModel)]="allItemsSelected">
                            </th>
                            <th>Type</th>
                            <th>Filename</th>
                            <th>Format</th>
                            <th>Size</th>
                            <th>Date Submitted</th>
                        </thead>
                        <tr *ngFor="let d of disclosuresResult?.disclosures">
                            <td>
                                <input type="checkbox" [(ngModel)]="d.isSelected">
                            </td>
                            <td>
                                {{d.metadata?.type}}
                            </td>
                            <td>
                                <ng-template [ngIf]="d.contentLength == 0 && d.metadata?.documenturl" [ngIfElse]="storageLink">
                                    <a href="{{d.metadata?.documenturl}}" target="_blank">{{d.metadata["originalname"]}}</a>
                                </ng-template>
                                <ng-template #storageLink>
                                    <a href="{{d.uri}}" target="_blank">{{d.metadata["originalname"]}}</a>
                                </ng-template>
                            </td>
                            <td>{{d.format | uppercase}}</td>
                            <td>{{ getSize(d) }}</td>
                            <td>{{d.submittedOn | date:'mediumDate'}}</td>
                            <td>
                                <div *ngIf="(!d.metadata.status || d.metadata.status === 'Draft' || d.metadata.status === 'Unpublished')
                                            && (!d.metadata.category || d.metadata.category != 'Crown Disclosure')
                                            && (!d.metadata.trialstatus || d.metadata.trialstatus === 'Declined')">
                                    <app-access-control [Resource]="Resource.TRAFFIC_DISCLOSURE" [Permission]="Permission.DELETE_DISCLOSURE_DOCUMENT">
                                        <a class="delete-disclosure-document-link" (click)="onDeleteDisclosureDocumentClick($event, d)">Delete</a>
                                    </app-access-control>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="disclosuresResult?.disclosures.length == 0">
                            <td colspan="6">No disclosure found for this Violation Ticket.</td>
                        </tr>
                    </table>
                    <div *ngIf="errorMessage">
                        <span class="error">{{errorMessage}}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class="btn button-primary" type="button" [disabled]="!isAnyItemSelected()" (click)="onDownloadClick()">
                        Download
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>