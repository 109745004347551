import { Component, EventEmitter, OnInit } from '@angular/core';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { Contravention } from '@apis/shared/models/contravention.model';

@Component({
  selector: 'app-fine-uncollectable',
  templateUrl: './fine-uncollectable.component.html',
  styleUrls: ['./fine-uncollectable.component.scss']
})
export class FineUncollectableComponent implements OnInit {
  contravention: Contravention;
  bodyElement: JQuery<HTMLElement>;
  errorMessage: string;
  close: EventEmitter<any> = new EventEmitter();

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly adjudicationService: AdjudicationService) { }

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
  }

  onCloseIconClick(): void {        
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(null);
  }

  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(null);
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.adjudicationService.setContraventionAsUncollectable(this.contravention.contraventionNumber)
      .subscribe((contravention: Contravention) => {
        this.errorMessage = ""; 
        this.bodyElement.removeClass("overflow-hidden");
        this.spinner.hide();
        this.close.emit(contravention);     
      }, (error: any) => {
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else  {
          this.showErrors(error);
        }                    
        this.spinner.hide();
      });
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

}
