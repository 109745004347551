<form #form="ngForm">
    <div class="d-flex flex-column h-100">
        <div class="header">
            Review Extract Report
        </div>
        <div class="secondary-header">Download an extract of reviews requested within the selected period. Please note larger date ranges will take longer to extract and download.
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-3 col-left-margin">
            <label for="startDate">Start Date <span class="label-hint">(Required)</span></label>
            <input readonly required isLessThanStartDate="2020-12-01" #startDateControl="ngModel" type="text"
                bsDatepicker [bsConfig]="startDatePickerConfig" maxlength="10" class="form-control" id="startDate"
                name="startDate" placeholder="yyyy/mm/dd" ngModel
                [ngClass]="{'is-invalid':(startDateControl.touched) && startDateControl.invalid}">
            <div class="invalid-feedback">
                <div *ngIf="startDateControl.hasError('required')">Start Date is required</div>
                <div *ngIf="!startDateControl.hasError('required') && startDateControl.hasError('EarlierDate')">Start
                    Date can't be earlier than 01 Dec, 2020</div>
            </div>
            <div *ngIf="startDateControl.value > endDateControl.value && endDateControl.touched" class="text-danger">
                <p>Start Date cannot be greater than End Date</p>
            </div>
        </div>
        <div class="col-sm-3 col-left-margin">
            <label for="endDate">End Date <span class="label-hint">(Required)</span></label>
            <input readonly required isLessThanEndDate="2020-12-01" #endDateControl="ngModel" type="text" bsDatepicker
                [bsConfig]="endDatePickerConfig" maxlength="10" class="form-control" id="endDate" name="endDate"
                placeholder="yyyy/mm/dd" ngModel
                [ngClass]="{'is-invalid':(endDateControl.touched) && endDateControl.invalid}">
            <div class="invalid-feedback">
                <div *ngIf="endDateControl.hasError('required')">End Date is required</div>
                <div *ngIf="!endDateControl.hasError('required') && endDateControl.hasError('EarlierDate')">End Date
                    can't be earlier than 01 Dec, 2020</div>
            </div>
        </div>
        <div class="col-sm-2 col-left-margin-btn">
            <label></label>
            <button class="btn button-primary btn-block" type="button" 
                [disabled]="startDateControl.hasError('required') || endDateControl.hasError('required')"
                (click)="onDownloadClick(form)">Download</button>
        </div>
    </div>
</form>