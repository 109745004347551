import { ReviewItem } from '@apis/shared/models/review-item.model';
import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { Review } from '@apis/shared/models/review.model';
import { ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Event } from "@apis/shared/models/event.model";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import * as $ from "jquery";
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { AdjudicationService } from '../shared/services/adjudication.service';
import { EventTypes, JudicialReviewDecisionTypes, RepresentationTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { KeycloakService } from 'keycloak-angular';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { TimePipe } from '@apis/shared/pipes/time.pipe';
import { NgxSpinnerService } from "ngx-spinner";
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { Constants } from "@apis/shared/helpers/constants";
import { JudicialReviewActivityComponent } from './components/judicial-review-activity/judicial-review-activity.component';
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';

@Component({
  selector: 'app-judicial-review-details',
  templateUrl: './judicial-review-details.component.html',
  styleUrls: ['./judicial-review-details.component.scss']
})
export class JudicialReviewDetailsComponent implements OnInit {
  judicialReviewNumber: string;
  judicialReview: JudicialReview;
  review: Review;
  reviewItems: ReviewItem[];
  recentActivities: any[] = [];
  datePipe: DatePipe;
  timePipe: TimePipe;
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];
  applicantName: string;
  username: string;

  ReviewTypes = ReviewTypes;
  EventTypes = EventTypes;
  JudicialReviewDecisionTypes = JudicialReviewDecisionTypes;

  TypeTable = TypeTable;
  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;
  canReviewActivity: boolean = false;
  reviewTypeName: string;
  @ViewChild("judicialReviewActivity") judicialReviewActivityComponent: JudicialReviewActivityComponent;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly adjudicationService: AdjudicationService,
    private readonly documentService: DocumentService,
    readonly localStorageService: LocalStorageService,
    readonly viewContainerRef: ViewContainerRef,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) {

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
  }

  ngOnInit(): void {
    this.datePipe = new DatePipe("en-US");
    this.timePipe = new TimePipe();
    this.username = this.keycloakService.getUsername();
    this.activatedRoute.params.subscribe((data: Params) => {
      this.judicialReviewNumber = data.judicialReviewNumber;
      this.spinner.show();
      this.adjudicationService.getJudicialReviewByNumber(this.judicialReviewNumber)
        .subscribe((judicialReview: JudicialReview) => {
          this.loadData(judicialReview);
          this.spinner.hide();
        });
    });

    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canReviewActivity = this.localStorageService.hasPermission(Constants.Resource.JUDICIAL_REVIEW, Constants.Permission.REVIEW_ACTIVITY);
    });
  }

  private loadData(judicialReview: JudicialReview): void {
    this.judicialReview = judicialReview;

    if (this.reviewItems == null) {
      this.reviewItems = this.judicialReview.review.reviewItems;
    }

    this.populateRecentActivity();
    this.reviewTypeName = this.localStorageService.getTypeItemDescriptionById(+this.judicialReview?.review?.reviewTypeId, TypeTable.ReviewType);
    if (this.judicialReview?.review != null) {
      this.setApplicantName();
    }
  }

  private populateRecentActivity(): void {
    this.recentActivities = [];

    //Add all review events
    let events = this.judicialReview.events;
    this.pushEventsToActivities(events);

    this.recentActivities = this.recentActivities.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });

    this.recentActivities = this.recentActivities.length > 3 ? this.recentActivities.slice(0, 3) : this.recentActivities;

    setTimeout(() => {
      if ($(".recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".recent-activity-container:last-child")[0].offsetTop;
        $(".recent-activity-connector").height(offsetTop);
      }
    }, 100);
  }

  private pushEventsToActivities(events: Event[]) {
    if (events) {
      for (let event of events) {
        let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());
        let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
        let username = event.userName;
        let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
        let body = event.eventDetails;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          body: body,
          documents: event.documents,
          id: event.eventId,
          isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
        });
      }
    }
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.username
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  onTabHeaderClick(ev: any): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }

    let tabId = ev.target.id.split("_")[1];

    $(".tab-header-item").removeClass("selected");
    $(".tab-content-item").removeClass("selected");

    $(`#tabHeader_${tabId}`).addClass("selected");
    $(`#tab_${tabId}`).addClass("selected");
  }

  onDecisionUpdated(judicialReview: JudicialReview): void {
    this.loadData(judicialReview);
  }

  setApplicantName(): void {
    if (this.judicialReview.review.reviewTypeId == ReviewTypes.IrsReview)
      this.applicantName = `${this.judicialReview.review.driverFirstName ?? ""} ${this.judicialReview.review.driverLastName}`;
    else if (this.judicialReview.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview)
      this.applicantName = `${this.judicialReview.review.seizureDriverFirstName ?? ""} ${this.judicialReview.review.seizureDriverLastName}`;
    else if (this.judicialReview.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview)
      this.applicantName = `${this.judicialReview.review.reviewApplicant.firstName ?? ""} ${this.judicialReview.review.reviewApplicant.lastName}`;
    else
      this.applicantName = `${this.judicialReview.review.registeredOwnerFirstName ?? ""} ${this.judicialReview.review.registeredOwnerLastName}`;
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.judicialReviewId = this.judicialReview.judicialReviewId;
    componentRef.instance.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.judicialReview.events.push(result);
        this.populateRecentActivity();
        this.judicialReviewActivityComponent.populateRecentActivity();
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.judicialReview.events = this.judicialReview.events.filter(x => x.eventId !== eventId);
        this.judicialReview.events.push(result);
        this.populateRecentActivity();
        this.judicialReviewActivityComponent.populateRecentActivity();
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.judicialReview.events = this.judicialReview.events.filter(x => x.eventId !== eventId);
        this.populateRecentActivity();
        this.judicialReviewActivityComponent.populateRecentActivity();
      }
      this.viewContainerRef.clear();
    });
  }

  onEventDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    this.documentService.downloadDocument("", "Events", this.judicialReview.judicialReviewNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, document.documentName);
      },
        (error: any) => { });
  }

  reviewActivityTabSelected() {
    this.judicialReviewActivityComponent.setOffSetTopForReviewRecentActivity();
  }

  refreshJudicialReview(judicialReview: JudicialReview) {
    this.judicialReview = judicialReview;
    this.loadData(judicialReview);
  }

}
