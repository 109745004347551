<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Reschedule Review</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <form #rescheduleReviewForm="ngForm">
            <div class="modal-container-body">
                <div class="form-group mb-2">                
                    <label class="modal-body-label" for="oral">Review Method</label>
                    <div class="d-flex mt-1">                   
                        <div class="custom-control custom-radio custom-control-inline" 
                            *ngFor="let reviewMethodType of reviewMethodTypes; let i=index;"
                            [ngClass]="{'ml-4': i > 0}">
                            <input type="radio" class="custom-control-input" 
                                [(ngModel)]="review.reviewMethodTypeId"
                                [value]="reviewMethodType.id.toString()"
                                id="reviewMethodType_{{reviewMethodType.id}}"
                                name="reviewMethodTypeId"
                                (change)="onReviewMethodChange()"
                                [attr.disabled]="isReviewMethodTypeDisabled(reviewMethodType.id)"
                            />
                            <label class="custom-control-label" 
                                for="reviewMethodType_{{reviewMethodType.id}}">{{reviewMethodType.name}}</label>                                                
                        </div>                    
                    </div>                                                   
                </div>

                <div class="form-group row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == enumReviewMethodType.Oral">
                    <div class="col-12">
                        <app-failed-attendance-declaration
                        [review]="review"
                        [isFormSubmitted]="rescheduleReviewForm.submitted"
                        [isDisabled]="isReviewMethodTypeDisabled(review.reviewMethodTypeId)"
                        [isRSGPortal]="false">
                        </app-failed-attendance-declaration>
                    </div>
                </div>

                <div class="mb-3 auto-scheduler-container">
                    <app-auto-scheduler
                        class="adj-cal"
                        [scheduleDate]="reviewDateUtc"
                        [scheduleTime]="review.reviewTime"
                        [schedule]="availabilityView"
                        (onRefresh)="onScheduleRefreshClick()"
                        (scheduleDateSelected)="onScheduleDateSelected($event)"></app-auto-scheduler>
                </div>
                <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                    <p>
                        An error occurred. Please try again.
                    </p>
                </div>
                <div class="form-group mt-2 d-flex flex-column">
                    <label class="modal-body-label" for="eventDetails">Note</label>
                    <textarea 
                        id="eventDetails" 
                        name="eventDetails"
                        [(ngModel)]="eventDetails"
                        #note="ngModel"
                        class="modal-textarea"
                        [ngClass]="{ 'invalid': note.invalid && note.touched  }"                             
                        (input)="onTextareaInput()"></textarea>
                    <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>                
                    <span *ngIf="note.errors?.required != null && note.touched" 
                        class="invalid" style="margin-top: 6px;">Note is required</span>
                </div> 
            </div>
            <div class="form-group pt-2">
                <div class="info-box">
                    <div class="info-icon">
                        <i class="fas fa-info-circle fa-lg"></i>
                    </div>
                    <div class="info-box-main">A notification of this change will be sent to the applicant</div>
                </div>
            </div>
            <div class="button-container">
                <button id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</button>

                <button id="rescheduleButton" class="reschedule-button" [disabled]="rescheduleReviewForm.invalid && formSubmitted" 
                    (click)="onRescheduleClick($event)">
                    Reschedule
                    <i class="fas fa-cog fa-spin"></i>
                </button>
            </div>
            <div class="text-danger" *ngIf="isRescheduleRequestFailed">
                {{rescheduleErrorMessage}}
            </div>
        </form>
    </div>
</div>