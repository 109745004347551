


export class ContraventionView {
    public issueDate: Date = new Date();
    public contraventionNumber: string;
    public contraventionTypeId: number = 0;
    public recipientName: string;
    public fineAmount: number = 0;
    public dueAmount: number = 0;
    public contraventionStatusTypeId: number = 0;
    public hasDocumentsWithPendingSubmissions: boolean = false;
    public hasDocumentsRequiringRedaction: boolean = false;
    public isReServeRequired: boolean = false;    
    public reServeDate: Date;
    public contraventionTypeName: string;
    public contraventionStatusTypeName: string;

    constructor(init?: Partial<ContraventionView>) {
        
        Object.assign(this, init);
    }
}
