<div class="cancel-modal-overlay">
    <div class="cancel-modal">

        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Set this contravention as Uncollectable?</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body">
            <div>
                <span class="">Setting this contravention as Uncollectable will set the amount owing to $0.00. Are you sure?</span>
            </div>                       
            <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
                <span class="error">{{errorMessage}}</span>
            </div>
            <div class="button-container">
                <button id="btnCancelModalCancel" class="btn btn-cancel" (click)="onCancelClick()">Cancel</button>
                <button id="btnCancelModalConfirm" class="btn btn-confirm" (click)="onConfirmClick()">Confirm</button>
            </div>
            
        </div>
        
    </div>
</div>

