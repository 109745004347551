import { Component, OnInit } from '@angular/core';
import { Constants } from '@apis/shared/helpers/constants';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;
  
  canManageAdjudicators = false;
  canManageReviews = false;
  canViewCalendar = false;

  widgetId = "0";

  constructor(readonly localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canManageAdjudicators = this.localStorageService.hasPermission(Constants.Resource.DASHBOARD,Constants.Permission.MANAGE_ADJUDICATORS);
      this.canManageReviews = this.localStorageService.hasPermission(Constants.Resource.DASHBOARD,Constants.Permission.MANAGE_REVIEWS);
      this.canViewCalendar = this.localStorageService.hasPermission(Constants.Resource.DASHBOARD,Constants.Permission.VIEW_CALENDAR);

      if (this.canViewCalendar) {
        this.widgetId = "3";
      }else if (this.canManageReviews) {
        this.widgetId = "2";
      }else if (this.canManageAdjudicators) {
        this.widgetId = "1";
      }
    });
  }
}
