import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CalendarUtils,
  CalendarWeekViewComponent,
  DateAdapter,
  getWeekViewPeriod,
} from 'angular-calendar';
import {
  WeekView,
  GetWeekViewArgs,
  WeekViewTimeEvent,
  EventColor,
  CalendarEvent,
  WeekViewAllDayEventRow,
  WeekViewAllDayEvent,
  WeekViewHourColumn,
} from 'calendar-utils';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import { addDays, eachDayOfInterval, endOfDay, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';

export interface Resource {
  id: number;
  name: string;
}

interface WeekViewScheduler extends WeekView {
  resources: Resource[];
}

interface GetWeekViewArgsWithResources extends GetWeekViewArgs {
  resources: Resource[];
}

@Injectable()
export class WeekViewSchedulerCalendarUtils extends CalendarUtils {
  getWeekView(args: GetWeekViewArgsWithResources): WeekViewScheduler {
    console.log('args ', args);
    const { period } = super.getWeekView(args);
    const view: WeekViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      resources: [...args.resources],
    };

    view.resources.forEach((resource, columnIndex) => {      
      const events = args.events.filter(        
        (event) => event.meta.resource.id === resource.id
      );
      const columnView = super.getWeekView({
        ...args,
        events,
      });

      // Swap col position to row
      columnView.hourColumns.forEach(c => {
        c.events.forEach(e => {
          var height = e.height;
          e.height = 30;

          e.width = 70 * (height / 30);

          var top = e.top;
          e.top = 0;

          e.left = 70 * (top / 30);
        });
      });

      view.hourColumns = view.hourColumns.concat(columnView.hourColumns);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });

    return view;
  }
}

@Component({
  selector: 'resource-time-line-week-view',
  templateUrl: './resource-time-line-week-view.component.html',
  styleUrls: ['./resource-time-line-week-view.component.scss'],
  providers: [WeekViewSchedulerCalendarUtils],
})
export class ResourceTimeLineWeekViewComponent  extends CalendarWeekViewComponent
  implements OnChanges {
  @Input() resources: Resource[] = [];

  @Output() resourceChanged = new EventEmitter();

  view: WeekViewScheduler;

  daysInWeek = 5;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: WeekViewSchedulerCalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    protected elementRef: ElementRef
  ) {
    super(cdr, utils, locale, dateAdapter, elementRef);
  }

  trackByResourceId = (index: number, row: Resource) => row.id;

  trackByDay = (index: number, obj: any) => index;

  trackHourColumn = (index: number, obj: any) => +obj.date + index;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.resources) {
      this.refreshBody();
      this.emitBeforeViewRender();
    }

  }

  getWeekDays() {
    const weekDays = eachDayOfInterval({
      start: addDays(startOfWeek(this.viewDate), 1),
      end: addDays(endOfWeek(this.viewDate), -1)
    });

    return weekDays;
  }

  getDayHourColumns(day: Date, hourColumns: WeekViewHourColumn[]) {
    return hourColumns.filter(c => +c.date == +day);
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.resources.length);
  }

  protected getWeekView(events: CalendarEvent[]) {
    return this.utils.getWeekView({
      events,
      resources: this.resources,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      viewStart: addDays(startOfWeek(this.viewDate), 1),
      viewEnd: addDays(startOfWeek(this.viewDate), this.daysInWeek),
    });
  }
}
