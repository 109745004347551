import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DocumentService } from '@apis/shared/services/document.service';
import * as $ from "jquery";
import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Document } from '@apis/shared/models/document.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DateUtil } from '@apis/shared/helpers/date-util';

@Component({
  selector: "document-upload",
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit, AfterViewInit {
  @Input() tempFileFolder: string;
  @Input() documentRefTypeName: string;
  @Input() documentRefTypeNumber: string;
  @Input() documentTypeId: number;
  @Input() showDropZoneAfterUpload: boolean = false;
  @Input() uploadAndFinalize: boolean = false;
  @Input() disableRemoveDocument: boolean = false;
  @Input() validFileExtensions: string[];
  
  @Output() documentAdded = new EventEmitter<Document>();
  @Output() documentDeleted = new EventEmitter();
  
  documentTypeName: string;
  documentName: string;
  documentExtension: string;
  uploadedDocument: Document;
  storageFileName: string;
  dropZoneText: string = "Drag and drop or click to upload";
  private selectedFile: File;

  @ViewChild("dropZone") dropZone: ElementRef;
  @ViewChild("uploadFileInput") uploadFileInput: ElementRef;
  @ViewChild("uploadContainer") uploadContainer: ElementRef;
  @ViewChild("uploadBarProgress") uploadBarProgress: ElementRef;
  @ViewChild("removeFileLink") removeFileLink: ElementRef;

  get isDocumentSelected(): boolean {
    return this.documentName?.length > 0;
  }

  constructor(private readonly documentService: DocumentService,
    private readonly localStorageService: LocalStorageService) { }

  ngAfterViewInit(): void {    
    this.setDragDropEvents();
  }

  ngOnInit(): void {  
    if (!this.validFileExtensions)
    {
      this.validFileExtensions = [".pdf", ".docx", ".doc", 
      ".jpg", ".jpeg", ".gif", ".png", 
      ".avi", ".mkv", ".mov", ".mp4", 
      ".mpg", ".wma", ".wmv", ".mp3", 
      ".wav", ".txt", ".wpd"];
    }

    if (this.tempFileFolder == null) {
      this.tempFileFolder = Guid.create().toString();
    }

    this.documentTypeName = this.localStorageService.getTypeItemDescriptionById(this.documentTypeId, TypeTable.DocumentType);
  }

  ngOnChanges(): void {
    if (this.disableRemoveDocument) {
      this.removeFileLink.nativeElement.classList.add("disabled");
    }
  }

  private setDragDropEvents() {        
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName: string) => {      
      document.body[`on${eventName}`] = this.preventDefaults;             
    });
            
    this.dropZone.nativeElement.ondragenter = (ev: any) => {
      this.preventDefaults(ev);
      this.dropZone.nativeElement.classList.add("highlight");
    };

    this.dropZone.nativeElement.ondragover = (ev: any) => {
      this.preventDefaults(ev);
      this.dropZone.nativeElement.classList.add("highlight");
    };

    this.dropZone.nativeElement.ondragleave = (ev: any) => {
      this.preventDefaults(ev);
      this.dropZone.nativeElement.classList.remove("highlight");
    };
    
    this.dropZone.nativeElement.ondrop = (ev: any) => {        
      this.preventDefaults(ev);
      this.dropZone.nativeElement.classList.remove("highlight");
      this.handleDrop(ev);
    };    
  }

  private preventDefaults(ev: any): void {    
    ev.preventDefault();
    ev.stopPropagation();    
  }

  private handleDrop(ev: any): void {           
    let files = ev.dataTransfer.files;
    
    for (let index = 0; index < files.length; index++) {
      let file = files[index];                 
      this.uploadFile(file);
    }        
  }
    
  onUploadFileChanged(ev: any): void {
    var fileList = (ev.target.files as FileList);
    
    for (let index = 0; index < fileList.length; index++) {
      let file = fileList[index];      
      this.uploadFile(file);
    }   
  } 

  uploadFile(file: File): void {   
    this.selectedFile = file;
    this.uploadFileInput.nativeElement.value = null;
    let lastIndex = file.name.lastIndexOf(".");
    this.documentExtension = file.name.substring(lastIndex);     
    let fileWithoutExtension = file.name.substring(0, lastIndex);    

    if (!this.validFileExtensions.includes(this.documentExtension.toLowerCase())) {
      this.dropZone.nativeElement.classList.add("alert-danger");
      $(".drop-zone span").text(`${this.documentExtension} is an invalid file extension. Please try again.`);
      return;
    }
        
    this.dropZone.nativeElement.classList.remove("alert-danger");
    $(".drop-zone span").text(this.dropZoneText);
    this.documentName = file.name;
   
    this.dropZone.nativeElement.classList.remove("show");
    this.uploadContainer.nativeElement.classList.add("show");

    this.storageFileName = Guid.create().toString();

    this.documentService.uploadDocumentAsync(file, this.tempFileFolder, this.storageFileName)
      .subscribe((result: any) => {                  
        if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            this.uploadBarProgress.nativeElement.innerHTML = `${percentage}%`;  
            this.uploadBarProgress.nativeElement.style.width =` ${percentage}%`;
         
        } else if (+result.type == 4) {
          this.uploadedDocument = new Document({
            contentGuid: this.storageFileName,
            documentTypeId: this.documentTypeId,
            documentName: this.documentName,            
            documentExtension: this.documentExtension.replace(".", ""),
            documentSize: this.getDocumentSize(),
            uploadedDate: DateUtil.today(),
            isSubmitLater: false,
            isPublished: true
          });

          this.documentAdded.emit(this.uploadedDocument);

          if (this.showDropZoneAfterUpload) {
            this.showDropZone();
          } else {
            this.uploadBarProgress.nativeElement.innerHTML = "Upload Complete";
            this.removeFileLink.nativeElement.classList.remove("disabled");
          }   
          
          if (this.uploadAndFinalize) {
            this.finalizeUpload();
          }                                  
        }            
      });
  }

  finalizeUpload(): void {
    this.documentService.finalizeDocuments(this.tempFileFolder, this.documentRefTypeName, this.documentRefTypeNumber)
      .subscribe((result: any) => {});  
  }

  onDocumentNameClick(ev: any): void {
    let storageFileName = `${this.uploadedDocument.contentGuid}.${this.uploadedDocument.documentExtension}`;    
    this.documentService.downloadDocument(this.tempFileFolder, "", "", storageFileName, this.uploadedDocument.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, this.documentName);          
        },
        (error: any) => {});
  }

  onRemoveFileLinkClick(): void {
    if (!this.disableRemoveDocument && this.uploadedDocument) {
      let storageFileName = `${this.uploadedDocument.contentGuid}.${this.uploadedDocument.documentExtension}`;
      this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
        .subscribe((result: any) => {
          this.showDropZone();
          this.documentDeleted.emit();
        },
        (error: any) => {});
    }
  }  

  removeDocument(): void {
    if (this.selectedFile != null) {
      this.onRemoveFileLinkClick();
    }
  }
  
  showDropZone(): void {
    this.documentName = "";
    this.selectedFile = null;
    this.uploadedDocument = null;
    this.uploadContainer.nativeElement.classList.remove("show");
    this.removeFileLink.nativeElement.classList.add("disabled");
    this.dropZone.nativeElement.classList.add("show");      
  }

  getDocumentSize(): string {
    if (this.selectedFile == null) {
      return "";
    }

    let size = this.selectedFile.size;

    if (size < 1024) {
      return `${size} bytes`;
    }
      
    if (size >= 1024 && size < 1048576) {
      return `${(size/1024).toFixed(2)}kb`;
    }
      
    if (size >= 1048576 && size < 1073741824) {
      return `${((size/1024)/1024).toFixed(2)}mb`;
    }
          
    if (size >= 1073741824) {
      return `${(((size/1024)/1024)/1024).toFixed(2)}gb`;  
    }      
  }
}
