import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';

@Component({
  selector: 'applicant-availability-modal',
  templateUrl: './applicant-availability-modal.component.html',
  styleUrls: ['./applicant-availability-modal.component.scss']
})
export class ApplicantAvailabilityModalComponent implements OnInit {
  @Input() review: Review;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;  
  reviewPeriodStartDate: Date;
  reviewPeriodEndDate: Date;
  
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {         
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");   
            
    let startDate = new Date(this.review.reviewScheduleRequest.startDate);
    this.reviewPeriodStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    let endDate = new Date(this.review.reviewScheduleRequest.endDate);
    this.reviewPeriodEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
   
    if (this.reviewPeriodStartDate.valueOf() == this.reviewPeriodEndDate.valueOf()) {
      endDate.setDate(endDate.getDate() + 14);
      this.reviewPeriodEndDate = endDate;    
    }    
  }

  onCloseClick(): void {       
    this.bodyElement.removeClass("overflow-hidden");    
    this.close.emit();
  }    
}
