import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, Subscriber, throwError } from 'rxjs';
import { map, find, catchError } from "rxjs/operators";
import { environment } from "apps/adjudication/src/environments/environment";
import { SeizureView } from '@apis/shared/models/seizure-view.model';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DisclosuresResult } from '@apis/shared/models/disclosures-result.model';
import { DisclosureInfo } from '@apis/shared/models/disclosure-info.model';
import { DisclosureDocument } from '@apis/shared/models/disclosure-document.model';

@Injectable()
export class DisclosureService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }

  getDisclosures(ticketNumber: string): Observable<DisclosuresResult> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/disclosures/${ticketNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => new DisclosuresResult(response))
    );
  }

  downloadDisclosures(ticketNumber: string, disclosures: DisclosureInfo[]): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/disclosures/${ticketNumber}/download`, disclosures, 
    { headers: {'Authorization': `Bearer ${this.user.token}`},  responseType: 'blob' });
  }

  deleteDisclosure(ticketNumber: string, disclosure: DisclosureInfo): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/disclosures/${ticketNumber}`, {
      headers: {'Authorization': `Bearer ${this.user.token}`},
      params: {
        "documentName": disclosure.name
      }
    });
  }
}
