import { Component, OnInit } from '@angular/core';
import { SchedulerService } from '../../../shared/services/scheduler.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { AdjudicatorSchedule } from '../../../shared/models/adjudicator-schedule.model';
import { MeetingTypes } from '@apis/shared/enums/app.enum';
import { Schedule } from '@apis/shared/models/schedule.model';
import { UserSchedule } from '@apis/shared/models/user-schedule.model';
import { Constants } from "@apis/shared/helpers/constants";

@Component({
  selector: 'manage-adjudicators',
  templateUrl: './manage-adjudicators.component.html',
  styleUrls: ['./manage-adjudicators.component.scss']
})
export class ManageAdjudicatorsComponent implements OnInit {
  adjudicatorScheduleList: AdjudicatorSchedule[];
  userScheduleList: UserSchedule[];
  errorMessage: string;
  maxCountMask= "0";
  maxCount99Mask = "90";

  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;

  isDisabled = false;
  canEditAdjudicators = false;

  constructor(
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly schedulerService: SchedulerService) {
   }

  ngOnInit(): void {
    this.loadAdjudicators();
    this.isDisabled = !this.localStorageService.hasPermission(Constants.Resource.MANAGE_ADJUDICATORS_DASHBOARD,Constants.Permission.EDIT_REVIEW_MAX_BOOKINGS);          
    this.canEditAdjudicators = this.localStorageService.hasPermission(Constants.Resource.DASHBOARD,Constants.Permission.MANAGE_ADJUDICATORS_EDIT);          
  }

  onResetClick() {
    this.errorMessage = '';
    this.loadAdjudicators();
  }

  onSubmitClick(isValid: boolean) {
    this.errorMessage = '';

    // Return if form is invalid.
    if (!isValid) return;

    this.updateModel();

    this.spinner.show();
    this.schedulerService.postSchedules(this.userScheduleList)
      .subscribe((result: UserSchedule[]) => {
        this.userScheduleList = result;
        this.adjudicatorScheduleList = result.map(u => this.flattenUserSchedule(u));
        this.spinner.hide();
      }, (error: any) => {
        this.errorMessage = 'An error occurred while processing your request. Please try again.';
        this.spinner.hide();
      });      
  }

  trackAdj(index: number, obj: any): any {
    return index;
  }

  loadAdjudicators() {
    this.spinner.show();
    this.schedulerService.getSchedules()
      .subscribe((result: UserSchedule[]) => {
        this.userScheduleList = result;
        this.adjudicatorScheduleList = result.map(u => this.flattenUserSchedule(u));
        this.spinner.hide();
      }, (error: any) => this.spinner.hide());      
  }

  flattenUserSchedule(userSchedule: UserSchedule) {
    return new AdjudicatorSchedule({
      userId: userSchedule.userId,
      firstName: userSchedule.firstName,
      lastName: userSchedule.lastName,
      meetingAccount: userSchedule.meetingAccount,
      irsWrittenReviewMaxBookings: userSchedule.schedules?.find(s => +s.meetingTypeId == MeetingTypes.IRSWrittenReview)?.maxBookingsPerDay,
      irsOralReviewMaxBookings: userSchedule.schedules?.find(s => +s.meetingTypeId == MeetingTypes.IRSOralReview)?.maxBookingsPerDay,
      seizureReviewMaxBookings: userSchedule.schedules?.find(s => +s.meetingTypeId == MeetingTypes.SeizureReview)?.maxBookingsPerDay,
      lateReviewMaxBookings: userSchedule.schedules?.find(s => +s.meetingTypeId == MeetingTypes.LateReview)?.maxBookingsPerDay
    });
  }
  
  updateModel() {
    this.adjudicatorScheduleList.forEach(s => {
      var userSchedule = this.userScheduleList.find(a => a.userId == s.userId);
      userSchedule.meetingAccount = s.meetingAccount;

      this.updateScheduleModel(MeetingTypes.IRSWrittenReview, s.irsWrittenReviewMaxBookings, userSchedule);
      this.updateScheduleModel(MeetingTypes.IRSOralReview, s.irsOralReviewMaxBookings, userSchedule);
      this.updateScheduleModel(MeetingTypes.SeizureReview, s.seizureReviewMaxBookings, userSchedule);
      this.updateScheduleModel(MeetingTypes.LateReview, s.lateReviewMaxBookings, userSchedule);
    });
  }

  updateScheduleModel(meetingTypeId: number, maxBookingsPerDay: number, userSchedule: UserSchedule) {
    var schedule = userSchedule.schedules?.find(s => +s.meetingTypeId == +meetingTypeId);

    if (schedule == null && maxBookingsPerDay != undefined) {
      schedule = new Schedule({
        userId: userSchedule.userId,
        meetingTypeId: meetingTypeId
      });
      userSchedule.schedules.push(schedule);
    }

    if (schedule) {
      schedule.maxBookingsPerDay = +maxBookingsPerDay;
    }
  }
}
