import { Component, OnInit, EventEmitter } from '@angular/core';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from "file-saver";
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { KeycloakService } from 'keycloak-angular';
import { DocumentTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-publish-original-modal',
  templateUrl: './publish-original-modal.component.html',
  styleUrls: ['./publish-original-modal.component.scss']
})
export class PublishOriginalModalComponent implements OnInit {
  document: Document;
  close: EventEmitter<Document> = new EventEmitter<Document>();
  
  body: JQuery<HTMLElement>;  
  modalType: string;  
  redactionModalDragDrop: HTMLElement;
  redactionModalUpload: HTMLElement;
  redactionModalPublish: HTMLElement;
  publishButton: HTMLElement;
   
  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  errorMessage: string = "";

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {   
    this.body = $(document.body);    
    this.body.addClass("overflow-hidden");      
  }

  getDocumentTypeName(document: Document): string {
    var documentTypeName = `${this.localStorageService.getDocumentTypes().find(x => x.id == document.documentTypeId)?.name}
      ${(document.documentDescription == null || document.documentDescription == "") ? "" : " " +  document.documentDescription}`;

    return documentTypeName;
  }
  
  onCloseIconClick(): void {  
    this.close.emit(null);
    this.hideModal();    
  }

  onCancelClick(ev: any): void {    
    this.close.emit(null); 
    this.hideModal();
  }

  private hideModal() {        
    this.body.removeClass("overflow-hidden");        
  }
  
  onPublishClick(ev: any): void {
    this.errorMessage = "";
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.document.isPublished = true;  
      
    //Call update document method
    this.spinner.show(); 
    this.documentService.updateDocumentAsync(this.document)
      .subscribe((result: any) => {          
          this.spinner.hide(); 
          this.close.emit(result);
          this.hideModal();
        },
        (error: any) => {          
          this.errorMessage = "Unable to publish the document. Please try later."; 
          this.spinner.hide(); 
        });
  }  
}