import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SeizureDetailsComponent } from './seizure-details.component';
import { SeizureInformationComponent } from './components/seizure-information/seizure-information.component';
import { SeizureRecipientInformationComponent } from './components/seizure-recipient-information/seizure-recipient-information.component';
import { SharedModule } from "@apis/shared/shared.module";
import { SupportingDocumentsModule } from '../shared/components/supporting-documents/supporting-documents.module';
import { NgxMaskDirective } from 'ngx-mask';
import { CancelSeizureModalComponent } from './modals/cancel-seizure-modal/cancel-seizure-modal.component';
import { SeizureActivityComponent } from './components/seizure-activity/seizure-activity.component';
import { DriverLogModule } from '../shared/components/driver-log/driver-log.module';

@NgModule({
  declarations: [
    SeizureDetailsComponent,
    SeizureInformationComponent,
    SeizureRecipientInformationComponent,
    CancelSeizureModalComponent,
    SeizureActivityComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    SupportingDocumentsModule,
    NgxMaskDirective,
    DriverLogModule
  ]
})
export class SeizureDetailsModule { }
