<div class="status-checkbox-container" 
    *ngFor="let statusType of statusTypes;"
    (click)="onStatusCheckboxClick($event)"
    id="statusCheckboxContainer_{{statusType.id}}"> 
    <ng-container *ngIf="statusType.id<1000 || 
        (getActiveStatusTypeIds().includes(this.ReviewStatusTypes.Complete) || getActiveStatusTypeIds().includes(this.RequestStatusTypes.Complete))">
        <div *ngIf="statusType.id<1000" class="status-checkbox"></div>
        <div *ngIf="statusType.id>1000" class="status-checkbox sub-status-checkbox"></div>
        <div class="status-pill {{statusType.name.toLowerCase().replace(' ', '-')}}">
            {{statusType.name}}
        </div>
    </ng-container>  
</div>
