
export class JudicialReviewPagedSearch {
    public pageNumber: number;
    public pageSize: number;
    public searchTerm: string;
    public orderBy: string;
    public sortDirection: string    

    constructor(init?: Partial<JudicialReviewPagedSearch>) {
        
        Object.assign(this, init);
    }
}
