import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogService } from '../../../shared/services/log.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogSearchRequestModel } from '../../models/log-search-request.model';

@Component({
  selector: 'driver-log',
  templateUrl: './driver-log.component.html',
  styleUrls: ['./driver-log.component.scss']
})
export class DriverLogComponent implements OnInit {
  @Input() getSearchRequestCallback: () => LogSearchRequestModel;
  @Output() onSelectionChanged = new EventEmitter<any>();

  hasErrors: boolean;

  constructor(private readonly spinner: NgxSpinnerService,
    private readonly logService: LogService) { }

  ngOnInit(): void {
  }

  onShowDriverLogsChange(ev: any): void {
    this.hasErrors = false;  

    if (ev.target.checked) {
      this.spinner.show();       
      var searchRequest = this.getSearchRequestCallback();

      this.logService.getDriverLogs(searchRequest)
        .subscribe((result: any) => {
          this.onSelectionChanged.emit(result);
          this.spinner.hide();
        },
        (error: any) => {
          this.hasErrors = true;  
          this.spinner.hide();        
        });
    } else {
      this.onSelectionChanged.emit();
    }
  }
}
