export const Colors: any = {
    Red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    Blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    Yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    SteelBlue: {
        primary: '#3f7784',
        secondary: '#47ded9'
    },
    Spruce: {
        primary: '#6cc5a9',
        secondary: '#e0e9ce'
    },
    Green: {
        primary : '#3bb44d',
        secondary: '#ace6b4'
    },
    Orange: {
        primary: '#f05c2c',
        secondary: '#ffac42'
    }
  };