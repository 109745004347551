<div id="sideNav" class="side-nav">
    <div id="sideNavArrow" class="side-nav-arrow side-nav-left-arrow" (click)="onSideNavLeftArrowClick()">
        <i id="arrowIcon" class="fas fa-chevron-left"></i>
    </div>
    <ul>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.DASHBOARD">
            <li><a [routerLink]="['/dashboard']" [ngClass]="{'selected': isSelected('dashboard') }">DASHBOARD</a></li>
        </app-access-control>        
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.REVIEWS">
            <li><a [routerLink]="['/reviews']" [ngClass]="{'selected': isSelected('/reviews') }">REVIEWS</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.REQUESTS">
            <li><a [routerLink]="['/requests']" [ngClass]="{'selected': isSelected('/requests') }">REQUESTS</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.CONTRAVENTIONS">
            <li><a [routerLink]="['/contraventions']" [ngClass]="{'selected': isSelected('/contraventions') }">CONTRAVENTIONS</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.SEIZURES">
            <li><a [routerLink]="['/seizures']" [ngClass]="{'selected': isSelected('/seizures') }">SEIZURES</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.REPORTS">
            <li><a [routerLink]="['/reports']" [ngClass]="{'selected': isSelected('/reports') }">REPORTS</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.FINANCE">
          <li><a [routerLink]="['/finance']" [ngClass]="{'selected': isSelected('finance') }">FINANCE</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.JUDICIAL_REVIEWS">
            <li><a [routerLink]="['/judicial-reviews']" [ngClass]="{'selected': isSelected('judicial-review') }">JUDICIAL REVIEWS</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.TOW_COMPANIES">
            <li><a [routerLink]="['/tow-companies']" [ngClass]="{'selected': isSelected('tow-companies') }">TOW COMPANIES</a></li>
        </app-access-control>
        <app-access-control [Resource]="Resource.SIDE_NAV_COMPONENT" [Permission]="Permission.TRAFFIC_DISCLOSURE">
            <li><a [routerLink]="['/traffic-disclosure']" [ngClass]="{'selected': isSelected('traffic-disclosure') }">TRAFFIC DISCLOSURE</a></li>
        </app-access-control>
    </ul>
</div>
