


export class ReviewPagedSearch {
    public pageNumber: number;
    public pageSize: number;
    public searchTerm: string;
    public orderBy: string;
    public sortDirection: string
    public reviewTypeId: number;
    public reviewMethodTypeId: number;
    public reviewStatusTypeIds: string;
    public excludeReviewStatusTypeIds: string;
    public userId: number;
    public checkRequiresRedaction: boolean;
    public checkPendingSubmissions: boolean;
    public checkRequiresCommunication: boolean;
    public decisionTypeIds: string;
    public checkRequiresAction: boolean;

    constructor(init?: Partial<ReviewPagedSearch>) {
        
        Object.assign(this, init);
    }
}
