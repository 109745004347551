<div class="modal-overlay">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-container-title">
                <span>Delete </span>                
                {{document == null ? "" : getDocumentTypeName(document)}}
            </div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>              
        <div class="modal-container-body">
            <div class="alert-icon">                
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="publish-text">
                Deleting this file will remove it from the system. This action cannot be undone.<br />Are you sure?
            </div>            
        </div>
        <div *ngIf="errorMessage.length > 0">
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="d-flex justify-content-end">
            <div class="cancel-button" (click)="onCancelClick($event)" id="cancelButton">Cancel</div>
            <div id="deleteButton" class="delete-button" (click)="onDeleteClick($event)">Delete</div>
        </div>

    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
</div>

