


export class ReviewView {
    public receivedDate: Date = new Date();
    public reviewNumber: string;
    public decisionDueDate: Date;
    public reviewDate: Date;
    public reviewTime: string;
    public applicantName: string;
    public reviewTypeId: number = 0;
    public reviewMethodTypeId: number = 0;
    public userId: number;
    public adjudicatorName: string;
    public reviewStatusTypeId: number = 0;
    public hasDocumentsWithPendingSubmissions: boolean = false;
    public hasDocumentsRequiringRedaction: boolean = false;
    public contactMethodTypeId: number = 0;
    public isPhoneContactRequired: boolean = false;
    public reviewDateTime: string;
    public reviewTypeName: string;
    public reviewMethodTypeName: string;
    public reviewStatusTypeName: string;
    public decisionTypeId: number;

    constructor(init?: Partial<ReviewView>) {
        
        Object.assign(this, init);
    }
}
