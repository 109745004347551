import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../../../../../../libs/shared/services/local-storage.service";

@Pipe({
    name: "reviewStatusType"
})
export class ReviewStatusTypePipe implements PipeTransform {
    constructor(private readonly localStorageService: LocalStorageService) {}

    transform(val: number): string {
        let reviewStatusType = this.localStorageService.getReviewStatusTypes().find(type => +type.id === +val);

        if (reviewStatusType != null) {
            return reviewStatusType.name;
        }

        return "";
    }
}