<div class="heading-table">Supporting Documents</div>
<div class="d-flex mb-3">        
    <table class="table">
        <thead>
            <tr>
                <th>Type</th>
                <th>Filename</th>
                <ng-container *ngIf="showPublishedDate">
                    <th>Published Date</th>
                    <th>Uploaded Date</th>
                </ng-container>
                <th *ngIf="!showPublishedDate">Date</th>
                <th>Submitter</th>
                <th style="width: 75px;">Format</th>
                <th style="width: 75px;">Size</th>
                <th>Visibility</th>   
                <th *ngIf="hasUpdateAccess"></th>                     
            </tr>
        </thead>
                    
        <tbody *ngIf="supportingDocuments?.length > 0">
            
            <tr *ngFor="let supportingDocument of supportingDocuments" 
                [ngClass]="getRowCssClass(supportingDocument)">
                <td [title]="getDocumentTypeName(supportingDocument, true)">
                    <div class="d-flex align-items-center h-100">                        
                        <div class="alert-icon" *ngIf="!isPendingResults(supportingDocument) && isRedactionRequired(supportingDocument)">
                            <i class="fas fa-exclamation-triangle fa-lg"></i>                                
                        </div>
                        <div class="warning-icon" *ngIf="isPendingResults(supportingDocument)">                
                            <i class="fas fa-exclamation-triangle fa-lg"></i>
                        </div>
                        <div class="document-type-name" [ngClass]="{'requires-redaction': supportingDocument.isPublished == null }">
                            {{getDocumentTypeName(supportingDocument, false)}}
                        </div>
                    </div>                                            
                </td>
                <td [title]="supportingDocument.documentName">
                    <div *ngIf="!isPendingResults(supportingDocument) && supportingDocument.documentName != null" class="d-flex align-items-center">
                        <img class="download-file-icon" src="assets/images/download-file.png" (click)="onDownloadDocumentClick(supportingDocument)"/>
                        <a class="download-file-link" 
                            (click)="onDownloadDocumentClick(supportingDocument)">{{supportingDocument.documentName}}</a>
                    </div>        
                    <div *ngIf="isPendingResults(supportingDocument) || supportingDocument.documentName == null" class="d-flex align-items-center">
                        <div>Pending Submission</div>
                    </div>
                </td>
                <ng-container *ngIf="showPublishedDate">
                    <td>
                        <div class="date">{{supportingDocument.publishedDate | date:"mediumDate"}}</div>                    
                    </td>
                    <td>
                        <div class="date">{{supportingDocument.uploadedDate | date:"mediumDate"}}</div>                    
                    </td>
                </ng-container>
                <ng-container *ngIf="!showPublishedDate">
                    <td *ngIf="supportingDocument.documentTypeId == DocumentTypes.WitnessStatements && supportingDocument.publishedDate != null; else showUploadDate">{{supportingDocument.publishedDate | date:"mediumDate"}}</td>
                    <ng-template #showUploadDate>
                        <td>
                            <div class="date">{{supportingDocument.uploadedDate | date:"mediumDate"}}</div>                    
                        </td>
                    </ng-template>
                </ng-container>
                <td [title]="supportingDocument.uploadedBy">
                    <div class="uploaded-by">{{supportingDocument.uploadedBy}}</div>                    
                </td>
                <td>
                    <div class="format">{{(supportingDocument.documentExtension == null || supportingDocument.documentExtension == "")? "" : "." + supportingDocument.documentExtension.toUpperCase() }}</div>                    
                </td>
                <td>{{supportingDocument.documentSize}}</td>
                <td>
                    <ng-container *ngIf="!isRedactionRequired(supportingDocument) || (!canUpdateDocument && !canUploadRedatedDocument)">
						{{supportingDocument.documentTypeId == DocumentTypes.WitnessStatements && supportingDocument.isPublished == null ? "Hidden" : supportingDocument.isPublished == null ? "" : supportingDocument.isPublished ? "Published" : "Hidden" }}
					</ng-container>                        
					<ng-container *ngIf="isRedactionRequired(supportingDocument) && (canUpdateDocument || canUploadRedatedDocument)">
                        <select class="form-control redaction-dropdown" (change)="onRedactionDropdownChange($event, supportingDocument)">
								<option class="redaction-dropdown-option" value="0">Hidden</option>
								<option class="redaction-dropdown-option" value="1" *ngIf="canUpdateDocument">Publish Original</option>
								<option class="redaction-dropdown-option" value="2" *ngIf="canUploadRedatedDocument">Upload Redacted Version</option>
							</select>                                                        
					</ng-container>                           
                </td> 
                <td>
                    <app-access-control [Resource]="Resource.SUPPORTING_DOCUMENT" [Permission]="Permission.DELETE_DOCUMENT">
                        <a class="delete-document-link" [ngClass]="{'hide': supportingDocument.isDeleted || isPendingResults(supportingDocument) }"
                            (click)="onDeleteDocumentLinkClick($event, supportingDocument)">delete</a>                 
                    </app-access-control>
                    <app-access-control [Resource]="Resource.SUPPORTING_DOCUMENT" [Permission]="Permission.UPLOAD_DOCUMENT">
                        <a class="document-action-link" [ngClass]="{'hide': supportingDocument.isDeleted || (supportingDocument.documentName != null && !isPendingResults(supportingDocument)) }"
                            (click)="onUploadDocumentLinkClick($event, supportingDocument)">upload</a>    
                    </app-access-control>
                </td>
            </tr>                      
        </tbody>
        <tbody *ngIf="supportingDocuments?.length == 0">
            <tr>
                <td colspan="7">No supporting documents found.</td>
            </tr>
        </tbody> 
    </table>   
</div>
<app-access-control [Resource]="Resource.SUPPORTING_DOCUMENT" [Permission]="Permission.ADD_ADDITIONAL_DOCUMENT">
    <button class="btn button-secondary-outline float-right mb-4" (click)="AddOptionalDocument()">Add Additional Document</button>
</app-access-control>