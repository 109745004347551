import { DecisionTypes, JudicialReviewDecisionTypes } from './../../../../../../../libs/shared/enums/app.enum';
import { Component, OnInit, Input, Output, ViewContainerRef, OnChanges, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes } from '@apis/shared/enums/app.enum';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { KeycloakService } from 'keycloak-angular';
import { Event } from "@apis/shared/models/event.model";
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { AdjudicationService } from '../../../shared/services/adjudication.service';

@Component({
  selector: 'judicial-review-activity',
  templateUrl: './judicial-review-activity.component.html',
  styleUrls: ['./judicial-review-activity.component.scss']
})
export class JudicialReviewActivityComponent implements OnInit, OnChanges {
  @Input() judicialReview: JudicialReview;
  @Output() activityNoteSubmitted = new EventEmitter<JudicialReview>();
  
  recentActivities: any[] = [];  
  TypeTable = TypeTable;
  JudicialReviewDecisionTypes = JudicialReviewDecisionTypes;
  EventTypes = EventTypes;
  username: string;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
 
  constructor(private readonly localStorageService: LocalStorageService,
    private adjudicationService: AdjudicationService,    
    readonly viewContainerRef: ViewContainerRef,
    private readonly keycloakService: KeycloakService,
    private readonly documentService: DocumentService) { }

  ngOnInit(): void {         
    this.username = this.keycloakService.getUsername();
  }

  ngOnChanges(): void {    
     if(this.judicialReview != null) {

      if (this.judicialReview.reReviewNumber != null) {
        this.adjudicationService.getReviewByNumber(this.judicialReview.reReviewNumber).subscribe(reReview => {
          this.judicialReview.reReview = reReview;
        }, error => {});
      }

      this.populateRecentActivity();
     }
  }

  public populateRecentActivity(): void {
    this.recentActivities = [];
    
    //Add all judicial review events
    let events = this.judicialReview.events;    
    this.pushEventsToActivities(events);

    this.recentActivities = this.recentActivities?.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });    

    this.setOffSetTopForReviewRecentActivity();
  }

  private pushEventsToActivities(events: Event[])
  {
    let datePipe = new DatePipe("en-US");
    if (events)
    {
      for (let event of events) {          
        let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = datePipe.transform(eventDateTime, "HH:mm");
        let username = event.userName;
        let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
        let body = event.eventDetails;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          body: body,
          documents: event.documents,
          id: event.eventId,
          isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
        });
      }
    }
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.username
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  public setOffSetTopForReviewRecentActivity() {
    setTimeout(() => {  
      if ($(".review-recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".review-recent-activity-container:last-child")[0].offsetTop;    
          $(".review-recent-activity-connector").height(offsetTop);
          $(".review-recent-activity-group").animate({ scrollTop: 0 }, "fast");
        }
      }, 100);      
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.judicialReviewId = this.judicialReview.judicialReviewId;    
    componentRef.instance.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.judicialReview.events.push(result);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.judicialReview);
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.judicialReview.events = this.judicialReview.events.filter(x=>x.eventId !== eventId);
        this.judicialReview.events.push(result);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.judicialReview);
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.judicialReview.events = this.judicialReview.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.judicialReview);
      }
      this.viewContainerRef.clear();
    });
  }

  onEventDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Events", this.judicialReview.judicialReviewNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }
}
