<div class="d-flex flex-column h-100">
    <div class="header">
        Dashboard /
        <select [(ngModel)]="widgetId">
            <option *ngIf="canManageAdjudicators" value="1">Manage Adjudicators</option>
            <option *ngIf="canManageReviews" value="2">Manage Reviews</option>
            <option *ngIf="canViewCalendar" value="3">My Calendar</option>
        </select>
    </div>
    <div class="main" [ngSwitch]="widgetId">
        <div *ngSwitchCase="'1'">
            <manage-adjudicators></manage-adjudicators>
        </div>
        <div *ngSwitchCase="'2'">
            <manage-events class="event-calendar"></manage-events>
        </div>
        <div *ngSwitchCase="'3'">
            <event-calendar class="event-calendar"></event-calendar>
        </div>
    </div>
</div>