import { MeetingType } from "./types/meeting-type.model";

export class ScheduledEvent {
    public scheduledEventId: number = 0;
    public updatedBy: string;
    public updatedOn: Date;
    public userId: number = 0;
    public meetingTypeId: number;
    public startDate: Date;
    public endDate: Date;
    public title: string;
    public attendees: string;
    public isAllDay: boolean;
    public expiresOn: Date;
    public meetingKey: string;
    public meetingUrl: string;
    public iCalendarUrl: string;
    public recordNumber: string;
    public meetingType: MeetingType;
    public availabilityTypeId: number;
    public eventDate: string;
    public eventTime: string;
    public duration: number;
    public complexity: number;
    public specificUserId: number;
    public itemNumber: string;
    public decisionDueDate: Date;
    public createdBy: string;
    public createdOn: Date;
    
    constructor(init?: Partial<ScheduledEvent>) {
        Object.assign(this, init);
    }
}
