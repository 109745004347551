<div class="d-flex flex-column h-100">
    <div class="header">
        Seizures
    </div>
    <div class="main">
        <div class="filter-section">            
            <div class="filter-by-header">Filter by:</div>
            <div class="form-group">
                <div class="filter-type-header">Seizure Type</div>
                <div class="filter-type-container">                    
                    <select class="form-control w-75" id="seizureTypesDropdown" (change)="onSeizureTypeChange($event)"
                        [(ngModel)]="selectedSeizureTypeId">
                        <option class="seizure-type-option" [ngValue]="null">All</option>
                        <option class="seizure-type-option" *ngFor="let type of adjudicationSeizureTypes" value="{{type.id}}">{{type.name}}</option>                        
                    </select>                                        
                </div>
            </div>
            
            <div class="form-group">               
                <div class="filter-type-header d-flex align-items-center justify-content-between">
                    Status
                    <a class="select-all-link" (click)="onSelectAllLinkClick($event)">Select All</a>
                </div>
                <div class="filter-type-container">
                    <status-checkboxes 
                        [statusTypes]="seizureStatusTypes"
                        (change)="onSeizureStatusTypeChange($event)"></status-checkboxes>                                                                              
                </div>
            </div>

            <action-checkboxes 
                [showContact]="false"
                [showClosure]="false"
                (change)="OnActionCheckboxesChange($event)"></action-checkboxes>

            <a [routerLink]="[]" class="clear-filters disabled filter-action-lnk" (click)="onClearFiltersClick($event)">Clear All Filters</a>     
        </div>

        <div class="w-100 pl-4 pr-4">
            <div class="d-flex justify-content-between align-items-center">
                <div class="search-box">
                    <input id="searchTextbox" type="text" class="form-control" placeholder="Search by name or number" 
                        [(ngModel)]="searchText"
                        (keydown)="onSearchTextboxKeyDown($event)" />                
                    <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick()"><i class="fas fa-search fa-lg"></i></button>                
                </div>
                <div class="d-flex align-items-center">
                    <div class="paging align-self-end">                                   
                        <label for="pageSizeTop" class="page-size-label">Page Size</label>
                        <select class="form-control page-size-select" id="pageSizeTop" (change)="onPageSizeChange($event)">
                            <option value="10" [selected]="+pageSize == 10">10</option>
                            <option value="25" [selected]="+pageSize == 25">25</option>
                            <option value="50" [selected]="+pageSize == 50">50</option>
                            <option value="100" [selected]="+pageSize == 100">100</option>
                        </select>                               
                        <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>                    
                        <div class="previous-link" 
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                            (click)="onPreviousLinkClick($event)">Previous</div>
                        
                        <ng-container *ngFor="let page of pageNumbers; let i=index;">
                            <div id="pageNumberTop_{{page}}" class="page-number"                                 
                                [ngClass]="{'selected': +pageNumber == +page, 'mr-0': +i==4 }"
                                (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                        </ng-container>
                        
                        <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                            <div class="page-number ellipsis" [ngClass]="{ 'ml-3': pageNumbers.length == 0  }">...</div>
                            <div id="pageNumberTop_{{totalPages}}" class="page-number ml-0" 
                                [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                                (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                        </ng-container>

                        <div class="next-link"
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                            (click)="onNextLinkClick($event)">Next</div>                        
                        <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>                        
                    </div>  
                    <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.EXPORT_LIST">
                        <div class="export-list" (click)="onExportListClick($event)">
                            <img class="export-list-icon" src="assets/images/export-list.svg" />
                            <div class="ml-2">Export List</div>
                        </div>
                    </app-access-control>
                </div>
            </div>
            <div class="d-flex mt-3">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th style="width: 144px;">
                                <div class="d-flex">
                                    <span>Seizure Date</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('seizureDate')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th style="width: 144px;">
                                <div class="d-flex">
                                    <span>Re-Service</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('reServeDate')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex ">
                                    <span>Seizure Number</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('seizureNumber')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex ">
                                    <span>Seizure Type </span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('seizureTypeId')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex ">
                                    <span>Affected 3rd Party </span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('seizureGroupTypeId')"><i class="fas fa-sort"></i></span>
                                </div>                               
                            <th>                            
                                <div class="d-flex ">
                                    <span>Recipient Name</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('recipientName')"><i class="fas fa-sort"></i></span>
                                </div>  
                            <th style="width: 144px;">
                                <div class="d-flex">
                                    <span>Release Date</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('vehicleReleaseDate')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>                                
                                <div class="d-flex ">
                                    <span>Status</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('seizureStatusTypeId')"><i class="fas fa-sort"></i></span>
                                </div>  

                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredSeizures.length > 0">
                        <tr *ngFor="let seizure of filteredSeizures">
                            <td>{{seizure.seizureDate | date}}</td>
                            <td>{{getReServeDate(seizure)}}</td>
                            <td><a [routerLink]="['/seizure', seizure.seizureNumber]">{{seizure.seizureNumber}}</a></td>
                            <td>{{getAdjudicationSeizureTypeName(seizure)}}</td>
                            <td>{{getAffectedThirdParty(seizure)}}</td>
                            <td>{{seizure.recipientName}}</td>
                            <td>{{seizure.vehicleReleaseDate | date}}</td>                            
                            <td>
                                <div class="d-flex flex-wrap">
                                    <span class="badge badge-pill text-white px-2 py-1 mb-2 mr-1"
                                        [class.badge-status-seized]="seizure.seizureStatusTypeId == 1"
                                        [class.badge-status-inreview]="seizure.seizureStatusTypeId == 2"
                                        [class.badge-status-released]="seizure.seizureStatusTypeId == 3"
                                        [class.badge-status-cancelled]="seizure.seizureStatusTypeId == 4">
                                        {{localStorageService.getTypeItemDescriptionById(seizure.seizureStatusTypeId, TypeTable.SeizureStatusType) | uppercase}}
                                    </span>

                                    <div class="d-flex">                                        
                                        <div *ngIf="seizure.hasDocumentsRequiringRedaction" class="seizure-action-alert-icon">                
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>
                                        <div *ngIf="seizure.hasDocumentsWithPendingSubmissions" class="seizure-action-warning-icon">                
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>                                        
                                    </div>
                                </div>                                
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="filteredSeizures.length === 0">
                        <tr>
                            <td colspan="6">No seizures found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end align-items-center">            
                <label for="pageSizeBottom" class="page-size-label">Page Size</label>
                <select class="form-control page-size-select" id="pageSizeBottom" (change)="onPageSizeChange($event)">
                    <option value="10" [selected]="+pageSize == 10">10</option>
                    <option value="25" [selected]="+pageSize == 25">25</option>
                    <option value="50" [selected]="+pageSize == 50">50</option>
                    <option value="100" [selected]="+pageSize == 100">100</option>
                </select>                               
                <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>                    
                <div class="previous-link" 
                    [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                    (click)="onPreviousLinkClick($event)">Previous</div>
                
                <ng-container *ngFor="let page of pageNumbers; let i=index;">
                    <div id="pageNumberTop_{{page}}" class="page-number" 
                        [ngClass]="{'selected': +pageNumber == +page, 'mr-0': +i==4 }"
                        (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                </ng-container>
                
                <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                    <div class="page-number ellipsis" [ngClass]="{ 'ml-3': pageNumbers.length == 0  }">...</div>
                    <div id="pageNumberTop_{{totalPages}}" class="page-number ml-0" 
                        [ngClass]="{ 'selected': pageNumber == totalPages }"
                        (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                </ng-container>

                <div class="next-link"
                    [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                    (click)="onNextLinkClick($event)">Next</div>                        
                <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>     
            </div>
        </div>                
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>

