


export class Representation {
    public representationId: number = 0;
    public representationTypeId: number = 0;
    public otherRepresentationType: string;
    public firstName: string;
    public lastName: string;
    public practiceName: string;

    //non-db field
    public sameAsRequester: boolean;

    constructor(init?: Partial<Representation>) {
        
        Object.assign(this, init);
    }
}
