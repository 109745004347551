<div class="heading">Payment Extensions</div>
<div class="time-line mt-3">
    <div class="time-line-item flex-fill">
        <span class="point"></span>
        <div class="time-line-label mt-4">
            Original Due Date
        </div>
        <div>
            {{ contravention?.fine?.originalDueDate | date:'mediumDate' }}
        </div>
    </div>  
    <div class="time-line-item flex-fill" *ngIf="contravention?.fine?.extendedDueDate">
        <span class="point"></span>
        <div class="time-line-label mt-4">
            Extended Due Date
        </div>
        <div>
            {{ contravention?.fine?.extendedDueDate | date:'mediumDate' }}
        </div>
    </div>
    <div class="time-line-item flex-fill" *ngIf="contravention?.fine?.additionallyExtendedDueDate">
        <span class="point"></span>
        <div class="time-line-label mt-4">
            Aditionally Extended Due Date
        </div>
        <div>
            {{ contravention?.fine?.additionallyExtendedDueDate | date:'mediumDate' }}
        </div>
    </div>
</div>
<form #paymentHistoryForm="ngForm">
    <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.PAYMENT_HISTORY">
        <div class="heading">Payment History</div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <span class="error">{{errorMessage}}</span>
          </div>
        </div>
        <div class="history-table mt-0">
            <table>
                <thead>
                  <tr>
                    <th class="date-cell">Payment Date</th>
                    <th class="transactionid-cell">Transaction ID</th>
                    <th class="paidby-cell">Paid by</th>
                    <th class="location-cell">Location</th>
                    <th class="amount-cell">Amount Paid</th>
                    <th class="receipt-cell">Action</th>
                    <th class="refund-cell" *ngIf="canLogRefund">Refund</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of contravention?.payments">
                        <td class="date-cell">{{ p.paymentDate | date:'mediumDate' }}</td>
                        <td class="transactionid-cell" *ngIf="!p.edited">{{ getLocation(p)=='Registry'? p.financialTransaction.transactionId : (p.financialTransactionId | transactionid) }}</td>
                        <td class="transactionid-cell" *ngIf="p.edited">
                            <div class="row">
                                <div class="col-11">
                                    <input class="form-control" required [appTransactionIdFormatter]="true" [appTransactionIdValidator]="true" #transactionIDControl="ngModel" type="text" maxlength="19" id="transactionID" name="transactionID" placeholder="Transaction ID"  [(ngModel)]="paymentDataEditRequest.transactionId" [ngClass]="{'is-invalid': (transactionIDControl.touched || isSubmitClicked) && transactionIDControl.invalid}">
                                </div>
                            </div>
                        </td>
                        <td class="paidby-cell" *ngIf="!p.edited || getPaidBy(p)=='Recipient' || getPaidBy(p)=='APIS'">{{getPaidBy(p)}}</td>
                        <td class="paidby-cell" *ngIf="p.edited && getPaidBy(p)!='Recipient' && getPaidBy(p)!='APIS' ">
                            <div class="row">
                                <div class="col-4 p-0 pl-3">
                                    <input class="form-control" required #payerFirstNameControl="ngModel" type="text" maxlength="50" id="payerFirstName" name="payerFirstName" placeholder="First Name" [(ngModel)]="paymentDataEditRequest.payerFirstName" [ngClass]="{'is-invalid': (payerFirstNameControl.touched || isSubmitClicked) && payerFirstNameControl.invalid}">
                                </div>
                                <div class="col-4 px-1">
                                    <input class="form-control" required #payerLastNameControl="ngModel" type="text" maxlength="50" id="payerLastName" name="payerLastName" placeholder="Last Name" [(ngModel)]="paymentDataEditRequest.payerLastName" [ngClass]="{'is-invalid': (payerLastNameControl.touched || isSubmitClicked) && payerLastNameControl.invalid}">
                               </div>
                                <div class="col-3 p-0">
                                    <input [appMVIDNumber]="true" [appMVIDNumberValidator]="true" class="form-control" required #payerMvidControl="ngModel" type="text" maxlength="10" id="payerMvid" name="payerMvid" placeholder="MVID" [(ngModel)]="paymentDataEditRequest.payerMVID" [ngClass]="{'is-invalid': (payerMvidControl.touched || isSubmitClicked) && payerMvidControl.invalid}">
                                </div>
                            </div>    
                        </td>
                        <td class="location-cell">{{getLocation(p)}}</td>
                        <td class="amount-cell">{{ p.amount | currency }}</td>
                        <td class="receipt-cell">
                            <ng-container *ngIf="!isBatchPayment(p)">
                                <a class="receipt-link" *ngIf="!p.isSubmittedByRegistry"
                                                (click)="onViewReceiptClick(p.financialTransactionId)">Download Receipt</a>
                                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                                    <div class="col d-flex justify-content-end">
                                        <div *ngIf="getLocation(p)=='Registry' && !p.edited" class="data-edit-button" (click)="onEditPaymentClick(p)"></div>
                                        <div *ngIf="getLocation(p)=='Registry' && p.edited" class="data-edit-ok-button" (click)="onEditPaymentSaveClick(paymentHistoryForm.valid)"></div>
                                        <div *ngIf="getLocation(p)=='Registry' && p.edited" class="data-edit-cancel-button" (click)="onEditPaymentCancelClick(p)"></div>
                                    </div>
                                </app-access-control>
                            </ng-container>
                        </td>
                        <td class="refund-cell" *ngIf="canLogRefund">
                            <!-- <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.IMMEDIATE_CANCEL">
                                <button *ngIf="displayFailedRefund(p)" class="btn btn-primary btn-sm" (click)="refundFinePayment(p.financialTransaction.transactionId)">Submit</button>
                            </app-access-control> -->
                            <div *ngIf="p.refundStatus == RefundStatus.Completed"> Completed </div>
                            <div class="log-refund-link" *ngIf="p.refundStatus == RefundStatus.Refundable" (click)="onLogRefundClick(p)">Log Refund</div>
                        </td> 
                    </tr>    
                                
                    <tr *ngIf="contravention?.payments.length == 0">
                        <td colspan="8">No records found.</td>
                    </tr>
                </tbody>                        
            </table>
        </div>

        <div class="totals mt-4">
            <div><h5>Total Paid</h5></div>
            <div><h5>{{ amountPaid | currency}}</h5></div>
        </div>


        <div class="totals mb-4">
            <div><h5>Balance Owing</h5></div>
            <div><h5>{{ amountDue | currency}}</h5></div>
        </div>

    </app-access-control>
</form>
