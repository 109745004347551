import { AbstractControl, ValidatorFn } from "@angular/forms";

export function MustMatchValidator(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): {[key: string]: boolean} | null => {          
        if (control.value != otherControl.value) {
            return {
                mustMatch: true
            }
        }

        return null;
    }
}