import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '@apis/shared/helpers/constants';
import { TowCompanyService } from '@apis/shared/services/tow-company.service';
import { TowCompany } from '@apis/shared/models/tow-company.model';
import { DeleteTowCompaniesModalComponent } from './modals/delete-tow-companies-modal/delete-tow-companies-modal.component';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-tow-companies',
  templateUrl: './tow-companies.component.html',
  styleUrls: ['./tow-companies.component.scss']
})
export class TowCompaniesComponent implements OnInit {
  towSearchText: string;
  towCompanies: TowCompany[];
  editingTowCompany: TowCompany;
  errorMessage: string;
  isSubmitClicked: boolean = false;
  hasEditPermissions: boolean = false;

  constructor(
    private towCompanyService: TowCompanyService,
    private readonly spinner: NgxSpinnerService,
    private readonly viewContainerRef: ViewContainerRef,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService
    ) { }

  ngOnInit(): void {
    this.hasEditPermissions = this.localStorageService.hasPermission(Constants.Resource.TOW_COMPANIES,Constants.Permission.EDIT_TOW_LOT);  
  }

  onTowSearchKeyDown(ev: KeyboardEvent, isValid: boolean): void {
    if (ev.key.toLowerCase() === "enter") {
      this.onSearchButtonClick(isValid);
    }
  }

  onSearchButtonClick(isValid: boolean): void {
    if (isValid) {
      this.errorMessage = null;
      this.findTowCompanies(this.towSearchText);
    }
  }

  findTowCompanies(towSearchText: string) {
    if (towSearchText == null || towSearchText.trim().length == 0) {
      return;
    }

    this.spinner.show();    

    this.towCompanyService.searchTowCompaniesAsync(towSearchText)
      .subscribe(towCompanies => {
        this.towCompanies = towCompanies;
        this.spinner.hide();
      });
  }

  onAddTowCompanyClick()
  {
    this.isSubmitClicked = false;
    this.editingTowCompany = new TowCompany();
    this.editingTowCompany.towCompanyId = 0;
    this.editingTowCompany.isEditing = true;

    this.towCompanies.push(this.editingTowCompany);
  }

  onEditTowCompanyClick(towCompany: TowCompany)
  {
    this.isSubmitClicked = false;
    // Disable any other edit
    this.towCompanies.filter(x=> x.isEditing).forEach(towCompany => {
      this.revertTowCompany(towCompany);
    });

    // Remove any pending new record
    if(this.towCompanies.some(x=>x.towCompanyId==0))
    {
      this.towCompanies.pop();
    }

    towCompany.isEditing = true;
    this.editingTowCompany = {... towCompany };
  }

  onSaveTowCompanyClick(towCompany: TowCompany)
  {
    this.isSubmitClicked = true;
    if (!this.isTowInformationValid(towCompany))
      return;
    
    var isAdding = towCompany.towCompanyId == 0;
    this.errorMessage = "";
    this.spinner.show();    

    this.towCompanyService.saveTowCompanyAsync(towCompany)
      .subscribe(updatedTow => {
        towCompany.towCompanyId = updatedTow.towCompanyId;
        towCompany.isEditing = false;
        this.spinner.hide();
        this.toastr.success(`Towing company ${isAdding?"added":"updated"}`,towCompany.towCompanyName,{positionClass: "toast-bottom-right", closeButton: true});
      }, (error: any) => {
        this.showErrors(error);
        this.spinner.hide();
      });
  }

  onCancelTowCompanyClick(towCompany: TowCompany)
  {
    this.isSubmitClicked = false;
    if (towCompany.towCompanyId > 0)
    {
      // Revert the changes
      this.revertTowCompany(towCompany);
    }
    else
    {
      this.towCompanies.pop();
    }
  }

  onDeleteTowCompanyClick(towCompany: TowCompany): void {
    this.isSubmitClicked = false;
    this.errorMessage = null;
    let componentRef = this.viewContainerRef.createComponent(DeleteTowCompaniesModalComponent);

    componentRef.instance.towCompany = Object.assign({}, towCompany);
    componentRef.instance.close.subscribe((towLotDeleted: boolean) => {
      this.viewContainerRef.clear();
      if (towLotDeleted) {
        let index = this.towCompanies.indexOf(towCompany);
        if (index != -1) {
          this.towCompanies.splice(index, 1);
          this.toastr.success("Towing company deleted",towCompany.towCompanyName,{positionClass: "toast-bottom-right", closeButton: true});
        }
        else {
          this.errorMessage = "Unable to delete the tow company. Please try again later.";
        }
      }
    },
    (error: any) => {
      this.errorMessage = "Unable to delete the tow company. Please try again later.";
    });
  }

  isAnyTowCompanyInEditMode()
  {
    return this.towCompanies.some(x=>x.isEditing);
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  getTableRowClass(towCompany: TowCompany)
  {
    return towCompany.isEditing? "editing-row" : "";
  }
  
  isTowInformationValid(towCompany: TowCompany) {
    // Check required fields
    return !(towCompany.towCompanyName.trim().length == 0 
        || towCompany.towLotAddress.trim().length == 0
        || towCompany.city.trim().length == 0
        || towCompany.postalCode.trim().length == 0
        || towCompany.phoneNumber.trim().length == 0)
  }

  revertTowCompany(towCompany: TowCompany)
  {
     // Revert the changes
     towCompany.towCompanyName = this.editingTowCompany.towCompanyName;
     towCompany.towLotAddress = this.editingTowCompany.towLotAddress;
     towCompany.city = this.editingTowCompany.city;
     towCompany.postalCode = this.editingTowCompany.postalCode;
     towCompany.phoneNumber = this.editingTowCompany.phoneNumber;
     towCompany.faxNumber = this.editingTowCompany.faxNumber;
     towCompany.towLotEmailAddress = this.editingTowCompany.towLotEmailAddress;
     towCompany.isEditing = false;
  }
}



