<div class="goa-paging">
  <nav>
    <ul>
      <li class="previous"><a [routerLink]="[]" (click)="onPreviousLinkClick()">Previous</a></li>

      <li *ngFor="let pageNumber of pageNumbers; let i=index;" [ngClass]="{'active': pageNumber == pageIndex}">
        <ng-container *ngIf="pageNumber == pageIndex; else pageLink">
            {{ pageNumber }}
        </ng-container>
        <ng-template #pageLink>
            <a [routerLink]="[]" (click)="onPageNumberClick(pageNumber)">{{ pageNumber }}</a>
        </ng-template>
      </li>
      
      <li class="next"><a [routerLink]="[]" (click)="onNextLinkClick()">Next</a></li>
    </ul>
  </nav>
</div>
