


export class SeizureView {
    public seizureDate: Date = new Date();
    public reServeDate: Date;
    public seizureNumber: string;
    public seizureTypeId: number = 0;
    public seizureGroupTypeId: number = 0;
    public recipientName: string;
    public vehicleReleaseDate: Date = new Date();
    public seizureStatusTypeId: number = 0;
    public hasDocumentsWithPendingSubmissions: boolean = false;
    public hasDocumentsRequiringRedaction: boolean = false;
    public thirdPartyName: string;

    constructor(init?: Partial<SeizureView>) {
        
        Object.assign(this, init);
    }
}
