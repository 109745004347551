
export class LogRefundRequest {
    public recordNumber: string;
    public refundAmount: number = 0;
    public sourceTransactionId: string;
    public refundTransactionId: string;
    public refundDate: Date;
    public note: string;
    public transactionTypeId: number;

    constructor(init?: Partial<LogRefundRequest>) {
        Object.assign(this, init);
    }
}