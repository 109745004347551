<div class="cal-week-view" role="grid">
  <div class="d-flex cal-header" role="row">
    <div class="adj-header">Adjudicator</div>
    <div
      *ngFor="
        let hour of view.hourColumns[0].hours;
        trackBy: trackByHour;
        let odd = odd
      "
      class="cal-hour d-flex"
      [class.cal-hour-odd]="odd"
    >
      <mwl-calendar-week-view-hour-segment
        [ngClass]="cal-header"
        [style.width.px]="70"
        *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
        [style.height.px]="hourSegmentHeight"
        [segment]="segment"
        [segmentHeight]="hourSegmentHeight"
        [locale]="locale"
        [customTemplate]="hourSegmentTemplate"
        [isTimeLabel]="true"
      >
      </mwl-calendar-week-view-hour-segment>
    </div>
  </div>

  <div class="cal-time-events">
    <div
      class="cal-time-label-column adj-col"
      *ngIf="view.hourColumns.length > 0"
    >
      <div
        class="cal-header adj-name"
        *ngFor="let resource of resources; trackBy: trackByResourceId"
        tabindex="0"
        role="columnheader"
      >
        <b>{{ resource.name }}</b>
      </div>
    </div>
    <div
      class="cal-day-columns d-flex flex-column"
      [class.cal-resize-active]="timeEventResizes.size > 0"
      #dayColumns
    >
      <div
        class="cal-day-column d-flex"
        *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn"
      >
        <div
          *ngFor="let timeEvent of column.events; trackBy: trackByWeekTimeEvent"
          #event
          class="cal-event-container"
          [class.cal-draggable]="
            timeEvent.event.draggable && timeEventResizes.size === 0
          "
          [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
          [class.cal-ends-within-day]="!timeEvent.endsAfterDay"
          [ngClass]="timeEvent.event.cssClass"
          [hidden]="timeEvent.height === 0 && timeEvent.width === 0"
          [style.top.px]="timeEvent.top"
          [style.height.px]="timeEvent.height"
          [style.left.px]="timeEvent.left"
          [style.width.px]="timeEvent.width"
        >
          <mwl-calendar-week-view-event
            [locale]="locale"
            [weekEvent]="timeEvent"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipDisabled]="dragActive || timeEventResizes.size > 0"
            [tooltipDelay]="tooltipDelay"
            [customTemplate]="eventTemplate"
            [eventTitleTemplate]="eventTitleTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            [column]="column"
            (eventClicked)="
              eventClicked.emit({
                event: timeEvent.event,
                sourceEvent: $event.sourceEvent
              })
            "
          >
          </mwl-calendar-week-view-event>
        </div>
        <div
          *ngFor="let hour of column.hours; trackBy: trackByHour; let odd = odd"
          class="cal-hour d-flex"
          [class.cal-hour-odd]="odd"
        >
          <mwl-calendar-week-view-hour-segment
            *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
            [style.width.px]="70"
            [style.height.px]="hourSegmentHeight"
            [segment]="segment"
            [segmentHeight]="hourSegmentHeight"
            [locale]="locale"
            [customTemplate]="hourSegmentTemplate"
            (mwlClick)="
              hourSegmentClicked.emit({
                date: segment.date,
                sourceEvent: $event
              })
            "
            [clickListenerDisabled]="hourSegmentClicked.observers.length === 0"
            mwlDroppable
            [dragOverClass]="
              !dragActive || !snapDraggedEvents ? 'cal-drag-over' : null
            "
            dragActiveClass="cal-drag-active"
            (drop)="eventDropped($event, segment.date, false)"
          >
          </mwl-calendar-week-view-hour-segment>
        </div>
      </div>
    </div>
  </div>
</div>
