import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { ContraventionListingComponent } from './contravention-listing/contravention-listing.component';
import { ContraventionDetailsComponent } from './contravention-details/contravention-details.component';
import { FinanceComponent } from './finance/finance.component';
import { ReportsComponent } from './reports/reports.component';
import { ReviewCancellationReportComponent } from './reports/components/review-cancellation-report.component';
import { ContraventionsExtractReportComponent } from './reports/components/contraventions-extract-report.component';
import { SeizuresExtractReportComponent } from './reports/components/seizures-extract-report.component';
import { ReviewExtractReportComponent } from './reports/components/review-extract-report.component';
import { ReviewDetailsComponent } from "./review-details/review-details.component";
import { ReviewListingComponent } from './review-listing/review-listing.component';
import { RequestListingComponent } from './request-listing/request-listing.component';
import { SeizureListingComponent } from './seizure-listing/seizure-listing.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { AuthGuard } from '../../../../libs/shared/security/auth.guard';
import { SeizureDetailsComponent } from './seizure-details/seizure-details.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { ScheduledMaintenanceComponent } from './scheduled-maintenance/scheduled-maintenance.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Constants } from '@apis/shared/helpers/constants';
import { UnauthorizedComponent } from '@apis/shared/components/unauthorized/unauthorized.component';
import { JudicialReviewListingComponent } from './judicial-review-listing/judicial-review-listing.component';
import { JudicialReviewDetailsComponent } from './judicial-review-details/judicial-review-details.component';
import { ReReviewDetailsComponent } from './re-review-details/re-review-details.component';
import { TrafficDisclosureComponent } from './traffic-disclosure/traffic-disclosure.component';
import { TowCompaniesComponent } from './tow-companies/tow-companies.component';

/*
 --> Developer notes: <--
 If the default landing page is not permitted, AuthGuard examines the user's permissions and moves on to the next available page in the side navigation resource.
 The permission name is used by the system as the route path. Therefore, ensure that the route path matches the permission name in the database (only for listing pages).
*/
const routes: Routes = [
  { path: "dashboard", component: DashboardComponent, canActivate: mapToCanActivate([AuthGuard]), data: { permission : Constants.Permission.DASHBOARD } },
  { path: "contraventions", component: ContraventionListingComponent, canActivate: mapToCanActivate([AuthGuard]), data: { permission : Constants.Permission.CONTRAVENTIONS } },
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  { path: "contravention/:contraventionNumber", component: ContraventionDetailsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.CONTRAVENTIONS }  },
  { path: "finance", component: FinanceComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.FINANCE } },
  { path: "review/:reviewNumber", component: ReviewDetailsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REVIEWS }  },
  { path: "reviews", component: ReviewListingComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REVIEWS }  },
  { path: "requests", component: RequestListingComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REQUESTS }  },
  { path: "requests/:requestNumber", component: RequestDetailsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REQUESTS }  },
  { path: "seizures", component: SeizureListingComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.SEIZURES } },
  { path: "seizure/:seizureNumber", component: SeizureDetailsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.SEIZURES }  },
  { path: "reports", component: ReportsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REPORTS } },
  { path: "review-cancellation-report", component: ReviewCancellationReportComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REPORTS } },
  { path: "contraventions-extract-report", component: ContraventionsExtractReportComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REPORTS } },
  { path: "seizures-extract-report", component: SeizuresExtractReportComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REPORTS } },
  { path: "review-extract-report", component: ReviewExtractReportComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.REPORTS } },
  { path: "judicial-reviews", component: JudicialReviewListingComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.JUDICIAL_REVIEWS } },
  { path: "judicial-reviews/:judicialReviewNumber", component: JudicialReviewDetailsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.JUDICIAL_REVIEWS } },
  { path: "judicial-reviews/:judicialReviewNumber/re-review", component: ReReviewDetailsComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.JUDICIAL_REVIEWS } },
  { path: "traffic-disclosure", component: TrafficDisclosureComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.TRAFFIC_DISCLOSURE } },
  { path: "tow-companies", component: TowCompaniesComponent, canActivate: mapToCanActivate([AuthGuard]),  data: { permission : Constants.Permission.TOW_COMPANIES } },
  { path: 'internal-server-error', component: InternalServerErrorComponent},
  { path: 'scheduled-maintenance', component: ScheduledMaintenanceComponent},
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
