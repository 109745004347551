import { StopInformation } from '@apis/shared/models/stop-information.model';
import { Component, OnInit, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { Contravention } from '@apis/shared/models/contravention.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { KeycloakService } from 'keycloak-angular';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { MovesVehicle } from '@apis/shared/models/moves-vehicle.model';
import { TowCompany } from '@apis/shared/models/tow-company.model';
import { TowCompanyService } from '@apis/shared/services/tow-company.service';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { RegisteredOwnerContact } from '@apis/shared/models/registered-owner-contact.model';
import { IdentificationTypes, RecipientTypes, VehicleRegistrationStatusTypes } from '@apis/shared/enums/app.enum';
import { VehicleRegistrationStatusType } from '@apis/shared/models/types/vehicle-registration-status-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModdedTitleCasePipe } from '@apis/shared/pipes/moddedTitleCase.pipe';


@Component({
  selector: 'seizure-information',
  templateUrl: './seizure-information.component.html',
  styleUrls: ['./seizure-information.component.scss'],
})
export class SeizureInformationComponent implements OnInit {
  @Input() contravention: Contravention;
  @Input() vehicleSeizure: VehicleSeizure;
  @Output() getVehicleSeizureChange = new EventEmitter<VehicleSeizure>();

  policeServiceInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  registeredOwner: SectionDetailsModel[] = [];
  towLotInformation: SectionDetailsModel[] = [];
  declarations: SectionDetailsModel[] = [];
  supportingDocumentDeclarations: SectionDetailsModel[] = [];
  showSupportingDocumentDeclarations: boolean;
  seizureType: string = "";
  location: string = "";
  detachmentName: string;
  seizureDatePickerConfig: Partial<BsDatepickerConfig>;
  Constants = Constants;
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;
  policeNarrativeDetails: SectionDetailsModel[] = [];
  trailerInformation: SectionDetailsModel[] = [];
  movesVehicleInformation: SectionDetailsModel[] = [];
  movesVehicle: MovesVehicle;
  occurrenceLocationDetails: SectionDetailsModel[] = [];
  isSaving: boolean = false;
  towCompanies: TowCompany[];
  towCompany: TowCompany;
  selectedTowCompany: TowCompany = null;
  isSubmitClicked: boolean = false;

  isSeizureDateEdited: boolean = false;
  isSeizureTimeEdited: boolean = false;
  isPoliceFileNumberEdited: boolean = false;
  isTowLotInfoEdited: boolean = false;
  seizureDateErrorMessage: string = "";
  seizureTimeErrorMessage: string = "";
  policeFileNumberErrorMessage: string = "";
  towLotErrorMessage: string = "";
  vehicle: Vehicle;
  recipient: Recipient;
  RecipientTypes = RecipientTypes;
  VehicleRegistrationStatusTypes = VehicleRegistrationStatusTypes;
  genderTypes: GenderType[];
  dateOfBirthPickerConfig: Partial<BsDatepickerConfig>;
  countryId: number;
  provinceId: number;
  genderId: number;
  dateOfBirth: Date;
  countryTypes: any = [];
  provinceTypes: any = [];
  isRegisteredOwnerEdited: boolean = false;
  errorMessageRegisteredOwner: string;
  isAllowEditForBusinessSuperUser: boolean = false;
  driverLicenceClassification: any;
  isAlbertaDriverLicense:boolean;
  isSameAsDriverSelectionMade: boolean = false;
  
  constructor(private localStorageService: LocalStorageService,
    private towCompanyService: TowCompanyService,
    private adjudicationService: AdjudicationService,
    private readonly keycloakService: KeycloakService,
    private datePipe: DatePipe,
    private readonly spinner: NgxSpinnerService,
    public moddedTitleCasePipe: ModdedTitleCasePipe,
    public titleCasePipe: TitleCasePipe) {
    this.seizureDatePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
        isAnimated: true
      })
    this.dateOfBirthPickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
        minDate: new Date(1900, 0, 1), // 1900/01/01 - Month is 0 based index
        maxDate: new Date(2099, 11, 31), // 2099/12/31 - Month is 0 based index
        isAnimated: true,
        customTodayClass: 'custom-today-class'
      });
  }

  ngOnInit(): void {
    this.towCompanyService.getTowCompaniesAsync()
      .subscribe(towCompanies => {
        this.towCompanies = towCompanies;
      });

    this.toCheckEditPermission();
  }

  PopulateSeizureInformation() {
    this.toCheckEditPermission();

    this.vehicle = JSON.parse(JSON.stringify(this.vehicleSeizure.vehicle));
    if (this.vehicleSeizure.seizureTypeId == 1) { //Part of Contravention    
      this.recipient = this.contravention?.recipient;
    }
    else {
      this.recipient = this.vehicleSeizure?.recipient;
    }
    this.genderTypes = this.localStorageService.getGenderTypes();

    let policeOfficerName: string;
    let regimentalNumber: string;
    let agencyId: number;
    let detachmentId: number;

    this.ClearSectionDetails();
    if (this.vehicleSeizure?.policeServiceInformation?.length > 0) {
      const policeServiceInformation = this.vehicleSeizure.policeServiceInformation[0];
      policeOfficerName = policeServiceInformation.policeOfficerName;
      regimentalNumber = policeServiceInformation.regimentalNumber;
      agencyId = policeServiceInformation?.detachment?.agencyId;
      detachmentId = policeServiceInformation?.detachmentId;
    }

    var declarationTypes = this.localStorageService.getDeclarationTypes();

    //Populate Seizure details section
    this.policeServiceInformation.push(new SectionDetailsModel('Name of Police Officer', policeOfficerName));
    this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', regimentalNumber));
    this.policeServiceInformation.push(new SectionDetailsModel('Police Service', this.localStorageService.getAgencies().find(x => x.agencyId == agencyId)?.agencyName));
    this.detachmentName = this.localStorageService.getDetachments().find(x => x.detachmentId == detachmentId)?.detachmentName;

    //Seizure Information
    if (this.vehicleSeizure.seizureTypeId == 1) { //Part of Contravention
      this.seizureType = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.formattedChargeCode;
    }
    else {
      if (this.vehicleSeizure.contraventionTypeId == null) {
        this.seizureType = this.localStorageService.getSeizureTypes().find(x => x.id == this.vehicleSeizure.seizureTypeId)?.name;
      } else {
        this.seizureType = this.localStorageService.getContraventionTypes().find(x => x.id == this.vehicleSeizure.contraventionTypeId)?.formattedChargeCode;
      }
    }

    //Additional location details    
    var additionalLocationDetails = this.vehicleSeizure.additionalLocationDetails?.trim();
    if (additionalLocationDetails == null || additionalLocationDetails == '') {
      additionalLocationDetails = this.vehicleSeizure.occurrenceLocation?.additionalLocationDetails?.trim();
    }

    // Occurrence Information      
    this.occurrenceLocationDetails.push(new SectionDetailsModel('Location of Seizure', this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure.locationTypeId)?.name));
    if (this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE2 && this.vehicleSeizure.seizureTypeId == 1) {
      this.occurrenceLocationDetails.push(new SectionDetailsModel('Address or Highway Number', this.vehicleSeizure.occurrenceLocation?.addressOrHighwayNumber));
      this.occurrenceLocationDetails.push(new SectionDetailsModel('GPS Coordinates', `${this.vehicleSeizure.occurrenceLocation?.latitude ?? ""} ${this.vehicleSeizure.occurrenceLocation?.longitude ?? ""}`));
      this.occurrenceLocationDetails.push(new SectionDetailsModel('At Intersection of', this.vehicleSeizure.occurrenceLocation?.atStreet));
      this.occurrenceLocationDetails.push(new SectionDetailsModel('Relative location', `${this.vehicleSeizure.occurrenceLocation?.directionTypeId ? this.localStorageService.getDirectionTypes().find(x => x.id == this.vehicleSeizure.occurrenceLocation?.directionTypeId)?.name + " of " : ""} ${this.vehicleSeizure.occurrenceLocation?.relativeLocation ?? ""}`));
    }
    this.occurrenceLocationDetails.push(new SectionDetailsModel('Additional Location Information', additionalLocationDetails, true));

    //Vehicle Information
    this.movesVehicle = this.vehicleSeizure?.vehicle?.movesVehicle;

    this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicleSeizure?.vehicle?.licencePlateNumber));
    this.vehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.vehicleSeizure?.vehicle?.isStolenPlate? "Yes": "No"));
    this.vehicleInformation.push(new SectionDetailsModel("No Plate", this.vehicleSeizure?.vehicle?.isNoPlate? "Yes": "No"));
    this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicleSeizure?.vehicle?.vehicleIdentificationNumber));
    this.vehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.vehicleRegistrationStatusTypeId)?.name));
    this.vehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.clientTypeId)?.name));
    this.vehicleInformation.push(new SectionDetailsModel('Licenced Country', this.vehicleSeizure?.vehicle?.licensedCountry?.name));
    if (this.vehicleSeizure?.vehicle?.licensedCountryId == 40 || this.vehicleSeizure?.vehicle?.licensedCountryId == 236) // Canada OR U. S. A.
    {
      this.vehicleInformation.push(new SectionDetailsModel('Province', this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.licensedProvinceId)?.name));
    }
    else
    {
      this.vehicleInformation.push(new SectionDetailsModel('Province', this.vehicleSeizure?.vehicle?.licensedProvince));
    }
    this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicleSeizure?.vehicle?.make));
    this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicleSeizure?.vehicle?.model));
    this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicleSeizure?.vehicle?.year?.toString()));
    this.vehicleInformation.push(new SectionDetailsModel('Colour', this.vehicleSeizure?.vehicle?.colour));
    this.vehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.vehicleSeizure?.vehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));

    if (!(this.vehicleSeizure?.vehicle?.movesLookupDate && this.vehicleSeizure?.vehicle?.isStolenPlate))
    {
      this.vehicleInformation.push(new SectionDetailsModel('Class of Plate', this.vehicleSeizure?.vehicle?.licencePlateClass));
      this.vehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.vehicleSeizure?.vehicle?.vehicleStyle));
      this.vehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.vehicleSeizure?.vehicle?.vehicleWeight));
      this.vehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.vehicleSeizure?.vehicle?.vehicleSeatingCapacity));
      this.vehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.vehicleStatusTypeId)?.name));
      this.vehicleInformation.push(new SectionDetailsModel('Last Service Type', this.vehicleSeizure?.vehicle?.lastServiceType));
      this.vehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.vehicleSeizure?.vehicle?.lastServiceDate, 'dd MMM, yyyy')));
    }

    //Moves Vehicle Information
    this.movesVehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.movesVehicle?.licencePlateNumber));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.movesVehicle?.vehicleIdentificationNumber));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.movesVehicle?.vehicleRegistrationStatusTypeId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.movesVehicle?.clientTypeId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.movesVehicle?.licensedCountryId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.movesVehicle?.licensedCountryId == 40 || this.movesVehicle?.licensedCountryId == 236)? (this.movesVehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.movesVehicle?.licensedProvinceId)?.name) : this.movesVehicle?.licensedProvince));
    this.movesVehicleInformation.push(new SectionDetailsModel('Make', this.movesVehicle?.make));
    this.movesVehicleInformation.push(new SectionDetailsModel('Model', this.movesVehicle?.model));
    this.movesVehicleInformation.push(new SectionDetailsModel('Year', this.movesVehicle?.year?.toString()));
    this.movesVehicleInformation.push(new SectionDetailsModel('Colour', this.movesVehicle?.colour));
    this.movesVehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.movesVehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
    this.movesVehicleInformation.push(new SectionDetailsModel('Class of Plate', this.movesVehicle?.licencePlateClass));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.movesVehicle?.vehicleStyle));
    this.movesVehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.movesVehicle?.vehicleWeight));
    this.movesVehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.movesVehicle?.vehicleSeatingCapacity));
    this.movesVehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.movesVehicle?.vehicleStatusTypeId)?.name));
    this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Type', this.movesVehicle?.lastServiceType));
    this.movesVehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.movesVehicle?.lastServiceDate, 'dd MMM, yyyy')));

    // Trailer Information
    this.trailerInformation.push(new SectionDetailsModel("Trailer Involved", this.vehicleSeizure?.vehicle?.isTrailerInvolved? "Yes": "No"));
    if (this.vehicleSeizure?.vehicle?.isTrailerInvolved)
    {
      this.trailerInformation.push(new SectionDetailsModel('Trailer Licence Plate Number', this.vehicleSeizure?.vehicle?.trailerLicencePlateNumber));
      this.trailerInformation.push(new SectionDetailsModel('Additional Trailer Information', this.vehicleSeizure?.vehicle?.additionalTrailerInformation));
      if (this.vehicleSeizure?.vehicle?.isSecondTrailerInvolved)
      {
        this.trailerInformation.push(new SectionDetailsModel('Second Trailer Licence Plate Number', this.vehicleSeizure?.vehicle?.secondTrailerLicencePlateNumber));
        this.trailerInformation.push(new SectionDetailsModel('Additional Second Trailer Information', this.vehicleSeizure?.vehicle?.additionalSecondTrailerInformation));
      }
    }

    //Registered Owner
    if (this.vehicleSeizure?.vehicle?.isNoRegisteredOwner)
      this.registeredOwner.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
    else
    {
      this.registeredOwner.push(new SectionDetailsModel('Same as Driver', this.vehicleSeizure?.vehicle?.registeredOwner?.isSameAsDriver? "Yes": "No", null));
      this.registeredOwner.push(new SectionDetailsModel('Driver’s Licence Number', this.vehicleSeizure?.vehicle?.registeredOwner?.driverLicenceNumber));
      this.registeredOwner.push(new SectionDetailsModel('M.V.I.D', this.vehicleSeizure?.vehicle?.registeredOwner?.motorVehicleIdentificationNumber));
      this.registeredOwner.push(new SectionDetailsModel('Registered Owner Name', this.vehicleSeizure?.vehicle?.registeredOwner?.otherName == null? `${this.vehicleSeizure?.vehicle?.registeredOwner?.firstName} ${this.vehicleSeizure?.vehicle?.registeredOwner?.lastName}` : `${this.vehicleSeizure?.vehicle?.registeredOwner?.firstName} ${this.vehicleSeizure?.vehicle?.registeredOwner?.otherName}, ${this.vehicleSeizure?.vehicle?.registeredOwner?.lastName}`));
      this.registeredOwner.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.genderId)?.name));
      this.registeredOwner.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.vehicleSeizure?.vehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
      this.registeredOwner.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
      this.registeredOwner.push(new SectionDetailsModel('Address 1', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
      this.registeredOwner.push(new SectionDetailsModel('Address 2', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
      this.registeredOwner.push(new SectionDetailsModel('Country', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.country?.name));
      if (this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236) // Canada OR U. S. A.
      {
        this.registeredOwner.push(new SectionDetailsModel('Province', this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name));
      }
      else
      { 
        this.registeredOwner.push(new SectionDetailsModel('Province', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.province));
      }
      this.registeredOwner.push(new SectionDetailsModel('City', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.city));
      this.registeredOwner.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.postalCode));
    }

    //Tow Lot Information
    this.towLotInformation.push(new SectionDetailsModel('Towing Company', this.vehicleSeizure?.towCompanyName));
    this.towLotInformation.push(new SectionDetailsModel('Name of Towing Company', this.vehicleSeizure?.towCompanyName));
    this.towLotInformation.push(new SectionDetailsModel('Tow Lot Address', this.vehicleSeizure?.towLotAddress));
    this.towLotInformation.push(new SectionDetailsModel('City/Town/Village', this.vehicleSeizure?.towLotCity));
    this.towLotInformation.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.towLotPostalCode));
    this.towLotInformation.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.towLotPhoneNumber));
    this.towLotInformation.push(new SectionDetailsModel('Fax Number', this.vehicleSeizure?.towLotFaxNumber));
    this.towLotInformation.push(new SectionDetailsModel('Received By (Towing Company Representative)', this.vehicleSeizure?.towLotRepresentative));
    this.towLotInformation.push(new SectionDetailsModel('Email Address', this.vehicleSeizure?.towLotEmailAddress));

    //Re-Serve
    if (this.vehicleSeizure.isReServeRequired == true) {
      let reServeMethodTypeName = this.localStorageService.getTypeItemDescriptionById(this.vehicleSeizure.reServeMethodTypeId, TypeTable.ReServeMethodType);
      this.declarations.push(new SectionDetailsModel('Is Re-Service Required?', "Yes"));
      this.declarations.push(new SectionDetailsModel('Re-Service Date', this.datePipe.transform(this.vehicleSeizure.reServeDate, "mediumDate")));
      this.declarations.push(new SectionDetailsModel("Re-Service Method", reServeMethodTypeName));
      this.declarations.push(new SectionDetailsModel("Reason for Re-Service", this.vehicleSeizure.reServeReason, true));
    }

    var declarationTypes = this.localStorageService.getDeclarationTypes();

    var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.vehicleSeizure.officerSubmissionDeclarationTypeId).description;

    //Declarations
    this.declarations.push(new SectionDetailsModel('Full Name of Officer', this.vehicleSeizure.policeOfficerFullName));
    this.declarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.vehicleSeizure.submissionDate, 'dd MMM, yyyy')));
    this.declarations.push(new SectionDetailsModel(officerSubmissionDeclaration, this.vehicleSeizure.isTrueInformation? "Yes": "No", true));

    if (this.vehicleSeizure.officerAdditionalDetailsDeclarationTypeId) {
      const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.vehicleSeizure.officerAdditionalDetailsDeclarationTypeId).description;
      this.declarations.push(new SectionDetailsModel('Full Name of Officer', this.vehicleSeizure.policeOfficerFullName));
      this.declarations.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.vehicleSeizure.additionalDetailsDate, 'dd MMM, yyyy')));
      this.declarations.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
    }

    // Supporting Document Declarations

    this.vehicleSeizure.policeServiceInformation.forEach(p => {
      if (p.declarationInformation != null) {
        var declaration = declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
        this.supportingDocumentDeclarations.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
        this.supportingDocumentDeclarations.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
        this.supportingDocumentDeclarations.push(new SectionDetailsModel(declaration.description, 'Yes', true));
        this.showSupportingDocumentDeclarations = true;
      }
    });

    //Police Narrative Additional Note
    var policeNarrative = this.vehicleSeizure.additionalNotes;
    if (this.contravention != null && (policeNarrative == null || policeNarrative?.trim() == '')) {
      policeNarrative = this.contravention.additionalNotes;
    }
    this.policeNarrativeDetails.push(new SectionDetailsModel('',  policeNarrative, true));

    //Check if IsSameAsDriver selection is made or not
    this.isSameAsDriverSelectionMade = this.vehicleSeizure?.vehicle?.registeredOwner?.isSameAsDriver != null;
  }

  onPlusMinusIconClick(imgId: string, divId: string): void {
    let div = document.getElementById(divId);
    let img = document.getElementById(imgId);

    if (div.classList.contains("show")) {
      div.classList.remove("show");
      img.setAttribute("src", "assets/images/plus.svg");
    } else {
      div.classList.add("show");
      img.setAttribute("src", "assets/images/minus.svg");
    }
  }

  onExpandAllChange(ev: any): void {
    if ($(".collapse").hasClass("show")) {
      $(".plus-minus-icon img").attr("src", "assets/images/plus.svg");
      $(".collapse").removeClass("show");
    } else {
      $(".plus-minus-icon img").attr("src", "assets/images/minus.svg");
      $(".collapse").addClass("show");
    }
  }

  editSeizureDateClick()
  {
    this.isSeizureDateEdited = true;
  }

  editSeizureTimeClick()
  {
    this.isSeizureTimeEdited = true;
  }

  editPoliceFileNumberClick()
  {
    this.isPoliceFileNumberEdited = true;
  }

  editSeizureDateSaveClick(isValid: boolean)
  {
    if(isValid)
    {
      this.isSaving = true;
      this.adjudicationService.updateVehicleSeizureDate(this.vehicleSeizure.seizureNumber, this.datePipe.transform(this.vehicleSeizure.seizureDate, 'yyyy-MM-dd'))
        .subscribe((result: VehicleSeizure) => {
          this.seizureDateErrorMessage = "";
          this.vehicleSeizure = result;
          this.PopulateSeizureInformation()
          this.isSeizureDateEdited = false;
          this.isSaving = false;
          this.getVehicleSeizureChange.emit(result);
        },
          (error: any) => {
            if (error.status == 401 || error.status == 403) {
              this.seizureDateErrorMessage = "You are unauthorized to perform this operation";
            }
      else  {
              this.isSaving = false;
              this.seizureDateErrorMessage = this.getErrorMessage(error);
            }
          });
    }
  }

  editSeizureTimeSaveClick(isValid: boolean)
  {
    if(isValid)
    {
      this.isSaving = true;
      this.adjudicationService.updateVehicleSeizureTime(this.vehicleSeizure.seizureNumber, this.vehicleSeizure.seizureTime)
        .subscribe((result: VehicleSeizure) => {
          this.seizureTimeErrorMessage = "";
          this.vehicleSeizure = result;
          this.PopulateSeizureInformation()
          this.isSeizureTimeEdited = false;
          this.isSaving = false;
          this.getVehicleSeizureChange.emit(result);
        },
          (error: any) => {
            if (error.status == 401 || error.status == 403) {
              this.seizureTimeErrorMessage = "You are unauthorized to perform this operation";
            }
      else  {
              this.isSaving = false;
              this.seizureTimeErrorMessage = this.getErrorMessage(error);
            }
          });
    }
  }

  editPoliceFileNumberSaveClick(isValid: boolean)
  {
    if(isValid)
    {
      this.isSaving = true;
      this.adjudicationService.updateVehicleSeizurePoliceFileNumber(this.vehicleSeizure.seizureNumber, this.vehicleSeizure.policeFileNumber)
        .subscribe((result: VehicleSeizure) => {
          this.policeFileNumberErrorMessage = "";
          this.vehicleSeizure = result;
          this.PopulateSeizureInformation()
          this.isPoliceFileNumberEdited = false;
          this.isSaving = false;
          this.getVehicleSeizureChange.emit(result);
        },
          (error: any) => {
            if (error.status == 401 || error.status == 403) {
              this.policeFileNumberErrorMessage = "You are unauthorized to perform this operation";
            }
      else  {
              this.isSaving = false;
              this.policeFileNumberErrorMessage = this.getErrorMessage(error);
            }
          });
    }
  }

  editTowLotInfoClick()
  {
    this.towCompany = new TowCompany();
    this.towCompany.towCompanyName = this.vehicleSeizure?.towCompanyName;
    this.towCompany.towLotAddress = this.vehicleSeizure?.towLotAddress;
    this.towCompany.city = this.vehicleSeizure?.towLotCity;
    this.towCompany.postalCode = this.vehicleSeizure?.towLotPostalCode;
    this.towCompany.phoneNumber = this.vehicleSeizure?.towLotPhoneNumber;
    this.towCompany.faxNumber = this.vehicleSeizure?.towLotFaxNumber;
    this.towCompany.towLotRepresentative = this.vehicleSeizure?.towLotRepresentative;
    this.towCompany.towLotEmailAddress = this.vehicleSeizure?.towLotEmailAddress;
    this.selectedTowCompany = this.towCompanies.find(x => x.towCompanyName == this.vehicleSeizure?.towCompanyName && x.towLotAddress == this.vehicleSeizure?.towLotAddress && x.city == this.vehicleSeizure?.towLotCity)??null;
    this.isTowLotInfoEdited = true;
  }

  saveEditedTowLotInfo(isValid:boolean) 
  {
    this.isSubmitClicked=true;
    if(isValid)
    {
      this.isSaving = true;

      this.vehicleSeizure.towCompanyName = this.towCompany.towCompanyName;
      this.vehicleSeizure.towLotAddress = this.towCompany.towLotAddress;
      this.vehicleSeizure.towLotCity = this.towCompany.city;
      this.vehicleSeizure.towLotPostalCode = this.towCompany.postalCode;
      this.vehicleSeizure.towLotPhoneNumber = this.towCompany.phoneNumber;
      this.vehicleSeizure.towLotFaxNumber = this.towCompany.faxNumber;
      this.vehicleSeizure.towLotRepresentative = this.towCompany.towLotRepresentative;
      this.vehicleSeizure.towLotEmailAddress = this.towCompany.towLotEmailAddress;

      this.adjudicationService.updateVehicleSeizureTowLotInfo(this.vehicleSeizure)
        .subscribe((result: VehicleSeizure) => {
          this.towLotErrorMessage = "";
          this.vehicleSeizure = result;
          this.PopulateSeizureInformation()
          this.isTowLotInfoEdited = false;
          this.isSaving = false;
          this.getVehicleSeizureChange.emit(result);
        },
          (error: any) => {
            if (error.status == 401 || error.status == 403) {
              this.towLotErrorMessage = "You are unauthorized to perform this operation";
            }
      else  {
              this.isSaving = false;
              this.towLotErrorMessage = this.getErrorMessage(error);
            }
          });
    }
  }

  cancelEditedTowLotInfo()
  {
    this.isTowLotInfoEdited = false;
    this.towCompany = null;
    this.selectedTowCompany = null;
    this.towLotErrorMessage = "";
  }

  onTowLotChange(towCompany: TowCompany)
  {
    this.towCompany = towCompany;
    if (towCompany)
      this.towCompany.postalCode = towCompany.postalCode===null || towCompany.postalCode===undefined ? "" : towCompany.postalCode;
  }

  getErrorMessage(error: any): string {
    if (error?.error && Array.isArray(error?.error))
      return error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      return error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      return error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      return error.error.errors;
    else
      return "Something went wrong. Please try again later.";
  }

  ClearSectionDetails():void{    
    this.policeServiceInformation = [];
    this.vehicleInformation = [];
    this.registeredOwner = [];
    this.towLotInformation = [];
    this.declarations = [];
    this.supportingDocumentDeclarations = [];
    this.policeNarrativeDetails = [];
    this.trailerInformation = [];
    this.movesVehicleInformation = [];
    this.occurrenceLocationDetails = [];
  }

  saveRegisteredOwnerClick(isValid: boolean) {
    this.isSubmitClicked = true;
    if (isValid) {
      this.vehicle.registeredOwner.registeredOwnerContact.provinceId = this.provinceId;
      this.vehicle.registeredOwner.registeredOwnerContact.countryId = this.countryId;

      if (this.vehicle.registeredOwner.registeredOwnerContact?.postalCode)
        this.vehicle.registeredOwner.registeredOwnerContact.postalCode = this.vehicle.registeredOwner.registeredOwnerContact.postalCode.toUpperCase();

      this.vehicle.registeredOwner.genderId = this.genderId;
      this.vehicle.registeredOwner.dateOfBirth = this.dateOfBirth;

      this.spinner.show();
      this.adjudicationService.updateVehicleSeizureRegisteredOwner(this.vehicleSeizure.seizureNumber, this.vehicle.registeredOwner)
        .subscribe((result: VehicleSeizure) => {
          this.getVehicleSeizureChange.emit(result);
          this.clearEditedRegisteredOwnerInformation();
          this.spinner.hide();
        }, (error: any) => {
          if (error.status == 401 || error.status == 403) {
            this.errorMessageRegisteredOwner = "You are unauthorized to perform this operation";
          }
          else {
            this.showErrors(error);
          }
          this.spinner.hide();
        });
    }
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessageRegisteredOwner = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessageRegisteredOwner = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessageRegisteredOwner = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessageRegisteredOwner = error.error.errors;
    else
      this.errorMessageRegisteredOwner = "Something went wrong. Please try again later.";
  }

  onCountryChange() {
    if (this.countryId == 40) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryId == 236) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 236; });
    }
    else {
      this.vehicle.registeredOwner.registeredOwnerContact.provinceId = null;
      this.provinceId = null;
    }

    this.vehicle.registeredOwner.registeredOwnerContact.province = null;

    this.setDriverLicenceClassification();
    return this.provinceTypes;
  }

  editRegisteredOwnerClick() {
    this.isRegisteredOwnerEdited = true;
    this.countryTypes = this.localStorageService.getCountryTypes();
    this.provinceTypes = this.localStorageService.getProvinceTypes();

    this.countryId = Number(this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId) ? Number(this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId) : null;
    this.provinceId = Number(this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId) ? Number(this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId) : null;
    this.genderId = Number(this.vehicle.registeredOwner.genderId) ? Number(this.vehicle.registeredOwner.genderId) : null;

    this.dateOfBirth = this.vehicle?.registeredOwner?.dateOfBirth ? new Date(this.vehicle.registeredOwner.dateOfBirth) : null;
    this.setDriverLicenceClassification();

    if (this.countryId == 40) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryId == 236) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 236; });
    }
  }

  clearEditedRegisteredOwnerInformation() {
    this.isRegisteredOwnerEdited = false;
    this.errorMessageRegisteredOwner = null;
  }

  cancelRegisteredOwnerClick() {
    this.clearEditedRegisteredOwnerInformation();
    this.getVehicleSeizureChange.emit(this.vehicleSeizure);
  }

  onProvinceTextChange(e) {
    this.vehicle.registeredOwner.registeredOwnerContact.provinceId = null;
    this.provinceId = null;
  }

  setDriverLicenceClassification() {
    this.isAlbertaDriverLicense = this.provinceId == 1;
    // Phase 2 Build - driver licence classification was added to the database so get it from there is available.
    if (this.contravention?.recipient?.recipientIdentification?.driversLicenceClassificationId) {
      this.driverLicenceClassification = this.contravention?.recipient?.recipientIdentification?.driversLicenceClassificationId;
      return;
    }

    // If driver licence classification is not available in database then derive it from province.
    if (this.isAlbertaDriverLicense) {
      this.driverLicenceClassification = 1;
    } else if (this.contravention?.recipient?.isNoLicenceProduced) {
      this.driverLicenceClassification = 3;
    } else {
      this.driverLicenceClassification = 2;
    }
  }

  toCheckEditPermission() {
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey !== null && rolePermissionKey !== undefined) {
      rolePermissionKey.forEach(p => {
        if (p.roleName === Constants.Role.BUSINESS_ANALYST || p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
          if (p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
            this.isAllowEditForBusinessSuperUser = true;
          }
        }
      });
    }
  }

  onSameAsDriverChange()
  {
    if (this.vehicle.registeredOwner?.isSameAsDriver)
    {
      //Update country province model as well
      this.countryId = Number(this.recipient.recipientContact.countryId) ? Number(this.recipient.recipientContact.countryId) : null;
      this.provinceId = Number(this.recipient.recipientContact.provinceId) ? Number(this.recipient.recipientContact.provinceId) : null;
      this.vehicle.registeredOwner.registeredOwnerContact.province = this.recipient.recipientContact.province;

      if (this.recipient.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
        this.vehicle.registeredOwner.driverLicenceNumber = this.recipient.recipientIdentification.identificationNumber;
      }
      this.vehicle.registeredOwner.motorVehicleIdentificationNumber = this.recipient.recipientIdentification.motorVehicleIdentificationNumber;
      this.vehicle.registeredOwner.firstName = this.recipient.firstName;
      this.vehicle.registeredOwner.lastName = this.recipient.lastName;
      this.vehicle.registeredOwner.otherName = this.recipient.otherName;
      this.genderId = Number(this.recipient.recipientIdentification.genderId) ? Number(this.recipient.recipientIdentification.genderId) : null;
      this.dateOfBirth = this.recipient.recipientIdentification.dateOfBirth ? new Date(this.recipient.recipientIdentification.dateOfBirth) : null;
      this.vehicle.registeredOwner.registeredOwnerContact.phoneNumber1 = this.recipient.recipientContact.phoneNumber1;
      this.vehicle.registeredOwner.registeredOwnerContact.addressLine1 = this.recipient.recipientContact.addressLine1;
      this.vehicle.registeredOwner.registeredOwnerContact.addressLine2 = this.recipient.recipientContact.addressLine2;
      this.vehicle.registeredOwner.registeredOwnerContact.addressLine3 = this.recipient.recipientContact.addressLine3;
      this.vehicle.registeredOwner.registeredOwnerContact.countryId = this.recipient.recipientContact.countryId;
      this.vehicle.registeredOwner.registeredOwnerContact.provinceId = this.recipient.recipientContact.provinceId;
      this.vehicle.registeredOwner.registeredOwnerContact.province = this.recipient.recipientContact.province;
      this.vehicle.registeredOwner.registeredOwnerContact.city = this.recipient.recipientContact.city;
      this.vehicle.registeredOwner.registeredOwnerContact.postalCode = this.recipient.recipientContact.postalCode;
    }
    else if (this.isSameAsDriverSelectionMade) //This is to avoid clearing fields for the first time selection.
    {
        this.clearRegisteredOwnerData();
    }

    this.isSameAsDriverSelectionMade = true;
  }

  private clearRegisteredOwnerData()
  {
    this.vehicle.registeredOwner.driverLicenceNumber = "";
    this.vehicle.registeredOwner.motorVehicleIdentificationNumber = "";
    this.vehicle.registeredOwner.firstName = "";
    this.vehicle.registeredOwner.otherName = "";
    this.vehicle.registeredOwner.lastName = "";
    this.vehicle.registeredOwner.genderId = null;
    this.genderId = null;
    this.vehicle.registeredOwner.dateOfBirth = null;
    this.dateOfBirth = null;
    this.vehicle.registeredOwner.registeredOwnerContact.addressLine1 = "";
    this.vehicle.registeredOwner.registeredOwnerContact.addressLine2 = "";
    this.vehicle.registeredOwner.registeredOwnerContact.addressLine3 = "";
    this.vehicle.registeredOwner.registeredOwnerContact.phoneNumber1 = "";
    this.vehicle.registeredOwner.registeredOwnerContact.phoneNumber2 = "";
    this.vehicle.registeredOwner.registeredOwnerContact.faxNumber = "";
    this.vehicle.registeredOwner.registeredOwnerContact.countryId = null;
    this.vehicle.registeredOwner.registeredOwnerContact.provinceId = null;
    this.vehicle.registeredOwner.registeredOwnerContact.province = "";
    this.countryId = null;
    this.provinceId = null;
    this.vehicle.registeredOwner.registeredOwnerContact.province = "";
    this.vehicle.registeredOwner.registeredOwnerContact.city = "";
    this.vehicle.registeredOwner.registeredOwnerContact.postalCode = "";
  }
}
