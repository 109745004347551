<div class="cancel-modal-overlay">
    <div class="cancel-modal">
        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Cancel Seizure 
                <span class="error">{{errorMessageHeader}}</span>
            </div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body">
            <label for="cancellationReason" class="bold-text">Reason for cancelling <span class="label-hint">(Required)</span></label>
            <textarea id="cancellationReasonTextarea" name="cancellationReason"
                class="cancel-reason-textarea" 
                [(ngModel)]="cancellationReason"
                #cancellationReasonModel="ngModel"                
                (input)="onCancelTextareaInput($event)"
                [ngClass]="{'alert-border': isSubmitClicked && cancellationReasonModel.invalid }"
                ></textarea>
            <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            <div class="invalid-feedback"
                [ngClass]="{ 'show': cancellationReasonModel.invalid && isSubmitClicked }">
                <div>Reason for cancelling is required.</div>
            </div>  

            <div class="documents-section" *ngIf="isSeizureCancelled">
                <div class="documents-section-header">Uploaded Documents</div>
                <div class="d-flex mt-3 mb-3 flex-column">        
                    <table class="table">
                        <thead>
                            <tr>                                                
                                <th>Filename</th>
                                <th>Type</th>
                                <th>Date Added</th>
                                <th>Format</th>
                                <th>File Size</th>                                                                       
                            </tr>
                        </thead>                                    
                        <tbody *ngIf="documents?.length > 0">                    
                            <tr *ngFor="let document of documents">                                
                                <td>   
                                    <div class="d-flex align-items-center">
                                        <img class="download-file-icon" src="assets/images/download-file.png" />
                                        <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                                    </div>                                                       
                                </td>
                                <td style="padding-bottom: 8px !important;">{{getDocumentType(document.documentTypeId)}}</td>
                                <td style="padding-bottom: 8px !important;">{{document.uploadedDate | date: "mediumDate"}}</td>
                                <td class="document-format"  style="padding-bottom: 8px !important;">{{document.documentExtension}}</td>
                                <td  style="padding-bottom: 8px !important;">{{document.documentSize}}</td>                                
                            </tr>                      
                        </tbody>                               
                    </table>                    
                </div>
            </div>

            <div class="documents-section" *ngIf="!isSeizureCancelled">
                <div class="documents-section-header">Documents</div>
                <div class="document-container">
                    <div id="requiredDocumentContainer" class="d-flex justify-content-between required-document-container">
                        <div class="document-type">
                            Cancellation Authorization</div>
                        <div class="d-flex flex-column justify-content-around align-items-end">
                            <button class="upload-button" 
                                (click)="onUploadRequiredDocumentClick(requiredDocumentUpload)">Upload</button>
                            <input type="file" #requiredDocumentUpload (change)="onRequiredDocumentChange($event)" />
                            <div class="document-upload-hint">Allowed: PDF, maximum file size is 5 MB</div>
                            <div *ngIf="requiredDocumentUploadError?.length > 0" 
                                class="document-upload-error-container">
                                <div>Upload error:</div>
                                <div class="document-upload-error">{{requiredDocumentUploadError}}</div>
                            </div>
                        </div>
                    </div>
                    <div id="requiredDocumentUploadContainer" class="upload-container row">
                        <div class="col-12 col-lg-6">
                            <div class="document-type pt-0">Cancellation Authorization</div>
                            <a class="document-name" (click)="onTempDocumentNameClick(requiredDocument)">{{requiredDocumentName}}</a>                            
                        </div>
                        <div class="upload-bar col-12 col-lg-4">
                            <div id="requiredDocumentUploadProgress" class="upload-bar-progress"></div>              
                        </div>
                        <a id="requiredDocumentUploadRemoveFileLink" 
                            class="remove-file-link col-12 col-lg-2"
                            [ngClass]="{'disabled': uploadingRequiredDocument }"
                            (click)="onRemoveFileLinkClick(requiredDocument)">Remove File</a>
                    </div>
                    <div id="additionalDocumentUploadContainer" class="upload-container row">
                        <div class="col-12 col-lg-6">
                            <div class="document-type pt-0">Additional Cancellation Document</div>
                            <a class="document-name" (click)="onTempDocumentNameClick(additionalDocument)">{{additionalDocumentName}}</a>
                        </div>
                        <div class="upload-bar col-12 col-lg-4">
                            <div id="additionalDocumentUploadProgress" class="upload-bar-progress"></div>              
                        </div>
                        <a id="additionalDocumentUploadRemoveFileLink" 
                            class="remove-file-link col-12 col-lg-2"
                            [ngClass]="{'disabled': uploadingAdditionalDocument }"
                            (click)="onRemoveFileLinkClick(additionalDocument)">Remove File</a>
                    </div>
                </div>
                <button class="additional-document-button"
                    (click)="onUploadAdditionalDocumentClick(additionalDocumentUpload)">Additional Document</button>
                <input type="file" #additionalDocumentUpload (change)="onAdditionalDocumentUploadChange($event)" />
                <div class="document-upload-hint">Allowed: Maximum file size is 5 MB</div>
                <div *ngIf="additionalDocumentUploadError?.length > 0" 
                    class="document-upload-error-container">
                    <div>Upload error:</div>
                    <div class="document-upload-error">{{additionalDocumentUploadError}}</div>
                </div>
            </div>                  
        </div>
        <div class="cancellation-alert">
            <div class="alert-icon">                
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="cancellation-alert-text">
                <span class="bold-text">Are you sure you want to cancel this seizure?</span>
                <div>This action cannot be undone.</div>
            </div>                       
        </div>
        <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container">
            <div id="btnCancelModalCancel" class="cancel-button" (click)="onCancelClick()" >Cancel</div>
            <div id="btnCancelModalConfirm" class="confirm-button" 
                [ngClass]="{'disabled': maxCharacters < 0 || isSeizureCancelled || uploadingRequiredDocument || uploadingAdditionalDocument }" 
                (click)="onConfirmCancellationClick(cancellationReasonModel.valid)">Confirm Cancellation</div>
        </div>
    </div>
</div>
<div class="confirm-modal-overlay">
    <div class="confirm-modal">

        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Confirm Cancellation</div>
            <div class="close-icon" (click)="showHideConfirmModal(false)">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body">
            <div class="cancellation-alert">
                <div class="alert-icon">                
                    <i class="fas fa-exclamation-triangle fa-lg"></i>
                </div>
                <div class="cancellation-alert-text">
                    <span class="bold-text">Are you sure you want to cancel this seizure?</span>
                </div>                       
            </div>

            <div class="button-container">
                <button id="btnGoBack" class="btn btn-cancel" (click)="showHideConfirmModal(false)">Cancel</button>
                <button id="btnConfirm" class="btn btn-confirm" (click)="onConfirmClick()">Confirm</button>
            </div>
            
        </div>
        
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>
