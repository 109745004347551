import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes, ReviewStatusTypes } from "@apis/shared/enums/app.enum";
import { ReviewService } from '../../../shared/services/review.service';

@Component({
  selector: 'app-change-review-status-modal',
  templateUrl: './change-review-status-modal.component.html',
  styleUrls: ['./change-review-status-modal.component.scss']
})
export class ChangeReviewStatusModalComponent implements OnInit {
  review: Review;  

  reviewStatusTypeId: number = 0;
  selectedReviewStatusTypeId: number;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;
  reviewStatusTypes: ReviewStatusType[] = [];
  reviewStatusDropdown: JQuery<HTMLElement>;
  changeButton: JQuery<HTMLElement>;
  eventDetails: string;
    
  @Output() close: EventEmitter<Review> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService) { }

  ngOnInit(): void {        
    this.reviewStatusTypes = this.localStorageService.getReviewStatusTypes().filter(r => +r.id == ReviewStatusTypes.Prep 
                                                                                      || +r.id == ReviewStatusTypes.PendingReview);
    this.selectedReviewStatusTypeId = +this.review.reviewStatusTypeId;
   
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.changeButton = $(".change-button");      
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onChangeModalClick(ev: any): void {
    if (this.changeButton.hasClass("disabled")) {
      return;
    }
        
    this.changeButton.addClass("disabled");
    this.changeButton.addClass("saving");

    let event = new Event({      
      eventTypeId: EventTypes.ReviewStatusChanged,
      eventDetails: this.eventDetails      
    });
            
    const review = new Review({
      reviewId: +this.review.reviewId,
      reviewStatusTypeId: +this.reviewStatusTypeId     
    });

    review.events.push(event);
        
    this.reviewService.putReviewStatus(review)
      .subscribe(
        (result: Review) => { 
          this.changeButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {});    
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  

  isChangeButtonDisabled(): boolean {
    return this.maxCharacters < 0
      || this.reviewStatusTypeId == 0
      || this.reviewStatusTypeId == this.selectedReviewStatusTypeId;
  }
}
