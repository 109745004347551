import { LateReviewRequest } from './late-review-request.model';

import { Review } from "./review.model";
import { StayOrder } from "./stay-order.model";
import { Document } from './document.model';
import { Event } from './event.model';

export class JudicialReview {
        public judicialReviewId :number = 0;        
        public judicialReviewNumber :string;        
        public courtActionNumber :string;        
        public servedDate :Date;        
        public receivedDate :Date;        
        public reviewId :number;        
        public reviewNumber :string;        
        public judicialReviewDecisionDate :Date;        
        public judicialReviewDecisionTypeId :number;        
        public reReviewId :number;        
        public reReviewNumber :string;        
        public judicialReviewDecisionSubmittedDate :Date;        
        public contraventionId :number;       
        public vehicleSeizureId :number;        
        public review :Review;   
        public lateReviewRequest: LateReviewRequest;
        public termsAndConditionsDate :Date;     
        public recipientName :string;
        public stayOrders :StayOrder[] = [];
        public documents: Document[] = [];
        public events: Event[] = [];  
        public tempFolderName: string;    
        public reReview: Review;

    constructor(init?: Partial<JudicialReview>) {
        
        Object.assign(this, init);
    }
}