import { Component, OnInit, Input, Output, ViewContainerRef, OnChanges, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Notification } from '@apis/shared/models/notification.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContactMethodTypes, EventTypes, RequestTypes } from '@apis/shared/enums/app.enum';
import { RequestService } from '../../../shared/services/request.service';
import { Observable, Subscriber } from 'rxjs';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { KeycloakService } from 'keycloak-angular';
import { Event } from "@apis/shared/models/event.model";
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { Constants } from "@apis/shared/helpers/constants";
import { LogEntry } from '@apis/shared/models/log-entry.model';
import { LogSearchRequestModel } from '../../../shared/models/log-search-request.model';

@Component({
  selector: 'request-activity',
  templateUrl: './request-activity.component.html',
  styleUrls: ['./request-activity.component.scss']
})
export class RequestActivityComponent implements OnInit, OnChanges {
  @Input() request: any;
  @Input() requestType: number;
  @Output() activityNoteSubmitted = new EventEmitter<Request>();

  recentActivities: any[] = [];
  TypeTable = TypeTable;
  EventTypes = EventTypes;
  RequestTypes = RequestTypes;
  loggedUserName: string;
  requestNumber: string;
  requestId: number;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  datePipe: DatePipe;

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly requestService: RequestService,
    readonly viewContainerRef: ViewContainerRef,
    private readonly keycloakService: KeycloakService,
    private readonly documentService: DocumentService) { }

  ngOnInit(): void {    
    this.datePipe = new DatePipe("en-US");
    this.loggedUserName = this.keycloakService.getUsername();
  }

  ngOnChanges(): void {    
    if(this.request != null) {   
      if (this.requestType == RequestTypes.LEACancellationRequest)
      {
        this.requestId =  this.request.noticeCancellationRequestId;   
        this.requestNumber = this.request.noticeCancellationRequestNumber;    
      }
      else
      {
        this.requestId =  this.request.lateReviewRequestId;   
        this.requestNumber = this.request.lateReviewRequestNumber;    
      }
              
      this.populateRecentActivity();
    }
 }

 canModifyNote(recentActivity: any): boolean {
  return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
    && (recentActivity.by == this.loggedUserName
    // Allow Case Coordinator to delete notes created by Case Administrator
    || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
}
 
  getNotificationTypeName(notificationTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(notificationTypeId, TypeTable.NotificationType)
  }

  getContactedBy(contactedBy: string): string {
    return contactedBy != null ? contactedBy : "-";
  }
  
  getContactMethodTypeName(contactMethodTypeId: number): string {   
    let contactMethodTypeName = this.localStorageService.getTypeItemDescriptionById(contactMethodTypeId, TypeTable.ContactMethodType);    
    return contactMethodTypeName != null && contactMethodTypeName.length > 0 ? contactMethodTypeName : "-";
  }

  getNotificationDate(notificationDate: Date): string {    
    if(notificationDate != null) {
      notificationDate = new Date(notificationDate.toString().replace(/[zZ]/, ""));

      // Offset the timezone
      notificationDate.setMinutes(notificationDate.getMinutes() - notificationDate.getTimezoneOffset()); 

      return new DatePipe("en-US").transform(notificationDate, "mediumDate")
    } else {
      return "-";
    }
  }
  
  onNotificationCheckboxClick(ev: any, notification: Notification): void {
    if (!ev.target.parentElement.classList.contains("active")) {
      ev.target.parentElement.classList.add("active");

      new Observable((subscriber: Subscriber<Notification>) => {
        if (notification.lateReviewRequestId != null) {
          this.requestService.putLateReviewRequestNotification(notification)
            .subscribe((result: Notification) => {
              subscriber.next(result);
            }, (error: any) => subscriber.error(error));
        } 
      }).subscribe((result: Notification) => {        
        if (result != null) {            
          let notificationDate = new Date(result.notificationDate.toString().replace(/[zZ]/, ""));
          notificationDate.setMinutes(notificationDate.getMinutes() - notificationDate.getTimezoneOffset());
          
          let index = this.request.notifications.findIndex(n => +n.notificationId == +result.notificationId);
          this.request.notifications.splice(index, 1, result);

          this.request.isPhoneContactRequired = this.request.notifications.findIndex(n => +n.contactMethodTypeId == ContactMethodTypes.Phone && n.notificationDate == null) != -1;
        }
      });                        
    } 
  }

  onDriverLogsSelectionChanged(result: any): void {
    this.populateRecentActivity(result);
  }

  getDriverLogSearchRequest = () => {
    var searchRequest = new LogSearchRequestModel();

    var searchCriteria = this.request.documents.map(d => d.contentGuid);
    searchCriteria.push(this.request.lateReviewRequestNumber);

    searchRequest.searchCriteria = searchCriteria;
    searchRequest.fromDate = this.request.requestDate;

    return searchRequest;
  }

  public populateRecentActivity(logs?: LogEntry[]): void {
    this.recentActivities = [];    
    let events = this.request.events;    
    
    for (let event of events) {          
      let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
      eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
      let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
      let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
      let username = event.userName;
      let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
      let body = event.eventDetails;

      this.recentActivities.push({
        date: `${eventDate} at ${eventTime}`,
        dateTime: eventDateTime,
        by: username,
        title: title,
        body: body,
        documents: event.documents,
        id: event.eventId,
        isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
      });
    }

    this.pushDriverLogsToActivities(logs);
    
    this.recentActivities = this.recentActivities?.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });    

    this.setOffSetTopForRequestRecentActivity();
  }

  public setOffSetTopForRequestRecentActivity() {    
    setTimeout(() => {  
      if ($(".request-recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".request-recent-activity-container:last-child")[0].offsetTop;    
          $(".request-recent-activity-connector").height(offsetTop);
          $(".request-recent-activity-group").animate({ scrollTop: 0 }, "fast");
        }
      }, 100);
  }

  onAddNoteLinkClick(): void {        
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    if (this.requestType == RequestTypes.LEACancellationRequest)
      componentRef.instance.noticeCancellationRequestId = this.requestId;      
    else  
      componentRef.instance.lateReviewRequestId = this.requestId;      
      
    componentRef.instance.documentRefTypeNumber = this.requestNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.request.events.push(result);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.request);
      }
      this.viewContainerRef.clear();
    });
  }
  
  onEditNoteLinkClick(eventId: number): void {        
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.requestNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.request.events = this.request.events.filter(x=>x.eventId !== eventId);
        this.request.events.push(result);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.request);
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.requestNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.request.events = this.request.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();
        this.activityNoteSubmitted.emit(this.request);
      }
      this.viewContainerRef.clear();
    });
  }

  onEventDocumentNameClick(document: Document): void {      
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Events", this.requestNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  private pushDriverLogsToActivities(logs: LogEntry[])
  {
    if (logs) {
      for (let log of logs) {          
        let eventDateTime = new Date(log.timeStamp.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
        let username = log.userId;
        let title = log.title;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          id: log.id,
          isFromDriverLogs: true
        });
      }
    }
  }
}
