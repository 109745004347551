import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContactMethodTypes, DocumentTypes, EventTypes, JudicialReviewDecisionTypes, NotificationTypes } from "@apis/shared/enums/app.enum";
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { NgModel, Validators } from '@angular/forms';
import { Notification } from '@apis/shared/models/notification.model';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { Document } from '@apis/shared/models/document.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { JudicalReviewDecisionType } from '@apis/shared/models/types/judicial-review-decision-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { Guid } from 'guid-typescript';
import { DocumentUploadComponent } from '../../../shared/components/document-upload/document-upload.component';

@Component({
  selector: 'app-judicial-review-decision',
  templateUrl: './judicial-review-decision.component.html',
  styleUrls: ['./judicial-review-decision.component.scss']
})
export class JudicialReviewDecisionComponent implements OnInit {
  judicialReview: JudicialReview;
  isCourtActionNumberReadonly: boolean = false;
  courtDecisionDocumentTypeId = DocumentTypes.CourtDecision;

  judicialReviewUploads: Document[];
  selectedDocument: Document;
  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  document: Document;
  validFileExtensions: string[];

  errorMessage: string = "";


  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;

  submitButton: JQuery<HTMLElement>;

  disableFileUpload: boolean = false;
  formSubmitted: boolean = false;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];
  tempFolderName: string;

  receivedDate: Date;
  courtActionNumber: string = "";
  fileUploadModel: FileUploadModel;
  JudicialReviewDecisionTypes: JudicialReviewDecisionTypes;

  judicialReviewDecisionTypes: JudicalReviewDecisionType[];
  @Output() close: EventEmitter<JudicialReview> = new EventEmitter();

  @ViewChild(DocumentUploadComponent) documentUploadComponent: DocumentUploadComponent;

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private readonly adjudicationService: AdjudicationService) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
  }

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");
    this.submitButton = $(".submit-button");

    this.validFileExtensions = this.localStorageService.getDocumentTypes()?.find(x => x.id == this.courtDecisionDocumentTypeId).acceptedFileTypes.split(',').map(item => `.${item}`);
    //Initialize file upload model and a temporary file storage location
    this.fileUploadModel = new FileUploadModel();
    this.fileUploadModel.tempFileFolder = Guid.create().toString();
    this.addPlaceHolder(DocumentTypes.CourtDecision, false);    

    //Get Types
    this.judicialReviewDecisionTypes = this.localStorageService.getJudicialReviewDecisionTypes();
    this.judicialReviewDecisionTypes = this.judicialReviewDecisionTypes.filter(t => t.id != +JudicialReviewDecisionTypes.ContraventionCancelled); // JTI-5781: ContraventionCancelled is no longer available

    if (this.judicialReview.contraventionId) {
      this.judicialReviewDecisionTypes = this.judicialReviewDecisionTypes.filter(t => t.id != +JudicialReviewDecisionTypes.SeizureCancelled);
    } else {
      this.judicialReviewDecisionTypes = this.judicialReviewDecisionTypes.filter(t => t.id != +JudicialReviewDecisionTypes.ContraventionCancelledByCourtOrder
                                                                                  && t.id != +JudicialReviewDecisionTypes.ContraventionCancelledByConsentOrder);
    }

    if (this.judicialReview?.courtActionNumber && this.judicialReview?.courtActionNumber?.length > 0) {
      this.isCourtActionNumberReadonly = true;
    }
  }

  addPlaceHolder(documentType: DocumentTypes, isOptional: boolean = false) {    
    this.selectedDocument = new Document({
      documentTypeId: +documentType,
      isOptional: isOptional
    });    
  }

  onCloseIconClick(): void {    
    this.removeOverflow();
    this.close.emit();
  }

  onSubmitClick(ev: any): void {
    if (this.submitButton.hasClass("disabled")) {
      return;
    }
    this.formSubmitted = true;
    
    if (this.judicialReview.judicialReviewDecisionDate && this.judicialReview.judicialReviewDecisionTypeId != null && this.validateDocuments()) {

      this.submitButton.addClass("disabled");
      this.submitButton.addClass("saving");     
      this.disableFileUpload = true;

      if (this.selectedDocument != null) {     
        // Set document uploaded_date and uploaded_by
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());   
        this.selectedDocument.uploadedDate = today;
        this.selectedDocument.uploadedBy = "System Generated";
        this.judicialReview.documents.push(this.selectedDocument);
      }

      this.judicialReview.tempFolderName = this.fileUploadModel.tempFileFolder;

      this.adjudicationService.updateJudicialReviewDecision(this.judicialReview)
        .subscribe(
          (result: JudicialReview) => {
            this.submitButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
          },
          (error: any) => { this.submitButton.removeClass("saving");
          this.submitButton.removeClass("disabled");
          this.disableFileUpload = false;
          this.errorMessage = "Unable to update judicial review decision. Please try later.";});
    }
  }

  removeOverflow(): void {
    this.bodyElement.removeClass("overflow-hidden");
  }

  validateDocuments(): boolean {    
    if (this.selectedDocument.documentName==null || this.selectedDocument.documentName=="") {
      this.errorMessage = "A Court decision letter is required.";
      return false;
    }
    return true;
  }

  // onDocumentAdded(document: Document): void {
  //   this.selectedDocument = document;    
  //   this.errorMessage = "";
  // }

  onCancelDocument(document: Document)
  {
    this.selectedDocument = null;
  }

}
