import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContactMethodTypes, EventTypes } from "@apis/shared/enums/app.enum";
import { KeycloakService } from 'keycloak-angular';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { ReviewService } from '../../../shared/services/review.service';
import { CountryType } from '@apis/shared/models/types/country-type.model';
import { ProvinceType } from '@apis/shared/models/types/province-type.model';
import { AbstractControl, NgForm, NgModel, ValidatorFn, Validators } from '@angular/forms';
import { MustMatchValidator } from "../../../shared/validators/must-match.validator";

@Component({
  selector: 'app-change-contact-method-modal',
  templateUrl: './change-contact-method-modal.component.html',
  styleUrls: ['./change-contact-method-modal.component.scss']
})
export class ChangeContactMethodModalComponent implements OnInit, AfterViewInit {
  review: Review;

  maxCharacters: number = 250;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  modalTextarea: JQuery<HTMLElement>;    
  changeButton: JQuery<HTMLElement>;

  phoneContactMethod: JQuery<HTMLElement>;
  textContactMethod: JQuery<HTMLElement>;
  emailContactMethod: JQuery<HTMLElement>;

  eventDetails: string;  
 
  contactMethodTypes: ContactMethodType[];  
  contactMethodType = ContactMethodTypes;

  phoneFormSubmitted: boolean;
  emailFormSubmitted: boolean;
  textFormSubmitted: boolean;

  countryTypes: CountryType[];
  provinceTypes: ProvinceType[];
    
  @Output() close: EventEmitter<Review> = new EventEmitter();

  @ViewChild("contactMethodTypeId") contactMethodTypeControl: NgModel;

  confirmPhoneNumber: string;
  tempPhoneNumber:string;
  @ViewChild("phoneNumber") phoneNumberControl: NgModel;
  @ViewChild("phoneNumberConfirmation") confirmPhoneNumberControl: NgModel;    
  @ViewChild("phoneContactForm") phoneContactForm: NgForm;

  confirmEmail: string;
  @ViewChild("email") emailControl: NgModel;
  @ViewChild("emailConfirmation") confirmEmailControl: NgModel;    
  @ViewChild("emailContactForm") emailContactForm: NgForm;
  
  confirmTextPhoneNumberModel: string;
  @ViewChild("textPhoneNumber") textPhoneNumberControl: NgModel;
  @ViewChild("confirmTextPhoneNumber") confirmTextPhoneNumberControl: NgModel;
  @ViewChild("textContactForm") textContactForm: NgForm;

  
  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService) { }

  ngAfterViewInit(): void {         
      this.phoneNumberControl.valueChanges.subscribe((value: string) => {
        this.confirmPhoneNumberControl.control.updateValueAndValidity();
      });
      this.confirmPhoneNumberControl.control.setValidators([MustMatchValidator(this.phoneNumberControl.control)]); 
          
      this.emailControl.valueChanges.subscribe((value: string) => {
        this.confirmEmailControl.control.updateValueAndValidity();
      });
      this.confirmEmailControl.control.setValidators([MustMatchValidator(this.emailControl.control)]);
        
      this.textPhoneNumberControl.valueChanges.subscribe((value: string) => {
        this.confirmTextPhoneNumberControl.control.updateValueAndValidity();
      });
      this.confirmTextPhoneNumberControl.control.setValidators([MustMatchValidator(this.textPhoneNumberControl.control)]);    
  }

  ngOnInit(): void { 
    //JTI:1708 Trim country code from phone number
    if(this.review.phoneNumber!=null && this.review.phoneNumber.trim()!="")
    {      
      this.tempPhoneNumber = this.review.phoneNumber.substr(1);
    }     
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();
    this.countryTypes = this.localStorageService.getCountryTypes();
    this.provinceTypes = this.localStorageService.getProvinceTypes();

    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.changeButton = $(".change-button"); 

    this.phoneContactMethod = $(".phone-contact-method");
    this.textContactMethod = $(".text-contact-method");
    this.emailContactMethod = $(".email-contact-method");

    this.showContactMethodSection();
  }

  onContactMethodTypeChange(): void {    
    this.showContactMethodSection();
  }

  showContactMethodSection(): void {        
    $("div[class*='contact-method']").removeClass("show");

    switch (+this.review.contactMethodTypeId) {
      case ContactMethodTypes.Phone:        
        $(".phone-contact-method").addClass("show");
        if (this.review.contactAddress?.provinceId != null) {
          this.review.contactAddress.provinceId = +this.review.contactAddress.provinceId;
        }
    
        if (this.review.contactAddress?.countryId != null) {
          this.review.contactAddress.countryId = +this.review.contactAddress.countryId;
        }  
        break;
      case ContactMethodTypes.Email:
        $(".email-contact-method").addClass("show");
        break;
      case ContactMethodTypes.Text:
        $(".text-contact-method").addClass("show");
        break;
    }
  }
  
  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }

  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onChangeModalClick(ev: any): void {    
    switch(+this.review.contactMethodTypeId) {
      case ContactMethodTypes.Phone:
        this.phoneFormSubmitted = true;     

        if (this.phoneContactForm.invalid) {
          return;
        }
        break;
      case ContactMethodTypes.Email:
        this.emailFormSubmitted = true;                
        if (this.emailContactForm.invalid) {
          return;
        }
        break;
      case ContactMethodTypes.Text:
        this.textFormSubmitted = true;

        if (this.textContactForm.invalid) {
          return;
        }
        break;
    }
    
    this.changeButton.addClass("disabled");
    this.changeButton.addClass("saving");

    let event = new Event({      
      eventTypeId: EventTypes.ContactMethodChanged,
      eventDetails: this.eventDetails    
    });
            
    const review = new Review({
      reviewId: this.review.reviewId,
      contactMethodTypeId: +this.review.contactMethodTypeId,
      contactAddress: this.review.contactAddress,
      email: this.review.email,
      phoneNumber: this.review.phoneNumber
    });
    
    review.events.push(event);

    switch(+this.review.contactMethodTypeId) {
      case ContactMethodTypes.Phone:
        review.email = null;
        break;
      case ContactMethodTypes.Email:
        review.contactAddress = null;
        review.phoneNumber = null;
        break;
      case ContactMethodTypes.Text:
        review.contactAddress = null;
        review.email = null;
        break;
    }
            
    this.reviewService.putContactMethod(review)
      .subscribe(
        (result: Review) => { 
          this.changeButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {});    
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  

  isChangeButtonDisabled(): boolean {
    return this.maxCharacters < 0
      || +this.review.contactMethodTypeId == 0;
  } 
}