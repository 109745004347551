import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AdjudicationService } from "../shared/services/adjudication.service";
import { ContraventionInformationComponent } from './components/contravention-information/contravention-information.component';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionStatusType } from '@apis/shared/models/types/contravention-status-type.model';
import { RecipientInformationComponent } from './components/recipient-information/recipient-information.component';
import { PaymentInformationComponent } from './components/payment-information/payment-information.component';
import { ContraventionStatusTypes, DocumentTypes, EventTypes,RequestTypes } from '@apis/shared/enums/app.enum';
import { CancelContraventionRequest } from '@apis/shared/models/cancel-contravention-request.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { PoliceServiceInformation } from '@apis/shared/models/police-service-information.model';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Event } from '@apis/shared/models/event.model';
import { KeycloakService } from 'keycloak-angular';
import { CommonMethods } from '../shared/helpers/common-methods';
import { CancelContraventionModalComponent } from './modals/cancel-contravention-modal/cancel-contravention-modal.component';
import { ReviewRequestExtensionModalComponent } from "../shared/components/modals/review-request-extension-modal/review-request-extension-modal.component";
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { EnableLateReviewModalComponent } from '../shared/components/modals/enable-late-review-modal/enable-late-review-modal.component';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ContraventionActivityComponent } from './components/contravention-activity/contravention-activity.component';
import { addDays } from 'date-fns';
import { FineUncollectableComponent } from './modals/fine-uncollectable/fine-uncollectable.component';
import { EnableReviewModalComponent } from '../shared/components/modals/enable-review-modal/enable-review-modal.component';

@Component({
  selector: 'app-contravention-details',
  templateUrl: './contravention-details.component.html',
  styleUrls: ['./contravention-details.component.scss']
})
export class ContraventionDetailsComponent implements OnInit, AfterViewInit {
  contraventionNumber: string;
  contravention: Contravention;
  policeServiceInformation: PoliceServiceInformation;
  supportingDocuments: any;
  recentActivities: any[] = [];  
  contraventionStatusTypes: ContraventionStatusType[] = [];  
  datePipe: DatePipe;
  review: any;
  amountPaid = 0;
  amountDue = 0;
  paymentDueInfo: string = "";
  isDueDateExtended: boolean = false;
  isRedactionRequired: boolean = false;    
  errorMessage: string = "";
  isContraventionCancelled: boolean = false;
  detachmentName: string;  
  cancellationReason: string = "";
  manageContraventionDropDown: HTMLSelectElement;
  disableExtendReviewDeadline: boolean = false;
  disableFineUncollectable: boolean = false;
  EventTypes = EventTypes;
  RequestTypes = RequestTypes;
  TypeTable = TypeTable;
  loggedUserName: string;
  disableEnableLateReview: boolean = false;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  canImmediateCancel: boolean =false;
  canReviewRequestExtension: boolean =false;
  canEnableLateReview: boolean =false;
  canSetFineUncollectable: boolean = false;
  canEnableReview: boolean =false;
  disableEnableReview: boolean = false;
  requestType:number;
  isBusinessSuperUser: boolean = false;

  @ViewChild(ContraventionInformationComponent) contraventionInformation: ContraventionInformationComponent;
  @ViewChild(RecipientInformationComponent) recipientInformation: RecipientInformationComponent;
  @ViewChild(PaymentInformationComponent) paymentInformation: PaymentInformationComponent;
  @ViewChild(ContraventionActivityComponent) contraventionActivity: ContraventionActivityComponent;

  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly adjudicationService: AdjudicationService,
    readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly spinner: NgxSpinnerService,
    private readonly keycloakService: KeycloakService) { }

  ngAfterViewInit(): void {
    this.manageContraventionDropDown = document.getElementById("manageContravention") as HTMLSelectElement;        
  }


  ngOnInit(): void {                      
    this.datePipe = new DatePipe("en-US");    
    this.loggedUserName = this.keycloakService.getUsername();
    
    this.activatedRoute.params.subscribe((data: Params) => {
      this.contraventionNumber = data.contraventionNumber;

      this.spinner.show();
      this.adjudicationService.getContraventionByNumber(this.contraventionNumber)
        .subscribe((result: Contravention) => {
          this.contravention = result;   
          this.adjudicationService.getStopInformation(this.contravention?.contraventionNumber)
          .subscribe((stopInformation: StopInformation) => {        
          this.contravention.stopInformation = stopInformation;
          this.loadContraventionData();          
          this.spinner.hide();        
        });                           
        }, (error: any) => this.spinner.hide());      
    });

    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canImmediateCancel = this.localStorageService.hasPermission(Constants.Resource.CONTRAVENTION,Constants.Permission.IMMEDIATE_CANCEL);
      this.canReviewRequestExtension = this.localStorageService.hasPermission(Constants.Resource.CONTRAVENTION,Constants.Permission.REVIEW_REQUEST_EXTENSION);
      this.canEnableLateReview = this.localStorageService.hasPermission(Constants.Resource.CONTRAVENTION,Constants.Permission.ENABLE_LATE_REVIEW);
      this.canSetFineUncollectable = this.localStorageService.hasPermission(Constants.Resource.CONTRAVENTION,Constants.Permission.FINE_UNCOLLECTABLE);
      this.canEnableReview = this.localStorageService.hasPermission(Constants.Resource.CONTRAVENTION,Constants.Permission.ENABLE_REVIEW);

      // Check for the BusinessSuperUser role. Determines whether the user can view notice cancellation requests.
      let rolePermissionKey = this.localStorageService.getRolePermissions();
      if (rolePermissionKey !== null && rolePermissionKey !== undefined) {
        rolePermissionKey.forEach(p => {
          if (p.roleName === 'BusinessSuperUser') {
            this.isBusinessSuperUser = true;
          }
        });
      }
    });
  }

  populatePaymentInfo() {
    // Reset amount paid to 0
    this.amountPaid = 0;

    //Calculate Amount Owing
    if (this.contravention?.payments && this.contravention.payments.length > 0) {
      this.contravention?.payments.forEach(p => this.amountPaid += p.amount);
    }

    if(this.contravention?.contraventionStatusTypeId == ContraventionStatusTypes.Uncollectable) {
      this.amountDue = 0;  
    } else {
      this.amountDue = this.contravention?.fine?.fineAmount + this.contravention?.fine?.victimFineSurchargeAmount - this.amountPaid;
    }

    //Check Payment Due Date
    this.paymentDueInfo = this.datePipe.transform(this.contravention.fine.dueDate, "mediumDate");
    if (this.contravention?.fine?.additionallyExtendedDueDate != null
      || this.contravention?.fine?.extendedDueDate != null)
    {
      this.isDueDateExtended = true;
    }
  }

  populateRecentActivity(): void {
    this.recentActivities = [];

    this.setContraventionSubmittedRecentActivity();                
    
    let events = this.contravention.events;

    for (let event of events) {    
      let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
      eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());
      
      let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
      let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
      let username = event.userName;
      let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
      let body = event.eventDetails;
     
      let documents = event.documents;

      if (+event.eventTypeId == EventTypes.ContraventionCancelled) {
        documents = this.contravention.documents.filter(d => 
          +d.documentTypeId == DocumentTypes.ContraventionCancellationAuthorization
          ||  +d.documentTypeId == DocumentTypes.ContraventionCancellationAdditional)
      }
      
      this.recentActivities.push({
        date: `${eventDate} at ${eventTime}`,
        dateTime: eventDateTime,
        by: username,
        title: title,
        body: body,
        documents: documents,
        id: event.eventId,
        eventTypeId:event.eventTypeId,
        noticeCancellationRequestId: event.noticeCancellationRequestId,
        isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
      });
    }

    this.recentActivities = this.recentActivities.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });

    this.recentActivities = this.recentActivities.length > 3 ? this.recentActivities.slice(0,3) : this.recentActivities;
    
    setTimeout(() => {
      if ($(".recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".recent-activity-container:last-child")[0].offsetTop;    
        $(".recent-activity-connector").height(offsetTop);
      }     
    }, 100);              
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.loggedUserName
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  isCancelledByLEArequest(recentActivity):Boolean
  {
      if(recentActivity.eventTypeId == EventTypes.ContraventionCancelled && recentActivity.noticeCancellationRequestId)
      {
        this.requestType = RequestTypes.LEACancellationRequest;
         return true;           
      }
      return false;
  }

  private setContraventionSubmittedRecentActivity(): void {
    
    let submissionDateTime = new Date(this.contravention.submissionDate);
    let timeComponents = this.contravention.submissionTime.split(":");        
    timeComponents.forEach((c: string, index: number) => {
      if (index == 0) submissionDateTime.setHours(parseInt(c));
      if (index == 1) submissionDateTime.setMinutes(parseInt(c));
      if (index == 2) submissionDateTime.setSeconds(parseInt(c));      
    });

    let submissionDate = this.datePipe.transform(submissionDateTime.toString(), "mediumDate");
    let submissionTime = this.datePipe.transform(submissionDateTime.toString(), "HH:mm");

    let submittedBy = this.contravention.policeOfficerFullName;
    let policeService = this.localStorageService.getAgencies().find(x => x.agencyId == this.policeServiceInformation?.detachment?.agencyId)?.agencyName;
    
    this.recentActivities.push({ 
      date: `${submissionDate} at ${submissionTime}`, 
      dateTime: new Date(`${submissionDate} ${submissionTime}`),
      by: `${submittedBy} (${policeService})`,
      title: this.localStorageService.getTypeItemDescriptionById(EventTypes.ContraventionSubmitted, TypeTable.EventType), 
      body: null
    });
  }
 
  onViewClick(element: any): void {
    element.scrollIntoView(false)
  }

  onTabHeaderClick(ev: any): void {    
    if (ev.target.classList.contains("selected")) {
      return;
    }
   
    let tabId = ev.target.id.split("_")[1];

    $(".tab-header-item").removeClass("selected");
    $(".tab-content-item").removeClass("selected");
    
    $(`#tabHeader_${tabId}`).addClass("selected");
    $(`#tab_${tabId}`).addClass("selected");
  }
 
  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.contraventionId = this.contravention.contraventionId;
    componentRef.instance.documentRefTypeNumber = this.contravention.contraventionNumber;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.contravention.events.push(result);
        this.populateRecentActivity();     
        this.contraventionActivity.populateRecentActivity();
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.contravention.contraventionNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.contravention.events = this.contravention.events.filter(x=>x.eventId !== eventId);
        this.contravention.events.push(result);
        this.populateRecentActivity();      
        this.contraventionActivity.populateRecentActivity();  
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.contravention.contraventionNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.contravention.events = this.contravention.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();    
        this.contraventionActivity.populateRecentActivity();    
      }
      this.viewContainerRef.clear();
    });
  }

  getContraventionClass(statusId: any): string {
    let contraventionStatusName = this.localStorageService.getTypeItemDescriptionById(statusId, 5);
    if (contraventionStatusName == null) {
      return;
    }

    return `contravention-status contravention-status-${contraventionStatusName.toLowerCase().replace(" ", "")}`;
  }

  onManageContraventionChange(e: any): void {
    switch (e.target.value) {
      case "1":
        this.showCancelContraventionModal();
        break;
      case "2":
        this.showReviewRequestExtensionModal();
        break;
      case "3":    
        this.showEnableLateReviewModal();
        break;
      case "4":
        this.showFineUncollectableModal();
        break;
      case "5":
        this.showEnableReviewModal();
        break;
    }
  }

  showEnableReviewModal(): void {
    this.viewContainerRef.clear();

    let componentRef = this.viewContainerRef.createComponent(EnableReviewModalComponent);
    componentRef.instance.contraventionNumber = this.contravention.contraventionNumber;
    componentRef.instance.close.subscribe((result: Contravention) => {
      if (result != null) {
        this.contravention = result;     
        this.loadContraventionData();   
      }

      componentRef.destroy();
      this.manageContraventionDropDown.selectedIndex = 0;
    });
  }

  showEnableLateReviewModal(): void {
    this.viewContainerRef.clear();

    let componentRef = this.viewContainerRef.createComponent(EnableLateReviewModalComponent);
    componentRef.instance.contraventionId = this.contravention.contraventionId;
    componentRef.instance.close.subscribe((result: Contravention) => {
      if (result != null) {
        this.contravention = result;     
        this.loadContraventionData();   
      }

      componentRef.destroy();
      this.manageContraventionDropDown.selectedIndex = 0;
    });
  }

  showReviewRequestExtensionModal(): void {
    this.viewContainerRef.clear();

    let componentRef = this.viewContainerRef.createComponent(ReviewRequestExtensionModalComponent);
    componentRef.instance.contraventionId = this.contravention.contraventionId;
    componentRef.instance.close.subscribe((result: Contravention) => {
      if (result != null) {
        this.contravention = result;     
        this.loadContraventionData();   
      }

      componentRef.destroy();
      this.manageContraventionDropDown.selectedIndex = 0;
    });

  }

  showCancelContraventionModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(CancelContraventionModalComponent);

    componentRef.instance.contravention = Object.assign({}, this.contravention);
    componentRef.instance.close.subscribe((result: Contravention) => {
      if (result != null) {
        this.contravention = result;
        this.loadContraventionData();
      }
                  
      if (this.manageContraventionDropDown != null) {
        this.manageContraventionDropDown.selectedIndex = 0;
      }
      
      componentRef.destroy();
    });    
  }

  showFineUncollectableModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(FineUncollectableComponent);

    componentRef.instance.contravention = Object.assign({}, this.contravention);
    componentRef.instance.close.subscribe((result: Contravention) => {
      if (result != null) {
        this.contravention = result;
        this.loadContraventionData();
      }
                  
      if (this.manageContraventionDropDown != null) {
        this.manageContraventionDropDown.selectedIndex = 0;
      }
      
      componentRef.destroy();
    });    
  }
  
  loadContraventionData() {        
      if (this.contravention?.policeServiceInformation?.length > 0) {
        this.policeServiceInformation = this.contravention.policeServiceInformation[0];
      }

      this.detachmentName = this.policeServiceInformation?.detachment?.detachmentName;
      
      this.review = this.contravention.review;

      this.isRedactionRequired = this.contravention.documents.filter(x => [DocumentTypes.PoliceNarrative, DocumentTypes.WitnessStatements, DocumentTypes.Other].includes(+x.documentTypeId) && x.isDeleted != true && x.isPublished == null && !x.isSubmitLater).length > 0
      this.contraventionInformation.contravention = this.contravention;
      this.recipientInformation.contravention = this.contravention;
      this.paymentInformation.contravention = this.contravention;
      this.contraventionActivity.contravention = this.contravention;
      this.contraventionInformation.PopulateContraventionInformation();
      this.recipientInformation.PopulateRecipientInformation();
      this.paymentInformation.calculateTotals();
      this.contraventionActivity.populateRecentActivity();

      this.populatePaymentInfo();
      this.populateRecentActivity();

      this.contraventionStatusTypes = this.localStorageService.getContraventionStatusTypes();      
      this.setReviewRequestExtensionAvailability();   
      this.setEnableLateReviewAvailability();  
      this.setFineUncollectableAvailability();
      this.setEnableReviewAvailability();
  }

  setEnableReviewAvailability(): void {
    var today = new Date();    
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  
    var reviewLastDate = addDays(new Date(this.contravention.issueDate), 7);

    this.disableEnableReview = this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled
                                      || this.contravention.payments.length == 0 
                                      || this.contravention.events.some(x => x.eventTypeId == EventTypes.ReviewEnabled)
                                      || today > reviewLastDate; // Disable if the IRS review deadline is already passed (issuance date + 7 days)
  }

  setEnableLateReviewAvailability(): void {
    var today = new Date();    
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  
    var lateReviewLastDate = addDays(new Date(this.contravention.issueDate), 365);

    this.disableEnableLateReview = this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled
                                      || this.contravention.lateReviewRequestItems.length > 0
                                      || today > lateReviewLastDate; // Disable if the IRS is more than a 1 year old (issuance date + 365 days)
  }

  setReviewRequestExtensionAvailability(): void {
    if (this.review != null) {
      this.disableExtendReviewDeadline = true;
      return;
    }

    if (+this.contravention.contraventionStatusTypeId != ContraventionStatusTypes.Unpaid
        && +this.contravention.contraventionStatusTypeId != ContraventionStatusTypes.Issued) {
      this.disableExtendReviewDeadline = true;
      return;
    }

    let today = new Date();    
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    
    let issueDate = new Date(this.contravention.issueDate);
    let reviewRequestDeadline = new Date(issueDate);
    reviewRequestDeadline = new Date(reviewRequestDeadline.setDate(reviewRequestDeadline.getDate() + 7));

    let extendedReviewRequestDeadline = new Date(issueDate);
    extendedReviewRequestDeadline = new Date(extendedReviewRequestDeadline.setDate(extendedReviewRequestDeadline.getDate() + 11));

    let reviewRequestExtensionDate = new Date(this.contravention.reviewRequestExtensionDate);
    
    if (today.valueOf() > extendedReviewRequestDeadline.valueOf()) {
      this.disableExtendReviewDeadline = true;
    }

    if (reviewRequestExtensionDate.valueOf() > extendedReviewRequestDeadline.valueOf()) {
      this.disableExtendReviewDeadline = true;
    }
  }
  
  checkIfContraventionCancelled() {
    if (this.contravention?.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled) {
      this.isContraventionCancelled = true; 
      this.cancellationReason = this.contravention?.events.find(x => x.eventTypeId == EventTypes.ContraventionCancelled)?.eventDetails;
      document.getElementById('cancelReason').setAttribute("disabled","");  
      this.errorMessage = "Contravention already cancelled"
    }
    else {
      this.isContraventionCancelled = false;
      this.cancellationReason = "";
      document.getElementById('cancelReason').removeAttribute("disabled");  
      this.errorMessage = "";
    }
  }

  setFineUncollectableAvailability() {
    if (this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Unpaid 
      || this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Overdue) {
        this.disableFineUncollectable = false;
    } else {
      this.disableFineUncollectable = true;
    }
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onRecentActivityDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    let documentRefTypeName = "Events";

    if ([DocumentTypes.ContraventionCancellationAuthorization, DocumentTypes.ContraventionCancellationAdditional].includes(+document.documentTypeId)) {
      documentRefTypeName = "Contraventions";
    }

    this.documentService.downloadDocument("", documentRefTypeName, this.contravention.contraventionNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  contraventionInformationCallback()
  {
    this.ngOnInit();
  }

  contraventionActivityTabSelected() {
    this.contraventionActivity.setOffSetTopForContraventionRecentActivity();
  }

  refreshContravention(contravention: Contravention)
  {
    this.contravention = contravention;
    this.loadContraventionData();
  }

  onViewReceiptClicked(transactionId: number) {
    this.spinner.show();
    this.adjudicationService.getFinePaymentReceipt(this.contraventionNumber, transactionId)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention - ${this.contraventionNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  onPaymentInfoEdited(contravention: Contravention)
  {
    this.contravention = contravention;
    this.loadContraventionData();
  }
}
