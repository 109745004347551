import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DisclosureService } from '../../../shared/services/disclosure.service';
import { DisclosureInfo } from '@apis/shared/models/disclosure-info.model';

@Component({
  selector: 'app-delete-disclosure-modal',
  templateUrl: './delete-disclosure-modal.component.html',
  styleUrls: ['./delete-disclosure-modal.component.scss']
})
export class DeleteDisclosureModalComponent implements OnInit {
  disclosureDocument: DisclosureInfo;
  ticketNumber: string;
  close: EventEmitter<Document> = new EventEmitter<Document>();
  body: JQuery<HTMLElement>;

  errorMessage: string;

  constructor(
    private readonly disclosureService: DisclosureService,
    private readonly spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.body = $(document.body);
    this.body.addClass("overflow-hidden");
  }

  onCloseIconClick(): void {
    this.close.emit(null);
    this.hideModal();
  }

  onCancelClick(): void {
    this.close.emit(null);
    this.hideModal();
  }

  private hideModal() {
    this.body.removeClass("overflow-hidden");
  }

  onDeleteClick(): void {
    this.spinner.show();

    this.disclosureService.deleteDisclosure(this.ticketNumber, this.disclosureDocument)
      .subscribe((response: any) => {
          this.spinner.hide();
          if (response) {
            this.close.emit(response);
            this.hideModal();
          }
          else {
            this.errorMessage = "Unable to delete the disclosure document. (Note: Some types of disclosures cannot be deleted, including crown disclosures, published disclosures, delivered disclosures, and disclosures submitted for virtual traffic trials.)";
          }
        },
        (error: any) => {
          this.errorMessage = "Unable to delete the disclosure document. Please try again later.";
          this.spinner.hide();
        });
  }
}
