import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { RepresentationTypes, TransactionMethodTypes, DecisionTypes, TransactionTypes, TransactionStatusTypes, ContraventionStatusTypes } from '@apis/shared/enums/app.enum';
import { ContraventionPayment } from '@apis/shared/models/contravention-payment.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { Constants } from "@apis/shared/helpers/constants";
import { NgxSpinnerService } from 'ngx-spinner';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { PaymentDataEditRequest } from '@apis/shared/models/payment-data-edit-request.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { LogRefundModalComponent } from '../../../shared/components/modals/log-refund-modal/log-refund-modal.component';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';
import { RefundStatus } from '@apis/shared/enums/refund-status.enum';
@Component({
  selector: 'payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})
export class PaymentInformationComponent implements OnInit {
  @Input() contravention: Contravention;
  @Output() viewReceiptClicked = new EventEmitter<number>();
  @Output() paymentInfoEdited = new EventEmitter<Contravention>();

  amountPaid = 0;
  amountDue = 0;

  RepresentationTypes = RepresentationTypes;
  TransactionStatusTypes =TransactionStatusTypes;
  TransactionTypes = TransactionTypes;
  errorMessage:string;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  paymentDataEditRequest: PaymentDataEditRequest;
  isSubmitClicked: boolean = false;
  canLogRefund: boolean = false;
  RefundStatus = RefundStatus;

  constructor(private readonly spinner: NgxSpinnerService,
    private readonly adjudicationService: AdjudicationService,
    private readonly localStorageService: LocalStorageService,
    private readonly viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canLogRefund = this.localStorageService.hasPermission(Constants.Resource.CONTRAVENTION,Constants.Permission.LOG_REFUND);
    });
  }

  calculateTotals()
  {
    this.amountPaid=0;
    this.amountDue=0;

    if (this.contravention?.payments && this.contravention.payments.length > 0) {      
      this.contravention?.payments.forEach(p => this.amountPaid += p.amount);
    }
    if(this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled
      || this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Uncollectable) {
      this.amountDue = 0;  
    } else {
      this.amountDue = this.contravention?.fine?.fineAmount + this.contravention?.fine?.victimFineSurchargeAmount - this.amountPaid;
    }

    // Set payment refund status
    this.contravention?.payments.forEach(p => {
      if (p.financialTransaction.transactionTypeId == TransactionTypes.RefundFinePayment) {
        p.refundStatus = RefundStatus.NonRefundable;
      } else {
        var isPaymentRefunded = this.contravention.payments.some(rp => rp.financialTransaction.sourceTransactionId == p.financialTransaction.transactionId.toString());
        p.refundStatus = isPaymentRefunded ? RefundStatus.Completed : RefundStatus.Refundable;
      }
    });     
  }

  getPaidBy(payment: ContraventionPayment) {
    if (payment.isSubmittedByRegistry && !payment.registryAgentInformation[0].isPaidByRecipient) {
        return `${payment.registryAgentInformation[0].payerFirstName} ${payment.registryAgentInformation[0].payerLastName} (${payment.registryAgentInformation[0].payerMVID??'No MVID'})`;
    } else if(payment.financialTransaction.transactionTypeId == TransactionTypes.RefundFinePayment) {
      return 'APIS';
    } else if (!payment.isSubmittedByRegistry && payment.representation != null) {
      var relationship = payment.representation.representationTypeId == RepresentationTypes.Other? payment.representation.otherRepresentationType : this.localStorageService.getTypeItemDescriptionById(payment.representation.representationTypeId, TypeTable.RepresentationType);
      return `${payment.representation.firstName} ${payment.representation.lastName} (${relationship})`;
    } else {
      return 'Recipient';
    }
  }

  getLocation(payment: ContraventionPayment) {
    if (payment.financialTransaction.transactionMethodTypeId == TransactionMethodTypes.MotorVehicleDepartment) {
      return 'Registry';
    } else {
      return 'APIS';
    }
  }

  isBatchPayment(payment: ContraventionPayment) {
    return payment.movesBatchID != null && payment.movesBatchID.trim().length > 0;
  }

  onViewReceiptClick(transactionId: number) {
    this.viewReceiptClicked.emit(transactionId);
  }

  onEditPaymentClick(payment: any)
  {
    this.paymentDataEditRequest = new PaymentDataEditRequest ();
    this.paymentDataEditRequest.financialTransactionId = payment.financialTransactionId;
    this.paymentDataEditRequest.transactionId = payment.financialTransaction.transactionId;
    this.paymentDataEditRequest.payerFirstName = payment.registryAgentInformation[0]?.payerFirstName;
    this.paymentDataEditRequest.payerLastName = payment.registryAgentInformation[0]?.payerLastName;
    this.paymentDataEditRequest.payerMVID = payment.registryAgentInformation[0]?.payerMVID;
    
    payment.edited = true;
  }

  onEditPaymentSaveClick(isValid: boolean)
  {
    this.isSubmitClicked = true;

    if (isValid)
    {
      this.spinner.show();
      this.adjudicationService.updateContraventionPaymentInformation(this.contravention.contraventionNumber, this.paymentDataEditRequest)
        .subscribe((result: Contravention) => {
          this.paymentInfoEdited.emit(result);
          this.spinner.hide();
        }, (error: any) => {
          this.spinner.hide();            
      });
    }
  }

  onEditPaymentCancelClick(p: any)
  {
    p.edited = false;
  }

  onLogRefundClick(payment: ContraventionPayment) {
    this.viewContainerRef.clear();

    var logRefundRequest = new LogRefundRequest({
      recordNumber: this.contravention.contraventionNumber,
      refundAmount: payment.amount,
      sourceTransactionId: payment.financialTransaction.transactionId,
      transactionTypeId: payment.financialTransaction.transactionTypeId
    });

    let componentRef = this.viewContainerRef.createComponent(LogRefundModalComponent);
    componentRef.instance.logRefundRequest = logRefundRequest;
    componentRef.instance.close.subscribe((result: any) => {
      componentRef.destroy();
      if (result != null) {
        this.paymentInfoEdited.emit(result);
      }
    });
  }

  displayFailedRefund(payment: ContraventionPayment) {
    if(this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled && payment.financialTransaction.transactionTypeId == TransactionTypes.FinePayment) {
      // apply if review cancelled and fine payment for which refund fail and cannot find associated contravention payment
      return !this.contravention?.payments.some(x=> x.financialTransaction.sourceTransactionId === payment.financialTransaction.transactionId);
    }
    return false;
  }

  refundFinePayment(transactionId: string) {
      this.spinner.show();
      this.adjudicationService.refundFinePayment(this.contravention.contraventionNumber, transactionId)
      .subscribe((result: Contravention) => {
        this.contravention = result;
        this.calculateTotals();
        this.spinner.hide();
      }, 
      (error: any) => {
        this.showErrors(error);
        this.spinner.hide();              
      });
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }
   
}
