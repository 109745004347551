<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Order to Stay</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="form-group row">
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label" for="courtActionNumber">Court Action Number</label>
                    <input type="text" id="courtActionNumber" name="courtActionNumber" #courtActionNumberModel="ngModel"
                        [(ngModel)]="stayOrderCourtActionNumber" class="form-control"
                        [readOnly]="isCourtActionNumberReadOnly || isRevoke" />
                </div>
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label required" for="stayOrderEffectiveDate">Effective Date</label>
                    <input type="text" required [disabled]="isEffectiveDateReadOnly || isRevoke" class="form-control"
                        id="stayOrderEffectiveDate" name="stayOrderEffectiveDate" #dp="bsDatepicker" bsDatepicker
                        [bsConfig]="datePickerConfig" [dateCustomClasses]="dateCustomClasses" placeholder="mm/dd/yyyy"
                        [(ngModel)]="stayOrderEffectiveDate" #stayOrderEffectiveDateModel="ngModel"
                        [ngClass]="{ 'alert-border': stayOrderEffectiveDateModel.invalid && formSubmitted}" />
                    <span class="calendar-icon" (click)="dp.toggle()"><i class="far fa-calendar"></i></span>
                </div>
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label" for="stayOrderExpiryDate">Expiration Date</label>
                    <input type="text" [disabled]="isRevoke" class="form-control" id="stayOrderExpiryDate"
                        name="stayOrderExpiryDate" #dp1="bsDatepicker" bsDatepicker [bsConfig]="datePickerConfig"
                        [dateCustomClasses]="dateCustomClasses" placeholder="mm/dd/yyyy"
                        [(ngModel)]="stayOrderExpirationDate" #stayOrderExpiryDateModel="ngModel" />
                    <span class="calendar-icon" (click)="dp1.toggle()"><i class="far fa-calendar"></i></span>
                </div>
            </div>
            <div *ngIf="isRevoke" class="form-group row">
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label required" for="stayOrderCancelledDate">Cancellation Date</label>
                    <input type="text" required class="form-control" id="stayOrderCancelledDate"
                        name="stayOrderCancelledDate" #dp2="bsDatepicker" bsDatepicker [bsConfig]="datePickerConfig"
                        [dateCustomClasses]="dateCustomClasses" placeholder="mm/dd/yyyy" [(ngModel)]="cancelledDate"
                        #cancelledDateModel="ngModel"
                        [ngClass]="{ 'alert-border': cancelledDateModel.invalid && formSubmitted}" />
                    <span class="calendar-icon" (click)="dp2.toggle()"><i class="far fa-calendar"></i></span>
                </div>
            </div>
            <div>
                <div class="label">Court Order</div>
            </div>

            <div class="d-flex mt-3 mb-3 flex-column">
                <table>
                    <tbody *ngIf="stayOrderUploads?.length > 0">
                        <tr *ngFor="let document of stayOrderUploads">
                            <td>
                                <div class="d-flex align-items-center">
                                    <img class="download-file-icon" src="assets/images/download-file.png" />
                                    <a (click)="onDocumentNameClick(document)"
                                        class="download-file-link">{{document.documentName}}</a>
                                </div>
                            </td>
                            <td *ngIf="!isRevoke">
                                <div class="upload-complete-label">Upload Complete</div>
                            </td>                            
                            <td *ngIf="!isRevoke"><a class="remove-file-link"
                                [ngClass]="{ 'disabled': disableFileUpload }" (click)="onRemoveUploadClick(document)">Remove</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <document-upload [tempFileFolder]="tempFileFolder" documentRefTypeName="JudicialReviews/StayOrders"
                [documentRefTypeNumber]="documentRefTypeNumber"
                [documentTypeId]="stayOrderUploadsDocumentTypeId" [showDropZoneAfterUpload]="true"
                [uploadAndFinalize]="false" [isDisabled]="disableFileUpload" (documentUploadStarted)="onStayOrderUploadStarted()"
                (documentAdded)="onStayOrderUploadAdded($event)"></document-upload>
            <div class="document-upload-hint">Allowed: {{this.validFileExtensions.join(', ')}}</div> 
            <div *ngIf="errorMessage.length > 0">
                <span class="error">{{errorMessage}}</span>
            </div>
        </div>
        <div class="button-container">
            <div id="submitButton" class="submit-button" (click)="onSubmitClick($event)">
                {{isRevoke? "Revoke Stay" : "Submit"}}
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>