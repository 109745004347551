import { Component, OnInit, EventEmitter } from '@angular/core';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from "file-saver";
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript';
import { KeycloakService } from 'keycloak-angular';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DocumentUploadRequest } from '@apis/shared/models/document-upload-request.model';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss']
})
export class UploadDocumentModalComponent implements OnInit {
  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  document: Document;
  newDocument: Document;

  close: EventEmitter<Document> = new EventEmitter<Document>();

  body: JQuery<HTMLElement>;     
  dropZone: JQuery<HTMLElement>;
 
  uploadContainer: JQuery<HTMLElement>;
  publishDisclaimer: JQuery<HTMLElement>;
  publishButton: JQuery<HTMLElement>;
  removeFileLink: JQuery<HTMLElement>;
  downloadFileLink: JQuery<HTMLElement>;
  fileName: string = "";
  documentType: string = "";
  uploadProgress: string = "";
  modalOverlay: JQuery<HTMLElement>;  

  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  errorMessage: string = "";

  constructor(private readonly documentService: DocumentService,
    private readonly localStorageService: LocalStorageService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {    
    if (this.tempFileFolder == null) {
      this.tempFileFolder = Guid.create().toString();
    }

    this.dropZone = $(".drop-zone");
    this.publishButton = $(".publish-button");
    this.removeFileLink = $(".remove-file-link");
    this.downloadFileLink = $(".download-file-link");
    this.modalOverlay = $(".modal-overlay");
    this.uploadContainer = $(".upload-container"); 
    this.publishDisclaimer = $(".publish-disclaimer");
    
    this.body = $(document.body);
    this.body.addClass("overflow-hidden");

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName: string) => {
      document.body[`on${eventName}`] = this.preventDefaults;
      this.dropZone.on(eventName, this.preventDefaults);      
    });

    ["dragenter", "dragover"].forEach(eventName => {
      this.dropZone.on(eventName, (ev: any) => this.dropZone.addClass("highlight"));      
    });
    
    ["dragleave", "drop"].forEach(eventName => {
      this.dropZone.on(eventName, (ev: any) => this.dropZone.removeClass("highlight"));      
    });
                
    this.dropZone[0].ondrop = (ev: any) => this.handleDrop(ev);
  }

  private preventDefaults(ev: any): void {    
    ev.preventDefault();
    ev.stopPropagation();    
  }

  private handleDrop(ev: any): void {       
    let files = ev.dataTransfer.files;
    this.processFiles(files);    
  }

  onUploadFileChanged(ev: any): void {
    var fileList = (ev.target.files as FileList);
    this.processFiles(fileList);
  }  

  processFiles(files: FileList)
  {
    this.errorMessage = "";
    if (files.length > 1)
    {
      this.errorMessage = "Only single file upload is allowed";
      return;
    }

    this.uploadFile(files[0]);
  }

  onDownloadDocumentClick(){    
    var storageFileName = `${this.newDocument.contentGuid}.${this.newDocument.documentExtension}`;
    this.documentService.downloadDocument(this.tempFileFolder, null, null, storageFileName, this.newDocument.documentName)
      .subscribe((result: Blob) => {
        if (result) { 
          fileSaver.saveAs(result, this.newDocument.documentName);
        }
      });
  }
   
  onCancelClick(ev: any): void { 
    this.errorMessage = "";  
    if (this.newDocument != null) {
      this.onRemoveFileClick(ev);
    } 

    this.close.emit();
    this.hideModal();    
  }

  getDocumentTypeName(document: Document): string {
    var documentTypeName = `${this.localStorageService.getDocumentTypes().find(x => x.id == document.documentTypeId)?.name}${(document.documentDescription == null || document.documentDescription =="")? "": " " +  document.documentDescription}`;
    return documentTypeName;
  }

  private uploadFile(file: File): void {
    this.documentType = this.getDocumentTypeName(this.document);
    this.dropZone.hide();
    this.uploadContainer.css("display", "flex");
    this.publishDisclaimer.css("display", "flex");

    let lastIndex = file.name.lastIndexOf(".");
    let extension = file.name.substring(lastIndex);    
    this.fileName = file.name; 

    let uploadBarInitializing = $(".upload-bar-initializing");
    let uploadBarProgress = $(".upload-bar-progress");
    uploadBarInitializing.css("display", "flex");
    uploadBarProgress.css("display", "none");

    var newFileName = Guid.create().toString();
        
    this.documentService.uploadDocumentAsync(file, this.tempFileFolder, newFileName)
      .subscribe((result: any) => {                
        if (+result.type === 1) {         
            uploadBarInitializing.css("display", "none");
            uploadBarProgress.css("display", "flex");
  
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);
            this.uploadProgress = `${percentage}%`;
            uploadBarProgress.css("width", this.uploadProgress);
         
        } else if (+result.type === 4) {         
          this.uploadProgress = "Upload Complete";
          
          this.publishButton.removeClass("disabled");
          this.removeFileLink.removeClass("disabled");

          //Prepare new document object
          this.newDocument = new Document({
            documentId: this.document.documentId,
            contentGuid: newFileName,
            stopInformationId: this.document.stopInformationId,
            contraventionId: this.document.contraventionId,
            reviewId: this.document.reviewId,
            vehicleSeizureId: this.document.vehicleSeizureId,
            documentDescription: this.document.documentDescription,
            documentExtension: extension.replace(".", ""),
            documentName: this.fileName,
            documentSize: CommonUtil.getDocumentSize(file),
            documentTypeId: this.document.documentTypeId,
            isPublished: true,
            isSubmitLater: false,
            uploadedBy: "Alberta Justice"
          });       
        }            
      });
  }

  onPublishClick(ev: any): void {
    this.errorMessage = "";
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    //Call update document method
    this.spinner.show(); 
    this.newDocument.uploadedDate = DateUtil.today();

    if (this.contravention?.contraventionId != null) {
      this.documentRefTypeName = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
      this.documentRefTypeNumber = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 
        ? this.contravention.contraventionNumber 
        : this.contravention.stopInformationId.toString();
    } else if (this.vehicleSeizure?.contraventionId != null) {
      this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
      this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
        ? this.vehicleSeizure.contraventionNumber
        : this.vehicleSeizure.stopInformationId.toString();
    } else if (this.vehicleSeizure?.vehicleSeizureId != null) {
      this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "VehicleSeizures" : "Stop"; 
      this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
        ? this.vehicleSeizure.seizureNumber
        : this.vehicleSeizure.stopInformationId.toString();
    }

    //Call update api for document update
    var documentUploadRequest = new DocumentUploadRequest();
    documentUploadRequest.documents = [this.newDocument];
    documentUploadRequest.tempFolderName = this.tempFileFolder;
    documentUploadRequest.documentRefTypeName = this.documentRefTypeName;
    documentUploadRequest.documentRefTypeNumber = this.documentRefTypeNumber;

    this.documentService.updateAndFinalizeDocumentsAsync(documentUploadRequest)
      .subscribe((result: any) => {
        this.spinner.hide(); 
        if (result && result.length > 0)
          this.close.emit(result[0]);
      },
      (error: any) => {
        this.errorMessage = "Unable to publish the document. Please try later."; 
        this.spinner.hide(); 
      });
  }

  private hideModal() {        
    this.body.removeClass("overflow-hidden");    
  }

  onRemoveFileClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    var fileName = `${this.newDocument.contentGuid}.${this.newDocument.documentExtension}`;

    this.documentService.deleteDocument(fileName, this.tempFileFolder)
      .subscribe((result: any) => {        
        this.publishButton.addClass("disabled");
        this.removeFileLink.addClass("disabled");
        
        this.newDocument = null;
        this.uploadContainer.hide();
        this.publishDisclaimer.hide();
        this.dropZone.css("display", "flex");
      });
  }

}
