import { ReviewTypes } from "@apis/shared/enums/app.enum";
import { Colors } from "./colors";

export class CommonMethods {
    static hasUpdateAccess(userRoles: string[]): boolean {
      return userRoles.find(r => ["dfm", "casemanager"].includes(r.toLowerCase())) != null
    }

    static hasEditNoteAccess(userRoles: string[]): boolean {
      return userRoles.find(r => r.toLowerCase() == "casemanager") != null
    }

    static getEventColorByMeetingType(meetingTypeId: number, availabilityTypeId: number) {
      switch (+meetingTypeId) {
        case 1:
          return Colors.SteelBlue;
        case 2:
          return Colors.Orange;
        case 3:
          return Colors.Red;
        default:
          if (availabilityTypeId == 1){
            return Colors.Blue;
          } else {
            return Colors.Yellow;
          }
      }
    }

    static renameDecisionByReviewTypeId(decisionTypeName: string, reviewTypeId: number) {
      if (reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
        if (decisionTypeName == 'Confirm') return 'Denied';
        if (decisionTypeName == 'Cancel') return 'Granted';
      }

      return decisionTypeName;
    }
  }
  