


export class VehicleSeizurePagedSearch {
    public pageNumber: number;
    public pageSize: number;
    public searchTerm: string;
    public orderBy: string;
    public sortDirection: string
    public seizureTypeId: number;
    public seizureStatusTypeIds: string;        
    public checkRequiresRedaction: boolean;
    public checkPendingSubmissions: boolean;
    
    constructor(init?: Partial<VehicleSeizurePagedSearch>) {
        
        Object.assign(this, init);
    }
}
