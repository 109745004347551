export class LogEntry {
    public id: number = 0;
    public title: string;
    public message: string;
    public level: string;
    public timeStamp: Date;
    public userId: string;
    public clientIp: string;
    public requestMethod: string;
    public endpoint: string;
    public statusCode: string;

    constructor(init?: Partial<LogEntry>) {
        Object.assign(this, init);
    }
}
