import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Event } from "@apis/shared/models/event.model";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes, MeetingTypes, RequestTypes } from "@apis/shared/enums/app.enum";
import { User } from '@apis/shared/models/user.model';

import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { RequestService } from '../../../shared/services/request.service';
import { SchedulerService } from '../../../shared/services/scheduler.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-assign-adjudicator-modal',
  templateUrl: './assign-adjudicator-modal.component.html',
  styleUrls: ['./assign-adjudicator-modal.component.scss']
})
export class AssignAdjudicatorModalComponent implements OnInit {
  request: any;
  requestType: any;
  adjudicatorId: number;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;
  adjudicators: User[] = [];  
  assignButton: JQuery<HTMLElement>;
  eventDetails: string;  
  userId: number = 0;
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly requestService: RequestService,
    private readonly schedulerService: SchedulerService,
    private datePipe: DatePipe,
    private readonly router: ActivatedRoute) { }

  ngOnInit(): void {     

    this.router.queryParams.subscribe(params => {
      if (params.type == RequestTypes.LateReview) {

      var startDate = new Date(this.datePipe.transform(new Date(), "shortDate"));

      var endDate = new Date(new Date().setHours(23,59,59,999));

      this.schedulerService.getAvailableAdjudicators(startDate, endDate, MeetingTypes.LateReview, 0).subscribe((data:any) => {
        this.adjudicators = this.localStorageService.getAdjudicators().filter(a => data.includes(a.userId.toString()));
      });
    }
    else {
      this.adjudicators = this.localStorageService.getAdjudicators();
    }
    });

    this.adjudicatorId = +this.request.userId;
        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.assignButton = $(".assign-button");       
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onAssignModalClick(ev: any): void {
    if (this.assignButton.hasClass("disabled")) {
      return;
    }
    
    this.assignButton.addClass("disabled");
    this.assignButton.addClass("saving");

    var assignedAdjudicator = this.adjudicators?.find(x=>x.userId==this.userId);
    this.eventDetails = `${assignedAdjudicator.firstName} ${assignedAdjudicator.lastName}`;

    const event = new Event({      
      eventTypeId: EventTypes.AdjudicatorAssigned,
      eventDetails: this.eventDetails      
    });

    const lateReviewRequest = new LateReviewRequest({
      lateReviewRequestNumber: this.request.lateReviewRequestNumber,
      userId: +this.userId      
    });

    lateReviewRequest.events.push(event);

    this.requestService.assignAdjudicatorLateReviewRequest(lateReviewRequest)
      .subscribe(
        (result: LateReviewRequest) => { 
          this.assignButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {});             
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }

  isAssignButtonDisabled(): boolean {
    return this.maxCharacters < 0 
      || this.userId == 0
      || this.userId == this.adjudicatorId;
  }
}