import { Component, OnInit } from '@angular/core';
import { StayOrder } from '@apis/shared/models/stay-order.model';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { JudicialReviewView } from '@apis/shared/models/judicial-review-view.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { WorkBook, WorkSheet, utils, writeFile} from "xlsx";
import { AdjudicationService } from '../shared/services/adjudication.service';
import * as $ from "jquery";
import { NgxSpinnerService } from 'ngx-spinner';
import { JudicialReviewPagedSearch } from "@apis/shared/models/judicial-review-paged-search.model";
import { PagedResult } from "@apis/shared/models/paged-result.model";
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { ActivatedRoute, ParamMap, Route, Router, UrlSerializer } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as fileSaver from "file-saver";

@Component({
  selector: 'app-judicial-review-listing',
  templateUrl: './judicial-review-listing.component.html',
  styleUrls: ['./judicial-review-listing.component.scss']
})
export class JudicialReviewListingComponent implements OnInit {
  TypeTable = TypeTable;
  stayOrders : StayOrder[] =[];
  judicialReviews: any[];
  filteredJudicialReviews: any[] = [];    
  pageNumber: number = 1;
  totalPages: number;
  pageNumbers: number[] = [];
  pageRange: number = 5;
  pageSize: number = 25;
  totalCount: number;
  sortDirection = "desc";
  orderBy = "servedDate";  
  searchText: string;  
  previousLink: JQuery<HTMLElement>;
  nextLink: JQuery<HTMLElement>;
  judicialReviewsTable: HTMLElement;  
  isLastRange: boolean = false;  
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;

  private columns = [{
      propertyName: "servedDate",
      ascending: false      
    }, {
      propertyName: "judicialReviewNumber",
      ascending: false      
    }, {
      propertyName: "courtActionNumber",
      ascending: false      
    }, {
      propertyName: "reviewNumber",
      ascending: false    
    }, {
      propertyName: "termsAndConditionsDate",
      ascending: false    
    }, {
      propertyName: "recipient",
      ascending: false
    }, {
      propertyName: "hasStayOrder",
      ascending: false    
    }, {
      propertyName: "judicialReviewDecisionTypeId",
      ascending: false    
    }, {
      propertyName: "judicialReviewDecisionDate",
      ascending: false    
    }
  ];
  
  constructor(private readonly adjudicationService: AdjudicationService,
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly urlSerializer: UrlSerializer) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(paramMap => {
      setTimeout(() => {              
        this.initFilters(paramMap);
        this.setFilteredJudicialReviews();
      });
    });
    
    this.spinner.show();    
    
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {      
      this.judicialReviewsTable = document.getElementById("judicialReviewsTable");          
  
      this.previousLink = $(".previous-link");    
      this.nextLink = $(".next-link");    

      this.setFilteredJudicialReviews();
    });
  }

  initFilters(paramMap: ParamMap) {
    paramMap.keys.forEach(key => {
      var value = paramMap.get(key);
      switch (key) {
        case "pageNumber":
          this.pageNumber = +value;
          break;
        case "pageSize":
          this.pageSize = +value;
          break;
        case "sortDirection":
          this.sortDirection = value;
          break;
        case "orderBy":
          this.orderBy = value;
          break;
        case "searchTerm":
          this.searchText = value;
          break;
      }
    });
  }

  private setFilteredJudicialReviews(): void {
    let judicialReviewPagedSearch = new JudicialReviewPagedSearch({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      orderBy: this.orderBy
    });
        
    if (this.searchText?.length > 0) {
      judicialReviewPagedSearch.searchTerm = this.searchText.trim().toLowerCase();      
    }

    this.setWindowHistory(judicialReviewPagedSearch);

    this.adjudicationService.getJudicialReviewsByPagedSearch(judicialReviewPagedSearch)
      .subscribe((result: PagedResult) => {        
        this.judicialReviews = result.items;
        this.totalCount = result.totalCount;     
      
        this.filteredJudicialReviews = Array.from(this.judicialReviews);
        this.setPageNumbers();
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
      });
  }

  setPageNumbers(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    if (+this.totalPages === 0) {
      this.totalPages = 1;
      this.pageNumbers = [1];
      return;
    }

    let totalPageRanges = Math.ceil(this.totalPages / this.pageRange);        
    let totalPagesAsArray = Array(this.totalPages).fill(1).map((value: any, index: number) => value + index);
    let totalPageRangesAsArray = Array(totalPageRanges).fill(0).map((value: any, index: number) => value + index);    
    let pageRangeIndex = totalPageRangesAsArray.find((value: any, index: number) => this.pageNumber <= (value * this.pageRange) + this.pageRange);
    
    this.isLastRange = totalPageRangesAsArray.length - 1 == pageRangeIndex;
    this.pageNumbers = totalPagesAsArray.slice(pageRangeIndex * this.pageRange, (pageRangeIndex * this.pageRange) + this.pageRange);
  }
  
  onPreviousLinkClick(ev: any): void {  
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.pageNumber -= 1;    
    if (+this.pageNumber <= 1) {
      this.pageNumber = 1;
    }
     
    this.setFilteredJudicialReviews();
  }

  onNextLinkClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    this.pageNumber += 1;
  
    if (+this.pageNumber >= this.totalPages) {
      this.pageNumber = this.totalPages;     
    }            
    
    this.setFilteredJudicialReviews();
  }

  onPageNumberClick(ev: any, pageNumber: number): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }
        
    this.pageNumber = pageNumber;    
    this.setFilteredJudicialReviews();
  }

  private setWindowHistory(pagedSearch: JudicialReviewPagedSearch): void {    
    const urlTree = this.router.createUrlTree(['/judicial-reviews'], { queryParams: pagedSearch });
    window.history.replaceState({}, '', this.urlSerializer.serialize(urlTree));
  }
  
  onSearchTextboxKeyDown(ev: KeyboardEvent): void {
    if (ev.key.toLowerCase() === "enter") {
      this.onSearchButtonClick();
    }
  }

  onSearchButtonClick(): void {  
    console.log("search button click...");
    this.pageNumber = 1;  
    this.setFilteredJudicialReviews();
  }
  
  private toNumber(val: any, maxLength?: number): number {    
    let date = new Date(val);
    
    let number = 0;
        
    if (date != null && date.toString() != "Invalid Date") {      
      number = date.valueOf();
    } else if (+val >= 0) {
      number = +val;    
    } else {              
      for (let index = 0; index < maxLength; index++) {
        number += val.charCodeAt(index);            
      }
    }
    
    return number;
  }
      
  onSortIconContainerClick(propertyName: string): void {
    const sortIconContainer = $(`.sort-icon-container.${propertyName}`);    
    const sortIconContainerFirstChild = sortIconContainer.children(":first-child");
    const sortIconContainerLastChild = sortIconContainer.children(":last-child");
        
    if (sortIconContainerFirstChild.hasClass("selected")) {
      // ascending
      sortIconContainerFirstChild.removeClass("selected");
      sortIconContainerLastChild.addClass("selected");
    } else if (sortIconContainerLastChild.hasClass("selected")) {
      // descending
      sortIconContainerLastChild.removeClass("selected")
    } else {
      // default
      sortIconContainerFirstChild.addClass("selected");      
    }
    
    if ($(".sort-icon-container > .selected").length > 0) {
      this.sortJudicialReviews();      
    } else {
      this.setFilteredJudicialReviews();
    }
    
  }

  private sortJudicialReviews(): void {
    $(".sort-icon-container > .selected").each((index: number, element: HTMLElement) => {
      this.filteredJudicialReviews.sort((a: JudicialReview, b: JudicialReview) => {

        let propertyName = element.getAttribute("propertyName");
        let sort = element.getAttribute("sort").toLowerCase();
        let first = Date.parse(a[propertyName]);
        let second = Date.parse(b[propertyName]);

        if (sort == "ascending") {
          if (!isNaN(first) && !isNaN(second)) {
            return first - second;
          } else {
            if (a[propertyName] > b[propertyName]) return 1;
            if (a[propertyName] < b[propertyName]) return -1;
          }

        } else if (sort == "descending") {
          if (!isNaN(first) && !isNaN(second)) {
            return second - first;
          } else {
            if (a[propertyName] > b[propertyName]) return -1;
            if (a[propertyName] < b[propertyName]) return 1;
          }
        }
                                                        
        return 0;
      });
    });         
  }
    
  onExportListClick(ev: any): void {    

    this.spinner.show();
    setTimeout(() => {
      let element = $(".loading-text > p");      
      element.html(`<span style='font-size: 17px !important;'>Please be patient while APIS generates your report</span>`); 
    }, 100);

    this.adjudicationService.downloadJudicialReviewsCSVExport()
      .subscribe((result: HttpEvent<Blob>) => {       
        if (result.type == HttpEventType.Response) {
          this.spinner.hide();
          fileSaver.saveAs(result.body, "judicial-reviews.csv");          
        }        
      }, (error: any) => this.spinner.hide());                     
  
  }  

  onSortIconClick(propertyName: string): void {  
    let column = this.columns.find(c => c.propertyName == propertyName);    
    column.ascending = !column.ascending;

    this.sortDirection = "desc";
    if (column.ascending) {
      this.sortDirection = "asc";
    }

    this.orderBy = propertyName;
    
    this.setFilteredJudicialReviews();        
  }  

  onPageSizeChange(ev: any): void {
    let target = ev.target as HTMLSelectElement;    
    this.pageSize = parseInt(target.selectedOptions[0].value);
    this.pageNumber = 1;

    this.setFilteredJudicialReviews();
  }

}
