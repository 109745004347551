<div class="d-flex flex-column">
    <div class="heading">Applicant Information</div>
    <div class="info-container row">
        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Applicant Name</label>
            <div class="info-text">{{applicantName}}</div>
        </div>
        <div *ngIf="isThirdPartySeizure" class="info-container-item col-12 col-lg-4">
            <label class="info-label">Interest in Vehicle</label>
            <div class="info-text">{{vehicleInterestTypeName}}</div>
        </div>
        <div class="info-container-item col-12 col-lg-4">
            <label class="info-label">Email Address</label>
            <div class="info-text">{{review?.driverEmail}}</div>
        </div>
    </div>
    <ng-container *ngIf="review?.isSubmittedByRegistry">
        <div class="heading">Registry Information</div>
        <div class="info-container row">
            <div class="info-container-item col-12 col-lg-4">
                <label class="info-label">Registry Agent Username</label>
                <div class="info-text">{{registryAgentInformation.userName}}</div>
            </div>
            <div class="info-container-item col-12 col-lg-4">
                <label class="info-label">Organization Code</label>
                <div class="info-text">{{registryAgentInformation.organizationCode}}</div>
            </div>
            <div class="info-container-item col-12 col-lg-4">
                <label class="info-label">Payer</label>
                <div class="info-text">{{registryAgentInformation.isPaidByRecipient? "Recipient" : registryAgentInformation.payerFirstName + " " + registryAgentInformation.payerLastName + " (" + registryAgentInformation.payerMVID + ")"}}</div>
            </div>
        </div>
    </ng-container>
    <form #representationInfoForm="ngForm">
        <div class="row">
            <div class="col-12 col-sm-12">
                <span class="error">{{errorMessage}}</span>
            </div>
        </div>
        <div class="heading d-flex justify-content-start">
            <div class="justify-content-start">
                <div>Representation Information</div>
            </div>
            <div class="d-flex col-6 col-sm-6">
                <app-access-control [Resource]="Resource.REVIEW_REPRESENTATION" [Permission]="Permission.DATA_EDITS">
                    <div class="justify-content-start">
                        <button *ngIf="!isRepresntationInformationEdited" class="btn-sm" (click)="editRepresentationInformationClick()">Edit</button>
                        <button *ngIf="isRepresntationInformationEdited" class="btn-sm"  (click)="saveEditedRepresentationInformation(representationInfoForm.valid)">Save</button>
                        <button *ngIf="isRepresntationInformationEdited" class="btn-sm ml-1" (click)="cancelEditedRepresentationInformation()">Cancel</button>
                    </div>
                </app-access-control>
            </div>
        </div>
        <div class="info-container row" *ngIf="!isRepresntationInformationEdited">    
            <div class="info-container-item col-12 col-lg-4">
                <label class="info-label">Type of Representation</label>
                <div class="info-text">{{representationTypeName}}</div>
            </div>
            <div class="info-container-item col-12 col-lg-4" *ngIf="representationTypeName != null && representationTypeId != RepresentationTypes.SelfRepresentation">
                <label class="info-label">{{representationTypeName + " Name"}}</label>
                <div class="info-text">{{representationFullName}}</div>
            </div>
            <div class="info-container-item col-12 col-lg-4" *ngIf="representationTypeName != null && representationPracticeName != null && representationTypeId != RepresentationTypes.SelfRepresentation">
                <label class="info-label">{{representationTypeName}} Practice Name</label>
                <div class="info-text">{{representationPracticeName}}</div>
            </div>        
            <div class="info-container-item col-12 col-lg-4">
                <label class="info-label">Preferred Contact Method</label>
                <div class="info-text">{{preferredContactMethodName}}</div>
            </div>
            <div class="info-container-item col-12 col-lg-4" *ngIf="isEmailPreferredContactMethod">
                <label class="info-label">Email Address</label>
                <div class="info-text">{{review?.email}}</div>
            </div>
            <div class="info-container-item col-12 col-lg-4" *ngIf="!isEmailPreferredContactMethod">
                <label class="info-label">Phone Number</label>
                <div class="info-text">{{review?.phoneNumber}}</div>
            </div>
            <ng-container *ngIf="review?.contactMethodTypeId == ContactMethodTypes.Phone">
                <div class="info-container-item col-12 col-lg-4">
                    <label class="info-label">Address 1</label>
                    <div class="info-text">{{review?.contactAddress?.addressLine1}}</div>
                </div>
                <div class="info-container-item col-12 col-lg-4">
                    <label class="info-label">Address 2</label>
                    <div class="info-text">{{review?.contactAddress?.addressLine2?review?.contactAddress?.addressLine2:'-'}}</div>
                </div>
                <div class="info-container-item col-12 col-lg-4">
                    <label class="info-label">City</label>
                    <div class="info-text">{{review?.contactAddress?.city}}</div>
                </div>
                <div class="info-container-item col-12 col-lg-4">
                    <label class="info-label">Postal Code</label>
                    <div class="info-text">{{review?.contactAddress?.postalCode}}</div>
                </div>
                <div class="info-container-item col-12 col-lg-4">
                    <label class="info-label">Country</label>
                    <div class="info-text">{{localStorageService.getTypeItemDescriptionById(this.review?.contactAddress?.countryId, TypeTable.CountryType)}}</div>
                </div>
                <div class="info-container-item col-12 col-lg-4" *ngIf="this.review?.contactAddress?.countryId == 40 || this.review?.contactAddress?.countryId == 236">
                    <label class="info-label">Province</label>
                    <div class="info-text">{{localStorageService.getTypeItemDescriptionById(this.review?.contactAddress?.provinceId, TypeTable.ProvinceType)}}</div>
                </div>
                <div class="info-container-item col-12 col-lg-4" *ngIf="this.review?.contactAddress?.countryId != 40 && this.review?.contactAddress?.countryId != 236">
                    <label class="info-label">Province</label>
                    <div class="info-text">{{this.review?.contactAddress?.province}}</div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="isRepresntationInformationEdited">    
            <div class="info-container row">
                <div class="info-container-item col-12 col-lg-4">
                    <label for="representationType" class="required">Type of Representation</label>
                    <select class="form-control" id="representationType" name="representationType" required
                        [(ngModel)]="representationTypeId" (change)="onRepresentationTypeChange()"
                        #representationTypeModel="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && representationTypeModel.invalid }">
                        <option [ngValue]="null">Select a Representation Type</option>
                        <option [value]="RepresentationTypes.SelfRepresentation">Self-representation</option>
                        <option *ngFor="let repType of representationTypes" [value]="repType.id">{{repType.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>Type of Representation is required</div>
                    </div>
                </div>
                <ng-container *ngIf="representationTypeId == RepresentationTypes.Other">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="otherRepresentationType" class="required">Other Representation Type</label>
                        <input type="text" class="form-control" id="otherRepresentationType" name="otherRepresentationType"
                            [(ngModel)]="otherRepresentationType" #otherRepresentationTypeModel="ngModel" required
                            [ngClass]="{ 'is-invalid': isSubmitClicked && otherRepresentationTypeModel.invalid }">
                        <div class="invalid-feedback">
                            <div>Other Representation Type is required</div>
                        </div>
                    </div>   
                </ng-container>
                <ng-container *ngIf="representationTypeId != null && representationTypeId != RepresentationTypes.SelfRepresentation">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="firstName" class="required">{{repTitle}}First Name</label>
                        <input type="text" class="form-control" id="firstName" name="firstName"
                            [(ngModel)]="firstName" #firstNameModel="ngModel" required
                            [ngClass]="{ 'is-invalid': isSubmitClicked && firstNameModel.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}First Name is required</div>
                        </div>
                    </div>
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="lastName" class="required">{{repTitle}}Last Name</label>
                        <input type="text" class="form-control" id="lastName" name="lastName"
                            [(ngModel)]="lastName" #lastNameModel="ngModel" required
                            [ngClass]="{ 'is-invalid': isSubmitClicked && lastNameModel.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}Last Name is required</div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="representationTypeId == RepresentationTypes.Agent || representationTypeId == RepresentationTypes.Lawyer">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="practiceName" class="required">{{repTitle}}Practice Name</label>
                        <input type="text" class="form-control" id="practiceName" name="practiceName"
                            [(ngModel)]="practiceName" #practiceNameModel="ngModel" required
                            [ngClass]="{ 'is-invalid': isSubmitClicked && practiceNameModel.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}Practice Name is required</div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="info-container row">
                <div class="info-container-item col-12 col-lg-4">
                    <label for="contactMethod" class="required">Preferred Contact Method</label>
                    <select class="form-control" id="contactMethod" name="contactMethod" required
                        [(ngModel)]="contactMethodTypeId" (change)="onContactMethodChange()"
                        #contactMethodModel="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactMethodModel.invalid }">
                        <option [ngValue]="null">Select a Method</option>
                        <option *ngFor="let contactMethodType of contactMethodTypes" [value]="contactMethodType.id">{{contactMethodType.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>Preferred Contact Method is required</div>
                    </div>
                </div>		
            </div>
            <div class="info-container row">
                <ng-container *ngIf="contactMethodTypeId == ContactMethodTypes.Email">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="contactEmailAddress" class="required">Email Address</label>
                        <input type="text" class="form-control" id="contactEmailAddress" name="contactEmailAddress"
                            [(ngModel)]="email" required email
                            #contactEmailAddressModel="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactEmailAddressModel.invalid }">
                        <div class="invalid-feedback">
                            <div *ngIf="contactEmailAddressModel.errors?.required">Email Address is required</div>
                            <div *ngIf="contactEmailAddressModel.errors?.email">Please provide a valid email address</div>
                        </div>
                    </div>
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="confirmContactEmailAddress" class="required">Confirm Email Address</label>
                        <input type="text" class="form-control" id="confirmContactEmailAddress" name="confirmContactEmailAddress"
                            [(ngModel)]="confirmEmail" required email
                            isMatching="{{email}}"
                            #confirmContactEmailAddressModel="ngModel" 
                            [ngClass]="{'is-invalid': confirmContactEmailAddressModel.invalid && isSubmitClicked}"/>
                        <div class="invalid-feedback">
                            <div *ngIf="confirmContactEmailAddressModel.errors?.required">Confirm Email Address is required</div>
                            <div *ngIf="confirmContactEmailAddressModel.errors?.email">Please provide a valid email address</div>
                            <div *ngIf="!confirmContactEmailAddressModel.errors?.required && !confirmContactEmailAddressModel.errors?.email && confirmContactEmailAddressModel.errors?.mustMatch">Emails must match</div>
                        </div>
                    </div>	                	
                </ng-container>

                <ng-container *ngIf="contactMethodTypeId == ContactMethodTypes.Text || contactMethodTypeId == ContactMethodTypes.Phone">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="contactPhoneNumber" class="required">Phone Number</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend2">+1</span>
                            </div>
                            <input type="text" class="form-control" id="contactPhoneNumber" name="contactPhoneNumber"
                                [(ngModel)]="tempPhoneNumber" (ngModelChange)="phoneNumber = '1'+tempPhoneNumber" 
                                placeholder="numbers only" aria-describedby="inputGroupPrepend2" 
                                required pattern="[0-9]{10}$" maxlength="10"
                                #contactPhoneNumberControl="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && contactPhoneNumberControl.invalid }">
                            <div class="invalid-feedback">
                                <div *ngIf="contactPhoneNumberControl.errors?.required">Phone Number is required</div>
                                <div *ngIf="contactPhoneNumberControl.errors?.pattern">Invalid Phone Number</div>
                            </div>
                        </div>
                    </div>
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="confirmContactPhoneNumber" class="required">Confirm Phone Number</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">+1</span>
                            </div>
                            <input type="text" class="form-control" id="confirmContactPhoneNumber" name="confirmContactPhoneNumber"
                                [(ngModel)]="confirmPhoneNumber"
                                placeholder="numbers only" aria-describedby="inputGroupPrepend3" 
                                required pattern="[0-9]{10}$" maxlength="10"
                                isMatching="{{tempPhoneNumber}}"
                                #confirmContactPhoneNumberControl="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && confirmContactPhoneNumberControl.invalid }">
                            <div class="invalid-feedback">
                                <div *ngIf="confirmContactPhoneNumberControl.errors?.required">Confirm Phone Number is required</div>
                                <div *ngIf="confirmContactPhoneNumberControl.errors?.pattern">Invalid Phone Number</div>
                                <div *ngIf="!confirmContactPhoneNumberControl.errors?.required && !confirmContactPhoneNumberControl.errors?.pattern && confirmContactPhoneNumberControl.errors?.mustMatch">Phone Numbers must match</div>
                            </div>
                        </div>
                    </div>		
                </ng-container>
            </div>
            <ng-container *ngIf="contactMethodTypeId == ContactMethodTypes.Phone">
                <div class="info-container row">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="address1" class="required">Address 1</label>
                        <input type="text" maxlength="44" class="form-control" required
                            id="address1" name="address1" placeholder="Ex. 123 Street or PO Box, Range Road or Rural Route" 
                            [(ngModel)]="contactAddress.addressLine1" #address1="ngModel"
                            [ngClass]="{'is-invalid': isSubmitClicked && address1.invalid}">
                        <div class="invalid-feedback">
                            <div>Address 1 is required</div>
                        </div>
                    </div>
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="address2">Address 2</label>
                        <input type="text" maxlength="44" class="form-control" 
                            id="address2" name="address2" placeholder="Ex. Unit Number" [(ngModel)]="contactAddress.addressLine2">
                    </div>
                </div>
                
                <div class="info-container mb-4">
                    <div class="info-container-item col-12 col-lg-8 pl-0 pr-4">
                        <app-country-province (onChangeEvent)="refreshContactCountryProvince($event)" [countryProvince]="contactCountryProvince"></app-country-province>
                    </div> 
                </div>
    
                <div class="info-container row">
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="city">City/Town/Village <span class="label-hint">(Required)</span></label>
                        <input required #cityControl="ngModel" maxlength="30" type="text" class="form-control" id="city" name="city" placeholder="City/Town/Village" [(ngModel)]="contactAddress.city" [ngClass]="{'is-invalid': isSubmitClicked && cityControl.invalid}">
                        <div class="invalid-feedback">
                            <div>City/Town/Village is required</div>
                        </div>
                    </div>
                    <div class="info-container-item col-12 col-lg-4">
                        <label for="postalCode">Postal Code <span class="label-hint">(Required)</span></label>
                        <input required #postalCodeControl="ngModel" maxlength="10" type="text" class="form-control" id="postalCode" name="postalCode" [(ngModel)]="contactAddress.postalCode" [ngClass]="{'is-invalid': isSubmitClicked && postalCodeControl.invalid}">
                        <div class="invalid-feedback">
                            <div>Postal Code is required</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="info-container row">
                <div class="info-container-item col-12">
                    <label class="modal-body-label" for="eventDetails">Note</label>
                    <textarea 
                        id="eventDetails" 
                        name="eventDetails"
                        [(ngModel)]="eventDetails"
                        class="modal-textarea"                                   
                        (input)="onTextareaInput($event)"></textarea>
                    <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
                </div>
            </div>            
        </ng-container>
    </form>

    <div *ngIf="displayFailedAttendanceDeclaration">
        <div class="heading">Oral Review Election</div>
        <div class="info-text" [innerHTML]="declarationSafeHTML"></div>
    </div>
    
    <div class="heading">Grounds for dispute</div>
    <div class="info-container">
        <div *ngFor="let reviewItem of review?.reviewItems">
            <div class="info-form-group">
                <label class="info-label">Contravention</label>
                <div class="info-text">
                    <ng-container *ngIf="reviewItem?.contraventionId != null">
                        <a [routerLink]="['/contravention', reviewItem?.recordNumber]">{{reviewItem?.recordNumber}}</a>
                    </ng-container>
                    <ng-container *ngIf="reviewItem?.vehicleSeizureId != null">
                        <a [routerLink]="['/seizure', reviewItem?.recordNumber]">{{reviewItem?.recordNumber}}</a>
                    </ng-container>    
                </div>
                <div class="info-text">{{getContraventionTypeName(reviewItem.contraventionTypeId)}}</div>
            </div>
            <div class="info-form-group" *ngIf="reviewItem?.reviewGrounds">
                <label class="info-label">Grounds for Review</label>
                <div class="info-text">{{reviewItem?.reviewGrounds}}</div>
            </div>
            <hr>
        </div>
        <div class="info-form-group" *ngIf="review?.additionalRequestNotes">
            <label class="info-label">Additional Request Notes</label>
            <div class="info-text">{{review?.additionalRequestNotes}}</div>
        </div>

        <div class="info-form-group" *ngIf="isThirdPartySeizure && review.recipientRelationship">
            <label class="info-label">Describe Your Relationship with the Recipient</label>
            <div class="info-text">{{review?.recipientRelationship}}</div>
        </div>

        <div class="info-form-group row" *ngIf="review?.reviewTypeId == ReviewTypes.IrsReview">
            <div class="col-12 col-lg-4">
                <label class="info-label">Interpreter</label>
                <div class="info-text">{{review?.isAccompainedByInterpreter ? "Yes" : "No"}}</div>
            </div>
        </div>

        <div class="info-form-group row" *ngIf="isThirdPartySeizure">
            <div class="col-12 col-lg-4">
                <label class="info-label">Is this vehicle registered under a company?</label>
                <div class="info-text">{{review?.isCommercialVehicle ? "Yes" : "No"}}</div>
            </div>
            <div class="col-12 col-lg-4">
                <label class="info-label">Was the Vehicle Taken without Your Permission?</label>
                <div class="info-text">{{review?.isVehicleTakenWithoutPermission ? "Yes" : "No"}}</div>
            </div>
            <div class="col-12 col-lg-4">
                <label class="info-label">Was the Vehicle Stolen?</label>
                <div class="info-text">{{review?.isVehicleStolen ? "Yes" : "No"}}</div>
            </div>
        </div>

        <div class="info-form-group row" *ngIf="isThirdPartySeizure && review.reviewApplicant?.vehicleInterestTypeId == VehicleInterestTypes.Lessor">
            <div class="col-12 col-lg-4">
                <label class="info-label">Did You Have a Rental Agreement with the Recipient/Driver?</label>
                <div class="info-text">{{review?.hasRentalAgreement ? "Yes" : "No"}}</div>
            </div>
        </div>

        <div class="form-group">
            <label class="info-label" [innerHTML]="termsConditionsStatement"></label>
            <div class="info-text" *ngIf="review?.termsConditionsVersion; else noTermsConditionsAccepted">
                Yes
            </div>
            <ng-template #noTermsConditionsAccepted>
                <div class="info-text">
                    -
                </div>
            </ng-template>
        </div>
    </div>
    
    <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.VIEW_DOWNLOAD_RECIPIENT_SUBMISSIONS">
        <div class="heading-table">Applicant-Submitted Supporting Documents</div>
        <div class="d-flex mb-3">        
            <table class="table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Filename</th>
                        <th>Date Submitted</th>
                        <th>Format</th>
                        <th>File Size</th>                                          
                    </tr>
                </thead>
                            
                <tbody *ngIf="supportingDocuments?.length > 0">
                    
                    <tr *ngFor="let document of supportingDocuments">
                        <td>
                            <div>{{getDocumentTypeName(document.documentTypeId)}}</div>                                              
                        </td>
                        <td>                       
                            <div class="d-flex align-items-center">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                            </div>                                 
                        </td>
                        <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                        <td>{{document.documentExtension}}</td>
                        <td>{{document.documentSize}}</td>              
                    </tr>                      
                </tbody>
                <tbody *ngIf="supportingDocuments?.length == 0">
                    <tr>
                        <td colspan="5">No applicant-submitted supporting documents found.</td>
                    </tr>
                </tbody> 
            </table>        
        </div>
    </app-access-control>
    <form #paymentHistoryForm="ngForm">
        <div class="heading-table">Review Payment Information</div>
        <div class="d-flex mb-3">        
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 220px;">Payment Method</th>
                        <th style="width: 200px;">Transaction ID</th>
                        <th>
                            <div class="payment-amount-column">Amount</div>
                        </th>   
                        <th class="receipt-cell">Action</th>
                        <th class="refund-cell" *ngIf="canLogRefund">
                            Refund
                        </th>
                    </tr>
                </thead>
                            
                <tbody *ngIf="review?.payments != null && review?.payments?.length > 0">
                    
                    <tr *ngFor="let payment of review.payments">
                        <td>{{review.isSubmittedByRegistry? "Registry": payment.cardType + " ending in " + payment.cardLastFourDigits}}</td>
                        <td *ngIf="!payment.edited">{{review.isSubmittedByRegistry? payment.financialTransaction.transactionId : payment.financialTransactionId.toString().padStart(13, "0")}}</td>
                        <td *ngIf="payment.edited">
                            <div class="row">
                                <div class="col-11">
                                    <input class="form-control" required [appTransactionIdFormatter]="true" [appTransactionIdValidator]="true" #transactionIDControl="ngModel" type="text" maxlength="19" id="transactionID" name="transactionID" placeholder="Transaction ID"  [(ngModel)]="paymentDataEditRequest.transactionId" [ngClass]="{'is-invalid': (transactionIDControl.touched || isSubmitClicked) && transactionIDControl.invalid}">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="payment-amount-column">{{payment.amount | currency}}</div>
                        </td> 
                        <td class="receipt-cell">
                            <a class="receipt-link" *ngIf="!review.isSubmittedByRegistry"
                                (click)="onViewReceiptClick()">Download Receipt</a>
                            <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.DATA_EDITS">
                                <div class="col d-flex justify-content-end">
                                    <div *ngIf="review.isSubmittedByRegistry && !payment.edited" class="data-edit-button" (click)="onEditPaymentClick(payment)"></div>
                                    <div *ngIf="review.isSubmittedByRegistry && payment.edited" class="data-edit-ok-button" (click)="onEditPaymentSaveClick(paymentHistoryForm.valid)"></div>
                                    <div *ngIf="review.isSubmittedByRegistry && payment.edited" class="data-edit-cancel-button" (click)="onEditPaymentCancelClick(payment)"></div>
                                </div>
                            </app-access-control>
                        </td>                        
                        <td class="refund-cell" *ngIf="canLogRefund">
                            <div *ngIf="payment.refundStatus == RefundStatus.Completed"> Completed </div>
                            <div class="log-refund-link" *ngIf="payment.refundStatus == RefundStatus.Refundable" (click)="onLogRefundClick(payment)">Log Refund</div>
                        </td>
                    </tr>                      
                </tbody>
                <tbody *ngIf="review?.payments == null || review?.payments?.length == 0">
                    <tr>
                        <td colspan="5">No review payments found.</td>
                    </tr>
                </tbody> 
            </table>        
        </div>  
    </form>                
</div>