import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContactMethodTypes, EventTypes, NotificationTypes } from "@apis/shared/enums/app.enum";
import { ReviewService } from '../../../shared/services/review.service';
import { NgModel, Validators } from '@angular/forms';
import { Notification } from '@apis/shared/models/notification.model';

@Component({
  selector: 'app-cancel-review-modal',
  templateUrl: './cancel-review-modal.component.html',
  styleUrls: ['./cancel-review-modal.component.scss']
})
export class CancelReviewModalComponent implements OnInit, AfterViewInit {
  review: Review;  
    
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;
    
  confirmCancellationButton: JQuery<HTMLElement>;
  eventDetails: string;
  cancellationType: number;
  
  formSubmitted: boolean = false;
  @ViewChild("eventDetailsModel") eventDetailsModel: NgModel;
  @Output() close: EventEmitter<Review> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService) { }

  ngAfterViewInit(): void {
    this.eventDetailsModel.control.setValidators(Validators.required);
  }

  ngOnInit(): void {            
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.confirmCancellationButton = $(".confirm-cancellation-button");      
  }
  
  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onConfirmCancellationClick(ev: any): void {
    this.formSubmitted = true;
    if (this.eventDetailsModel.invalid) {
      return;
    }
  
    this.confirmCancellationButton.addClass("disabled");
    this.confirmCancellationButton.addClass("saving");

    let event = new Event({      
      eventTypeId: this.cancellationType == 1 ? EventTypes.ReviewCancelledByRecipient : EventTypes.ReviewCancelledAdministratively,
      eventDetails: this.eventDetails      
    });
        
    let notification = new Notification({      
      notificationTypeId: NotificationTypes.ReviewIsCancelled,
      contactMethodTypeId: +this.review.contactMethodTypeId    
    })
            
    const review = new Review({
      reviewId: +this.review.reviewId
    });

    review.events.push(event);
    review.notifications.push(notification);
              
    this.reviewService.cancelReview(review)
      .subscribe(
        (result: Review) => { 
          this.confirmCancellationButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {});    
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  
}