import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestType } from '@apis/shared/models/types/request-type.model';
import { RequestStatusType } from '@apis/shared/models/types/request-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RequestService } from '../shared/services/request.service';
import { PageEvent } from '../shared/components/pagination/pagination.component';
import { RequestView } from '@apis/shared/models/request-view.model';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { RequestPagedSearch } from '@apis/shared/models/request-paged-search.model';
import { PagedResult } from '@apis/shared/models/paged-result.model';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ActionCheckboxesComponent } from '../shared/components/action-checkboxes/action-checkboxes.component';
import { RequestDecisionTypes, RequestStatusTypes } from '@apis/shared/enums/app.enum';
import { RequestDecisionType } from '@apis/shared/models/types/request-decision-type.model';
import { RequestTypes } from '@apis/shared/enums/app.enum';
import { User } from '@apis/shared/models/user.model';
import { Constants } from '@apis/shared/helpers/constants';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as fileSaver from "file-saver";
import { ActivatedRoute, Router, ParamMap, UrlSerializer } from '@angular/router';
import { StatusCheckboxesComponent } from '../shared/components/status-checkboxes/status-checkboxes.component';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-request-listing',
  templateUrl: './request-listing.component.html',
  styleUrls: ['./request-listing.component.scss'],
})
export class RequestListingComponent implements OnInit {
  TypeTable = TypeTable;

  RequestColDef = {
    RequestDate: { property: "requestDate", dataType: "date" },
    RequestNumber: { property: "requestNumber", dataType: "string" },
    RequestType: { property: "requestTypeId", dataType: "typeTable", typeTable: TypeTable.RequestType },
    Requestor: { property: "requestor", dataType: "string" },
    Adjudicator: { property: "adjudicatorName", dataType: "string" },
    Status: { property: "requestStatusTypeId", dataType: "typeTable", typeTable: TypeTable.RequestStatusType }
  };

  private columns = [{
      propertyName: "requestDate",
      ascending: false      
    }, {
      propertyName: "requestNumber",
      ascending: false      
    }, {
      propertyName: "requestTypeId",
      ascending: false      
    }, {
      propertyName: "requestor",
      ascending: false    
    }, {
      propertyName: "adjudicatorName",
      ascending: false    
    }, {
      propertyName: "requestStatusTypeId",
      ascending: false    
    }
  ];
  
  pageNumbers: number[] = [];
  pageNumber: number = 1;
  pageRange: number = 5;  
  totalPages: number = 10;
  pageSize: number = 25;
  searchText: string;
  orderBy: string = "requestDate";
  sortDirection: string = "desc";
  totalCount: number;
  actionsChecked: string[] = [];
  isLastRange: boolean = false;
  clearFiltersLink: JQuery<HTMLElement>;

  selectedRequestTypeId: number = null;
  selectedRequestStatusTypeIds: number[] = [];
  selectedRequestDecisionTypeIds: number[] = [];
  selectedAdjudicatorId: number = 0;

  requests: RequestView[] = [];
  filteredRequests: RequestView[] = [];

  requestTypes: RequestType[];
  requestStatusTypes: RequestStatusType[];
  requestDecisionTypes: RequestDecisionType[];

  currentSortColumn = '';
  currentSortOrder = '';

  RequestStatusTypes = RequestStatusTypes;
  RequestTypes = RequestTypes;
  RequestDecisionTypes = RequestDecisionTypes;

  isSuperUser:boolean = false;
  adjudicators: User[] = [];

  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  Role: any = Constants.Role;

  @ViewChild(StatusCheckboxesComponent) statusCheckboxesComponent: StatusCheckboxesComponent;
  @ViewChild(ActionCheckboxesComponent) actionCheckboxesComponent: ActionCheckboxesComponent;

  constructor(
    private readonly requestService: RequestService,
    readonly localStorageService: LocalStorageService,
    private readonly keyCloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly urlSerializer: UrlSerializer
  ) {}

  ngOnInit(): void {
    this.clearFiltersLink = $(".clear-filters");
    this.adjudicators = this.localStorageService.getAdjudicators();
    const loggedInAdjudicator = JSON.parse(localStorage.getItem("userKey"));
    this.selectedAdjudicatorId = this.adjudicators.find(a => a.emailAddress == loggedInAdjudicator?.emailAddress)?.userId??0;

    if (this.keyCloakService.isUserInRole(this.Role.SENIOR_ADJUDICATOR)) this.selectedAdjudicatorId = 0;

    this.requestTypes = this.localStorageService.getRequestTypes().filter(x=>x.id!=RequestTypes.AdditionalTimetoPay);    
    this.requestStatusTypes = this.localStorageService.getRequestStatusTypes();
    this.requestDecisionTypes = this.localStorageService.getRequestDecisionTypes();

    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey!==null && rolePermissionKey!==undefined) {
      rolePermissionKey.forEach( p => {
          if (p.roleName==='BusinessSuperUser') {
            this.selectedRequestTypeId = RequestTypes.LEACancellationRequest;
            this.isSuperUser = true;
          } 
      });
    }
    if(!this.isSuperUser)
    {
      this.requestTypes = this.requestTypes.filter(x=>x.id!=RequestTypes.LEACancellationRequest);
    }

    //Custom logic to add decision status as part of "Complete" review status checkboxes
    var completeStatusIndex = this.requestStatusTypes.indexOf(this.requestStatusTypes.find(x => x.name.toLowerCase() == "complete")); 
    this.requestDecisionTypes.forEach((decision, index) => {      
        decision.id += 1000; //To separate decision types from review status types
        decision.name = decision.name;
        this.requestStatusTypes.splice(completeStatusIndex + index + 1, 0, decision);      
    })

    this.route.queryParamMap.subscribe(paramMap => {
      setTimeout(() => {              
        this.initFilters(paramMap);
        this.setFilteredRequests(); 
      });
    });
  }

  onRequestTypeChange(ev: any): void {    
    this.clearFiltersLink.removeClass("disabled");
    this.pageNumber = 1;
    this.selectedAdjudicatorId = this.selectedRequestTypeId == RequestTypes.LEACancellationRequest ? 0 : this.selectedAdjudicatorId;
    this.setFilteredRequests();
  }

  onRequestStatusTypeChange(requestStatusTypeIds: number[]): void {    
    this.selectedRequestStatusTypeIds = requestStatusTypeIds.filter(x => x < 1000);
    this.selectedRequestDecisionTypeIds = requestStatusTypeIds.filter(x => x > 1000 && requestStatusTypeIds.includes(RequestStatusTypes.Complete));
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");
    this.setFilteredRequests();
  } 

  onSearchTextboxKeyDown(ev: KeyboardEvent): void {
    if (ev.key.toLowerCase() === 'enter') {
      this.onSearchButtonClick();
    }
  }

  onSearchButtonClick(): void {    
    this.pageNumber = 1;
    this.setFilteredRequests();
  }

  onClearFiltersClick(ev: any): void {
    this.statusCheckboxesComponent.clearAll();
    this.actionCheckboxesComponent.clearAll();

    if (ev.target.classList.contains("disabled")) {
      return;
    }

    this.clearFilters();

    ev.target.classList.add("disabled");    
  }

  onSelectAllLinkClick(ev: any): void {
    this.statusCheckboxesComponent.selectAll();
    this.actionCheckboxesComponent.selectAll();
    
    this.clearFiltersLink.removeClass("disabled");  

    this.pageNumber = 1;

    this.setFilteredRequests();
  }

  onSortClick(colDef: any) {
    this.sortData(colDef);
  }

  sortData(colDef: any) {
    this.filteredRequests = this.filteredRequests.sort((a: any, b: any) => {
      let obj1 = a[colDef.property];
      let obj2 = b[colDef.property];

      if (colDef.dataType === 'string') {
        obj1 = obj1.toLowerCase();
        obj2 = obj2.toLowerCase();
      }

      if (colDef.dataType === 'typeTable') {
        obj1 = this.localStorageService.getTypeItemDescriptionById(obj1, colDef.typeTable).toLowerCase();
        obj2 = this.localStorageService.getTypeItemDescriptionById(obj2, colDef.typeTable).toLowerCase();
      }


      if (this.currentSortColumn != colDef.property) {
        this.currentSortColumn = colDef.property;
        this.currentSortOrder = 'desc';
      } else {
        this.currentSortOrder = (this.currentSortOrder == 'asc') ? 'desc' : 'asc';
      }

      if (this.currentSortOrder == 'asc') {
        return obj1 < obj2 ? -1 : (obj1 > obj2) ? 1 : 0;
      } else {
        return obj1 < obj2 ? 1 : (obj1 > obj2) ? -1 : 0;
      }
    });
  }

  initFilters(paramMap: ParamMap) {
    paramMap.keys.forEach(key => {
      var value = paramMap.get(key);
      switch (key) {
        case "pageNumber":
          this.pageNumber = +value;
          break;
        case "pageSize":
          this.pageSize = +value;
          break;
        case "sortDirection":
          this.sortDirection = value;
          break;
        case "orderBy":
          this.orderBy = value;
          break;
        case "searchTerm":
          this.searchText = value;
          break;
        case "requestTypeId":
          this.selectedRequestTypeId = +value;
          break;
        case "userId":
          this.selectedAdjudicatorId = +value;
          break;
        case "requestStatusTypeIds":
          this.selectedRequestStatusTypeIds = value.split(',').map(x => +x);          
          break;
        case "requestDecisionTypeIds":
          this.selectedRequestDecisionTypeIds = value.split(',').map(x => +x+1000);          
          break;
        case "checkRequiresCommunication":
          this.actionsChecked.push('contact');
          break;
      }
    });

    this.statusCheckboxesComponent.init(this.selectedRequestStatusTypeIds.concat(this.selectedRequestDecisionTypeIds));
    this.actionCheckboxesComponent.init(this.actionsChecked);
  }

  private setFilteredRequests(): void {
    let requestPagedSearch = new RequestPagedSearch({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      orderBy: this.orderBy
    });
    
    if (this.selectedRequestTypeId == null) {
      this.selectedRequestTypeId = 0;
    }

    if (this.selectedRequestTypeId >= 0) {
      requestPagedSearch.requestTypeId = this.selectedRequestTypeId;
    }

    if (this.selectedAdjudicatorId > 0) {
      requestPagedSearch.userId = this.selectedAdjudicatorId;
    }
    if (this.selectedRequestStatusTypeIds.length > 0) {
      requestPagedSearch.requestStatusTypeIds = this.selectedRequestStatusTypeIds.join(",");
    }

    if (this.selectedRequestDecisionTypeIds.length > 0) {
      requestPagedSearch.requestDecisionTypeIds = Array.prototype.map.call(this.selectedRequestDecisionTypeIds, function(item) { return item-1000 }).join(",");
    }

    if (this.searchText?.length > 0) {      
      requestPagedSearch.searchTerm = this.searchText.trim().toLowerCase();      
    }

    if (this.actionsChecked.includes("contact")) {
      requestPagedSearch.checkRequiresCommunication = true;
    }

    this.spinner.show();    

    var requestPagedSearchCopy = Object.assign({}, requestPagedSearch);
    if (requestPagedSearchCopy.requestTypeId == null) {
      requestPagedSearchCopy.requestTypeId = 0;
    }
    this.setWindowHistory(requestPagedSearch);
   
    this.requestService.getRequestsByPagedSearch(requestPagedSearch)
      .subscribe((result: PagedResult) => {       
        if(!this.isSuperUser)
        {
          this.requests = result.items.filter(x=>x.requestTypeId!=RequestTypes.LEACancellationRequest);            
        }    
        else
        {   
          this.requests = result.items;    
        }    
        this.totalCount = result.totalCount;     
      
        this.filteredRequests = Array.from(this.requests);
        this.setPageNumbers();
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
      });
  }

  clearFilters() {
    this.actionCheckboxesComponent.clearAll();
    this.selectedRequestTypeId = null;
    this.selectedRequestStatusTypeIds = [];
    this.selectedRequestDecisionTypeIds = [];
    this.selectedAdjudicatorId = 0;

    $(".request-status-checkbox-container").removeClass("active");
    $(".request-decision-status-checkbox-container").removeClass("active");

    this.searchText = '';
    this.pageNumber = 1;

    this.setFilteredRequests();
  }

  setPageNumbers(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    if (+this.totalPages === 0) {
      this.totalPages = 1;
      this.pageNumbers = [1];
      return;
    }

    let totalPageRanges = Math.ceil(this.totalPages / this.pageRange);        
    let totalPagesAsArray = Array(this.totalPages).fill(1).map((value: any, index: number) => value + index);
    let totalPageRangesAsArray = Array(totalPageRanges).fill(0).map((value: any, index: number) => value + index);    
    let pageRangeIndex = totalPageRangesAsArray.find((value: any, index: number) => this.pageNumber <= (value * this.pageRange) + this.pageRange);
    
    this.isLastRange = totalPageRangesAsArray.length - 1 == pageRangeIndex;
    this.pageNumbers = totalPagesAsArray.slice(pageRangeIndex * this.pageRange, (pageRangeIndex * this.pageRange) + this.pageRange);
  }

  onPreviousLinkClick(ev: any): void {  
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.pageNumber -= 1;    
    if (+this.pageNumber <= 1) {
      this.pageNumber = 1;
    }
     
    this.setFilteredRequests();
  }

  onNextLinkClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    this.pageNumber += 1;
  
    if (+this.pageNumber >= this.totalPages) {
      this.pageNumber = this.totalPages;     
    }            
    
    this.setFilteredRequests();
  }

  onPageNumberClick(ev: any, pageNumber: number): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }
        
    this.pageNumber = pageNumber;    
    this.setFilteredRequests();
  }

  onPageSizeChange(ev: any): void {
    let target = ev.target as HTMLSelectElement;    
    this.pageSize = parseInt(target.selectedOptions[0].value);
    this.pageNumber = 1;

    this.setFilteredRequests();
  }

  onSortIconClick(propertyName: string): void {  
    let column = this.columns.find(c => c.propertyName == propertyName);    
    column.ascending = !column.ascending;

    this.sortDirection = "desc";
    if (column.ascending) {
      this.sortDirection = "asc";
    }

    this.orderBy = propertyName;    
    this.setFilteredRequests();        
  }  

  onActionCheckboxesChange(actionsChecked: string[]): void {    
    this.actionsChecked = actionsChecked;
    this.clearFiltersLink.removeClass("disabled");
    this.pageNumber = 1;
    
    this.setFilteredRequests();
  }

  onAssignedToChange(ev: any): void {
    this.selectedAdjudicatorId = ev.target.selectedOptions[0].value;     
    this.pageNumber = 1;
    this.clearFiltersLink.removeClass("disabled");
    this.setFilteredRequests();
  }

  onExportListClick(ev: any): void {
    
    this.spinner.show();
    setTimeout(() => {
      let element = $(".loading-text > p");      
      element.html(`<span style='font-size: 17px !important;'>Please be patient while APIS generates your report</span>`); 
    }, 100);
    
    this.requestService.downloadRequestsCSVExport()
      .subscribe((result: HttpEvent<Blob>) => {       
        if (result.type == HttpEventType.Response) {
          this.spinner.hide();
          fileSaver.saveAs(result.body, "requests.csv");         
        }        
      }, (error: any) => this.spinner.hide());                     
  }

  private setWindowHistory(pagedSearch: RequestPagedSearch): void {  
    const urlTree = this.router.createUrlTree(['/requests'], { queryParams: pagedSearch });
    window.history.replaceState({}, '', this.urlSerializer.serialize(urlTree));
  }

  private toLocaleDateTime(requestDate: Date): Date {
    var requestDateTime = new Date(requestDate.toString().replace(/[zZ]/, ""));
    requestDateTime.setMinutes(requestDateTime.getMinutes() - requestDateTime.getTimezoneOffset());
    return requestDateTime;
  }
}
