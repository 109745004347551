import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { AdjudicationService } from "../../../services/adjudication.service";

@Component({
  selector: 'app-review-request-extension-modal',
  templateUrl: './review-request-extension-modal.component.html',
  styleUrls: ['./review-request-extension-modal.component.scss']
})
export class ReviewRequestExtensionModalComponent implements OnInit {
  contraventionId: number;
  vehicleSeizureId: number;

  reviewStatusTypeId: number = 0;
  selectedReviewStatusTypeId: number;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 190;
  modalTextarea: JQuery<HTMLElement>;
  
  confirmButton: JQuery<HTMLElement>;
  eventDetails: string;
    
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private readonly adjudicationService: AdjudicationService) { }

  ngOnInit(): void {         
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.confirmButton = $(".confirm-button");      
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 190 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onConfirmModalClick(ev: any): void {
    if (this.confirmButton.hasClass("disabled")) {
      return;
    }
        
    this.confirmButton.addClass("disabled");
    this.confirmButton.addClass("saving");

    if (this.contraventionId != null) {
      this.adjudicationService.extendContraventionReviewRequestDeadline(this.contraventionId, this.eventDetails)
        .subscribe((result: Contravention) => {
          if (result != null) {
            this.removeOverflow();
            this.close.emit(result);          
          }
        });
    } else if (this.vehicleSeizureId != null) {
      this.adjudicationService.extendVehicleSeizureReviewRequestDeadline(this.vehicleSeizureId, this.eventDetails)
        .subscribe((result: VehicleSeizure) => {
          if (result != null) {
            this.removeOverflow();
            this.close.emit(result);          
          }
        });
    }

    
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  
}
