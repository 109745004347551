import { DatePipe } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AvailabilityReportRequest } from '@apis/shared/models/availability-report-request.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ReportService } from 'apps/adjudication/src/app/shared/services/report.service';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from "file-saver";

@Component({
  selector: 'app-availability-report',
  templateUrl: './availability-report.component.html',
  styleUrls: ['./availability-report.component.scss']
})
export class AvailabilityReportComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;  
  dateCustomClasses: DatepickerDateCustomClasses[];

  datePipe: DatePipe = new DatePipe("en-US");

  reportRequest = new AvailabilityReportRequest();
  irsReviewWritten: boolean;
  irsReviewOral: boolean;
  seizureReview: boolean;
  
  @Output() close: EventEmitter<any> = new EventEmitter();
  
  constructor(
    readonly localStorageService: LocalStorageService,    
    private readonly spinner: NgxSpinnerService,
    private readonly reportService: ReportService) {
    this.datePickerConfig = Object.assign({}, 
      {
        containerClass: 'theme-dark-blue', 
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });
      
    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
   }

  ngOnInit(): void {
  }

  init() {
    this.reportRequest = new AvailabilityReportRequest();
  }

  onCancelClick() {
    this.close.emit();
  }

  onCloseClick() {
    this.close.emit();
  }

  onSubmit(isValid: boolean) {
    if (!isValid) return;

    this.reportRequest.meetingTypeIds = [];

    if (this.irsReviewWritten) {
      this.reportRequest.meetingTypeIds.push(1);
    }
    if (this.irsReviewOral) {
      this.reportRequest.meetingTypeIds.push(2);
    }
    if (this.seizureReview) {
      this.reportRequest.meetingTypeIds.push(3);
    }

    this.spinner.show();
    this.reportService.getAvailabilityReport(this.reportRequest)
    .subscribe((result: HttpEvent<Blob>) => {
      if (result.type == HttpEventType.Response) {
        this.spinner.hide();
        fileSaver.saveAs(result.body, "availability-report.csv");
      }
    }, (error: any) => this.spinner.hide());
  }
}
