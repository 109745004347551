


export class RequestView {
    public requestDate: Date = new Date();
    public requestNumber: string;
    public requestor: string;
    public adjudicatorName: string;
    public requestTypeId: number = 0;
    public requestStatusTypeId: number = 0;
    public requestDecisionTypeId: number = 0;
    public isPhoneContactRequired: boolean = false;
    public userId:number = 0;

    constructor(init?: Partial<RequestView>) {
        
        Object.assign(this, init);
    }
}
