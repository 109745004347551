import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DriversLicenceClassType } from '@apis/models/types/drivers-licence-class-type.model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { CountryProvinceModel } from '@apis/shared/models/country-province.model';
import { IdentificationTypes } from '@apis/shared/enums/app.enum';
import { IdentificationType } from '@apis/shared/models/types/identification-type.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { DatePipe } from '@angular/common';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { DriversLicenceStatusType } from '@apis/shared/models/types/drivers-licence-status-type.model';
import { MovesRecipientContact } from '@apis/shared/models/moves-recipient-contact.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';

@Component({
  selector: 'seizure-recipient-information',
  templateUrl: './seizure-recipient-information.component.html',
  styleUrls: ['./seizure-recipient-information.component.scss']
})
export class SeizureRecipientInformationComponent implements OnInit {
  @Input() contravention: Contravention;
  @Input() vehicleSeizure: VehicleSeizure;
  @Output() getVehicleSeizureChange = new EventEmitter<VehicleSeizure>();
  @Input() model: any;

  recipient: Recipient;
  provinceCode: string;
  licenceClass: string;
  licenceIssuingAuthority: String;
  licenceStatus: string;
  licenceExpiry: string;
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateOfBirth: Date;
  driversLicenceClassTypeId: number;
  motorVehicleIdentificationNumber: string;
  isSubmitClicked: boolean;
  driversLicenceClass: string;
  issuingCountryId: number;
  countryProvince: CountryProvinceModel;
  issuingProvinceId: number;
  driverLicenceClassification: any;
  issuingProvinceOther: string;
  isAlbertaDriverLicense:boolean;
  identificationNumber:string;
  driversLicenceClassTypes: DriversLicenceClassType[];
  isIdentificationInformationEdited: boolean=false;
  isNoLicenceProduced:boolean;
  seizureTypeId:number;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  errorMessage:string;
  IdentificationTypes = IdentificationTypes;
  identificationVerifiedBy: string;
  identificationTypes: IdentificationType[];
  identificationTypeId:number;
  identificationOther:string;
  isAllowEditIdentification:boolean = false;
  conditionCodes: string;
  recipientContactInformation: SectionDetailsModel[] = [];
  movesRecipientContactInformation: SectionDetailsModel[] = [];
  identificationExpiryDate: Date;
  genderTypes: GenderType[];
  genderId: number;
  driverLicenceStatusTypes: DriversLicenceStatusType[];
  driversLicenceStatusTypeId: number;
  intIsGDLDriver: number;
  movesRecipientContact: MovesRecipientContact;
  recipientContact: RecipientContact;
  countryId: number;
  provinceId: number;
  countryTypes: any = [];
  provinceTypes: any = [];
  isRecipientContactInformationEdited: boolean = false;
  isRecipientContactDetailsReadonly: boolean = false;
  isAllowEditIdentificationBusinessUser: boolean = false;
  errorMessageContactInfo: string;
  isSubmitRecipientContactClicked: boolean;

  constructor(private localStorageService: LocalStorageService, 
              private readonly adjudicationService: AdjudicationService,
              private readonly spinner: NgxSpinnerService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.datePickerConfig = Object.assign({}, 
      {
        containerClass: 'theme-dark-blue', 
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
        minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
        maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
        isAnimated: true
      });
    this.countryProvince = new CountryProvinceModel();
    this.countryProvince.isCountryRequired=false;
    this.countryProvince.isProvinceRequired=false;
    this.countryProvince.countryLabel = "Country";
    this.countryProvince.provinceLabel = "Province/State";

    this.genderTypes = this.localStorageService.getGenderTypes();
    this.driverLicenceStatusTypes = this.localStorageService.getDriversLicenceStatusTypes();

    this.toCheckEditPermission();
  }

  PopulateRecipientInformation() {
    this.toCheckEditPermission();
    if (this.vehicleSeizure.seizureTypeId == 1) { //Part of Contravention    
      this.recipient = this.contravention?.recipient;
    }
    else {
      this.recipient = this.vehicleSeizure?.recipient;
    }    
    this.seizureTypeId  = this.vehicleSeizure?.seizureTypeId;    

    this.provinceCode =  this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient?.recipientContact?.provinceId)?.code;

    if(this.recipient?.recipientIdentification?.driversLicenceClassTypeId==null) {
      this.licenceClass =  this.recipient?.recipientIdentification?.driversLicenceClass;
    } else {
      this.licenceClass =  this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.recipient?.recipientIdentification?.driversLicenceClassTypeId)?.name;
    }

    this.licenceIssuingAuthority = this.recipient?.recipientIdentification?.issuingCountry?.name;
    if (this.licenceIssuingAuthority != null)
    {
      if (this.recipient?.recipientIdentification?.issuingCountryId == 40 || this.recipient?.recipientIdentification?.issuingCountryId == 236) // Canada or USA
        this.licenceIssuingAuthority = this.recipient?.recipientIdentification?.issuingProvince?.name==null? this.licenceIssuingAuthority : `${this.licenceIssuingAuthority}, ${this.recipient?.recipientIdentification?.issuingProvince?.name}`;
      else
        this.licenceIssuingAuthority = this.recipient?.recipientIdentification?.issuingProvinceOther==null ? this.licenceIssuingAuthority : `${this.licenceIssuingAuthority}, ${this.recipient?.recipientIdentification?.issuingProvinceOther}`;
    }

    this.licenceStatus = this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.recipient?.recipientIdentification?.driversLicenceStatusTypeId)?.name;
    this.licenceExpiry = this.datePipe.transform(this.recipient?.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')
    this.identificationTypes = this.localStorageService.getIdentificationTypes();     
    this.identificationTypeId = this.recipient?.recipientIdentification?.identificationTypeId;  
    this.identificationVerifiedBy = this.localStorageService.getIdentificationTypes().find(x => x.id == this.recipient?.recipientIdentification?.identificationTypeId)?.name;   
    this.identificationOther = this.recipient?.recipientIdentification?.identificationOther;
    this.conditionCodes = Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.recipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join("");

    this.identificationExpiryDate = this.recipient?.recipientIdentification?.identificationExpiryDate;
    if (this.recipient?.recipientIdentification?.gender?.id != undefined && this.recipient?.recipientIdentification?.gender?.id != null) {
      this.genderId = Number(this.recipient?.recipientIdentification?.gender?.id);
    }

    if (this.recipient?.recipientIdentification?.driversLicenceStatusTypeId != undefined && this.recipient?.recipientIdentification?.driversLicenceStatusTypeId != null) {
      this.driversLicenceStatusTypeId = Number(this.recipient?.recipientIdentification?.driversLicenceStatusTypeId);
    }

    this.intIsGDLDriver = (this.recipient?.recipientIdentification?.isGDLDriver != null) ? (this.recipient?.recipientIdentification?.isGDLDriver ? 1 : 2) : 0;
    
    // Recipient Contact Information Section
    this.movesRecipientContact = this.recipient?.movesRecipientContact ? JSON.parse(JSON.stringify(this.recipient?.movesRecipientContact)) : null;
    this.recipientContact = this.recipient?.recipientContact ? JSON.parse(JSON.stringify(this.recipient?.recipientContact)) : null;
    this.recipientContactInformation = [];
    this.movesRecipientContactInformation = [];
    this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient?.recipientContact?.addressLine1));
    this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient?.recipientContact?.addressLine2));
    this.recipientContactInformation.push(new SectionDetailsModel('City',this.recipient?.recipientContact?.city));
    this.recipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient?.recipientContact?.countryId == 40 || this.recipient?.recipientContact?.countryId == 236)? (this.recipient?.recipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.recipient?.recipientContact?.provinceId)?.name) : this.recipient?.recipientContact?.province));
    this.recipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.recipient?.recipientContact?.countryId)?.name));
    this.recipientContactInformation.push(new SectionDetailsModel('Postal Code',this.recipient?.recipientContact?.postalCode));
    this.recipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient?.recipientContact?.phoneNumber1));
    this.recipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient?.recipientContact?.phoneNumber2));
    this.recipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient?.recipientContact?.phoneNumber3));
    this.recipientContactInformation.push(new SectionDetailsModel('Email Address', this.recipient?.recipientContact?.emailAddress));
    this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.recipient?.recipientContact?.isAddressDifferentFromMOVES? "Yes" : "No"));
    this.recipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.recipient?.recipientContact?.isNoFixedAddress? "Yes" : "No"));

    this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient?.movesRecipientContact?.addressLine1));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient?.movesRecipientContact?.addressLine2));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('City',this.recipient?.movesRecipientContact?.city));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient?.movesRecipientContact?.countryId == 40 || this.recipient?.movesRecipientContact?.countryId == 236)? (this.recipient?.movesRecipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.recipient?.movesRecipientContact?.provinceId)?.name) : this.recipient?.movesRecipientContact?.province));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.recipient?.movesRecipientContact?.countryId)?.name));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code',this.recipient?.movesRecipientContact?.postalCode));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient?.movesRecipientContact?.phoneNumber1));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient?.movesRecipientContact?.phoneNumber3));
    this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient?.movesRecipientContact?.phoneNumber2));
  }

  public refreshContraventionVehicleCountryProvince(e) {    
    if(e.provinceId == 1) {      
      this.driversLicenceClass=null; 
    } else {
      this.driversLicenceClassTypeId = 0;
    }
    this.issuingCountryId = e.countryId;    
    this.driversLicenceClassTypes =  this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == e.provinceId);
    this.setDriverLicenceClassification(e.provinceId);

    this.countryProvince.isProvinceRequired = this.countryProvince.countryId == 40 || this.countryProvince.countryId == 236; //Canada or USA
  }

  editIdentificationInformationClick() {
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey!==null && rolePermissionKey!==undefined) {
      rolePermissionKey.forEach( p => {
        if (p.roleName===Constants.Role.BUSINESS_ANALYST || p.roleName===Constants.Role.BUSINESS_SUPER_USER) {
          this.isAllowEditIdentification = true; 
          if (p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
            this.isAllowEditIdentificationBusinessUser = true;
          }        
        } 
    });     
    }
    // set current value in variables
    this.identificationNumber = this.recipient?.recipientIdentification?.identificationNumber;
    this.motorVehicleIdentificationNumber = this.vehicleSeizure?.recipient?.recipientIdentification?.motorVehicleIdentificationNumber;
    this.dateOfBirth = new Date(this.vehicleSeizure?.recipient?.recipientIdentification?.dateOfBirth);
    this.issuingCountryId = Number(this.vehicleSeizure?.recipient?.recipientIdentification?.issuingCountryId);
    this.issuingProvinceId = Number(this.vehicleSeizure?.recipient?.recipientIdentification?.issuingProvinceId);
    this.issuingProvinceOther = this.vehicleSeizure?.recipient?.recipientIdentification?.issuingProvinceOther;
    this.driversLicenceClassTypeId = Number(this.vehicleSeizure?.recipient?.recipientIdentification?.driversLicenceClassTypeId);
    this.driversLicenceClass = this.vehicleSeizure?.recipient?.recipientIdentification?.driversLicenceClass;
    this.setDriverLicenceClassification(this.vehicleSeizure?.recipient?.recipientIdentification.issuingProvinceId);
    if(this.vehicleSeizure?.recipient?.recipientIdentification?.identificationTypeId == IdentificationTypes.Other)
    {
      this.identificationTypeId = this.vehicleSeizure?.recipient?.recipientIdentification?.identificationTypeId;     
      this.identificationOther = this.vehicleSeizure?.recipient?.recipientIdentification?.identificationOther;   
    }
    // Populate Binding Properties
    this.countryProvince.countryId = this.issuingCountryId;
    if(this.vehicleSeizure?.recipient?.recipientIdentification?.issuingProvinceId==null) {
      this.countryProvince.province = this.vehicleSeizure?.recipient?.recipientIdentification?.issuingProvinceOther; 
    } else {
      this.countryProvince.provinceId = this.issuingProvinceId;
      this.driversLicenceClassTypes =  this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == this.issuingProvinceId);
    }
    this.countryProvince.isProvinceRequired = this.countryProvince.countryId == 40 || this.countryProvince.countryId == 236; //Canada or USA

    this.isIdentificationInformationEdited =! this.isIdentificationInformationEdited;
  }

  setDriverLicenceClassification(provinceId:any) {
    this.isNoLicenceProduced =  this.vehicleSeizure?.recipient?.isNoLicenceProduced;
    this.isAlbertaDriverLicense = provinceId == 1;

    // Phase 2 Build - driver licence classification was added to the database so get it from there is available.
    if (this.vehicleSeizure?.recipient?.recipientIdentification?.driversLicenceClassificationId)
    {
      this.driverLicenceClassification = this.vehicleSeizure?.recipient?.recipientIdentification?.driversLicenceClassificationId;
      return;
    }

    // If driver licence classification is not available in database then derive it from province.
    if (this.isAlbertaDriverLicense) {
      this.driverLicenceClassification = 1;
    } else if (this.vehicleSeizure?.recipient?.isNoLicenceProduced) {
      this.driverLicenceClassification = 3;
    } else {
      this.driverLicenceClassification = 2;
    }
  }

  cancelEditedIdentificationInformation() {
    this.clearEditedIdentificationInformation();
    this.getVehicleSeizureChange.emit(this.vehicleSeizure);
  }

  saveEditedIdentificationInformation(isValid:boolean) {
    this.isSubmitClicked=true;
    if(isValid) 
    {
        this.vehicleSeizure.recipient.recipientIdentification.issuingCountryId = this.countryProvince.countryId;
        this.vehicleSeizure.recipient.recipientIdentification.issuingProvinceId = this.countryProvince.provinceId;
        this.vehicleSeizure.recipient.recipientIdentification.issuingProvinceOther = this.countryProvince.province;
        this.vehicleSeizure.recipient.recipientIdentification.driversLicenceClassTypeId = this.driversLicenceClassTypeId;
        this.vehicleSeizure.recipient.recipientIdentification.driversLicenceClass = this.driversLicenceClass;    
        this.vehicleSeizure.recipient.recipientIdentification.identificationNumber = this.identificationNumber;
        this.vehicleSeizure.recipient.recipientIdentification.motorVehicleIdentificationNumber = this.motorVehicleIdentificationNumber;
        this.vehicleSeizure.recipient.recipientIdentification.dateOfBirth = this.dateOfBirth;
        this.vehicleSeizure.recipient.recipientIdentification.identificationTypeId = this.identificationTypeId;   
        this.vehicleSeizure.recipient.recipientIdentification.identificationOther = this.identificationOther;
        if (this.isAllowEditIdentificationBusinessUser) {
          this.vehicleSeizure.recipient.recipientIdentification.identificationExpiryDate = this.identificationExpiryDate;
          this.vehicleSeizure.recipient.recipientIdentification.genderId = this.genderId;
          this.vehicleSeizure.recipient.recipientIdentification.driversLicenceStatusTypeId = this.driversLicenceStatusTypeId;
          this.vehicleSeizure.recipient.recipientIdentification.isGDLDriver = this.recipient?.recipientIdentification?.isGDLDriver;
        }
        this.spinner.show();
        this.adjudicationService.updateVehicleSeizureRecipientIdentification(this.vehicleSeizure.seizureNumber, this.vehicleSeizure.recipient.recipientIdentification)
          .subscribe((result: VehicleSeizure) => {
            this.getVehicleSeizureChange.emit(result);
            this.clearEditedIdentificationInformation();
            this.spinner.hide();
          }, (error: any) => {
            if (error.status == 401 || error.status == 403) {
              this.errorMessage = "You are unauthorized to perform this operation";
            }          
            else  {
              this.showErrors(error);
            }    
            this.spinner.hide();   
          });        
      }
  }

  clearEditedIdentificationInformation() {
    this.issuingCountryId = null;
    this.issuingProvinceId = null;
    this.issuingProvinceOther=null;
    this.driversLicenceClassTypeId = null;
    this.driversLicenceClass =null;
    this.identificationNumber = null;
    this.motorVehicleIdentificationNumber = null;
    this.dateOfBirth = null;
    this.setDriverLicenceClassification(this.vehicleSeizure?.recipient?.recipientIdentification.issuingProvinceId);
    this.isIdentificationInformationEdited =! this.isIdentificationInformationEdited;
    this.errorMessage=null;
    this.genderId = null;

    switch (this.recipient?.recipientIdentification?.isGDLDriver) {
      case true: {
        this.intIsGDLDriver = 1;
        break;
      }
      case false: {
        this.intIsGDLDriver = 2;
        break;
      }
      default: {
        this.intIsGDLDriver = 0;
      }
    }
  }

  displayValue(value: any): string {
    if (value == null || value.trim() == "")
      return "-";
    else
      return value.trim();  
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  showErrorsContactInfo(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessageContactInfo = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessageContactInfo = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessageContactInfo = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessageContactInfo = error.error.errors;
    else
      this.errorMessageContactInfo = "Something went wrong. Please try again later.";
  }

  public onGDLDriverChange(event) {
    switch (event.target.value) {
      case "1": {
        this.recipient.recipientIdentification.isGDLDriver = true;
        break;
      }
      case "2": {
        this.recipient.recipientIdentification.isGDLDriver = false;
        break;
      }
      default: {
        this.recipient.recipientIdentification.isGDLDriver = null;
      }
    }
  }

  onAddressDifferentFromMovesChange() {
    if (!this.isRecipientReadOnly()) return;

    if (this.recipient.recipientContact.isAddressDifferentFromMOVES) {
      if (this.recipientContact.isAddressDifferentFromMOVES) {
        this.recipient.movesRecipientContact = new MovesRecipientContact();
        this.recipient.movesRecipientContact.addressLine1 = this.movesRecipientContact.addressLine1;
        this.recipient.movesRecipientContact.addressLine2 = this.movesRecipientContact.addressLine2;
        this.recipient.movesRecipientContact.addressLine3 = this.movesRecipientContact.addressLine3;
        this.recipient.movesRecipientContact.countryId = this.movesRecipientContact.countryId;
        this.recipient.movesRecipientContact.provinceId = this.movesRecipientContact.provinceId;
        this.recipient.movesRecipientContact.province = this.movesRecipientContact.province;
        this.recipient.movesRecipientContact.city = this.movesRecipientContact.city;
        this.recipient.movesRecipientContact.postalCode = this.movesRecipientContact.postalCode;
        this.recipient.movesRecipientContact.phoneNumber1 = this.movesRecipientContact.phoneNumber1;
        this.recipient.movesRecipientContact.phoneNumber2 = this.movesRecipientContact.phoneNumber2;
        this.recipient.movesRecipientContact.phoneNumber3 = this.movesRecipientContact.phoneNumber3;
        this.recipient.movesRecipientContact.emailAddress = this.movesRecipientContact.emailAddress;

        this.recipient.recipientContact.addressLine1 = this.recipientContact.addressLine1;
        this.recipient.recipientContact.addressLine2 = this.recipientContact.addressLine2;
        this.recipient.recipientContact.addressLine3 = this.recipientContact.addressLine3;
        this.recipient.recipientContact.countryId = this.recipientContact.countryId;
        this.recipient.recipientContact.provinceId = this.recipientContact.provinceId;
        this.recipient.recipientContact.province = this.recipientContact.province;
        this.recipient.recipientContact.city = this.recipientContact.city;
        this.recipient.recipientContact.postalCode = this.recipientContact.postalCode;
        this.recipient.recipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
        this.recipient.recipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
        this.recipient.recipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
        this.recipient.recipientContact.emailAddress = this.recipientContact.emailAddress;
        this.recipient.recipientContact.isNoFixedAddress = this.recipientContact.isNoFixedAddress;

        this.countryId = Number(this.recipientContact.countryId) ? Number(this.recipientContact.countryId) : null;
        this.provinceId = Number(this.recipientContact.provinceId) ? Number(this.recipientContact.provinceId) : null;
      }
      else {
        this.recipient.movesRecipientContact = new MovesRecipientContact();
        this.recipient.movesRecipientContact.addressLine1 = this.recipientContact.addressLine1;
        this.recipient.movesRecipientContact.addressLine2 = this.recipientContact.addressLine2;
        this.recipient.movesRecipientContact.addressLine3 = this.recipientContact.addressLine3;
        this.recipient.movesRecipientContact.countryId = this.recipientContact.countryId;
        this.recipient.movesRecipientContact.provinceId = this.recipientContact.provinceId;
        this.recipient.movesRecipientContact.province = this.recipientContact.province;
        this.recipient.movesRecipientContact.city = this.recipientContact.city;
        this.recipient.movesRecipientContact.postalCode = this.recipientContact.postalCode;
        this.recipient.movesRecipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
        this.recipient.movesRecipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
        this.recipient.movesRecipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
        this.recipient.movesRecipientContact.emailAddress = this.recipientContact.emailAddress;

        this.movesRecipientContact = new MovesRecipientContact();
        this.movesRecipientContact.addressLine1 = this.recipientContact.addressLine1;
        this.movesRecipientContact.addressLine2 = this.recipientContact.addressLine2;
        this.movesRecipientContact.addressLine3 = this.recipientContact.addressLine3;
        this.movesRecipientContact.countryId = this.recipientContact.countryId;
        this.movesRecipientContact.provinceId = this.recipientContact.provinceId;
        this.movesRecipientContact.province = this.recipientContact.province;
        this.movesRecipientContact.city = this.recipientContact.city;
        this.movesRecipientContact.postalCode = this.recipientContact.postalCode;
        this.movesRecipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
        this.movesRecipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
        this.movesRecipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
        this.movesRecipientContact.emailAddress = this.recipientContact.emailAddress;

        this.movesRecipientContactInformation = [];
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient?.movesRecipientContact?.addressLine1));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient?.movesRecipientContact?.addressLine2));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('City', this.recipient?.movesRecipientContact?.city));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient?.movesRecipientContact?.countryId == 40 || this.recipient?.movesRecipientContact?.countryId == 236) ? (this.recipient?.movesRecipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient?.movesRecipientContact?.provinceId)?.name) : this.recipient?.movesRecipientContact?.province));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.recipient?.movesRecipientContact?.countryId)?.name));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Postal Code', this.recipient?.movesRecipientContact?.postalCode));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient?.movesRecipientContact?.phoneNumber1));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient?.movesRecipientContact?.phoneNumber3));
        this.movesRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient?.movesRecipientContact?.phoneNumber2));

        this.recipient.recipientContact.addressLine1 = null;
        this.recipient.recipientContact.addressLine2 = null;
        this.recipient.recipientContact.addressLine3 = null;
        this.recipient.recipientContact.countryId = null;
        this.recipient.recipientContact.provinceId = null;
        this.recipient.recipientContact.province = null;
        this.recipient.recipientContact.city = null;
        this.recipient.recipientContact.postalCode = null;
        this.recipient.recipientContact.phoneNumber1 = null;
        this.recipient.recipientContact.phoneNumber2 = null;
        this.recipient.recipientContact.phoneNumber3 = null;
        this.recipient.recipientContact.emailAddress = null;
        this.recipient.recipientContact.isNoFixedAddress = false;

        this.countryId = null;
        this.provinceId = null;
      }
    }
    else {
      //Restore all driver contact information to it's original state
      this.recipient.recipientContact.addressLine1 = this.movesRecipientContact.addressLine1;
      this.recipient.recipientContact.addressLine2 = this.movesRecipientContact.addressLine2;
      this.recipient.recipientContact.addressLine3 = this.movesRecipientContact.addressLine3;
      this.recipient.recipientContact.countryId = this.movesRecipientContact.countryId;
      this.recipient.recipientContact.provinceId = this.movesRecipientContact.provinceId;
      this.recipient.recipientContact.province = this.movesRecipientContact.province;
      this.recipient.recipientContact.city = this.movesRecipientContact.city;
      this.recipient.recipientContact.postalCode = this.movesRecipientContact.postalCode;
      this.recipient.recipientContact.phoneNumber1 = this.movesRecipientContact.phoneNumber1;
      this.recipient.recipientContact.phoneNumber2 = this.movesRecipientContact.phoneNumber2;
      this.recipient.recipientContact.phoneNumber3 = this.movesRecipientContact.phoneNumber3;
      this.recipient.recipientContact.emailAddress = this.movesRecipientContact.emailAddress;
      this.recipient.recipientContact.isNoFixedAddress = false;

      this.countryId = Number(this.movesRecipientContact.countryId) ? Number(this.movesRecipientContact.countryId) : null;
      this.provinceId = Number(this.movesRecipientContact.provinceId) ? Number(this.movesRecipientContact.provinceId) : null;

      this.recipient.movesRecipientContact = null;
    }
  }

  isRecipientReadOnly() {
    return this.recipient?.movesLookupDate;
  }

  onProvinceTextChange(e) {
    this.recipient.recipientContact.provinceId = null;
    this.provinceId = null;
  }

  onCountryChange() {
    if (this.countryId == 40) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryId == 236) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 236; });
    }
    else {
      this.recipient.recipientContact.provinceId = null;
      this.provinceId = null;
    }

    this.contravention.recipient.recipientContact.province = null;

    return this.provinceTypes;
  }

  saveReceipentContactClick(isValid: boolean) {
    this.isSubmitRecipientContactClicked = true;
    if (isValid) {

      this.recipient.recipientContact.provinceId = this.provinceId;
      this.recipient.recipientContact.countryId = this.countryId;

      if (this.recipient.recipientContact?.postalCode)
        this.recipient.recipientContact.postalCode = this.recipient.recipientContact.postalCode.toUpperCase();

      this.spinner.show();
      this.adjudicationService.updateVehicleSeizureRecipientContact(this.vehicleSeizure.seizureNumber, this.recipient)
        .subscribe((result: VehicleSeizure) => {
          this.getVehicleSeizureChange.emit(result);
          this.clearEditedRecipientContactInformation();
          this.spinner.hide();
        }, (error: any) => {

          if (error.status == 401 || error.status == 403) {
            this.errorMessageContactInfo = "You are unauthorized to perform this operation";
          }
          else {
            this.showErrorsContactInfo(error);
          }
          this.spinner.hide();
        });
    }
  }

  cancelReceipentContactClick() {
    this.clearEditedRecipientContactInformation();
    this.getVehicleSeizureChange.emit(this.vehicleSeizure);
  }

  clearEditedRecipientContactInformation() {
    this.isRecipientContactInformationEdited = false;
    this.errorMessageContactInfo = null;
  }

  editReceipentContactClick() {
    this.isRecipientContactInformationEdited = true;
    this.countryTypes = this.localStorageService.getCountryTypes();
    this.provinceTypes = this.localStorageService.getProvinceTypes();

    this.countryId = Number(this.recipient?.recipientContact?.countryId) ? Number(this.recipient?.recipientContact?.countryId) : null;
    this.provinceId = Number(this.recipient?.recipientContact?.provinceId) ? Number(this.recipient?.recipientContact?.provinceId) : null;

    if (this.countryId == 40) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryId == 236) {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function (provinceType) { return provinceType.countryId == 236; });
    }
  }

  toCheckEditPermission() {
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey !== null && rolePermissionKey !== undefined) {
      rolePermissionKey.forEach(p => {
        if (p.roleName === Constants.Role.BUSINESS_ANALYST || p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
          this.isAllowEditIdentification = true;
          if (p.roleName === Constants.Role.BUSINESS_SUPER_USER) {
            this.isAllowEditIdentificationBusinessUser = true;
          }
        }
      });
    }
  }
}
