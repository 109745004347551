import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  BsDatepickerConfig,
  DatepickerDateCustomClasses,
} from 'ngx-bootstrap/datepicker';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';
import { Observable } from 'rxjs';
import { AdjudicationService } from '../../../services/adjudication.service';
import { ReviewService } from '../../../services/review.service';
import { RequestService } from '../../../services/request.service';
import { TransactionTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-log-refund-modal',
  templateUrl: './log-refund-modal.component.html',
  styleUrls: ['./log-refund-modal.component.scss'],
})
export class LogRefundModalComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();

  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  modalTextarea: JQuery<HTMLElement>;
  confirmButton: JQuery<HTMLElement>;

  logRefundRequest: LogRefundRequest;
  errorMessage: string;
  isSubmitClicked: boolean = false;
  maxCharacters: number = 250;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];

  constructor(private readonly adjudicationService: AdjudicationService,
    private readonly reviewService: ReviewService,
    private readonly requestService: RequestService) {}

  ngOnInit(): void {
    this.bodyElement = $(document.body);
    this.bodyElement.addClass('overflow-hidden');
    this.modalOverlay = $('.modal-overlay');
    this.modalTextarea = $('.modal-textarea');
    this.confirmButton = $('.confirm-button');
    this.datePickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true,
      }
    );

    this.dateCustomClasses = [
      { date: new Date(), classes: ['bg-secondary', 'text-white'] },
    ];
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;
  }

  onCloseIconClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onConfirmModalClick(isValid: boolean): void {
    if (this.confirmButton.hasClass('disabled')) {
      return;
    }

    this.isSubmitClicked = true;

    if (isValid) {
      this.confirmButton.addClass('disabled');
      this.confirmButton.addClass('saving');

      var logObservable: Observable<any>;
      switch(+this.logRefundRequest.transactionTypeId) {
        case TransactionTypes.FinePayment:
          logObservable = this.adjudicationService.logRefund(this.logRefundRequest);
          break;
        case TransactionTypes.ReviewFee:
          logObservable = this.reviewService.logRefund(this.logRefundRequest);
          break;
        case TransactionTypes.LateReviewRequestFee:
          logObservable = this.requestService.logRefund(this.logRefundRequest);
          break;
      }

      logObservable.subscribe((result: any) => {
        if (result != null) {
          this.removeOverflow();
          this.close.emit(result);
        }
      }, (error: any) => {
        this.confirmButton.removeClass("saving");
        this.confirmButton.removeAttr("disabled");

        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else  {
          this.showErrors(error);
        }    
    });
    }
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (
      typeof error?.error?.error === 'string' ||
      error?.error?.error instanceof String
    )
      this.errorMessage = error.error.error;
    else if (
      typeof error?.error?.errors === 'string' ||
      error?.error?.errors instanceof String
    )
      this.errorMessage = error.error.errors;
    else this.errorMessage = 'Something went wrong. Please try again later.';
  }

  removeOverflow(): void {
    this.bodyElement.removeClass('overflow-hidden');
  }
}
