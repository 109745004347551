<div class="modal-overlay modal-show">
    <div class="modal-container">  
        <div class="modal-container-header">
            <div class="modal-title">Assign To</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>      
        <div class="modal-container-body">
            <div class="form-group">                
                <select id="userId" 
                    name="userId" 
                    [(ngModel)]="userId"
                    class="form-control modal-select">
                    <option class="modal-select-option" [ngValue]="0">Select an Adjudicator</option>
                    <option class="modal-select-option"                        
                        *ngFor="let adjudicator of adjudicators" 
                        [disabled]="+adjudicator.userId==+adjudicatorId"
                        [ngValue]="adjudicator.userId">{{adjudicator.lastName}}, {{adjudicator.firstName}}</option>
                </select>
            </div>                       
        </div>
        <div class="button-container">
            <div class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
            <div class="assign-button" [ngClass]="{'disabled': isAssignButtonDisabled() }" (click)="onAssignModalClick($event)">
                Assign
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>
