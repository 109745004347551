<form #driverLicenceInfoForm="ngForm">
    <div class="d-flex flex-column">
        <div class="heading">Recipient Details</div>
        <label class="bold-text mt-4" *ngIf="recipient?.movesLookupDate">The following recipient information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="label">Name</div>
                <div class="value">{{recipient?.firstName}} {{recipient?.lastName}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12">
                <span class="error">{{errorMessage}}</span>
            </div>
        </div>
        <div class="heading d-flex justify-content-start">
            <div class="justify-content-start">
                <div>Identification Information</div>
            </div>
            <div class="d-flex col-6 col-sm-6" *ngIf="seizureTypeId != 1">
                <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.DATA_EDITS">
                    <button *ngIf="!isIdentificationInformationEdited" class="btn-sm" (click)="editIdentificationInformationClick()">Edit</button>
                    <button *ngIf="isIdentificationInformationEdited" class="btn-sm"  (click)="saveEditedIdentificationInformation(driverLicenceInfoForm.valid)">Save</button>
                    <button *ngIf="isIdentificationInformationEdited" class="btn-sm ml-1" (click)="cancelEditedIdentificationInformation()">Cancel</button>
                </app-access-control>
            </div>
        </div>
        <div class="row">  
            <ng-container *ngIf="recipient?.recipientIdentification?.identificationTypeId != IdentificationTypes.DriversLicence">
                <div class="col-12 col-sm-4">
                    <div class="label">
                        Identified by
                    </div>
                    <div *ngIf="identificationVerifiedBy && (!isIdentificationInformationEdited || !isAllowEditIdentification)" class="value">{{identificationVerifiedBy}}</div>                            
                    <div *ngIf="!identificationVerifiedBy && (!isIdentificationInformationEdited || !isAllowEditIdentification)" class="value">-</div>
                    <div *ngIf="isIdentificationInformationEdited && isAllowEditIdentification"  class="mt-10">
                        <select id="identificationTypeId" 
                            name="identificationTypeId" 
                            #identificationTypeIdControl="ngModel"
                            [(ngModel)]="identificationTypeId"                        
                            class="form-control modal-select">
                            <option class="modal-select-option" [ngValue]="0">Select a Identification Type</option>
                            <option class="modal-select-option"                        
                                *ngFor="let identificationType of identificationTypes"                                                 
                                [ngValue]="identificationType.id">{{identificationType.name}}</option>
                        </select>
                    </div>  
                </div>
                <div *ngIf="identificationTypeId==IdentificationTypes.Other" class="col-12 col-sm-4">
                    <div class="label">
                        Identification Type
                    </div>
                    <div *ngIf="!isAllowEditIdentification && !identificationOther" class="value">-</div>   
                    <div *ngIf="!isAllowEditIdentification &&  identificationOther" class="value">{{identificationOther}}</div>     
                    <div *ngIf="isIdentificationInformationEdited && isAllowEditIdentification" class="mt-10">
                        <input #identificationOtherControl="ngModel" type="text" [(ngModel)]="identificationOther" class="form-control form-control-sm custom-text-box" id="identificationOther" 
                                name="identificationOther" placeholder="Identification Type">                                                        
                    </div>                                     
                </div>
            </ng-container>
            <div *ngIf="identificationTypeId != IdentificationTypes.DriversLicence"  class="col-12 col-sm-4">
                <div class="label">
                   ID Number <span class="label-hint">(Required)</span>
                </div>                
                <div class="value" *ngIf="!isIdentificationInformationEdited" >{{displayValue(recipient?.recipientIdentification?.identificationNumber)}}</div>            
                <div *ngIf="isIdentificationInformationEdited">
                    <div class="mt-10">
                        <input required 
                                #identificationNumberControl="ngModel" type="text" [(ngModel)]="identificationNumber" class="form-control form-control-sm custom-text-box" id="identificationNumber" 
                                name="identificationNumber" placeholder="ID Number" 
                                [ngClass]="{'is-invalid':(identificationNumberControl.touched || isSubmitClicked) && identificationNumberControl.invalid}">                                
                        <div class="invalid-feedback">
                            <div *ngIf="identificationNumberControl.errors?.required ">ID Number is required</div>                            
                        </div>
                    </div>
                </div>
            </div>          
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">
                    Driver’s Licence Number <span class="label-hint" *ngIf="this.driverLicenceClassification == 1">(Required)</span>
                </div>
                <div class="value" *ngIf="!isIdentificationInformationEdited" >{{displayValue(recipient?.recipientIdentification?.identificationNumber)}}</div>            
                <div *ngIf="isIdentificationInformationEdited">
                    <div class="mt-10">
                        <input [appAlbertaLicence]="isAlbertaDriverLicense" [appAlbertaLicenceValidator]="isAlbertaDriverLicense" 
                                [required]="driverLicenceClassification == 1 || (driverLicenceClassification == 2 && !motorVehicleIdentificationNumber)" maxlength="16" 
                                #driversLicenceNumberControl="ngModel" type="text" [(ngModel)]="identificationNumber" class="form-control form-control-sm custom-text-box" id="driverLicenceNumber" 
                                name="driverLicenceNumber" placeholder="Driver's Licence Number" 
                                [ngClass]="{'is-invalid':(driversLicenceNumberControl.touched || isSubmitClicked) && driversLicenceNumberControl.invalid}">                                
                        <div class="invalid-feedback">
                            <div *ngIf="driversLicenceNumberControl.errors?.required && driverLicenceClassification == 1">Driver's Licence Number is required</div>
                            <div *ngIf="driversLicenceNumberControl.errors?.required && driverLicenceClassification == 2">Driver's Licence Number or M.V.I.D is required</div>
                            <div *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberLength && !driversLicenceNumberControl.errors?.required">Atleast 4 digits are required</div>
                            <div *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberFormat && !driversLicenceNumberControl.errors?.required">Only digits are allowed</div>
                            <div *ngIf="driversLicenceNumberControl.errors?.invalidCheckDigit && !driversLicenceNumberControl.errors?.required">Invalid Alberta Driver's Licence Number.</div>
                        </div>
                    </div>
                </div>
            </div>            
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">MVID Number <span class="label-hint" *ngIf="this.driverLicenceClassification == 1">(Required)</span>   </div>
                <div class="value" *ngIf="!isIdentificationInformationEdited" >{{displayValue(recipient?.recipientIdentification?.motorVehicleIdentificationNumber)}}</div>
                <div *ngIf="isIdentificationInformationEdited">
                    <div class="mt-10">                        
                        <input [appMVIDNumber]="isAlbertaDriverLicense" [appMVIDNumberValidator]="isAlbertaDriverLicense" 
                                [required]= "driverLicenceClassification == 1 || (driverLicenceClassification == 2 && !identificationNumber)" maxlength="10" #mvidNumberControl="ngModel" 
                                type="text" [(ngModel)]="motorVehicleIdentificationNumber" class="form-control" id="mvidNumber" name="mvidNumber" placeholder="M.V.I.D" 
                                [ngClass]="{'is-invalid':(mvidNumberControl.touched || isSubmitClicked) && mvidNumberControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="mvidNumberControl.errors?.required && driverLicenceClassification == 1">M.V.I.D is required</div>
                            <div *ngIf="mvidNumberControl.errors?.required && driverLicenceClassification == 2">Driver's Licence Number or M.V.I.D is required</div>
                            <div *ngIf="mvidNumberControl.errors?.invalidMvidNumberLength && !mvidNumberControl.errors?.required">M.V.I.D Number must be 10 digits long</div>
                            <div *ngIf="mvidNumberControl.errors?.invalidMvidNumberFormat && !mvidNumberControl.errors?.required">Only digits are allowed</div>
                            <div *ngIf="mvidNumberControl.errors?.invalidCheckDigit && !mvidNumberControl.errors?.required">Invalid M.V.I.D Number.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">Licence Class</div>
                <div class="value" *ngIf="!isIdentificationInformationEdited">{{displayValue(licenceClass)}}</div>
                <div *ngIf="isIdentificationInformationEdited">
                    <div class="mt-10">                        
                        <select *ngIf="issuingCountryId == 40" #licenceClassControl="ngModel" class="form-control" id="licenceClass" name="licenceClass" 
                        [(ngModel)]="driversLicenceClassTypeId" placeholder="Select a Class" 
                        [ngClass]="{'is-invalid':(licenceClassControl.touched || isSubmitClicked) && licenceClassControl.errors?.defaultSelected && !isNoLicenceProduced}">
                            <option value="0">Select a Class</option> 
                            <option *ngFor="let driversLicenceClassType of driversLicenceClassTypes" [ngValue]="driversLicenceClassType.id">{{driversLicenceClassType.name}}</option>
                        </select>
                        <input *ngIf="issuingCountryId != 40" #otherLicenceClassControl="ngModel" type="text" [(ngModel)]="driversLicenceClass" 
                        class="form-control" name=otherLicenceClass id=otherLicenceClass placeholder="Licence Class" 
                        [ngClass]="{'is-invalid': !isNoLicenceProduced && (otherLicenceClassControl.touched || isSubmitClicked) && otherLicenceClassControl.invalid}">
                    </div>
                </div>
            </div>            
            <div class="col-12 col-sm-4">
                <div class="label">Issuing Authority</div>
                <div class="value" *ngIf="!isIdentificationInformationEdited">{{displayValue(licenceIssuingAuthority)}}</div>
                <div *ngIf="isIdentificationInformationEdited" style="margin-top: -15px;">
                    <app-country-province (onChangeEvent)="refreshContraventionVehicleCountryProvince($event)" [useAdminIssuingAuthorityLabels]="true" [countryProvince]="countryProvince"></app-country-province>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="label">Gender</div>
                <div class="value" *ngIf="!isIdentificationInformationEdited || !isAllowEditIdentificationBusinessUser">
                    {{recipient?.recipientIdentification?.gender?.name}}</div>
                <div *ngIf="isIdentificationInformationEdited && isAllowEditIdentificationBusinessUser">
                    <div class="mt-10">
                        <select required #genderControl="ngModel" class="form-control" id="gender" name="gender"
                            [(ngModel)]="genderId"
                            [ngClass]="{'is-invalid':(genderControl.touched || isSubmitClicked) && genderControl.invalid }">
                            <option [ngValue]="null">Select a Gender</option>
                            <option *ngFor="let genderType of genderTypes" [ngValue]="genderType.id">
                                {{genderType.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Gender is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="label">Date of Birth</div>
                <div class="value" *ngIf="!isIdentificationInformationEdited">{{recipient?.recipientIdentification?.dateOfBirth |  date:'mediumDate'}}</div>
                <div *ngIf="isIdentificationInformationEdited">
                    <div class="mt-10">
                        <input required isBirthDate #dateOfBirthControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" class="form-control" id="dateOfBirth"  name="dateOfBirth" 
                        maxlength="10" placeholder="yyyy/mm/dd" [(ngModel)]="dateOfBirth" [ngClass]="{'is-invalid':(dateOfBirthControl.touched || isSubmitClicked) && dateOfBirthControl.invalid}" />                
                        <div class="invalid-feedback">
                            <div *ngIf="dateOfBirthControl.errors?.required">Date of Birth is required</div>
                            <div *ngIf="!dateOfBirthControl.errors?.required && dateOfBirthControl.hasError('FutureDate')">Date of Birth can't be a future date</div>
                            <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && dateOfBirthControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                            <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate')  && !dateOfBirthControl.hasError('InvalidYear')">Invalid Date of Birth</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">Licence status at time of seizure</div>
                <div *ngIf="!isIdentificationInformationEdited || !isAllowEditIdentificationBusinessUser" class="value">
                    {{displayValue(licenceStatus)}}</div>
                
                <div *ngIf="isIdentificationInformationEdited && isAllowEditIdentificationBusinessUser">
                    <div class="mt-10">
                        <select #licenceStatusControl="ngModel" class="form-control" id="driversLicenceStatusTypeId"
                            name="driversLicenceStatusTypeId" [(ngModel)]="driversLicenceStatusTypeId"
                            [ngClass]="{'is-invalid':(licenceStatusControl.touched || isSubmitClicked) && licenceStatusControl.invalid }">
                            <option [ngValue]="0">Select a Licence Status</option>
                            <option *ngFor="let driverLicenceStatusType of driverLicenceStatusTypes"
                                [ngValue]="driverLicenceStatusType.id">
                                {{driverLicenceStatusType.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">Licence Expiry</div>
                <div class="value" *ngIf="!isIdentificationInformationEdited || !isAllowEditIdentificationBusinessUser">
                    {{displayValue(licenceExpiry)}}</div>
                
                <div *ngIf="isIdentificationInformationEdited && isAllowEditIdentificationBusinessUser">
                    <div class="mt-10">
                        <input bsDatepicker [bsConfig]="datePickerConfig" #identificationExpiryDateControl="ngModel" type="text"
                            [(ngModel)]="identificationExpiryDate" class="form-control" id="identificationExpiryDate"
                            name="identificationExpiryDate" placeholder="Expiry Date"
                            [ngClass]="{'is-invalid':(identificationExpiryDateControl.touched || isSubmitClicked) && identificationExpiryDateControl.invalid}">
                    </div>
                </div>
            </div>
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">Is GDL/Novice Driver</div>
                <div *ngIf="!isIdentificationInformationEdited || !isAllowEditIdentificationBusinessUser" class="value">
                    {{recipient?.recipientIdentification?.isGDLDriver != null ? (recipient?.recipientIdentification?.isGDLDriver ? "Yes" : "No") : "-"}}</div>
                <div *ngIf="isIdentificationInformationEdited && isAllowEditIdentificationBusinessUser">
                    <div class="mt-10">
                        <select #isGDLDriverControl="ngModel" class="form-control" id="isGDLDriver" name="isGDLDriver"
                            [(ngModel)]="intIsGDLDriver" (change)="onGDLDriverChange($event)"
                            [ngClass]="{'is-invalid':( isGDLDriverControl.touched || isSubmitClicked) && isGDLDriverControl.invalid }">
                            <option value="0">Select if Driver is in the GDL Program?</option>
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="label">Eye Color</div>
                <div class="value">{{displayValue(recipient?.eyeColor)}}</div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="label">Hair Color</div>
                <div class="value">{{displayValue(recipient?.hairColor)}}</div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="label">Height</div>
                <div class="value">{{displayValue(recipient?.height)}}</div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="label">Weight</div>
                <div class="value">{{displayValue(recipient?.weight)}}</div>
            </div>
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12 col-sm-4">
                <div class="label">Condition Codes</div>
                <div class="value">{{displayValue(conditionCodes)}}</div>
            </div>
            <div *ngIf="identificationTypeId == IdentificationTypes.DriversLicence" class="col-12">
                <div class="label">Restrictions</div>
                <div class="value">{{displayValue(recipient?.recipientIdentification?.restrictions)}}</div>
            </div>
        </div>
    </div>
</form>
<form #recipientContactInfoForm="ngForm">
    <div class="d-flex flex-column">
        <div class="row">
            <div class="col-12 col-sm-12">
                <span class="error">{{errorMessageContactInfo}}</span>
            </div>
        </div>
        <div class="heading d-flex justify-content-start">
            <div class="justify-content-start">
                <div>Recipient contact details</div>
            </div>
            <div class="d-flex col-6 col-sm-6" *ngIf="seizureTypeId != 1">
                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                    <div class="justify-content-start">
                        <button *ngIf="!isRecipientContactInformationEdited && isAllowEditIdentificationBusinessUser"
                            class="btn-sm" (click)="editReceipentContactClick()">Edit</button>
                        <button *ngIf="isRecipientContactInformationEdited" class="btn-sm"
                            (click)="saveReceipentContactClick(recipientContactInfoForm.valid)">Save</button>
                        <button *ngIf="isRecipientContactInformationEdited" class="btn-sm ml-1"
                            (click)="cancelReceipentContactClick()">Cancel</button>
                    </div>
                </app-access-control>
            </div>
        </div>
        <div *ngIf="!isRecipientContactInformationEdited">
            <div *ngIf="isRecipientReadOnly() && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                <app-section-details sectionTitle=""
                    [sectionDetails]="movesRecipientContactInformation"></app-section-details>

                <label class="bold-text mt-4">The following contact information was provided by the recipient to the
                    officer</label>
                <app-section-details sectionTitle=""
                    [sectionDetails]="recipientContactInformation"></app-section-details>
            </div>

            <ng-container *ngIf="(isRecipientReadOnly() && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !isRecipientReadOnly()">
                <app-section-details sectionTitle=""
                    [sectionDetails]="recipientContactInformation"></app-section-details>
            </ng-container>
        </div>

        <div *ngIf="isRecipientContactInformationEdited">
            <div *ngIf="isRecipientReadOnly() && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                <app-section-details sectionTitle="" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                <label class="bold-text mt-4">The following contact information was provided by the recipient to the
                    officer</label>
            </div>

            <ng-container>   
                <div class="">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Address 1 <span class="label-hint">(Required)</span>
                                </div>

                                <div class="mt-10">
                                    <input [required]="true" maxlength="50" #address1Control="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.addressLine1"
                                        class="form-control form-control-sm custom-text-box" id="address1"
                                        name="address1" placeholder="Address 1"
                                        [ngClass]="{'is-invalid':(address1Control.touched || isSubmitRecipientContactClicked) && address1Control.invalid}" />
                                    <div class="invalid-feedback">
                                        <div *ngIf="address1Control.errors?.required">Address 1 is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Address 2
                                </div>

                                <div class="mt-10">
                                    <input maxlength="50" #address2Control="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.addressLine2"
                                        class="form-control form-control-sm custom-text-box" id="address2"
                                        name="address2" placeholder="Address 2"
                                        [ngClass]="{'is-invalid':(address2Control.touched || isSubmitRecipientContactClicked) && address2Control.invalid}" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    City <span class="label-hint">(Required)</span>
                                </div>

                                <div class="mt-10">
                                    <input [required]="true" maxlength="16" #cityControl="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.city"
                                        class="form-control form-control-sm custom-text-box" id="city" name="city"
                                        placeholder="City"
                                        [ngClass]="{'is-invalid':(cityControl.touched || isSubmitRecipientContactClicked) && cityControl.invalid}" />
                                    <div class="invalid-feedback">
                                        <div *ngIf="cityControl.errors?.required">City is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Country <span class="label-hint">(Required)</span>
                                </div>

                                <div class="mt-10">
                                    <select required #countryControl="ngModel" class="form-control" id="country"
                                        name="country"
                                        [disabled]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        (change)="onCountryChange()" [(ngModel)]="countryId"
                                        [ngClass]="{'is-invalid':(countryControl.touched || isSubmitRecipientContactClicked) && countryControl.invalid }">
                                        <option [ngValue]="null">Select a Country</option>
                                        <option *ngFor="let country of countryTypes" [ngValue]="country.id">
                                            {{country.name}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        <div *ngIf="countryControl.errors?.required">Country is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Province <span class="label-hint">(Required)</span>
                                </div>

                                <div class="mt-10">
                                    <select [required]="true" #provinceControl="ngModel" class="form-control"
                                        id="province" *ngIf="countryId == 40 || countryId == 236"
                                        [disabled]="(isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES) || !countryId"
                                        name="province" (change)="onCountryChange()" [(ngModel)]="provinceId"
                                        [ngClass]="{'is-invalid':(provinceControl.touched || isSubmitRecipientContactClicked) && provinceControl.invalid }">
                                        <option [ngValue]="null">Select a Province/State</option>
                                        <option *ngFor="let province of provinceTypes" [ngValue]="province.id">
                                            {{province.name}}</option>
                                    </select>
                                    <input
                                        [disabled]="(isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES) || !countryId"
                                        [required]="true" maxlength="30" #otherProvinceControl="ngModel"
                                        *ngIf="!(countryId == 40 || countryId == 236)" type="text"
                                        [(ngModel)]="recipient.recipientContact.province"
                                        class="form-control" name="otherprovince" id="otherprovince"
                                        placeholder="Province/State" (keyup)="onProvinceTextChange($event)"
                                        [ngClass]="{'is-invalid': (otherProvinceControl.touched || isSubmitRecipientContactClicked) && otherProvinceControl.invalid }">
                                    <div class="invalid-feedback">
                                        <div *ngIf="provinceControl?.errors?.required">Province is required</div>
                                    </div>
                                    <div class="invalid-feedback">
                                        <div *ngIf="otherProvinceControl?.errors?.required">Province is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Postal Code <span class="label-hint">(Required)</span>
                                </div>

                                <div class="mt-10">
                                    <input [required]="true" maxlength="16" #postalCodeControl="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.postalCode"
                                        class="form-control text-uppercase" id="postalCode" name="postalCode"
                                        placeholder="Postal Code" maxlength="15"
                                        [ngClass]="{'is-invalid':(postalCodeControl.touched || isSubmitRecipientContactClicked) && postalCodeControl.invalid}" />
                                    <div class="invalid-feedback">
                                        <div *ngIf="postalCodeControl.errors?.required">Postal Code is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Residence Phone
                                </div>

                                <div class="mt-10">
                                    <input maxlength="16" #residencePhoneControl="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.phoneNumber1"
                                        class="form-control form-control-sm custom-text-box" id="residencePhone"
                                        name="residencePhone" placeholder="Residence Phone"
                                        [ngClass]="{'is-invalid':(residencePhoneControl.touched || isSubmitRecipientContactClicked) && residencePhoneControl.invalid}" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Cell Phone
                                </div>

                                <div class="mt-10">
                                    <input maxlength="16" #cellPhoneControl="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.phoneNumber2"
                                        class="form-control form-control-sm custom-text-box" id="cellPhone"
                                        name="cellPhone" placeholder="Cell Phone"
                                        [ngClass]="{'is-invalid':(cellPhoneControl.touched || isSubmitRecipientContactClicked) && cellPhoneControl.invalid}" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Business Phone
                                </div>

                                <div class="mt-10">
                                    <input maxlength="16" #businessPhoneControl="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.phoneNumber3"
                                        class="form-control form-control-sm custom-text-box" id="businessPhone"
                                        name="businessPhone" placeholder="Business Phone"
                                        [ngClass]="{'is-invalid':(businessPhoneControl.touched || isSubmitRecipientContactClicked) && businessPhoneControl.invalid}" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Email Address
                                </div>

                                <div class="mt-10">
                                    <input maxlength="50" #emailAddressControl="ngModel" type="text"
                                        [readonly]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.emailAddress"
                                        class="form-control form-control-sm custom-text-box" id="emailAddress"
                                        name="emailAddress" placeholder="Email Address"
                                        [ngClass]="{'is-invalid':(emailAddressControl.touched || isSubmitRecipientContactClicked) && emailAddressControl.invalid}" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    Address Different from Moves
                                </div>

                                <div class="mt-10">
                                    <input type="checkbox" id="addressdifferentfromMoves"
                                        name="addressdifferentfromMoves" [disabled]="!isRecipientReadOnly()"
                                        [(ngModel)]="recipient.recipientContact.isAddressDifferentFromMOVES"
                                        (change)="onAddressDifferentFromMovesChange()">
                                </div>
                            </div>

                            <div class="col-12 col-sm-4">
                                <div class="label">
                                    No Fixed Address
                                </div>
                                
                                <div class="mt-10">
                                    <input type="checkbox" id="noFixedAddress" name="noFixedAddress"
                                        [disabled]="isRecipientReadOnly() && !recipient.recipientContact.isAddressDifferentFromMOVES"
                                        [(ngModel)]="recipient.recipientContact.isNoFixedAddress">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>
