import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Event } from '@apis/shared/models/event.model';
import { EventTypes, RequestTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { Constants } from "@apis/shared/helpers/constants";
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { KeycloakService } from 'keycloak-angular';
import { PoliceServiceInformation } from '@apis/shared/models/police-service-information.model';
import { LogEntry } from '@apis/shared/models/log-entry.model';
import { LogSearchRequestModel } from '../../../shared/models/log-search-request.model';

@Component({
  selector: 'contravention-activity',
  templateUrl: './contravention-activity.component.html',
  styleUrls: ['./contravention-activity.component.scss']
})
export class ContraventionActivityComponent implements OnInit {
  @Input() contravention: Contravention;
  @Output() activityNoteSubmitted = new EventEmitter<Contravention>();

  recentActivities: any[] = [];  
  TypeTable = TypeTable;
  EventTypes = EventTypes;
  username: string;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  RequestTypes:RequestTypes;
  requestType:number;
  datePipe: DatePipe;

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly adjudicationService: AdjudicationService,
    readonly viewContainerRef: ViewContainerRef,
    private readonly keycloakService: KeycloakService,
    private readonly documentService: DocumentService) { }

  ngOnInit(): void {      
    this.datePipe = new DatePipe("en-US");
    this.username = this.keycloakService.getUsername();
  }

  ngOnChanges(): void {    
    if(this.contravention != null) {
      this.populateRecentActivity();
    }
  }

  public populateRecentActivity(logs?: LogEntry[]): void {
    this.recentActivities = [];
    
    this.setContraventionSubmittedRecentActivity();                

    //Add all review events
    let events = this.contravention.events;    
    this.pushEventsToActivities(events);

    this.pushDriverLogsToActivities(logs);

    this.recentActivities = this.recentActivities?.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });    

    this.setOffSetTopForContraventionRecentActivity();
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.username
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.contraventionId = this.contravention.contraventionId;
    componentRef.instance.documentRefTypeNumber = this.contravention.contraventionNumber;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.contravention.events.push(result);
        this.populateRecentActivity();    
        this.activityNoteSubmitted.emit(this.contravention);    
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.contravention.contraventionNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.contravention.events = this.contravention.events.filter(x=>x.eventId !== eventId);
        this.contravention.events.push(result);
        this.populateRecentActivity();        
        this.activityNoteSubmitted.emit(this.contravention);    
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {    
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.contravention.contraventionNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.contravention.events = this.contravention.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();        
        this.activityNoteSubmitted.emit(this.contravention);    
      }
      this.viewContainerRef.clear();
    });
  }

  onRecentActivityDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Events", this.contravention.contraventionNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onDriverLogsSelectionChanged(result: any): void {
    this.populateRecentActivity(result);
  }

  getDriverLogSearchRequest = () => {
    var searchRequest = new LogSearchRequestModel();

    var searchCriteria = this.contravention.documents.map(d => d.contentGuid);
    searchCriteria.push(this.contravention.contraventionNumber);

    searchRequest.searchCriteria = searchCriteria;
    searchRequest.fromDate = this.contravention.submissionDate;

    return searchRequest;
  }

  isCancelledByLEArequest(recentActivity):Boolean
  {
      if(recentActivity.eventTypeId == EventTypes.ContraventionCancelled && recentActivity.noticeCancellationRequestId)
      {
        this.requestType = RequestTypes.LEACancellationRequest;
         return true;           
      }
      return false;
  }

  private pushDriverLogsToActivities(logs: LogEntry[])
  {
    if (logs) {
      for (let log of logs) {          
        let eventDateTime = new Date(log.timeStamp.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
        let username = log.userId;
        let title = log.title;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          id: log.id,
          isFromDriverLogs: true
        });
      }
    }
  }

  private pushEventsToActivities(events: Event[])
  {
    if (events)
    {
      for (let event of events) {          
        let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
        let username = event.userName;
        let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType)
        let body = event.eventDetails;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          body: body,
          documents: event.documents,
          id: event.eventId,
          isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
        });
      }
    }
  }

  public setOffSetTopForContraventionRecentActivity() {
    setTimeout(() => {  
      if ($(".contravention-recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".contravention-recent-activity-container:last-child")[0].offsetTop;    
          $(".contravention-recent-activity-connector").height(offsetTop);
          $(".contravention-recent-activity-group").animate({ scrollTop: 0 }, "fast");
        }
      }, 100);      
  }

  private setContraventionSubmittedRecentActivity(): void {
    let policeServiceInformation: PoliceServiceInformation;

    if (this.contravention?.policeServiceInformation?.length > 0) {
      policeServiceInformation = this.contravention.policeServiceInformation[0];
    }

    let submissionDateTime = new Date(this.contravention.submissionDate);
    let timeComponents = this.contravention.submissionTime.split(":");        
    timeComponents.forEach((c: string, index: number) => {
      if (index == 0) submissionDateTime.setHours(parseInt(c));
      if (index == 1) submissionDateTime.setMinutes(parseInt(c));
      if (index == 2) submissionDateTime.setSeconds(parseInt(c));      
    });

    let submissionDate = this.datePipe.transform(submissionDateTime.toString(), "mediumDate");
    let submissionTime = this.datePipe.transform(submissionDateTime.toString(), "HH:mm");

    let submittedBy = this.contravention.policeOfficerFullName;
    let policeService = this.localStorageService.getAgencies().find(x => x.agencyId == policeServiceInformation?.detachment?.agencyId)?.agencyName;

    this.recentActivities.push({ 
      date: `${submissionDate} at ${submissionTime}`, 
      dateTime: new Date(`${submissionDate} ${submissionTime}`),
      by: `${submittedBy} (${policeService})`,
      title: this.localStorageService.getTypeItemDescriptionById(EventTypes.ContraventionSubmitted, TypeTable.EventType), 
      body: null
    });
  }
}
