import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { SchedulerService } from '../../../../shared/services/scheduler.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes, MeetingTypes } from '@apis/shared/enums/app.enum';
import { Review } from '@apis/shared/models/review.model';
import { ReviewService } from 'apps/adjudication/src/app/shared/services/review.service';
import { Event } from "@apis/shared/models/event.model";
import { AvailabilityType } from '@apis/shared/models/types/availability-type.model';
import { Constants } from '@apis/shared/helpers/constants';
import { parseJSON } from 'date-fns';

@Component({
  selector: 'app-schedule-event',
  templateUrl: './schedule-event.component.html',
  styleUrls: ['./schedule-event.component.scss']
})
export class ScheduleEventComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;  
  dateCustomClasses: DatepickerDateCustomClasses[];

  startTime: string;
  endTime: string;

  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;

  adjudicators: User[] = [];

  availableAdjudicators: User[] = [];
  
  datePipe: DatePipe = new DatePipe("en-US");

  scheduledEvent: ScheduledEvent;

  availabilityTypes: AvailabilityType[];

  orgAdjudicatorId = 0;
  isAdjudicatorChanged = false;

  meetingTypes = MeetingTypes;

  @Input() showAdjudicator: boolean;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    readonly localStorageService: LocalStorageService,    
    private readonly spinner: NgxSpinnerService,
    private readonly schedulerService: SchedulerService,
    private readonly reviewService: ReviewService) {
    this.datePickerConfig = Object.assign({}, 
      {
        containerClass: 'theme-dark-blue', 
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });
      
    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
   }

  ngOnInit(): void {
    this.adjudicators = this.localStorageService.getAdjudicators().filter(u => u.isDisabled != true); 
    this.availabilityTypes = this.localStorageService.getAvailabilityTypes();
  }

  onSubmit(isValid: boolean) {
    if (isValid) {
      if (this.scheduledEvent.isAllDay) {
        this.scheduledEvent.endDate = this.scheduledEvent.startDate;
      }

      const startTimeHour = +this.startTime.split(':')[0];
      const startTimeMin = +this.startTime.split(':')[1];
      const endTimeHour = +this.endTime.split(':')[0];
      const endTimeMin = +this.endTime.split(':')[1];

      // Set start and end time
      this.scheduledEvent.startDate = new Date(this.scheduledEvent.startDate.setHours(startTimeHour, startTimeMin, 0));
      this.scheduledEvent.endDate = new Date(this.scheduledEvent.endDate.setHours(endTimeHour, endTimeMin, 0));

      if (!this.showAdjudicator) {
        // Set the UserId to current logged in UserId.
        const adjUser = this.localStorageService.getAdjudicationUser();

        const user = this.localStorageService.getAdjudicators().find(u => u.emailAddress == adjUser.emailAddress);

        this.scheduledEvent.userId = user.userId;
      }

      this.saveScheduledEvent();
    }
  }

  onDeleteClick() {
    if (+this.scheduledEvent.scheduledEventId > 0 && this.scheduledEvent.meetingTypeId == null) {
      this.spinner.show();
      this.schedulerService.deleteScheduledEvent(this.scheduledEvent.scheduledEventId)
        .subscribe((result: any) => {
          this.spinner.hide();
          this.close.emit(result);
        }, (error: any) => this.spinner.hide());    
    }
  }

  onCancelClick() {
    this.close.emit();
  }

  onCloseClick() {
    this.close.emit();
  }

  onSaveClick() {
    if (+this.scheduledEvent.meetingTypeId > 0)
    {
      this.reassignAdjudicator();
    } else {
      this.saveScheduledEvent();
    }
  }

  onAllDayCheckClicked(isChecked: boolean) {
    if (isChecked) {
      this.startTime = '08:00';
      this.endTime = '16:00';
    } else {
      this.startTime = '';
      this.endTime = '';
      this.scheduledEvent.endDate = null;
    }
  }

  onAdjudicatorChange(e) {
    this.isAdjudicatorChanged = this.scheduledEvent.userId != this.orgAdjudicatorId;
  }

  trackAvailableAdjudicator(index: number, obj: any): any {
    return index;
  }

  getAvailableAdjudicators() {
    this.schedulerService.getAvailableAdjudicators(      
      this.scheduledEvent.startDate,
      this.scheduledEvent.endDate,
      this.scheduledEvent.meetingTypeId,
      this.scheduledEvent.complexity)
    .subscribe((result: any[]) => {
      this.availableAdjudicators = this.adjudicators.filter(a => result.includes(a.userId.toString())
       || a.userId == this.scheduledEvent.userId);
    }, (error: any) => this.spinner.hide());    
  }

  saveScheduledEvent() {
    this.spinner.show();

    if (this.scheduledEvent.scheduledEventId == 0) {
      this.schedulerService.postScheduledEvent(this.scheduledEvent)
        .subscribe((result: ScheduledEvent) => {
          this.spinner.hide();
          this.close.emit(result);
        }, (error: any) => this.spinner.hide());    
    } else {
      this.schedulerService.putScheduledEvent(this.scheduledEvent)
        .subscribe((result: ScheduledEvent) => {
          this.spinner.hide();
          this.close.emit(result);
        }, (error: any) => this.spinner.hide());    
    }
  }

  reassignAdjudicator() {
    this.spinner.show();

    const event = new Event({
      eventTypeId: EventTypes.AdjudicatorReassigned,
      eventDetails: this.getAdjudicatorAssignedName()
    });

    const review = new Review({
      reviewNumber: this.scheduledEvent.recordNumber,
      userId: +this.scheduledEvent.userId
    });

    review.events.push(event);

    this.reviewService.reassignAdjudicator(review)
      .subscribe(
        (result: Review) => { 
          this.spinner.hide();
          this.close.emit(result);
        }, (error: any) => this.spinner.hide());  
  }

  loadScheduledEvent(eventId: number) {
    if (eventId == null || eventId == 0) {
      this.scheduledEvent = new ScheduledEvent();

      this.scheduledEvent.userId = null;
    } else {
      this.spinner.show();
      this.schedulerService.getScheduledEvent(eventId)
        .subscribe((result: ScheduledEvent) => {
            this.spinner.hide();
            
            this.scheduledEvent = result;
            
            this.scheduledEvent.startDate = new Date(this.scheduledEvent.startDate);      
            this.scheduledEvent.endDate = new Date(this.scheduledEvent.endDate);

            this.startTime = this.datePipe.transform(this.scheduledEvent.startDate, "HH:mm");
            this.endTime = this.datePipe.transform(this.scheduledEvent.endDate, "HH:mm");

            if (this.scheduledEvent.createdOn) {
              this.scheduledEvent.createdOn = parseJSON(this.scheduledEvent.createdOn);
            }

            if (this.scheduledEvent.updatedOn) {
              this.scheduledEvent.updatedOn = parseJSON(this.scheduledEvent.updatedOn);
            }

            if (+this.scheduledEvent.meetingTypeId > 0) {
              this.orgAdjudicatorId = this.scheduledEvent.userId;
              this.getAvailableAdjudicators();
            }
        }, (error: any) => this.spinner.hide());    
    }
  }

  private getAdjudicatorAssignedName(): string {
    const user = this.adjudicators.find(adjudicator => +adjudicator.userId === +this.scheduledEvent.userId);
    if (user != null) {
      return `${user.firstName} ${user.lastName}`;
    }
  }
}
