import { formatDate } from "@angular/common";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import * as moment from "moment";
import * as fileSaver from "file-saver";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSpinnerService } from "ngx-spinner";
import { ReportService } from "../../shared/services/report.service";

@Component({
  selector: 'app-contraventions-extract-cancellation-report',
  templateUrl: './contraventions-extract-report.component.html',
  styleUrls: ['./contraventions-extract-report.component.scss']
})

export class ContraventionsExtractReportComponent {
  datePickerConfig: Partial<BsDatepickerConfig>;
  startDatePickerConfig: Partial<BsDatepickerConfig>;
  endDatePickerConfig: Partial<BsDatepickerConfig>;
  startDate: Date;
  endDate: Date;
  fromDate: string;
  toDate: string;
  constructor(
    private readonly reportService: ReportService,
    private readonly spinner: NgxSpinnerService) {

    this.datePickerConfig = Object.assign({},
    {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
        minDate: new Date(1900, 0, 1), // 1900/01/01 - Month is 0 based index
        maxDate: new Date(2099, 11, 31), // 2099/12/31 - Month is 0 based index
        isAnimated: true
    })

    this.startDatePickerConfig = Object.assign({},
    {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
        minDate: new Date(2020, 11, 1), //Month is zero based index so 11 is December
        isAnimated: true
    })
    this.endDatePickerConfig = Object.assign({},
    {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'YYYY/MM/DD',
        minDate: new Date(2020, 11, 1), //Month is zero based index so 11 is December
        isAnimated: true
    })
  }
  
  onDownloadClick(form: NgForm) {
    this.startDate = form.controls.startDate.value;
    this.endDate = form.controls.endDate.value;
 
    if (this.startDate > this.endDate) {
      return;
    }

    this.fromDate = (moment(this.startDate)).format('YYYY-MM-DD');
    this.toDate = (moment(this.endDate)).format('YYYY-MM-DD');

    this.spinner.show();
    setTimeout(() => {
      let element = $(".loading-text > p");
      element.html(`<span style='font-size: 17px !important;'>Loading...</span>`);
    }, 100);

    this.reportService.downloadContraventionsExtractReport(this.fromDate, this.toDate)
      .subscribe((result: HttpEvent<Blob>) => {
        if (result.type == HttpEventType.Response) {
          this.spinner.hide();
          fileSaver.saveAs(result.body, "APIS-Contraventions-Extract-Report.csv");
        }
      }, (error: any) => this.spinner.hide());
  }
}