


export class Notification {
    public notificationId: number = 0;
    public reviewId: number;
    public lateReviewRequestId: number;
    public paymentAdditionalTimeRequestId: number;
    public noticeCancellationRequestId: number;
    public notificationTypeId: number = 0;
    public contactedBy: string;
    public contactMethodTypeId: number;
    public notificationDueDate: Date;
    public notificationDate: Date;

    constructor(init?: Partial<Notification>) {
        
        Object.assign(this, init);
    }
}
