import { Document } from './document.model';

export class StayOrder {        
        public stayOrderId :number =0;
        public judicialReviewId : number;
        public effectiveDate: Date;
        public expirationDate: Date;     
        public stayOrderStatusTypeid :number;
        public stayOrderCancelledDate:Date;
        public stayOrderCourtActionNumber: string;    
        public documents: Document[] = [];
        public tempFolderName: string; 

    constructor(init?: Partial<StayOrder>) {
        
        Object.assign(this, init);
    }
}