import { Component, ViewChild, OnInit, ViewContainerRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { TypeTable } from "@apis/shared/enums/type-table.enum";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Contravention } from '@apis/shared/models/contravention.model';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { User } from '@apis/shared/models/user.model';
import * as $ from "jquery";
import { Form, FormControl, NgForm, NgModel, Validators } from '@angular/forms';
import { ApplicantAvailabilityModalComponent } from './modals/applicant-availability-modal/applicant-availability-modal.component';
import { NoteModalComponent } from '@apis/shared/components/modals/note-modal/note-modal.component';
import { ChangeReviewStatusModalComponent } from './modals/change-review-status-modal/change-review-status-modal.component';
import { ReviewService } from '../shared/services/review.service';
import { AdjudicationService } from '../shared/services/adjudication.service';
import { ContactMethodTypes, EventTypes, NotificationTypes, RepresentationTypes, RequestDecisionTypes, RequestStatusTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes, SeizureTypes } from '@apis/shared/enums/app.enum';
import { KeycloakService } from 'keycloak-angular';
import { Notification } from '@apis/shared/models/notification.model';
import { ReassignAdjudicatorModalComponent } from './modals/reassign-adjudicator-modal/reassign-adjudicator-modal.component';
import { ChangeContactMethodModalComponent } from './modals/change-contact-method-modal/change-contact-method-modal.component';
import { ContactAddress } from '@apis/shared/models/contact-address.model';
import { ChangeDecisionDueDateModalComponent } from './modals/change-decision-due-date-modal/change-decision-due-date-modal.component';
import { CancelReviewModalComponent } from './modals/cancel-review-modal/cancel-review-modal.component';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from "file-saver";
import { DateUtil } from "@apis/shared/helpers/date-util";
import { RequestService } from '../shared/services/request.service';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { ChangeReviewMethodModalComponent } from './modals/change-review-method-modal/change-review-method-modal.component';
import { RescheduleReviewModalComponent } from './modals/reschedule-review-modal/reschedule-review-modal.component';
import { TimePipe } from '@apis/shared/pipes/time.pipe';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ReviewSchedule } from '@apis/shared/models/review-schedule.model';
import { NgxSpinnerService } from "ngx-spinner";
import { DeleteNoteModalComponent } from '@apis/shared/components/modals/delete-note-modal/delete-note-modal.component';
import { CommonMethods } from '../shared/helpers/common-methods';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { LawEnforcementSubmissionsComponent } from './components/law-enforcement-submissions/law-enforcement-submissions.component';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { ReviewActivityComponent } from './components/review-activity/review-activity.component';
import { JudicialReviewServedModalComponent } from './modals/judicial-review-served-modal/judicial-review-served-modal.component';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ReviewLastViewedModalComponent } from './modals/review-last-viewed-modal/review-last-viewed-modal.component';
import { parseJSON } from 'date-fns';

@Component({
  selector: 'review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss']
})
export class ReviewDetailsComponent implements OnInit {
  today: Date = new Date();
  reviewNumber: string;
  review: Review;  
  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  stopInformation: StopInformation;

  supportingDocuments: any;
  recentActivities: any[] = [];     
  datePipe: DatePipe;
  timePipe: TimePipe;
  datePickerConfig: Partial<BsDatepickerConfig>;  
  adjudicators: User[] = [];  
  scheduleFormSubmitted: boolean = false;  
  dateCustomClasses: DatepickerDateCustomClasses[];
  manageReviewDropdown: HTMLSelectElement;  
  reviewMethodTypeName: string;  
  reviewTypeName: string;
  applicantName: string;
  representationName: string;
  username: string;
  reviewDateTime: Date;
  reviewDateUtc: Date;
  ReviewTypes = ReviewTypes;
  isLateReviewRequestApproved: boolean = false;
  lateReviewRequestApprovalDate: Date;
  isAnotherSeizureExists: boolean = false;
  anotherSeizureTypeId: number;
  anotherSeizureStatus: string;
  SeizureTypes = SeizureTypes;

  conflictingReviewTime: string;
  selectedAdjudicator: string;    
  
  EventTypes = EventTypes;
  TypeTable = TypeTable;
  loggedUserName: string;
  isRecipientYouth: boolean = false;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  canChangeReviewStatus: boolean =false;
  canChangeContactMethod: boolean =false;
  canReassignAdjudicator: boolean =false;
  canChangeReviewMethod: boolean =false;
  canRescheduleReview: boolean =false;
  canCancelReview: boolean =false;
  canChangeDecisionDueDate: boolean =false;
  canReviewActivity:boolean=false;
  canServeJudicialReview:boolean =false;
  scheduleForm: NgForm;
  reviewRequestDateTime: Date;
  @ViewChild("scheduleForm") set tempScheduleForm(tempScheduleForm: NgForm) {
    if (tempScheduleForm) {
      this.scheduleForm = tempScheduleForm;
    }
  };
    
  reviewDate: NgModel;
  @ViewChild("reviewDate") set tempReviewDate(tempReviewDate: NgModel) {
    if (tempReviewDate) {      
      this.reviewDate = tempReviewDate;
      this.reviewDate.valueChanges.subscribe((value: any) => {
        this.conflictingReviewTime = null;
      });
    }
  }

  reviewTime: NgModel;
  @ViewChild("reviewTime") set tempReviewTime(tempReviewTime: NgModel) {
    if (tempReviewTime) {
      this.reviewTime = tempReviewTime;
      this.reviewTime.valueChanges.subscribe((value: any) => {
        this.conflictingReviewTime = null;
      });      
    }
  }
  
  userId: NgModel;
  @ViewChild("userId") set tempUserId(tempUserId: NgModel) {
    if (tempUserId) { 
      this.userId = tempUserId;           
      this.userId.control.setValidators([Validators.required]);
      this.userId.control.valueChanges.subscribe((value: any) => {
        this.conflictingReviewTime = null;
        this.selectedAdjudicator = this.adjudicators.find(a => +a.userId == +value)?.firstName;        
      });
    }
  }
  
  scheduleButton: HTMLButtonElement;
  @ViewChild("scheduleButton") set tempScheduleButton(tempScheduleButton: ElementRef<HTMLButtonElement>) { 
    if (tempScheduleButton) {
      this.scheduleButton = tempScheduleButton.nativeElement as HTMLButtonElement;
    }
  }

  @ViewChild("leaSubmissions") leaSubmissionsComponent: LawEnforcementSubmissionsComponent;
  
  @ViewChild("reviewActivity") reviewActivityComponent: ReviewActivityComponent;
            
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly reviewService: ReviewService,
    private readonly requestService: RequestService,
    private readonly adjudicationService: AdjudicationService,
    private readonly documentService: DocumentService,
    readonly localStorageService: LocalStorageService,    
    readonly viewContainerRef: ViewContainerRef,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { 
      
      this.datePickerConfig = Object.assign({}, 
        {
          containerClass: 'theme-dark-blue', 
          showWeekNumbers: false,
          dateInputFormat: 'MMM DD, YYYY',
          isAnimated: true
        });
        
      this.dateCustomClasses = [
        { date: new Date(), classes: ["bg-secondary", "text-white"] }
      ];
    }
  
  ngOnInit(): void { 
    this.adjudicators = this.localStorageService.getAdjudicators(); 
    this.username = this.keycloakService.getUsername();
    this.manageReviewDropdown = (document.getElementById("manageReview") as HTMLSelectElement);                    
    this.datePipe = new DatePipe("en-US");
    this.timePipe = new TimePipe();

    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canReassignAdjudicator = this.localStorageService.hasPermission(Constants.Resource.REVIEW, Constants.Permission.REASSIGN_ADJUDICATOR);
      this.canChangeReviewStatus = this.localStorageService.hasPermission(Constants.Resource.REVIEW, Constants.Permission.CHANGE_REVIEW_STATUS);
      this.canReassignAdjudicator = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.REASSIGN_ADJUDICATOR);
      this.canChangeContactMethod = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.CHANGE_CONTACT_METHOD);
      this.canChangeReviewMethod = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.CHANGE_REVIEW_METHOD);
      this.canRescheduleReview = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.RESCHEDULE_REVIEW);
      this.canChangeDecisionDueDate = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.CHANGE_DECISION_DUE_DATE);
      this.canCancelReview = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.CANCEL_REVIEW);
      this.canReviewActivity = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.REVIEW_ACTIVITY);
    });

    this.activatedRoute.params.subscribe((data: Params) => {
      this.reviewNumber = data.reviewNumber;
      this.spinner.show();        
      this.reviewService.getReviewByNumber(this.reviewNumber)
        .subscribe((review: Review) => {
          this.loadData(review);
          if (review.reviewItems[0].contraventionId != null) {
            this.adjudicationService.getContraventionById(review.reviewItems[0].contraventionId)
              .subscribe((contravention: Contravention) => {
                this.contravention = contravention;
                this.isRecipientYouth = this.contravention.isRecipientYouth;
                if (this.contravention.vehicleSeizure)
                {
                  this.isAnotherSeizureExists = this.contravention.vehicleSeizure.isAnotherSeizureExists;
                  if(this.isAnotherSeizureExists)
                  {
                    this.anotherSeizureTypeId = this.contravention.vehicleSeizure.anotherSeizureTypeId;
                    this.anotherSeizureStatus = this.localStorageService.getTypeItemDescriptionById(this.contravention.vehicleSeizure.anotherSeizureStatusTypeId, TypeTable.SeizureStatusType)?.toUpperCase();
                  }
                }    
                
                this.adjudicationService.getStopInformation(this.contravention?.contraventionNumber)
                .subscribe((stopInformation: StopInformation) => {        
                  this.stopInformation = stopInformation;
                  this.populateRecentActivity();
                  this.setApplicantName();
                  this.CheckIfLateReviewExists();
                });          
              });              
          }

          if (review.reviewItems[0].vehicleSeizureId != null) {
            this.adjudicationService.getSeizureByNumber(review.reviewItems[0].recordNumber)
              .subscribe((vehicleSeizure: VehicleSeizure) => {
                if (vehicleSeizure.seizureTypeId == 1) // IRS Contravention Seizure          
                  this.getAssociatedContravention(vehicleSeizure.contraventionId);

                this.vehicleSeizure = vehicleSeizure;
                this.isRecipientYouth = this.vehicleSeizure.isRecipientYouth;
                this.isAnotherSeizureExists = this.vehicleSeizure.isAnotherSeizureExists;
                if(this.isAnotherSeizureExists)
                {
                  this.anotherSeizureTypeId = this.vehicleSeizure.anotherSeizureTypeId;
                  this.anotherSeizureStatus = this.localStorageService.getTypeItemDescriptionById(this.vehicleSeizure.anotherSeizureStatusTypeId, TypeTable.SeizureStatusType)?.toUpperCase();
                }     
                this.populateRecentActivity();           
                this.setApplicantName();  
                this.CheckIfLateReviewExists();
              });
          }

          const adjudicationUser = this.localStorageService.getAdjudicationUser();
          const userName = adjudicationUser.firstName + ' ' + adjudicationUser.lastName;

          // If the review has recently been viewed by another user, display ReviewLastViewedModal
          if (review.lastViewedAdjudicatorName && review.lastViewedAdjudicatorDate) {
            if (review.lastViewedAdjudicatorName !== userName) {
              const millisecondsSinceLastViewed = new Date().getTime() - parseJSON(review.lastViewedAdjudicatorDate).getTime();
              const minutesSinceLastViewed = millisecondsSinceLastViewed / 60000;
              if (minutesSinceLastViewed < Constants.Adjudication.REVIEW_LAST_VIEWED_MODAL_EXPIRES_MINUTES) {
                this.showReviewLastViewedModal();
              }
            }
          }

          // Review method cannot be changed on seizure reviews
          if (this.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview || this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
            this.canChangeReviewMethod = false;
          }

          // Senior Adjudicator cannot change review method, or reschedule an oral review
          if (this.keycloakService.isUserInRole(Constants.Role.SENIOR_ADJUDICATOR)) {
            this.canChangeReviewMethod = false;

            if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral) {
              this.canRescheduleReview = false;
            }
          }

          // Update last viewed user information
          review.lastViewedAdjudicatorName = userName;
          this.reviewService.updateLastViewedAdjudicator(review).subscribe();

          this.spinner.hide();
        });
    });
  }

  canModifyNote(recentActivity: any): boolean {
    return recentActivity.title == this.localStorageService.getTypeItemDescriptionById(EventTypes.NoteAdded, TypeTable.EventType)
      && (recentActivity.by == this.username
      // Allow Case Coordinator to delete notes created by Case Administrator
      || (recentActivity.isCreatedByCaseAdministrator && this.keycloakService.isUserInRole(Constants.Role.CASE_COORDINATOR)));
  }

  getAssociatedContravention(contraventionId: number)
  {
    this.adjudicationService.getContraventionById(contraventionId)
    .subscribe((result: any) => {
      this.contravention = result;  
      this.leaSubmissionsComponent.contravention = this.contravention;
    });
  }

  CanServeJudicialReview()
  {
    if(this.localStorageService.hasPermission(Constants.Resource.JUDICIAL_REVIEW,Constants.Permission.SERVE_JUDICIAL_REVIEW) 
      && this.review?.reviewStatusTypeId == ReviewStatusTypes.Complete 
      && this.review?.reviewItems && this.review?.reviewItems[0]?.decisionTypeId == 1
      && this.review?.isJudicialReviewServed == false)
    {
      return true;
    }
    return false;
  }

  CheckIfLateReviewExists() {
    var lateReviewRequest = null;

    if (this.contravention && this.contravention.lateReviewRequestItems?.length > 0) {
      lateReviewRequest = this.contravention.lateReviewRequestItems[this.contravention.lateReviewRequestItems.length - 1].lateReviewRequest;
    }else if (this.vehicleSeizure && this.vehicleSeizure.lateReviewRequestItems?.length > 0) {
      lateReviewRequest = this.vehicleSeizure.lateReviewRequestItems[this.vehicleSeizure.lateReviewRequestItems.length - 1].lateReviewRequest;
    }

    if (lateReviewRequest) {
      this.isLateReviewRequestApproved = lateReviewRequest.requestStatusTypeId == RequestStatusTypes.Complete && lateReviewRequest.requestDecisionTypeId == RequestDecisionTypes.Approved;
      this.lateReviewRequestApprovalDate = lateReviewRequest.decisionDate;
    }
  }

  private loadData(review: Review): void {
    this.review = review;        
    this.review.isPhoneContactRequired = this.review.notifications.findIndex(n => +n.contactMethodTypeId == ContactMethodTypes.Phone && n.notificationDate == null) != -1;
    
    this.updateReview();
    this.updateReviewTimeline();
    this.populateRecentActivity();
    this.setApplicantName();
  }

  private updateReview(): void 
  {
    this.manageReviewDropdown.selectedIndex = 0;
  
    if (this.review.reviewDate != null) {
      this.reviewDateUtc = parseJSON(this.review.reviewDate);

      this.review.reviewDate = new Date(this.review.reviewDate);
      this.reviewDateTime = new Date(`${this.review.reviewDate.toDateString()} ${this.review.reviewTime}`);
    }

    // JTI:653 Introduced review due date
    // let requestDate = new Date(this.review.requestDate);
    // requestDate.setDate(requestDate.getDate() + 21);
    // this.day21Deadline = requestDate;    
    
    this.reviewMethodTypeName = this.localStorageService.getReviewMethodTypes()?.find(r => +r.id == +this.review.reviewMethodTypeId)?.name;    
    this.reviewTypeName = this.localStorageService.getTypeItemDescriptionById(+this.review.reviewTypeId, TypeTable.ReviewType);

    this.reviewRequestDateTime = new Date(this.review.requestDate.toString().replace(/[zZ]/, ""));      
    this.reviewRequestDateTime.setMinutes(this.reviewRequestDateTime.getMinutes() - this.reviewRequestDateTime.getTimezoneOffset());     
  }

  private updateReviewTimeline(): void { 
    let reviewStatusTypeName = this.localStorageService.getTypeItemDescriptionById(+this.review.reviewStatusTypeId, TypeTable.ReviewStatusType);
    let statusTimelineCssClassMapping = {
      new: "new",
      prep: "scheduling-prep",
      reschedule: "scheduling-prep",
      "pending review": "pending-review",
      "pending decision": "pending-decision",
      complete: "complete"      
    };
    
    $(`.review-timeline-state`).removeClass("active");

    for (let key of Object.keys(statusTimelineCssClassMapping)) {    
      $(`.review-timeline-state.${statusTimelineCssClassMapping[key]}`).addClass("active");

      if (key.toLowerCase() === reviewStatusTypeName.toLowerCase()) {               
        break;
      }    
    }  
  }

  private populateRecentActivity(): void {
    this.recentActivities = [];
    
    //Add all review events
    let events = this.review.events;    
    this.pushEventsToActivities(events);

    this.recentActivities = this.recentActivities.sort((a: any, b: any) => {
      if (a.dateTime > b.dateTime) return -1;      
      if (b.dateTime > a.dateTime) return 1;
      return 0;
    });    
    
    this.recentActivities = this.recentActivities.length > 3 ? this.recentActivities.slice(0,3) : this.recentActivities;

    setTimeout(() => {     
      if ($(".recent-activity-container:last-child").length > 0) {
        let offsetTop = $(".recent-activity-container:last-child")[0].offsetTop;
        $(".recent-activity-connector").height(offsetTop);
      }    
    }, 100);
  }

  private pushEventsToActivities(events: Event[])
  {
    if (events)
    {
      for (let event of events) {          
        let eventDateTime = new Date(event.eventDate.toString().replace(/[zZ]/, ""));      
        eventDateTime.setMinutes(eventDateTime.getMinutes() - eventDateTime.getTimezoneOffset());     
        let eventDate = this.datePipe.transform(eventDateTime, "mediumDate");
        let eventTime = this.datePipe.transform(eventDateTime, "HH:mm");
        let username = event.userName;
        let title = this.localStorageService.getTypeItemDescriptionById(+event.eventTypeId, TypeTable.EventType);
        let body = event.eventDetails;

        this.recentActivities.push({
          date: `${eventDate} at ${eventTime}`,
          dateTime: eventDateTime,
          by: username,
          title: title,
          body: body,
          documents: event.documents,
          id: event.eventId,
          isCreatedByCaseAdministrator: event.isCreatedByCaseAdministrator
        });
      }
    }
  }

  private getAdjudicatorAssignedName(): string {
    const user = this.adjudicators.find(adjudicator => +adjudicator.userId === +this.review.userId);
    if (user != null) {
      return `${user.firstName} ${user.lastName}`;
    }

    return "Applicant";
  }

  onViewClick(): void {
    this.selectLEASubmissionsTab();
    this.leaSubmissionsComponent.scrollToDocumentsSection();
  }

  selectLEASubmissionsTab() {
    $(".tab-header-item").removeClass("selected");
    $(".tab-content-item").removeClass("selected");
    
    $(`#tabHeader_LawEnforcementSubmissions`).addClass("selected");
    $(`#tab_LawEnforcementSubmissions`).addClass("selected");
  }

  onTabHeaderClick(ev: any): void {        
    if (ev.target.classList.contains("selected")) {
      return;
    }
   
    let tabId = ev.target.id.split("_")[1];

    $(".tab-header-item").removeClass("selected");
    $(".tab-content-item").removeClass("selected");
    
    $(`#tabHeader_${tabId}`).addClass("selected");
    $(`#tab_${tabId}`).addClass("selected");
  }

  getReviewStatusTypeCssClass(reviewStatusTypeId: number): string {
    let reviewStatus = this.localStorageService.getTypeItemDescriptionById(reviewStatusTypeId, TypeTable.ReviewStatusType);
    return reviewStatus?.trim().toLowerCase().replace(" ", "-");
  }

  getReviewStatusTypeName(reviewStatusTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(+reviewStatusTypeId, TypeTable.ReviewStatusType);
  }

  setApplicantName(): void {
    var recipientName;
    var applicantName = `${this.review.reviewApplicant?.firstName??""} ${this.review.reviewApplicant?.lastName}`;

    if (this.review.reviewTypeId == ReviewTypes.IrsReview)
      recipientName = `${this.review.driverFirstName ??""} ${this.review.driverLastName}`;
    else if (this.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview)
      recipientName = `${this.review.seizureDriverFirstName??""} ${this.review.seizureDriverLastName}`;
    else if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview)
      recipientName = applicantName;
    else
      recipientName = `${this.review.registeredOwnerFirstName??""} ${this.review.registeredOwnerLastName}`;  
    
    // ------ Recipient Name under Summary section ------ 
    // If representation exists then show Representation on behalf of Recipient otherwise Recipient
    if (this.review.representation != null && this.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview) 
      this.representationName = `${this.review.representation.firstName} ${this.review.representation.lastName} on behalf of ${recipientName}`;
    else
      this.representationName = recipientName;

    // ------ Applicant Name under Applicant Submission section -----
    // If applicant is not same as recipient and it is not a self submit review then Applicant on behalf of Recipient otherwise Recipient
    if (applicantName != recipientName && !this.review.isSelfSubmit && this.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview) 
      this.applicantName = `${applicantName} on behalf of ${recipientName}`;
    else
      this.applicantName = recipientName;  
  }

  validateReviewTime(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      let reviewDate = this.review.reviewDate;
      reviewDate = new Date(reviewDate.getFullYear(), reviewDate.getMonth(), reviewDate.getDate());

      let reviewSchedule = new ReviewSchedule({
        reviewId: this.review.reviewId,
        reviewDate: reviewDate,
        reviewTime: this.review.reviewTime,
        userId: this.review.userId
      });

      this.conflictingReviewTime = null;
      this.scheduleButton.classList.add("saving");
      this.scheduleButton.setAttribute("disabled", "disabled");

      this.reviewService.getConflictingReviewTime(reviewSchedule)
        .subscribe((result: any) => {
          if (result != null) {
            this.conflictingReviewTime = result;

            this.scheduleButton.classList.remove("saving");
            this.scheduleButton.removeAttribute("disabled");
            subscriber.error(false);
          } else {
            subscriber.next(true);
          }                     
        });
    });
  }
     
  onScheduleClick(ev: any): void {   
    this.scheduleFormSubmitted = true;

    if (this.scheduleForm.form.invalid) {
      return;
    }

    this.validateReviewTime().subscribe(
      (result: boolean) => {           
        this.addNotificationToReview();          
        this.addEventsToReview();
    
        const year = this.review.reviewDate.getFullYear();
        const month = this.review.reviewDate.getMonth();
        const date = this.review.reviewDate.getDate();
    
        this.review.reviewDate = new Date(year, month, date);
               
        this.reviewService.scheduleReview(this.review)
          .subscribe((review: Review) => {             
            this.loadData(review);         

            this.scheduleFormSubmitted = false
            this.conflictingReviewTime = null;
            this.scheduleButton.classList.remove("saving");
            this.scheduleButton.removeAttribute("disabled");                                
          },
          error => {},
          () => {
            this.scheduleFormSubmitted = false;
            this.conflictingReviewTime = null;
            this.scheduleButton.classList.remove("saving");
            this.scheduleButton.removeAttribute("disabled");        
          });
      }, 
      (error: any) => {});                
  }

  private addNotificationToReview(): void {
    let reviewScheduledNotification = this.review.notifications.find(n => +n.notificationTypeId == NotificationTypes.ReviewScheduled);
    
    if (reviewScheduledNotification == null ) {
      this.review.notifications.push(new Notification({
        notificationTypeId: NotificationTypes.ReviewScheduled,
        contactMethodTypeId: this.review.contactMethodTypeId
      }));
    } else {
      this.review.notifications.push(new Notification({
        notificationTypeId: NotificationTypes.ReviewRescheduled,
        contactMethodTypeId: this.review.contactMethodTypeId        
      }));
    }
  }

  private addEventsToReview(): void {
    let reviewDateScheduledEvent = this.review.events.find(e => +e.eventTypeId == EventTypes.ReviewDateScheduled);
    
    let reviewDate = this.datePipe.transform(this.review.reviewDate, "mediumDate");    
    let reviewTime = this.timePipe.transform(this.review.reviewTime);

    if (reviewDateScheduledEvent == null) {      
      this.review.events.push(new Event({
        eventTypeId: EventTypes.ReviewDateScheduled,
        eventDetails: `Review date: ${reviewDate} at ${reviewTime}`        
      }));
      
    } else {
      this.review.events.push(new Event({
        eventTypeId: EventTypes.ReviewRescheduled,
        eventDetails: `Review rescheduled to ${reviewDate} at ${reviewTime}`
      }));
    }

    let eventTypeId = EventTypes.AdjudicatorAssigned;
    let adjudicatorAssignedEvent = this.review.events.find(e => +e.eventTypeId == eventTypeId);    
    if (adjudicatorAssignedEvent != null) {
      eventTypeId = EventTypes.AdjudicatorReassigned;
    }
    
    this.review.events.push(new Event({
      eventTypeId: eventTypeId,
      eventDetails: this.getAdjudicatorAssignedName()
    }));    
  }

  onAddNoteLinkClick(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.reviewId = this.review.reviewId;    
    componentRef.instance.documentRefTypeNumber = this.review.reviewNumber;
    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.review.events.push(result);
        this.populateRecentActivity();
        this.reviewActivityComponent.populateRecentActivity();        
      }
      this.viewContainerRef.clear();
    });
  }

  onEditNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(NoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.review.reviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: Event) => {
      if (result != null) {
        this.review.events = this.review.events.filter(x=>x.eventId !== eventId);
        this.review.events.push(result);
        this.populateRecentActivity();
        this.reviewActivityComponent.populateRecentActivity();        
      }
      this.viewContainerRef.clear();
    });
  }

  onDeleteNoteLinkClick(eventId: number): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(DeleteNoteModalComponent);
    componentRef.instance.documentRefTypeNumber = this.review.reviewNumber;
    componentRef.instance.eventId = eventId;

    componentRef.instance.close.subscribe((result: any) => {
      if (result) {
        this.review.events = this.review.events.filter(x=>x.eventId !== eventId);
        this.populateRecentActivity();
        this.reviewActivityComponent.populateRecentActivity();        
      }
      this.viewContainerRef.clear();
    });
  }

  onViewApplicantAvailabilityClick(): void {    
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(ApplicantAvailabilityModalComponent);
    componentRef.instance.review = this.review;
    componentRef.instance.close.subscribe((result: any) => this.viewContainerRef.clear());    
  }

  onManageReviewChange(ev: any): void {
    const selectedOption = ev.target.selectedOptions[0].innerHTML.toLowerCase();

    switch (selectedOption) {
      case "change status":
        this.showChangeReviewStatusModal();
        break;
      case "reassign adjudicator":
        this.showReassignAdjudicatorModal();
        break;
      case "change contact method":
        this.showChangeContactMethodModal();
        break;
      case "change review method":
        this.showChangeReviewMethodModal();
        break;
      case "reschedule review":
          this.showRescheduleReviewModal();
          break;
      case "change decision due date":
        this.showChangeDecisionDueDateModal();
        break;
      case "cancel review":
        this.showCancelReviewModal();
        break;
        case "judicial review served":
          this.showJudicialReviewServedModal();
          break;
    }  
  }

  showChangeReviewStatusModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(ChangeReviewStatusModalComponent);
    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }      
    });
  }

  showChangeReviewMethodModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(ChangeReviewMethodModalComponent);
    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }      
    });
  }

  showRescheduleReviewModal(): void {
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(RescheduleReviewModalComponent);
    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.reviewDateUtc = new Date(this.reviewDateUtc);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }      
    });
  }

  showReassignAdjudicatorModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(ReassignAdjudicatorModalComponent);
    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }      
    });    
  }

  showChangeContactMethodModal(): void {
    this.viewContainerRef.clear();        
    const componentRef = this.viewContainerRef.createComponent(ChangeContactMethodModalComponent);
    let newReview = Object.assign({}, this.review);;
    
    if (newReview.contactAddress == null) {
      newReview.contactAddress = new ContactAddress();
    }

    componentRef.instance.review = newReview;   
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {        
        this.loadData(result);
      }      
    });
  }

  showChangeDecisionDueDateModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(ChangeDecisionDueDateModalComponent);    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }
    });
  }

  showCancelReviewModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(CancelReviewModalComponent);    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }
    });
  }

  showJudicialReviewServedModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(JudicialReviewServedModalComponent);    
    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();
      this.manageReviewDropdown.selectedIndex = 0;
      if (result != null) {
        this.loadData(result);
      }
    });
  }

  showReviewLastViewedModal(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(ReviewLastViewedModalComponent);

    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe(() => {
      this.viewContainerRef.clear();
    });
  }

  isOralContraventionReview(): boolean {
    return this.review?.reviewMethodTypeId == ReviewMethodTypes.Oral && 
            this.review?.reviewItems[0]?.contraventionId != null;
  }
  
  onEventDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Events", this.review.reviewNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onReviewDateInput(ev: any): void {
    console.log(ev);
    this.conflictingReviewTime = null;
  }

  onViewReceiptClicked() {
    this.spinner.show();
    this.reviewService.getFeePaymentReceipt(this.review.reviewNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Review - ${this.review.reviewNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  reviewActivityTabSelected() {
    this.reviewActivityComponent.setOffSetTopForReviewRecentActivity();
  }

  refreshReview(review: Review)
  {
    this.loadData(review);
  }
}
