import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  sideNav: HTMLElement;
  arrowIcon: HTMLElement;
  sideNavArrow: HTMLElement;
  userRoles: string[];
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;
  constructor(private readonly keycloakService: KeycloakService) { }

  ngOnInit(): void {
    this.sideNav = document.getElementById("sideNav");
    this.arrowIcon = document.getElementById("arrowIcon");
    this.sideNavArrow = document.getElementById("sideNavArrow");

    this.userRoles = this.keycloakService.getUserRoles();
  }

  onSideNavLeftArrowClick(): void {
    if (this.sideNav.classList.contains("collapse")) {
      this.sideNav.classList.remove("collapse");

      this.arrowIcon.classList.remove("fa-chevron-right");
      this.arrowIcon.classList.add("fa-chevron-left");

      this.sideNavArrow.classList.remove("side-nav-right-arrow");
      this.sideNavArrow.classList.add("side-nav-left-arrow");
      
    } else {
      this.sideNav.classList.add("collapse");

      this.arrowIcon.classList.remove("fa-chevron-left");
      this.arrowIcon.classList.add("fa-chevron-right");

      this.sideNavArrow.classList.add("side-nav-right-arrow");
      this.sideNavArrow.classList.remove("side-nav-left-arrow");
    }
  }

  isSelected(section: string): boolean {
    if(window.location.href.toLowerCase().includes("judicial-review") && section.toLowerCase()=="review")
    {
      return false;
    }
    else
    {
      return window.location.href.toLowerCase().includes(section.toLowerCase());
    }
  }

  hasAccess(page: string): boolean {
    if (this.userRoles.find(r => r.toLowerCase() == "casemanager") != null) {
      return true;
    }

    if ((page == "contraventions" || page == "seizures") && this.userRoles.find(r => ["dfm", "cvs"].includes(r.toLowerCase())) != null){
      return true;
    }

    return false;
  }

}
