export class AdjudicatorSchedule {
    userId: number;
    firstName: string;
    lastName: string;
    irsOralReviewMaxBookings: number;
    irsWrittenReviewMaxBookings: number;
    seizureReviewMaxBookings: number;
    lateReviewMaxBookings: number;
    meetingAccount: string;

    constructor(init?: Partial<AdjudicatorSchedule>) {
        Object.assign(this, init);
    }
}