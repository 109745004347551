export class PagedResult {
    public pageNumber: number = 0;
    public pageSize: number = 0;
    public totalCount: number = 0;
    public items: any[] = [];
    public orderBy: string;
    public orderDir: string;

    constructor(init?: Partial<PagedResult>) {
        
        Object.assign(this, init);
    }
}
