<div class="d-flex flex-column h-100">
    <div class="header">
        Requests
    </div>
    <div class="main">
        <div class="filter-section">            
            <div class="filter-by-header">Filters</div>
            <div class="form-group">
                <div class="filter-type-header">Request Type</div>
                <div class="filter-type-container">                    
                    <select class="form-control w-75" id="requestTypesDropdown" (change)="onRequestTypeChange($event)"
                    [(ngModel)]="selectedRequestTypeId">
                        <option class="filter-type-option" [ngValue]="0">All</option>
                        <option class="filter-type-option" *ngFor="let requestType of requestTypes" 
                            value="{{requestType.id}}">{{requestType.name}}</option>
                    </select>                                        
                </div>
            </div>

            <div *ngIf="selectedRequestTypeId!=RequestTypes.LEACancellationRequest" class="form-group">
                <div class="filter-type-header">Assigned To</div>
                <div class="filter-type-container">                    
                    <select class="form-control w-75" [(ngModel)]="selectedAdjudicatorId"
                        id="assignedToDropdown" 
                        (change)="onAssignedToChange($event)">
                        <option class="filter-type-option" value="0">All</option>
                        <option class="filter-type-option" *ngFor="let adjudicator of adjudicators" value="{{adjudicator.userId}}">
                            {{adjudicator.lastName}}, {{adjudicator.firstName}}
                        </option>
                    </select>                                        
                </div>
            </div> 
            
            <div class="form-group">               
                <div class="filter-type-header d-flex align-items-center justify-content-between">
                    Status
                    <a class="select-all-link" (click)="onSelectAllLinkClick($event)">Select All</a>
                </div>
                <div class="filter-type-container">
                    <status-checkboxes 
                        [statusTypes]="requestStatusTypes"
                        (change)="onRequestStatusTypeChange($event)"></status-checkboxes>                                                                              
                </div>
            </div>

            <action-checkboxes
                [showRequiresRedaction]="false"
                [showPendingSubmissions]="false"
                [showClosure]="false"
                (change)="onActionCheckboxesChange($event)"
            ></action-checkboxes>

            <a [routerLink]="[]" class="clear-filters disabled filter-action-lnk" (click)="onClearFiltersClick($event)">Clear All</a>     
        </div>

        <div class="w-100 pl-4 pr-4">
            <div class="d-flex justify-content-between align-items-center">
                <div class="search-box">
                    <input id="searchTextbox" type="text" class="form-control" placeholder="Search by name or number" 
                        [(ngModel)]="searchText"
                        (keydown)="onSearchTextboxKeyDown($event)" />                
                    <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick()"><i class="fas fa-search fa-lg"></i></button>                
                </div>
                <div class="d-flex align-items-center">
                    <div class="paging align-self-end">                                                           
                        <label for="pageSizeTop" class="page-size-label">Page Size</label>
                        <select class="form-control page-size-select" id="pageSizeTop" (change)="onPageSizeChange($event)">
                            <option value="10" [selected]="+pageSize == 10">10</option>
                            <option value="25" [selected]="+pageSize == 25">25</option>
                            <option value="50" [selected]="+pageSize == 50">50</option>
                            <option value="100" [selected]="+pageSize == 100">100</option>
                        </select>                               
                        <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>                    
                        <div class="previous-link" 
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                            (click)="onPreviousLinkClick($event)">Previous</div>
                        
                        <ng-container *ngFor="let page of pageNumbers; let i=index;">
                            <div id="pageNumberTop_{{page}}" class="page-number"                                 
                                [ngClass]="{'selected': +pageNumber == +page, 'mr-0': +i==4 }"
                                (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                        </ng-container>
                        
                        <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                            <div class="page-number ellipsis" [ngClass]="{ 'ml-3': pageNumbers.length == 0  }">...</div>
                            <div id="pageNumberTop_{{totalPages}}" class="page-number ml-0" 
                                [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                                (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                        </ng-container>

                        <div class="next-link"
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                            (click)="onNextLinkClick($event)">Next</div>                        
                        <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>
                    </div>
                    <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.EXPORT_LIST">
                        <div class="export-list" (click)="onExportListClick($event)">
                            <img class="export-list-icon" src="assets/images/export-list.svg" />
                            <div class="ml-2">Export List</div>
                        </div>
                    </app-access-control>
                </div>
            </div>
            <div class="d-flex mt-3">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                <div class="d-flex">
                                    <span>Request Date</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('requestDate')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex ">
                                    <span>Request Number</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('requestNumber')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>                                
                                <div class="d-flex ">
                                    <span>Request Type</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('requestTypeId')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>                                
                                <div class="d-flex ">
                                    <span>Requestor</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('requestor')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>                                
                                <div class="d-flex ">
                                    <span>Adjudicator</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('adjudicatorName')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                            <th>                                                                
                                <div class="d-flex ">
                                    <span>Status</span>                                    
                                    <span class="sort-icon" (click)="onSortIconClick('requestStatusTypeId')"><i class="fas fa-sort"></i></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredRequests.length > 0">
                        <tr *ngFor="let request of filteredRequests">
                            <td>{{ toLocaleDateTime(request.requestDate) | date}}</td>
                            <td>
                                <a [routerLink]="['/requests', request.requestNumber]"
                                    [queryParams]="{type: request.requestTypeId}">
                                    {{request.requestNumber}}
                                </a>
                            </td>
                            <td>{{localStorageService.getTypeItemDescriptionById(request.requestTypeId, TypeTable.RequestType)}}</td>
                            <td>{{request.requestor}}</td>
                            <td>
                                <ng-container *ngIf="request?.adjudicatorName">
                                    {{request?.adjudicatorName}}
                                </ng-container>
                                <ng-container *ngIf="!request?.adjudicatorName && request.requestTypeId!=RequestTypes.LEACancellationRequest">
                                    Unassigned
                                </ng-container>
                                <ng-container *ngIf="!request?.adjudicatorName && request.requestTypeId==RequestTypes.LEACancellationRequest">
                                    -
                                </ng-container>
                            </td>
                            <td>
                                <div class="d-flex flex-wrap" style="width: 184px;">
                                    <span class="badge badge-pill text-white px-2 py-1 mb-2"
                                        [class.badge-status-new]="request.requestStatusTypeId == RequestStatusTypes.New"
                                        [class.badge-status-pending]="request.requestStatusTypeId == RequestStatusTypes.PendingDecision"
                                        [class.badge-status-complete]="request.requestStatusTypeId == RequestStatusTypes.Complete"
                                        [class.badge-status-payment-pending]="request.requestStatusTypeId == RequestStatusTypes.PaymentPending"
                                        [class.badge-status-cancelled]="request?.requestStatusTypeId == RequestStatusTypes.Cancelled">
                                        {{localStorageService.getTypeItemDescriptionById(request.requestStatusTypeId, TypeTable.RequestStatusType) | uppercase}}</span>

                                    <div class="d-flex">                                    
                                        <div *ngIf="request.isPhoneContactRequired" class="request-action-contact-icon">
                                            <i class="fas fa-phone fa-xs"></i>
                                        </div>   
                                    </div>
                                </div>
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="filteredRequests.length === 0">
                        <tr>
                            <td colspan="5">No requests found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end align-items-center">                                            
                <label for="pageSizeBottom" class="page-size-label">Page Size</label>
                <select class="form-control page-size-select" id="pageSizeBottom" (change)="onPageSizeChange($event)">
                    <option value="10" [selected]="+pageSize == 10">10</option>
                    <option value="25" [selected]="+pageSize == 25">25</option>
                    <option value="50" [selected]="+pageSize == 50">50</option>
                    <option value="100" [selected]="+pageSize == 100">100</option>
                </select>                               
                <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>                    
                <div class="previous-link" 
                    [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                    (click)="onPreviousLinkClick($event)">Previous</div>
                
                <ng-container *ngFor="let page of pageNumbers; let i=index;">
                    <div id="pageNumberTop_{{page}}" class="page-number" 
                        [ngClass]="{'selected': +pageNumber == +page, 'mr-0': +i==4 }"
                        (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                </ng-container>
                
                <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                    <div class="page-number ellipsis" [ngClass]="{ 'ml-3': pageNumbers.length == 0  }">...</div>
                    <div id="pageNumberTop_{{totalPages}}" class="page-number ml-0" 
                        [ngClass]="{ 'selected': pageNumber == totalPages }"
                        (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                </ng-container>

                <div class="next-link"
                    [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                    (click)="onNextLinkClick($event)">Next</div>                        
                <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>     
            </div>
        </div>                
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>

