import {KeycloakService} from 'keycloak-angular';
import { environment } from "apps/adjudication/src/environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
      keycloak.init({
        config: {
          url: environment.authUrl,
          realm: environment.authRealm,
          clientId: environment.authClientId
        },
        initOptions: {
          checkLoginIframe: true,
          onLoad: 'login-required'
        },
        loadUserProfileAtStartUp: true
      });
  }