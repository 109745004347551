


export class UserCalendarViewRequest {
    public userId: number;
    public startDate: Date;
    public endDate: Date;

    constructor(init?: Partial<UserCalendarViewRequest>) {
        
        Object.assign(this, init);
    }
}
