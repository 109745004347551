import { Component } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ReportService } from '../shared/services/report.service'
import { formatDate } from '@angular/common';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import * as fileSaver from "file-saver";
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})

export class FinanceComponent {
  datePickerConfig: Partial<BsDatepickerConfig>;
  startDatePickerConfig: Partial<BsDatepickerConfig>;
  endDatePickerConfig: Partial<BsDatepickerConfig>;
  startPayableDatePickerConfig: Partial<BsDatepickerConfig>;
  endPayableDatePickerConfig: Partial<BsDatepickerConfig>;
  startDate: Date;
  endDate: Date;
  fromDate: string;
  toDate: string;
  startPayableDate: Date;
  endPayableDate: Date;
  constructor(
    private readonly reportService: ReportService,
    private readonly spinner: NgxSpinnerService
  ) {
      this.datePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
          maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
          isAnimated: true
        })

      this.startDatePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true
          })
      this.endDatePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true
          })
      this.startPayableDatePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true
          })
      this.endPayableDatePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true
          })
    }
  onDownloadClick(form: NgForm) {
  this.startDate = form.controls.startDate.value;
  this.endDate = form.controls.endDate.value;

  this.fromDate = (moment(this.startDate)).format('YYYY-MM-DD');
  this.toDate = (moment(this.endDate)).format('YYYY-MM-DD');

  this.spinner.show();
  this.reportService.downloadFinancialJournalReport(this.fromDate, this.toDate)
    .subscribe((result: HttpEvent<Blob>) => {
      if (result.type == HttpEventType.Response) {
        this.spinner.hide();
        fileSaver.saveAs(result.body, "APIS-financial-extract.csv");
      }
    }, (error: any) => this.spinner.hide());

  }

  onPayableDownloadClick(form: NgForm) {
  //  console.log(form.controls.endPayableDate)
    this.startPayableDate = form.controls.startPayableDate.value;
    console.log("startPayableDate: ", formatDate(this.startPayableDate,'yyyy-MM-dd','en-US'));
    this.endPayableDate = form.controls.endPayableDate.value;
    console.log("endPayableDate: ", formatDate(this.endPayableDate,'yyyy-MM-dd','en-US'));
  }
}
