import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { CalendarEvent, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { addDays, addHours, endOfDay, endOfMonth, endOfWeek, parseISO, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { SchedulerService } from '../../../shared/services/scheduler.service';
import { ScheduleEventComponent } from '../modals/schedule-event/schedule-event.component';
import { Colors } from '../../../shared/helpers/colors'
import { UserCalendarViewRequest } from '@apis/shared/models/user-calendar-view-request.model';
import { ReviewService } from '../../../shared/services/review.service';
import { ReviewView } from '@apis/shared/models/review-view.model';
import { forkJoin } from 'rxjs';
import { CommonMethods } from '../../../shared/helpers/common-methods';

@Component({  
  selector: 'event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
  // encapsulation: ViewEncapsulation.None, // hack to get the styles to apply locally
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class EventCalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  showEvent = false;

  locale: string = 'en';

  // exclude weekends
  excludeDays: number[] = [0, 6];

  events: CalendarEvent[] = [];  

  scheduledEvents: ScheduledEvent[] = [];

  reviews: ReviewView[] = [];

  selectedEventId: number;

  scheduleEventComponent: ScheduleEventComponent;  
  @ViewChild('scheduleEvent', { static: false }) set scheduleEventContent(comp: ScheduleEventComponent) {
    if (comp) {
      this.scheduleEventComponent = comp;
    }
  }

  CalendarView = CalendarView;

  constructor(
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly schedulerService: SchedulerService,
    private readonly reviewService: ReviewService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  onViewChange(newView: CalendarView) {
    this.view = newView;
    this.loadData();
  }

  onEventClose(e) {
    this.showEvent = false;
    if (e != null) {
      this.loadData();
    }
  }

  onEventClicked({ event }: { event: CalendarEvent }): void {
    // Filter out holiday events
    if (event.meta.type == 'scheduledEvent') {
      if (this.selectedEventId != +event.meta?.e.scheduledEventId || this.showEvent == false) {
        this.showEvent = true;
        this.selectedEventId = +event.meta?.e.scheduledEventId;
        this.loadScheduledEvent();
      } else {
        this.showEvent = false;
      }
    }
  }

  closeEventSidebar() {
    this.showEvent = false;
  }

  loadData() {
    this.spinner.show();

    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    const user = this.localStorageService.getAdjudicationUser();

    const adjUser = this.localStorageService.getAdjudicators().find(u => u.emailAddress == user.emailAddress);

    if (adjUser == null) {
      // Adjudicator not added in APIS
      // TODO: Handle this error.
    }

    const userCalendarViewRequest = new UserCalendarViewRequest({
      userId: adjUser.userId,
      startDate: getStart(this.viewDate),
      endDate: getEnd(this.viewDate)
    });

    forkJoin({
      scheduledEvents: this.schedulerService.getCalendarView(userCalendarViewRequest),
      pedningDecisionReviews: this.reviewService.getPendingDecisionReviews(adjUser.userId, getStart(this.viewDate), getEnd(this.viewDate))
    }).subscribe((result: any) => {
      
      this.scheduledEvents = result.scheduledEvents;

      this.reviews = result.pedningDecisionReviews;

      this.loadEvents();

      this.spinner.hide();
    }, (error: any) => this.spinner.hide());
  }

  loadEvents() {
    var holidayEvents = this.getHolidayEvents();

    var calendarEvents = this.getCalendarEvents(this.scheduledEvents);
    
    this.events = holidayEvents.concat(calendarEvents);
  }

  getCalendarEvents(scheduledEvents: ScheduledEvent[]) {
    var calendarEvents = [];

    scheduledEvents.forEach(e => {
      let calendarEvent: any = {
          title: e.title,
          start: new Date(e.startDate),
          end: new Date(e.endDate),
          color: CommonMethods.getEventColorByMeetingType(e.meetingTypeId, e.availabilityTypeId),
          cssClass: e.meetingTypeId > 0 ? 'app-cal-event' : '',
          meta: {
            type: 'scheduledEvent',
            e: e
          }
      };

      if (e.meetingTypeId) {
        calendarEvent.actions = [
          {
            label: e.meetingTypeId == 2 ? '<img src="assets/images/camera-black.svg">' : '<img src="assets/images/pencil-black.svg">',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              this.onEventClicked({event});
            },
          }
        ];
      }

      calendarEvents.push(calendarEvent);
    });

    return calendarEvents;
  }

  getHolidayEvents() {
    const holidays = this.localStorageService.getHolidayList();

    var holidayEvents = [];

    holidays.forEach(h => {
      holidayEvents.push({
        color: Colors.Yellow,
        start: new Date(h.holidayDate),
        title: h.holidayName,
        cssClass: 'adj-cal-event-title',
        allDay: true,
        meta: {
          type: 'holiday',
          h,
        },
      });
    });    

    return holidayEvents;
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          if (
            segment.date.getHours() >= 12 &&
            segment.date.getHours() < 13
          ) {
            segment.cssClass = 'adj-cal-lunch-break';
          }
        });
      });
    });
  }

  showEventSection() {
    this.selectedEventId = 0;
    this.showEvent = !this.showEvent;
    this.loadScheduledEvent();
  }

  loadScheduledEvent() {
    setTimeout(() => {
      this.scheduleEventComponent.loadScheduledEvent(this.selectedEventId);
    });
  }
}
