<div #dropZone class="drop-zone show" (click)="uploadFile.click()">
    <input style="display: none;" type="file" [disabled]="isDisabled" (change)="onUploadFileChanged($event)" #uploadFile>
    <div><i class="fas fa-upload fa-lg"></i></div>
    <span>{{dropZoneText}}</span>
</div>
<div #uploadContainer class="upload-container">
    <div>
        <div *ngIf="documentTypeName != null" class="document-type">{{documentTypeName}}</div>
        <a class="document-name" (click)="onDocumentNameClick($event)">{{documentName}}</a>
    </div>
    <div class="upload-bar">
        <div #uploadBarProgress class="upload-bar-progress"></div>              
    </div>
    <a #removeFileLink class="remove-file-link disabled" (click)="onRemoveFileLinkClick()">Remove File</a>
</div>