
export class ReviewItemCancellationReason {
    public reviewItemCancellationReasonId: number = 0;
    public contraventionDetailTypeId: number;
    public reviewCancellationReasonTypeId: number = 0;
    public reviewCancellationSecondaryReasonTypeId: number;
    public reviewItemId: number = 0; 

    constructor(init?: Partial<ReviewItemCancellationReason>) {
        
        Object.assign(this, init);
    }
}
