import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DocumentTypes } from '@apis/shared/enums/app.enum';
import { DocumentService } from "@apis/shared/services/document.service";
import { KeycloakService } from 'keycloak-angular';

import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Document } from '@apis/shared/models/document.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DocumentUploadRequest } from '@apis/shared/models/document-upload-request.model';

@Component({
  selector: 'app-additional-document-modal',
  templateUrl: './additional-document-modal.component.html',
  styleUrls: ['./additional-document-modal.component.scss']
})
export class AdditionalDocumentModalComponent implements OnInit {
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  documentRefId: number;
  
  @Output() close: EventEmitter<Document[]> = new EventEmitter();

  additionalDocumentTypes: DocumentType[];
  selectedDocumentType: DocumentType = null;
  allowedFileTypes: string = "";
  validExtensions: string[]; 
  maxAllowedFileSize: string;
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  uploadButton: JQuery<HTMLElement>;
  dropZone: JQuery<HTMLElement>;
  username: string;
   
  tempFileFolder: string;
  documents: Document[] = [];
  
  disableFileUpload: boolean = false;
  fileErrorMessage: string;
  isSubmitClicked: boolean = false;
  errorMessage: string = "";
          
  constructor(private readonly localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {      
    this.tempFileFolder = Guid.create().toString();      

    //Get Dcoument Types
    if (this.documentRefTypeName == "Requests") {
      this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => [DocumentTypes.RecipientsConsentOfRepresentation, DocumentTypes.RecipientSubmission, DocumentTypes.Other].includes(+x.id));
    }
    else {
      this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => [DocumentTypes.Photo, DocumentTypes.AudioVideo, DocumentTypes.WitnessStatements, DocumentTypes.PoliceNotes, DocumentTypes.OtherOfficerAccount, DocumentTypes.TechnicalMaterial, DocumentTypes.ViolationTicket, DocumentTypes.Other, DocumentTypes.PoliceNarrative, DocumentTypes.SafeRoadsAlbertaDocumentation].includes(+x.id));
    }
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");         
    this.uploadButton = $(".upload-button");  
    this.dropZone = $(".drop-zone");

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName: string) => {
      document.body[`on${eventName}`] = this.preventDefaults;
      this.dropZone.on(eventName, this.preventDefaults);      
    });

    ["dragenter", "dragover"].forEach(eventName => {
      this.dropZone.on(eventName, (ev: any) => this.dropZone.addClass("highlight"));      
    });
    
    ["dragleave", "drop"].forEach(eventName => {
      this.dropZone.on(eventName, (ev: any) => this.dropZone.removeClass("highlight"));      
    });
                
    this.dropZone[0].ondrop = (ev: any) => this.handleDrop(ev);
  }

  private preventDefaults(ev: any): void {    
    ev.preventDefault();
    ev.stopPropagation();    
  }

  private handleDrop(ev: any): void {       
    this.validateAndUploadFiles(ev.dataTransfer.files);
  }

  onUploadFileChanged(ev: any): void {
    this.validateAndUploadFiles(ev.target.files as FileList)
  } 

  validateAndUploadFiles(fileList: FileList)
  {
    if (this.disableFileUpload) {
      return;
    }
    if (!this.selectedDocumentType)
    {
      this.errorMessage = "Please select a file category first";
      return;
    }

    var existingDocumentCount = this.documents.length;

    this.errorMessage = "";
    let validFiles: File[] = [];
    for (let index = 0; index < fileList.length; index++) {
      let file = fileList[index];    

      let document = new Document({
        contraventionId: this.documentRefTypeName == "Contraventions" ? this.documentRefId : null,
        vehicleSeizureId: this.documentRefTypeName == "VehicleSeizures" ? this.documentRefId : null,
        stopInformationId: this.documentRefTypeName == "Stop" ? this.documentRefId : null,
        lateReviewRequestId: this.documentRefTypeName == "Requests" ? this.documentRefId : null,
        contentGuid: Guid.create().toString(),
        documentTypeId: this.selectedDocumentType.id,
        documentName: file.name,            
        documentExtension: file.name.substring(file.name.lastIndexOf(".") + 1),
        documentSize: CommonUtil.getDocumentSize(file),
        uploadedBy: this.selectedDocumentType.id == DocumentTypes.SafeRoadsAlbertaDocumentation? "SafeRoads Alberta" : "Alberta Justice",
        uploadedDate: DateUtil.today(),
        isSubmitLater: false,
        isPublished: true,
        isUploading: true
      });
      
      if (this.isValidFile(file)) {
        if (this.documents.findIndex(d => d.documentName.toLowerCase() == file.name.toLowerCase()) == -1) {
          file["contentGuid"] = document.contentGuid;
          validFiles.push(file);
          this.documents.push(document);
        }
      }                                                
    } 

    setTimeout(() => {
      validFiles.forEach((file: File, index: number) => {
        this.uploadFile(file, index + existingDocumentCount)
      });
    }, 1000);
  }

  uploadFile(file: File, index: number): void {   
    let uploadBarProgress = document.getElementById(`uploadBarProgress_${index}`);
    let newDocument = this.documents[index];
    this.documentService.uploadDocumentAsync(file, this.tempFileFolder, file["contentGuid"])
      .subscribe((result: any) => {                  
        if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;
        } else if (+result.type == 4) {                              
          uploadBarProgress.innerHTML = "Upload Complete";
          newDocument.isUploading = false;
        }
      }, (error: any) => {
        this.errorMessage = "Something went wrong. Please try uploading the document again."
        let index = this.documents.findIndex(d => d.contentGuid == newDocument.contentGuid);
        if (index != -1) {
          // Minor bug: If a user starts a new document upload while another document's upload encounters an error, this splice will target the wrong document.
          // The user will be unable to submit, and will need to close and re-open the modal to fix the issue.
          this.documents.splice(index, 1);
        }
      });
  }

  // Returns true if any document upload is currently in progress.
  isUploadInProgress(): boolean {
    return this.documents.some(document => document.isUploading);
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;   

    this.documentService.downloadDocument(this.tempFileFolder, "", "", storageFileName, document.documentName)
    .subscribe((result: Blob) => {          
        fileSaver.saveAs(result, document.documentName);          
      },
      (error: any) => {});
  }

  onRemoveFileLinkClick(document: Document): void {    
    if (this.disableFileUpload || this.isUploadInProgress()) { // The document upload code uses the document's index. Deleting documents would change the index and cause errors.
      return;
    }
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
    this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
      .subscribe((result: any) => { 
        let index = this.documents.findIndex(d => d.contentGuid == document.contentGuid);
        if (index != -1) {
          this.documents.splice(index, 1);
        }
      },
      (error: any) => {
        this.errorMessage = "Unable to remove the file. Please try again.";
      });
  }

  onCloseIconClick(): void {   
    this.removeDocumentsCloseModal();
  }

  onCancelClick(): void {
    this.removeDocumentsCloseModal();
  }

  private removeDocumentsCloseModal(): void {
    this.documents.forEach((d: Document)=> {
        let storageFileName = `${d.contentGuid}.${d.documentExtension}`
        this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
          .subscribe((result: any) => {});
    });
  
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit();
  }

  onUploadClick(ev: any): void {
    if (this.uploadButton.hasClass("disabled")) {
      return;
    }
    this.spinner.show();
    this.uploadButton.addClass("disabled");
    this.uploadButton.addClass("saving");
    this.disableFileUpload = true;

    //Call update api for additional supporting documents
    var documentUploadRequest = new DocumentUploadRequest();
    documentUploadRequest.documents = this.documents;
    documentUploadRequest.tempFolderName = this.tempFileFolder;
    documentUploadRequest.documentRefTypeName = this.documentRefTypeName;
    documentUploadRequest.documentRefTypeNumber = this.documentRefTypeNumber;

    this.documentService.updateAndFinalizeDocumentsAsync(documentUploadRequest)
      .subscribe((result: any) => {
        this.spinner.hide(); 
        this.bodyElement.removeClass("overflow-hidden");
        this.close.emit(result);
      },
      (error: any) => {
        this.errorMessage = "Upload failed"; 
        this.spinner.hide(); 
      }
    )
}

  isValidFile(file: File): boolean {
    let size = file.size;
    if ((size/1024)/1024 > this.selectedDocumentType.maximumFileSize) {
      this.errorMessage = `Maximum file size is ${this.maxAllowedFileSize}. `;
    } 
        
    let fileExtension = file.name.substring(file.name.lastIndexOf(".")+1);     
             
    if (!this.validExtensions.includes(fileExtension.toLowerCase())) {
      this.errorMessage += "Invalid file extension.";           
    }

    if (this.errorMessage.length > 0)
      this.errorMessage = `Upload error: ${this.errorMessage}`;

    return this.errorMessage.length == 0;
  }

  onFileCtegoryChange()
  {
    this.errorMessage="";
    if (this.selectedDocumentType)
    {
      this.validExtensions = this.selectedDocumentType.acceptedFileTypes.split(',');
      this.maxAllowedFileSize = this.selectedDocumentType.maximumFileSize >= 1024? `${(this.selectedDocumentType.maximumFileSize / 1024).toFixed(0)} GB` : `${this.selectedDocumentType.maximumFileSize} MB`;
      this.allowedFileTypes = `Allowed: ${this.validExtensions.join(', ')}, maximum file size is ${this.maxAllowedFileSize}`;
    }
    else
    {
      this.allowedFileTypes = "";
    }
  }
     
  getDocumentTypeName(documentTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(documentTypeId, TypeTable.DocumentType);
  }
}