<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Re-enable Late Review</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <label for="reasonTextarea" class="bold-text">Reason <span class="label-hint">(Required)</span></label>
            <textarea id="reasonTextarea" name="reasonTextarea"
                class="modal-textarea" 
                required
                [(ngModel)]="reason"
                #reasonModel="ngModel"                
                (input)="onTextareaInput($event)"
                [ngClass]="{'alert-border': isSubmitClicked && reasonModel.invalid }"
                ></textarea>
            <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            <div class="invalid-feedback"
                [ngClass]="{ 'show': reasonModel.invalid && isSubmitClicked }">
                <div>Reason is required.</div>
            </div>

            <div class="reenable-alert">
                <div class="alert-icon">                
                    <i class="fas fa-exclamation-triangle fa-lg"></i>
                </div>
                <div class="alert-text">
                    This will re-enable the recipient’s ability to request a late review.
                    <span class="bold-text">Are you sure?</span>
                </div>                       
            </div>
        </div>
        <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
            <div id="confirmButton" class="confirm-button" (click)="onConfirmModalClick(reasonModel.valid)">
                Confirm
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>