<div class="form-group">
    <div class="filter-type-header d-flex align-items-center justify-content-between">
        Actions
    </div>
    <div class="filter-type-container">
        <div *ngIf="showRequiresRedaction" id="actionAlert" class="action-checkbox-container" (click)="onActionCheckboxClick('actionAlert')">   
            <div class="action-checkbox">                            
            </div>
            <div class="action-alert-icon">
                <i class="fas fa-exclamation-triangle fa-sm"></i>
            </div>
            <div class="action-label">Requires redaction</div>
        </div>
        <div *ngIf="showPendingSubmissions" id="actionWarning" class="action-checkbox-container" (click)="onActionCheckboxClick('actionWarning')">   
            <div class="action-checkbox">                            
            </div>
            <div class="action-warning-icon">
                <i class="fas fa-exclamation-triangle fa-sm"></i>
            </div>
            <div class="action-label">Pending submissions</div>
        </div>
        <div *ngIf="showContact" id="actionContact" class="action-checkbox-container" (click)="onActionCheckboxClick('actionContact')">   
            <div class="action-checkbox">                            
            </div>
            <div class="action-contact-icon">
                <i class="fas fa-phone fa-sm"></i>
            </div>
            <div class="action-label">Contact</div>
        </div>
        <div *ngIf="showClosure" id="actionClosure" class="action-checkbox-container" (click)="onActionCheckboxClick('actionClosure')">   
            <div class="action-checkbox">                            
            </div>
            <div class="action-closure-icon">
                <i class="fas fa-exclamation-triangle fa-sm"></i>
            </div>
            <div class="action-label">Requires action</div>
        </div>
    </div>
</div>