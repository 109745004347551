import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContactMethodTypes, DocumentTypes, EventTypes, NotificationTypes } from "@apis/shared/enums/app.enum";
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { NgModel, Validators } from '@angular/forms';
import { Notification } from '@apis/shared/models/notification.model';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { Document } from '@apis/shared/models/document.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';

@Component({
  selector: 'app-judicial-review-served-modal',
  templateUrl: './judicial-review-served-modal.component.html',
  styleUrls: ['./judicial-review-served-modal.component.scss']
})
export class JudicialReviewServedModalComponent implements OnInit {
  review: Review;
  documents: Document[] = [];
  documentTypes: DocumentType[];
  fileUploadModel: FileUploadModel;
  errorMessage: string = "";


  bodyElement: JQuery<HTMLElement>;
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;

  proceedButton: JQuery<HTMLElement>;

  disableFileUpload: boolean = false;
  formSubmitted: boolean = false;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];
  tempFolderName: string;

  receivedDate: Date;
  courtActionNumber: string = "";

  @Output() close: EventEmitter<Review> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly adjudicationService: AdjudicationService,
    private router: Router) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
  }

  ngOnInit(): void {
    this.tempFolderName = Guid.create().toString();
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");
    this.proceedButton = $(".proceed-button");

    //Get Types
    this.documentTypes = this.localStorageService.getDocumentTypes();

    //Initialize file upload model and a temporary file storage location
    this.fileUploadModel = new FileUploadModel();
    this.fileUploadModel.tempFileFolder = this.tempFolderName;

    this.addPlaceHolder(DocumentTypes.OriginatingApplication, false);
    this.addPlaceHolder(DocumentTypes.NoticeToProduceRecords, true);
    this.addPlaceHolder(DocumentTypes.NoticeOfConstitutionalQuestion, true);
  }

  addPlaceHolder(documentType: DocumentTypes, isOptional: boolean = false) {
    let document = null;
    document = new Document({
      documentTypeId: +documentType,
      isOptional: isOptional
    });
    this.documents.push(document);
  }

  trackDocument(index: number, document: Document): any {
    return document.documentNumber;
  }

  onCancelDcoument(document: Document) {
    this.documents.splice(this.documents.findIndex(x => x.contentGuid == document.contentGuid), 1)
  }

  // Returns true if any document upload is currently in progress.
  isUploadInProgress(): boolean {
    return this.documents.some(document => document.isUploading);
  }

  validateDocuments(): boolean {
    if (this.documents.filter(x => x && x.documentTypeId == DocumentTypes.OriginatingApplication && x.documentName != null && x.documentName.trim().length > 0).length == 0) {
      this.errorMessage = "An Originating application is required for judicial review.";
      return false;
    }
    return true;
  }

  onCloseIconClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();
    this.close.emit();
  }

  onProceedClick(ev: any): void {
    if (this.proceedButton.hasClass("disabled")) {
      return;
    }

    this.formSubmitted = true;
    if (this.validateDocuments() && this.receivedDate) {
      this.proceedButton.addClass("disabled");
      this.proceedButton.addClass("saving");
      this.disableFileUpload = true;

      // Set document uploaded_date and uploaded_by
      var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      this.documents?.filter(x => (x.documentName != null && x.documentName.trim().length > 0))?.forEach(document => {
        document.uploadedDate = today;
        document.uploadedBy = "System Generated";
      });

      //Call service to create judicial review    
      const judicialReview = new JudicialReview({
        courtActionNumber: this.courtActionNumber,
        receivedDate: this.receivedDate,
        documents: this.documents.filter(d => d.documentName != null && d.documentName.length > 0), //d && d.contentGuid && d.documentName
        tempFolderName: this.fileUploadModel.tempFileFolder,
        reviewId: this.review.reviewId,
        reviewNumber: this.review.reviewNumber
      });

      this.adjudicationService.postJudicialReview(judicialReview)
        .subscribe(
          (result: any) => {
            if (result != null) {
              // Navigate to judicial reviews dashboard
              this.router.navigateByUrl(`/judicial-reviews/${result.recordNumber}`);
            }
          },
          (error: any) => {
            this.proceedButton.removeClass("saving");
            this.proceedButton.removeClass("disabled");
            this.disableFileUpload = false;
            this.errorMessage = "Unable to add judicial review. Please try later.";
          });
    }
  }

  removeOverflow(): void {
    this.bodyElement.removeClass("overflow-hidden");
  }
}