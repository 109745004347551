import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private router: Router){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let newHeaders = request.headers.set("client-version", environment.clientVersion);
      request = request.clone({
        headers: newHeaders
      });

      return next.handle(request)
        .pipe(
            tap({
                error: (res) => {   
                  if (res.status == "503")
                    this.router.navigate(['/scheduled-maintenance'])   
                  if (res.statusText == "Unknown Error")
                      this.router.navigate(['/internal-server-error'])
                  throwError(res);
                }
              })
        )
    }

}