import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { Event } from "@apis/shared/models/event.model";
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { EventTypes } from "@apis/shared/enums/app.enum";
import { KeycloakService } from 'keycloak-angular';
import { ReviewService } from '../../../shared/services/review.service';
import { NgModel, Validators } from '@angular/forms';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-change-decision-due-date-modal',
  templateUrl: './change-decision-due-date-modal.component.html',
  styleUrls: ['./change-decision-due-date-modal.component.scss']
})
export class ChangeDecisionDueDateModalComponent implements OnInit {
  review: Review;  
    
  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 250;
  modalTextarea: JQuery<HTMLElement>;
    
  changeButton: JQuery<HTMLElement>;
  eventDetails: string;
  username: string;
  errorMessage: string;

  formSubmitted: boolean;

  datePickerConfig: Partial<BsDatepickerConfig>; 
  dateCustomClasses: DatepickerDateCustomClasses[];
    
  @Output() close: EventEmitter<Review> = new EventEmitter();

  constructor(private readonly localStorageService: LocalStorageService,
    private readonly reviewService: ReviewService,
    private readonly keycloakService: KeycloakService) { 
      this.datePickerConfig = Object.assign({}, 
        {
          containerClass: 'theme-dark-blue', 
          showWeekNumbers: false,
          dateInputFormat: 'MMM DD, YYYY',
          isAnimated: true
        });
        
      this.dateCustomClasses = [
        { date: new Date(), classes: ["bg-secondary", "text-white"] }
      ];

    }

  ngOnInit(): void { 
    this.review.decisionDueDate = new Date(this.review.decisionDueDate);
    this.username = this.keycloakService.getUsername();
   
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");
    this.modalTextarea = $(".modal-textarea");  
    this.changeButton = $(".change-button");      
  }
  
  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeOverflow();    
    this.close.emit();
  }

  onCancelModalClick(): void {
    this.removeOverflow();    
    this.close.emit();
  }

  onChangeModalClick(ev: any): void {
    if (this.changeButton.hasClass("disabled")) {
      return;
    }

    this.changeButton.addClass("disabled");
    this.changeButton.addClass("saving");

    let event = new Event({      
      eventTypeId: EventTypes.DecisionDueDateChanged,
      eventDetails: this.eventDetails      
    });
            
    const review = new Review({
      reviewId: +this.review.reviewId,
      decisionDueDate: this.review.decisionDueDate    
    });

    review.events.push(event);
          
    this.reviewService.putDecisionDueDate(review)
      .subscribe(
        (result: Review) => { 
          this.changeButton.removeClass("disabled");
            this.removeOverflow();
            this.close.emit(result);
        },
        (error: any) => {
          this.changeButton.removeClass("disabled");
          this.changeButton.removeClass("saving");
          this.showErrors(error);
        });    
  }

  removeOverflow(): void {        
    this.bodyElement.removeClass("overflow-hidden");    
  }  

  isChangeButtonDisabled(): boolean {
    return this.maxCharacters < 0
      || this.review.decisionDueDate == null;
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }
}
