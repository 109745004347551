<div class="d-flex flex-column mb-4">
    <div class="heading">Request Status</div>
    <div class="d-flex justify-content-between">        
        <div class="d-flex flex-row">
            <img src="assets/images/calendar-blue.svg" class="icon" />
            <div class="ml-3">
                <span class="info-label mb-auto">Date</span>
                <span class="d-block">{{ decisionDate }}</span>
            </div>
        </div>        
        <div class="d-flex flex-row">
            <button class="submit-decision-button" [hidden]="request?.requestDecisionTypeId != null" 
                (click)="showDecisionModal()">Submit</button>
        </div>                  
    </div>       
    <div *ngIf="request?.requestDecisionTypeId != null">
    <div class="heading">Details</div>
    <div class="d-flex mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th style="width: 200px;">Date</th>
                    <th style="width: 200px;">Outcome</th>
                    <th style="width: 200px;">Contravention Status</th>
                    <th style="width: 200px;">Reason</th>                                        
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>{{request.decisionDate | date:'mediumDate' }}</td>
                    <td>{{localStorageService.getTypeItemDescriptionById(request?.requestDecisionTypeId,
                        TypeTable.RequestDecisionType)}}</td>
                    <td *ngIf="request?.requestDecisionTypeId == RequestDecisionTypes.Approved">Cancelled</td>
                    <td *ngIf="request?.requestDecisionTypeId == RequestDecisionTypes.Denied">Not Cancelled</td>    

                    <td *ngIf="request?.requestDecisionTypeId == RequestDecisionTypes.Approved">-</td>
                    <td *ngIf="request?.requestDecisionTypeId == RequestDecisionTypes.Denied && request?.requestDenyReasonTypeId == RequestDenyReasonTypes.Other">{{request?.requestDenyReasonNotes}}</td> 
                    <td *ngIf="request?.requestDecisionTypeId == RequestDecisionTypes.Denied && request?.requestDenyReasonTypeId != RequestDenyReasonTypes.Other">{{localStorageService.getTypeItemDescriptionById(request?.requestDenyReasonTypeId,
                        TypeTable.RequestDenyReasonType)}}</td>                                         
                </tr>
            </tbody>            
        </table>
    </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="submitDecisionModal" tabindex="-1" role="dialog" aria-labelledby="submitDecisionModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <form #decisionForm="ngForm">
                <div class="modal-header border-0 pt-2">
                    <span class="modal-title h4" id="submitDecisionModalLabel">
                        Approve or Deny Request                        
                    </span>
                    <div class="close-icon" (click)="onCloseIconClick()">
                        <i class="far fa-times-circle fa-lg"></i>
                    </div>
                </div>
                <div class="modal-body py-0">
                    <div class="d-flex">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width: 200px;">Request Number</th>
                                    <th>Type</th>
                                    <th style="width: 180px;" class="required">Outcome</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ requestNumber }}</td>
                                    <td>{{ localStorageService.getTypeItemDescriptionById(requestType,
                                        TypeTable.RequestType) }}</td>
                                    <td>
                                        <select class="form-control modal-select mt-1 mb-1" name="decisionType"
                                            id="decisionType" #decisionType="ngModel" required
                                            [ngClass]="{ 'is-invalid': decisionForm.submitted && decisionType.invalid }"
                                            [(ngModel)]="decisionRequest.decisionTypeId">
                                            <option class="contravention-type-option" [ngValue]="null">--</option>
                                            <option class="contravention-type-option"
                                                *ngFor="let decisionType of decisionTypes" value="{{decisionType.id}}">
                                                {{decisionType.name}}</option>
                                        </select>
                                        <div *ngIf="decisionForm.submitted && decisionType.errors?.required"
                                            class="text-danger pb-1">
                                            Please select a decision
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                                                                      
                    </div>    
                    <div *ngIf="decisionRequest.decisionTypeId==RequestDecisionTypes.Denied">
                        <label for="requestDenyReasonType" class="required denyReasonlabel">Reason for denying cancellation request</label>
                        <select class="form-control" id="requestDenyReasonType" name="requestDenyReasonType" required
                            [(ngModel)]="decisionRequest.requestDenyReasonTypeId"
                            #requestDenyReasonType="ngModel" [ngClass]="{ 'is-invalid': decisionForm.submitted && requestDenyReasonType.invalid }">
                            <option [ngValue]="null">Select a reason</option>
                            <option *ngFor="let reqDenyReasonType of requestDenyReasonTypes" [value]="reqDenyReasonType.id">{{reqDenyReasonType.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Reason for denying cancellation is required</div>
                        </div>
                    </div>    
                    <div *ngIf="decisionRequest.requestDenyReasonTypeId==RequestDenyReasonTypes.Other">
                        <textarea class="note-modal-textarea" id="requestDenyReasonNotes" name="requestDenyReasonNotes" rows="6" [(ngModel)]="decisionRequest.requestDenyReasonNotes"
                        placeholder="Provide reason to LEA for why you are denying the cancellation request (Max 5000 Characters)"></textarea>
                    </div>                              
                    <div class="text-danger">{{ sysErrorMessage }}</div>
                    <div class="text-danger">{{ sysDetailedErrorMessage }}</div>
                </div>
                <div class="modal-footer border-0 d-flex flex-row-reverse justify-content-between">
                    <ng-container *ngIf="decisionRequest.decisionTypeId==RequestDecisionTypes.Denied">
                        <button id="saveSubmitButton" class="save-submit-button" (click)="onSubmitClick()">
                            Deny Request
                            <i class="fas fa-cog fa-spin"></i>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="decisionRequest.decisionTypeId!=RequestDecisionTypes.Denied && isContravention">
                        <button id="saveSubmitButton" class="save-submit-button" (click)="onSubmitClick()">
                            Cancel Contravention
                            <i class="fas fa-cog fa-spin"></i>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="decisionRequest.decisionTypeId!=RequestDecisionTypes.Denied && !isContravention">
                        <button id="saveSubmitButton" class="save-submit-button" (click)="onSubmitClick()">
                            Cancel Seizure
                            <i class="fas fa-cog fa-spin"></i>
                        </button>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>