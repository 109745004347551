


export class TowCompany {
    public towCompanyId: number = 0;
    public towCompanyName: string;
    public towLotAddress: string;
    public city: string;
    public postalCode: string;
    public phoneNumber: string;
    public faxNumber: string;
    public towLotRepresentative: string;
    public towLotEmailAddress: string;

    public isEditing: boolean = false;
    constructor(init?: Partial<TowCompany>) {
        
        Object.assign(this, init);
    }
}
