

import { Document } from './document.model';

export class DocumentUploadRequest {
    public documents: Document[];
    public tempFolderName: string;
    public documentRefTypeName: string;
    public documentRefTypeNumber: string;

    constructor(init?: Partial<DocumentUploadRequest>) {
        
        Object.assign(this, init);
    }
}
