import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../../../../../../libs/shared/services/local-storage.service";

@Pipe({
    name: "reviewType"
})
export class ReviewTypePipe implements PipeTransform {
    constructor(private readonly localStorageService: LocalStorageService) {}

    transform(val: number): string {
        let reviewType = this.localStorageService.getReviewTypes().find(type => +type.id === +val);    
                       
        if (reviewType != null) {
            return reviewType.name;
        }

        return "";
    }
}