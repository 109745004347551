import { Component, OnInit, ViewChild } from '@angular/core';

import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { WorkBook, WorkSheet, utils, writeFile} from "xlsx";
import { AdjudicationService } from '../shared/services/adjudication.service';
import { ReviewType } from '@apis/shared/models/types/review-type.model';
import { ReviewStatusType } from '@apis/shared/models/types/review-status-type.model';
import { ReviewMethodType } from '@apis/shared/models/types/review-method-type.model';
import { DatePipe } from '@angular/common';
import { User } from '@apis/shared/models/user.model';
import * as $ from "jquery";
import { StatusCheckboxesComponent } from '../shared/components/status-checkboxes/status-checkboxes.component';
import { ContactMethodTypes, DecisionTypes, ReviewStatusTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReviewView } from '@apis/shared/models/review-view.model';
import { ActionCheckboxesComponent } from '../shared/components/action-checkboxes/action-checkboxes.component';
import { ReviewPagedSearch } from "@apis/shared/models/review-paged-search.model";
import { PagedResult } from "@apis/shared/models/paged-result.model";
import { DecisionType } from '@apis/shared/models/types/decision-type.model';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { CommonMethods } from '../shared/helpers/common-methods';
import { ActivatedRoute, Router, ParamMap, UrlSerializer } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as fileSaver from "file-saver";
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'review-listing',
  templateUrl: './review-listing.component.html',
  styleUrls: ['./review-listing.component.scss']
})
export class ReviewListingComponent implements OnInit {  
  reviews: any[];
  filteredReviews: any[] = [];    
  pageNumber: number = 1;
  totalPages: number;
  pageNumbers: number[] = [];
  pageRange: number = 5;
  pageSize: number = 25;
  totalCount: number;
  sortDirection = "desc";
  orderBy = "reviewDateTime";
  selectedReviewTypeId: number = 0;
  selectedReviewMethodTypeId: number = 0;
  selectedAdjudicatorId: number = 0;
  selectedReviewStatusTypeIds: number[] = [];
  selectedDecisionTypeIds: number[] = [];  
  searchText: string;
  reviewTypes: ReviewType[];
  reviewStatusTypes: ReviewStatusType[];
  adjudicators: User[] = [];
  activeReviewStatusTypes: ReviewStatusType[] = [];
  inactiveReviewStatusTypes: ReviewStatusType[] = [];
  requiredDocumentTypes: DocumentType[] = [];

  reviewMethodTypes: ReviewMethodType[];
  previousLink: JQuery<HTMLElement>;
  nextLink: JQuery<HTMLElement>;
  reviewTypesDropdown: any;
  reviewMethodTypesDropdown: any;
  assignedToDropdown: any;
  clearAllLink: JQuery<HTMLElement>;
  reviewsTable: HTMLElement;
  actionsChecked: string[] = [];
  isLastRange: boolean = false;
  decisionTypes: DecisionType[];
  Resource:any=Constants.Resource;
  Permission:any=Constants.Permission;
  Role:any=Constants.Role;
  isSuperUser:boolean = false;
    
  @ViewChild(StatusCheckboxesComponent) statusCheckboxesComponent: StatusCheckboxesComponent;
  @ViewChild(ActionCheckboxesComponent) actionCheckboxesComponent: ActionCheckboxesComponent;

  private columns = [{
      propertyName: "reviewNumber",
      ascending: false      
    }, {
      propertyName: "decisionDueDate",
      ascending: false      
    }, {
      propertyName: "reviewDateTime",
      ascending: false      
    }, {
      propertyName: "applicantName",
      ascending: false    
    }, {
      propertyName: "reviewTypeName",
      ascending: false    
    }, {
      propertyName: "reviewMethodTypeName",
      ascending: false
    }, {
      propertyName: "adjudicatorName",
      ascending: false    
    }, {
      propertyName: "reviewStatusTypeName",
      ascending: false    
    }
  ];
  
  constructor(private readonly adjudicationService: AdjudicationService,
    readonly localStorageService: LocalStorageService,
    readonly keyCloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly urlSerializer: UrlSerializer) { }

  ngOnInit(): void {
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {

      this.adjudicators = this.localStorageService.getAdjudicators()
      var adjudicator = JSON.parse(localStorage.getItem("userKey"));
      this.selectedAdjudicatorId = this.adjudicators.find(a => a.emailAddress == adjudicator?.emailAddress)?.userId??0;

      if (this.keyCloakService.isUserInRole(this.Role.SENIOR_ADJUDICATOR)) this.selectedAdjudicatorId = 0;

      if (this.keyCloakService.isUserInRole(this.Role.BUSINESS_SUPER_USER)) this.isSuperUser = true;
      
      this.requiredDocumentTypes = this.localStorageService.getDocumentTypes().filter(documentType => documentType.isSupplemental == false && documentType.isSubmittableAtLaterDate == true);    
      this.reviewsTable = document.getElementById("reviewsTable");    
      this.clearAllLink = $(".clear-all-link");
  
      this.previousLink = $(".previous-link");    
      this.nextLink = $(".next-link");
  
      this.reviewTypesDropdown = document.getElementById("reviewTypesDropdown");
      this.reviewMethodTypesDropdown = document.getElementById("reviewMethodTypesDropdown");
      this.assignedToDropdown = document.getElementById("assignedToDropdown");
      
      this.reviewTypes = this.localStorageService.getReviewTypes(); 
      this.reviewStatusTypes = this.localStorageService.getReviewStatusTypes();
      //JTI-4470: remove payment pending status filter for non super users to avoid CM actions on these reviews which may cause issues in MOVES
      if(!this.isSuperUser)
      {
        var paymentPendingStatusIndex = this.reviewStatusTypes.indexOf(this.reviewStatusTypes.find(x => x.name.toLowerCase() == "paymentpending"));
        if (paymentPendingStatusIndex > -1) {
          this.reviewStatusTypes.splice(paymentPendingStatusIndex, 1);
        }
      }

      this.reviewMethodTypes = this.localStorageService.getReviewMethodTypes();
      this.decisionTypes = this.localStorageService.getDecisionTypes();

      this.reviewStatusTypes?.forEach(status => {
        if (["confirmed", "cancelled"].includes(status.name.toLowerCase())) {
          this.inactiveReviewStatusTypes.push(status);
        } else {
        this.activeReviewStatusTypes.push(status)
        }      
      });

      //Custom logic to add decision status as part of "Complete" review status checkboxes
      // Exclude vary and confirm decision type checkbox
      var completeStatusIndex = this.reviewStatusTypes.indexOf(this.reviewStatusTypes.find(x => x.name.toLowerCase() == "complete"));
      this.decisionTypes?.filter(d => d.id != DecisionTypes.VaryAndConfirm && d.id != DecisionTypes.CancelledByRecipient)?.forEach((decision, index) => {
        decision.id += 1000; //To separate decision types from review status types
        decision.name = `${decision.name} / ${CommonMethods.renameDecisionByReviewTypeId(decision.name, ReviewTypes.ThirdPartySeizureReview)}`;
        this.reviewStatusTypes.splice(completeStatusIndex + index + 1, 0, decision);
      })
      
      this.route.queryParamMap.subscribe(paramMap => {
        setTimeout(() => {              
          this.initFilters(paramMap);
          this.setFilteredReviews(); 
        });
      });
    });
  }

  initFilters(paramMap: ParamMap) {
    paramMap.keys.forEach(key => {
      var value = paramMap.get(key);
      switch (key) {
        case "pageNumber":
          this.pageNumber = +value;
          break;
        case "pageSize":
          this.pageSize = +value;
          break;
        case "sortDirection":
          this.sortDirection = value;
          break;
        case "orderBy":
          this.orderBy = value;
          break;
        case "searchTerm":
          this.searchText = value;
          break;
        case "reviewTypeId":
          this.selectedReviewTypeId = +value;
          break;
        case "reviewMethodTypeId":
          this.selectedReviewMethodTypeId = +value;
          break;
        case "userId":
          this.selectedAdjudicatorId = +value;
          break;
        case "reviewStatusTypeIds":
          this.selectedReviewStatusTypeIds = value.split(',').map(x => +x);          
          break; 
        case "decisionTypeIds":
          this.selectedDecisionTypeIds = value.split(',').map(x => +x+1000);          
          break;
        case "checkRequiresRedaction":
          this.actionsChecked.push('requires redaction');
          break;
        case "checkPendingSubmissions":
          this.actionsChecked.push('pending submissions');
          break;
        case "checkRequiresCommunication":
          this.actionsChecked.push('contact');
          break;
        case "checkRequiresAction":
          this.actionsChecked.push('requires action');
          break;
      }
    });

    this.statusCheckboxesComponent.init(this.selectedReviewStatusTypeIds.concat(this.selectedDecisionTypeIds));
    this.actionCheckboxesComponent.init(this.actionsChecked);
  }

  private setFilteredReviews(): void {
    let reviewPagedSearch = new ReviewPagedSearch({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortDirection: this.sortDirection,
      orderBy: this.orderBy
    });

    if (this.selectedReviewTypeId > 0) {
      reviewPagedSearch.reviewTypeId = this.selectedReviewTypeId
    }

    if (this.selectedReviewMethodTypeId > 0) {
      reviewPagedSearch.reviewMethodTypeId = this.selectedReviewMethodTypeId;
    }

    if (this.selectedAdjudicatorId > 0) {
      reviewPagedSearch.userId = this.selectedAdjudicatorId;
    }

    if (this.selectedReviewStatusTypeIds.length > 0) {
      reviewPagedSearch.reviewStatusTypeIds = this.selectedReviewStatusTypeIds.join(",");
    }

    if (!this.isSuperUser) {
      reviewPagedSearch.excludeReviewStatusTypeIds = ReviewStatusTypes.PaymentPending.toString();
    }

    if (this.selectedDecisionTypeIds.length > 0) {

      if (this.selectedDecisionTypeIds.includes(1000 + DecisionTypes.Confirm)) {
        this.selectedDecisionTypeIds.push(1000 + DecisionTypes.VaryAndConfirm);
      }

      reviewPagedSearch.decisionTypeIds = Array.prototype.map.call(this.selectedDecisionTypeIds, function(item) { return item-1000 }).join(",");
    }
            
    if (this.searchText?.length > 0) {
      reviewPagedSearch.searchTerm = this.searchText.trim().toLowerCase();      
    }

    if (this.actionsChecked.includes("requires redaction")) {
      reviewPagedSearch.checkRequiresRedaction = true;      
    }

    if (this.actionsChecked.includes("pending submissions")) {
      reviewPagedSearch.checkPendingSubmissions = true;
    }

    if (this.actionsChecked.includes("contact")) {
      reviewPagedSearch.checkRequiresCommunication = true;
    }

    if (this.actionsChecked.includes("requires action")) {
      reviewPagedSearch.checkRequiresAction = true;
    }

    this.spinner.show();    

    var reviewPagedSearchCopy = Object.assign({}, reviewPagedSearch);
    if (reviewPagedSearchCopy.userId == null) {
      reviewPagedSearchCopy.userId = 0;
    }
    this.setWindowHistory(reviewPagedSearchCopy);

    this.adjudicationService.getReviewsByPagedSearch(reviewPagedSearch)
      .subscribe((result: PagedResult) => {        
        this.reviews = result.items;
        this.totalCount = result.totalCount;     
      
        this.filteredReviews = Array.from(this.reviews);
        this.setPageNumbers();
        this.spinner.hide();
      }, (error: any) => {
        this.spinner.hide();
      });

      
  }

  setPageNumbers(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    if (+this.totalPages === 0) {
      this.totalPages = 1;
      this.pageNumbers = [1];
      return;
    }

    let totalPageRanges = Math.ceil(this.totalPages / this.pageRange);        
    let totalPagesAsArray = Array(this.totalPages).fill(1).map((value: any, index: number) => value + index);
    let totalPageRangesAsArray = Array(totalPageRanges).fill(0).map((value: any, index: number) => value + index);    
    let pageRangeIndex = totalPageRangesAsArray.find((value: any, index: number) => this.pageNumber <= (value * this.pageRange) + this.pageRange);
    
    this.isLastRange = totalPageRangesAsArray.length - 1 == pageRangeIndex;
    this.pageNumbers = totalPagesAsArray.slice(pageRangeIndex * this.pageRange, (pageRangeIndex * this.pageRange) + this.pageRange);
  }
  
  onPreviousLinkClick(ev: any): void {  
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.pageNumber -= 1;    
    if (+this.pageNumber <= 1) {
      this.pageNumber = 1;
    }
     
    this.setFilteredReviews();
  }

  onNextLinkClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    this.pageNumber += 1;
  
    if (+this.pageNumber >= this.totalPages) {
      this.pageNumber = this.totalPages;     
    }            
    
    this.setFilteredReviews();
  }

  onPageNumberClick(ev: any, pageNumber: number): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }
        
    this.pageNumber = pageNumber;    
    this.setFilteredReviews();
  }

  private setWindowHistory(pagedSearch: ReviewPagedSearch): void {    
    const urlTree = this.router.createUrlTree(['/reviews'], { queryParams: pagedSearch });
    window.history.replaceState({}, '', this.urlSerializer.serialize(urlTree));
  }
 
  private clearFilters(): void {        
    this.selectedReviewTypeId = 0;
    this.selectedReviewMethodTypeId = 0;
    this.selectedAdjudicatorId = 0;
      
    this.selectedReviewStatusTypeIds  = [];
    this.searchText = "";
    this.pageNumber = 1;
    this.reviewTypesDropdown.selectedIndex = 0;
    this.reviewMethodTypesDropdown.selectedIndex = 0;
    this.assignedToDropdown.selectedIndex = 0;
    
    this.setFilteredReviews();
  }
  
  onReviewTypeChange(ev: any): void {
    this.selectedReviewTypeId = ev.target.selectedOptions[0].value;    
    this.pageNumber = 1;
    this.clearAllLink.removeClass("disabled");
    this.setFilteredReviews();    
  }

  onReviewMethodTypeChange(ev: any): void {
    this.selectedReviewMethodTypeId = ev.target.selectedOptions[0].value;    
    this.pageNumber = 1;
    this.clearAllLink.removeClass("disabled");
    this.setFilteredReviews();    
  }

  onAssignedToChange(ev: any): void {
    this.selectedAdjudicatorId = ev.target.selectedOptions[0].value; 
    this.pageNumber = 1;
    this.clearAllLink.removeClass("disabled");
    this.setFilteredReviews();  
  }

  onReviewStatusTypeChange(reviewStatusTypeIds: number[]): void {    
    this.selectedReviewStatusTypeIds = reviewStatusTypeIds.filter(x => x < 1000);
    this.selectedDecisionTypeIds = reviewStatusTypeIds.filter(x => x > 1000 && reviewStatusTypeIds.includes(ReviewStatusTypes.Complete));
    this.pageNumber = 1;
    this.clearAllLink.removeClass("disabled");
    this.setFilteredReviews();
  } 
  
  onSearchTextboxKeyDown(ev: KeyboardEvent): void {
    if (ev.key.toLowerCase() === "enter") {
      this.onSearchButtonClick();
    }
  }

  onSearchButtonClick(): void {  
    console.log("search button click...");
    this.pageNumber = 1;
    this.clearAllLink.removeClass("disabled");    
    this.setFilteredReviews();
  }
  
  onClearAllLinkClick(ev: any): void {
    this.statusCheckboxesComponent.clearAll();
    this.actionCheckboxesComponent.clearAll();

    if (ev.target.classList.contains("disabled")) {
      return;
    }

    this.clearFilters();

    ev.target.classList.add("disabled");
  }

  onSelectAllLinkClick(ev: any): void {
    this.statusCheckboxesComponent.selectAll();
    this.actionCheckboxesComponent.selectAll();
    
    this.clearAllLink.removeClass("disabled");  

    this.pageNumber = 1;

    this.setFilteredReviews();
  }

  onActionCheckboxesChange(actionsChecked: string[]): void {    
    this.actionsChecked = actionsChecked;
    this.clearAllLink.removeClass("disabled");
    this.pageNumber = 1;
    
    this.setFilteredReviews();
  }
    
  private toNumber(val: any, maxLength?: number): number {    
    let date = new Date(val);
    
    let number = 0;
        
    if (date != null && date.toString() != "Invalid Date") {      
      number = date.valueOf();
    } else if (+val >= 0) {
      number = +val;    
    } else {              
      for (let index = 0; index < maxLength; index++) {
        number += val.charCodeAt(index);            
      }
    }
    
    return number;
  }
      
  onSortIconContainerClick(propertyName: string): void {
    const sortIconContainer = $(`.sort-icon-container.${propertyName}`);    
    const sortIconContainerFirstChild = sortIconContainer.children(":first-child");
    const sortIconContainerLastChild = sortIconContainer.children(":last-child");
        
    if (sortIconContainerFirstChild.hasClass("selected")) {
      // ascending
      sortIconContainerFirstChild.removeClass("selected");
      sortIconContainerLastChild.addClass("selected");
    } else if (sortIconContainerLastChild.hasClass("selected")) {
      // descending
      sortIconContainerLastChild.removeClass("selected")
    } else {
      // default
      sortIconContainerFirstChild.addClass("selected");      
    }
    
    if ($(".sort-icon-container > .selected").length > 0) {
      this.sortReviews();      
    } else {
      this.setFilteredReviews();
    }
    
  }

  private sortReviews(): void {
    $(".sort-icon-container > .selected").each((index: number, element: HTMLElement) => {
      this.filteredReviews.sort((a: ReviewView, b: ReviewView) => {

        let propertyName = element.getAttribute("propertyName");
        let sort = element.getAttribute("sort").toLowerCase();
        let first = Date.parse(a[propertyName]);
        let second = Date.parse(b[propertyName]);

        if (sort == "ascending") {
          if (!isNaN(first) && !isNaN(second)) {
            return first - second;
          } else {
            if (a[propertyName] > b[propertyName]) return 1;
            if (a[propertyName] < b[propertyName]) return -1;
          }

        } else if (sort == "descending") {
          if (!isNaN(first) && !isNaN(second)) {
            return second - first;
          } else {
            if (a[propertyName] > b[propertyName]) return -1;
            if (a[propertyName] < b[propertyName]) return 1;
          }
        }
                                                        
        return 0;
      });
    });         
  }
    
  onExportListClick(ev: any): void {        
    this.spinner.show();
    setTimeout(() => {
      let element = $(".loading-text > p");      
      element.html(`<span style='font-size: 17px !important;'>Please be patient while APIS generates your report</span>`); 
    }, 100);

    this.adjudicationService.downloadReviewsCSVExport()
    .subscribe((result: HttpEvent<Blob>) => {       
      if (result.type == HttpEventType.Response) {
        this.spinner.hide();
        fileSaver.saveAs(result.body, "reviews.csv");      
      }        
    }, (error: any) => this.spinner.hide());     
  }  
  
  getReviewStatusTypeCssClass(reviewStatusTypeId: number): string {
    let reviewStatus = this.reviewStatusTypes.find(type => +type.id === +reviewStatusTypeId);
    return reviewStatus?.name.trim().toLowerCase().replace(" ", "-")
  }  

  onSortIconClick(propertyName: string): void {  
    let column = this.columns.find(c => c.propertyName == propertyName);    
    column.ascending = !column.ascending;

    this.sortDirection = "desc";
    if (column.ascending) {
      this.sortDirection = "asc";
    }

    this.orderBy = propertyName;
    
    this.setFilteredReviews();        
  }  

  onPageSizeChange(ev: any): void {
    let target = ev.target as HTMLSelectElement;    
    this.pageSize = parseInt(target.selectedOptions[0].value);
    this.pageNumber = 1;

    this.setFilteredReviews();
  }
}