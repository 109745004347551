<div class="modal-overlay modal-show">
    <div class="availability-modal">
        <div class="modal-header">
            <div class="modal-title">Applicant Availability</div>
            <div class="close-icon" (click)="onCloseClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-body">
            <app-scheduler 
                [periodStartDate]="reviewPeriodStartDate" 
                [periodEndDate]="reviewPeriodEndDate" 
                [(preferredTimes)]="review.reviewScheduleRequest.availabilityTimeSlots"></app-scheduler>
        </div>
        <div class="button-container">
            <div id="closeButton" class="close-button" (click)="onCloseClick()">Close</div>            
        </div>
    </div>
</div>
