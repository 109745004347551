<div class="modal-overlay">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-container-title">                
                <span>Upload </span>
                {{document == null? "" : getDocumentTypeName(document)}}
            </div>
            <div class="close-icon" (click)="onCancelClick($event)">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="drop-zone" (click)="uploadFile.click()">
            <input style="display: none;" type="file" (change)="onUploadFileChanged($event)" #uploadFile>    
            <div><i class="fas fa-upload fa-lg"></i></div>
            Drag and drop or click to upload
        </div>
        <div class="upload-container">
            <div class="d-flex flex-column">
                <div class="documentTypeName">{{documentType}}</div>
                <a class="download-file-link mt-2" (click)="onDownloadDocumentClick()">{{fileName}}</a>
            </div>
            <div class="upload-bar">
                <div class="upload-bar-initializing">Please wait...</div>
                <div class="upload-bar-progress">{{uploadProgress}}</div>
            </div>
            <div class="remove-file-link disabled" (click)="onRemoveFileClick($event)">Remove File</div>
        </div>   
        <div class="publish-disclaimer">
            <div class="alert-icon">                
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="publish-text">
                Publishing this file will make it visible to the recipient. This action cannot be undone.<br />Are you sure?
            </div>            
        </div>    
        <div *ngIf="errorMessage.length > 0">
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="d-flex justify-content-end">
            <div class="cancel-button" (click)="onCancelClick($event)" id="cancelButton">Cancel</div>
            <div id="publishButton" class="publish-button disabled" (click)="onPublishClick($event)">Publish</div>
        </div>

    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
</div>

