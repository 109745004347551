


export class Schedule {
    public scheduleId: number = 0;
    public userId: number = 0;
    public meetingTypeId: number = 0;
    public frequency: number = 0;
    public startTime: string;
    public endTime: string;
    public workDays: string;
    public effectiveDate: Date;
    public expiryDate: Date;
    public maxBookingsPerDay: number = 0;

    constructor(init?: Partial<Schedule>) {
        
        Object.assign(this, init);
    }
}
