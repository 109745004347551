import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../../../../../../libs/shared/services/local-storage.service";

@Pipe({
    name: "reviewMethodType"
})
export class ReviewMethodTypePipe implements PipeTransform {
    constructor(private readonly localStorageService: LocalStorageService) {}

    transform(val: number): string {
        let reviewMethodType = this.localStorageService.getReviewMethodTypes().find(type => +type.id === +val);
        
        if (reviewMethodType != null) {
            return reviewMethodType.name;
        }

        return "";
    }
}