<div class="d-flex flex-column h-100">  
  <adjudication-header></adjudication-header>
  <div class="d-flex flex-fill">
    <side-nav></side-nav>
    <div class="w-100">        
        <router-outlet></router-outlet>
    </div>
  </div>
  <adjudication-footer></adjudication-footer>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>
  <ngx-spinner name="downloadDocumentSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Loading... </p></ngx-spinner>
</div>