<div class="d-flex flex-column h-100">
    <div class="header">
        <div class="top-header">
            <div class="d-flex">Judicial Review {{judicialReviewNumber}}</div>
        </div>
    </div>
    <div class="main">
        <div class="summary-section" *ngIf="judicialReview != null">
            <div class="summary-header">Summary</div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">{{judicialReview.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview ? "Recipient" : "Applicant"}}</div>
                        <div class="summary-item-value">{{applicantName}}</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/contravention-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Review Type</div>
                        <div class="summary-item-value">
                            <div>{{reviewTypeName}}</div>
                            <a *ngIf="judicialReview?.review != null"
                                [routerLink]="['/review', judicialReview?.review?.reviewNumber]">Review
                                {{judicialReview?.review?.reviewNumber}}</a>
                            <br>
                            <a *ngIf="reviewItems[0]?.contraventionId != null"
                                [routerLink]="['/contravention', reviewItems[0]?.recordNumber]">Contravention 
                                {{reviewItems[0]?.recordNumber}}</a>                            
                            <a *ngIf="reviewItems[0]?.vehicleSeizureId != null"
                                [routerLink]="['/seizure', reviewItems[0]?.recordNumber]">Seizure 
                                {{reviewItems[0]?.recordNumber}}</a>       
                        </div>
                        <div class="summary-item-value" *ngIf="judicialReview?.reReviewNumber">
                            <div>Re-Review</div>
                            <a [routerLink]="['/review', judicialReview?.reReviewNumber]">Review
                                {{judicialReview?.reReviewNumber}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Terms & Conditions Version</div>
                        <div class="summary-item-value">{{judicialReview?.termsAndConditionsDate | date:'mediumDate'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="summary-section-line"></div>

            <div class="recent-activity-summary-header">
                Recent Activity
                <div class="d-flex justify-content-end align-items-center">
                    <div class="add-note-icon">
                        <i class="fas fa-plus-circle"></i>
                    </div>
                    <div class="add-note-link" (click)="onAddNoteLinkClick()">Add a note</div>
                </div>
            </div>
            <div class="form-group recent-activity-group">
                <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
                    <div class="recent-activity-connector"></div>
                    <div *ngFor="let recentActivity of recentActivities; let i=index;"
                        class="recent-activity-container">
                        <div class="recent-activity-dot" [ngClass]="{ 'recent-activity-dot-submitted': i==0}"></div>
                        <div class="recent-activity-header">
                            <div>{{recentActivity.date}}</div>
                            <div>by {{recentActivity.by}}</div>
                        </div>
                        <div class="recent-activity-title">{{recentActivity.title}}</div>
                        <div *ngIf="recentActivity.body != null" class="recent-activity-content">{{recentActivity.body}}
                        </div>
                        <ng-container *ngIf="recentActivity.documents?.length > 0">
                            <div class="recent-activity-document" *ngFor="let document of recentActivity.documents">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onEventDocumentNameClick(document)"
                                    class="download-file-link">{{document.documentName}}</a>
                            </div>
                        </ng-container>   
                        <div *ngIf="canModifyNote(recentActivity)">
                            <div class="recent-activity-edit">
                                <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                                <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                            </div>
                        </div>                     
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="tabs-container">
            <div class="tabs">
                <div class="tab-header" (click)="onTabHeaderClick($event)">
                    <div id="tabHeader_Review" class="tab-header-item selected">Review</div>                    
                    <div id="tabHeader_ReviewActivity" class="tab-header-item" (click)="reviewActivityTabSelected()">
                        Review Activity
                    </div>                    
                    <div class="tab-header-item-fill"></div>
                </div>
                <div class="tab-content">
                    <div id="tab_Review" class="tab-content-item selected">
                        <judicial-review [judicialReview]="judicialReview"
                            (judicialReviewUpdated)="refreshJudicialReview($event)"></judicial-review>
                    </div>
                    <div id="tab_ReviewActivity" class="tab-content-item">
                        <judicial-review-activity #judicialReviewActivity [judicialReview]="judicialReview"
                            (activityNoteSubmitted)="refreshJudicialReview($event)"></judicial-review-activity>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>