

export class ReviewSchedule {
    public reviewId: number;
    public reviewDate: Date;
    public reviewTime: string;
    public userId: number;

    constructor(init?: Partial<ReviewSchedule>) {
        Object.assign(this, init);
    }
}