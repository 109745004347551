import { Component, OnInit, EventEmitter } from '@angular/core';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from "file-saver";
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript';
import { KeycloakService } from 'keycloak-angular';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DocumentUploadRequest } from '@apis/shared/models/document-upload-request.model';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-upload-redacted-modal',
  templateUrl: './upload-redacted-modal.component.html',
  styleUrls: ['./upload-redacted-modal.component.scss']
})
export class UploadRedactedModalComponent implements OnInit {
  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  document: Document;
  redactedDocument: Document;
  close: EventEmitter<Document[]> = new EventEmitter<Document[]>();

  body: JQuery<HTMLElement>;     
  dropZone: JQuery<HTMLElement>;
 
  uploadContainer: JQuery<HTMLElement>;
  publishDisclaimer: JQuery<HTMLElement>;
  publishButton: JQuery<HTMLElement>;
  removeFileLink: JQuery<HTMLElement>;
  downloadFileLink: JQuery<HTMLElement>;
  redactedFileName: string = "";
  redactedDocumentType: string = "";
  uploadProgress: string = "";
  modalOverlay: JQuery<HTMLElement>;  

  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  errorMessage: string = "";

  constructor(private readonly documentService: DocumentService,
    private readonly localStorageService: LocalStorageService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {    
    if (this.tempFileFolder == null) {
      this.tempFileFolder = Guid.create().toString();
    }

    this.dropZone = $(".drop-zone");
    this.publishButton = $(".publish-button");
    this.removeFileLink = $(".remove-file-link");
    this.downloadFileLink = $(".download-file-link");
    this.modalOverlay = $(".modal-overlay");
    this.uploadContainer = $(".upload-container"); 
    this.publishDisclaimer = $(".publish-disclaimer");
    
    this.body = $(document.body);
    this.body.addClass("overflow-hidden");

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName: string) => {
      document.body[`on${eventName}`] = this.preventDefaults;
      this.dropZone.on(eventName, this.preventDefaults);      
    });

    ["dragenter", "dragover"].forEach(eventName => {
      this.dropZone.on(eventName, (ev: any) => this.dropZone.addClass("highlight"));      
    });
    
    ["dragleave", "drop"].forEach(eventName => {
      this.dropZone.on(eventName, (ev: any) => this.dropZone.removeClass("highlight"));      
    });
                
    this.dropZone[0].ondrop = (ev: any) => this.handleDrop(ev);
  }

  private preventDefaults(ev: any): void {    
    ev.preventDefault();
    ev.stopPropagation();    
  }

  private handleDrop(ev: any): void {       
    let files = ev.dataTransfer.files;
    
    for (let index = 0; index < files.length; index++) {
      let file = files[index];
      this.uploadFile(file);
    }        
  }

  onDownloadRedactedDocumentClick(){    
    var storageFileName = `${this.redactedDocument.contentGuid}.${this.redactedDocument.documentExtension}`;
    this.documentService.downloadDocument(this.tempFileFolder, null, null, storageFileName, this.redactedDocument.documentName)
      .subscribe((result: Blob) => {
        if (result) { 
          fileSaver.saveAs(result, this.redactedDocument.documentName);
        }
      });
  }
   
  onCloseIconClick(ev: any): void { 
    this.errorMessage = "";  
    if (this.redactedDocument != null) {
      this.onRemoveFileClick(ev);
    } 

    this.close.emit();
    this.hideModal();    
  }

  onCancelClick(ev: any): void { 
    this.errorMessage = "";  
    if (this.redactedDocument != null) {
      this.onRemoveFileClick(ev);
    }

    this.close.emit();
    this.hideModal();
  }

  getDocumentTypeName(document: Document): string {
    var documentTypeName = `${this.localStorageService.getDocumentTypes().find(x => x.id == document.documentTypeId)?.name}${(document.documentDescription == null || document.documentDescription =="")? "": " " +  document.documentDescription}`;
    return documentTypeName;
  }

  private uploadFile(file: File): void {
    this.redactedDocumentType = this.getDocumentTypeName(this.document).replace(/original/i, "redacted");
    this.dropZone.hide();
    this.uploadContainer.css("display", "flex");
    this.publishDisclaimer.css("display", "flex");

    let lastIndex = file.name.lastIndexOf(".");
    let extension = file.name.substring(lastIndex);    
    this.redactedFileName = file.name; 

    let uploadBarInitializing = $(".upload-bar-initializing");
    let uploadBarProgress = $(".upload-bar-progress");
    uploadBarInitializing.css("display", "flex");
    uploadBarProgress.css("display", "none");

    var newFileName = Guid.create().toString();
        
    this.documentService.uploadDocumentAsync(file, this.tempFileFolder, newFileName)
      .subscribe((result: any) => {                
        if (+result.type === 1) {         
            uploadBarInitializing.css("display", "none");
            uploadBarProgress.css("display", "flex");
  
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);
            this.uploadProgress = `${percentage}%`;
            uploadBarProgress.css("width", this.uploadProgress);
         
        } else if (+result.type === 4) {         
          this.uploadProgress = "Upload Complete";
          
          this.publishButton.removeClass("disabled");
          this.removeFileLink.removeClass("disabled");

          //Prepare redacted document object
          this.redactedDocument = new Document({
            contentGuid: newFileName,
            stopInformationId: this.document.stopInformationId,
            contraventionId: this.document.contraventionId,
            reviewId: this.document.reviewId,
            vehicleSeizureId: this.document.vehicleSeizureId,
            documentDescription: "(Redacted)",
            documentExtension: extension.replace(".", ""),
            documentName: this.redactedFileName,
            documentSize: CommonUtil.getDocumentSize(file),
            documentTypeId: this.document.documentTypeId,
            isPublished: true,
            isSubmitLater: false,
            uploadedBy: this.document.uploadedBy
          });       
        }            
      });
  }

  onPublishClick(ev: any): void {
    this.errorMessage = "";
    if (ev.target.classList.contains("disabled")) {
      return;
    }

    this.document.isPublished = false;
      
    //Call update document method for original document
    this.spinner.show(); 
    this.document.documentDescription = "(Original)";

    this.documentService.updateDocumentAsync(this.document)
      .subscribe((response: any) => {          
        //Call update document method for redacted document
        this.redactedDocument.uploadedDate = this.document.uploadedDate;
        this.redactedDocument.originalDocumentId = this.document.documentId;
        if (this.contravention?.contraventionId != null) {
          this.documentRefTypeName = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
          this.documentRefTypeNumber = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 
            ? this.contravention.contraventionNumber 
            : this.contravention.stopInformationId.toString();
        } else if (this.vehicleSeizure?.contraventionId != null) {
          this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
          this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
            ? this.vehicleSeizure.contraventionNumber
            : this.vehicleSeizure.stopInformationId.toString();
        } else if (this.vehicleSeizure?.vehicleSeizureId != null) {
          this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "VehicleSeizures" : "Stop"; 
          this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
            ? this.vehicleSeizure.seizureNumber
            : this.vehicleSeizure.stopInformationId.toString();
        }
    
        //Call update api for document update
        var documentUploadRequest = new DocumentUploadRequest();
        documentUploadRequest.documents = [this.redactedDocument];
        documentUploadRequest.tempFolderName = this.tempFileFolder;
        documentUploadRequest.documentRefTypeName = this.documentRefTypeName;
        documentUploadRequest.documentRefTypeNumber = this.documentRefTypeNumber;
    
        this.documentService.updateAndFinalizeDocumentsAsync(documentUploadRequest)
          .subscribe((result: any) => {
            this.spinner.hide(); 
            if (result && result.length > 0)
              this.close.emit([this.document, result[0]]);
            this.hideModal();        
          },
          (error: any) => {
            this.errorMessage = "Unable to publish the document. Please try later."; 
            this.spinner.hide(); 
          });
      },
      (error: any) => {          
        this.errorMessage = "Unable to publish the document. Please try later."; 
        this.spinner.hide(); 
      });    
  }

  private hideModal() {        
    this.body.removeClass("overflow-hidden");    
  }

  onUploadFileChanged(ev: any): void {
    var fileList = (ev.target.files as FileList);
    
    for (let index = 0; index < fileList.length; index++) {
      let file = fileList[index];
      this.uploadFile(file);
    }   
  }  

  onRemoveFileClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    var fileName = `${this.redactedDocument.contentGuid}.${this.redactedDocument.documentExtension}`;

    this.documentService.deleteDocument(fileName, this.tempFileFolder)
      .subscribe((result: any) => {        
        this.publishButton.addClass("disabled");
        this.removeFileLink.addClass("disabled");
        
        this.redactedDocument = null;
        this.uploadContainer.hide();
        this.publishDisclaimer.hide();
        this.dropZone.css("display", "flex");
      });
  }

}